import { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { addDepartment, updateDepartment, checkCode } from '../../services/DepartmentsService';
import { Alert, AlertTitle } from '@material-ui/lab';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import IconButton from '@material-ui/core/IconButton';
import SaveChangesIcon from '@mui/icons-material/SaveAsOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { formControlLabelClasses } from '@mui/material';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    backgroundColor: 'paper',
  },
  dialog: {
    width: '400px',
    display: 'flex'
  },
  dialogBox: {
    display: 'flex',
    alignItems: 'center'
  },
  dialogText: {
    flex: '0.4'
  },
  dialogInput: {
    flex: '0.5'
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(2),
  },
}));

function DepartmentDialog(props) {
  const { openDialog, openEdit, selectedItems, text, updateTable, onDialogClose } = props;
  const [alertBox, setAlertBox] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [description, setDescription] = useState("");
  const [code, setCode] = useState("");
  const { t } = useTranslation();

  const handleDepartmentNameChange = (event) => {
    setDepartmentName(event.target.value)
  }
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  useEffect(() => {
    if(openEdit){
      setDepartmentName(selectedItems.name);
      setDescription(selectedItems.description);
      setCode(selectedItems.code);
    }
  }, [openEdit]);

  const clearFields = () => {
    setDepartmentName("");
    setDescription("");
    setCode("");
    setAlertBox("");
  };

  const handleAlertBox = (messages) => {
    setAlertBox(<Alert severity="warning">
      <AlertTitle>Could not create Department</AlertTitle>
      {messages.map((message, index) => {
        return <li key={index}>{message}</li>
      })}
    </Alert>)
  };

  const handleSave = async () => {
    let alertMessages = [];
    
    if (departmentName.length === 0) alertMessages.push("provide a Department name");
    if (code.length === 0) alertMessages.push("provide a Department code");

    if (openEdit) {
      if(await checkCode(selectedItems.id, code, "Department")) {
        alertMessages.push("Department code must be unique");
      } 
    }

    if (!openEdit) {
      if(await checkCode(null, code, "Department")) {
        alertMessages.push("Department code must be unique");
      }
    }

    if (alertMessages.length > 0) handleAlertBox(alertMessages);
    
    else {
      let res;
      if(openEdit) res = await updateDepartment(departmentName, description, code, selectedItems.id);
      else res = await addDepartment(departmentName, description, code)
      updateTable();
      onDialogClose(res);
      clearFields();
    }
  };

  const handleClose = () => {
    updateTable();
    onDialogClose();
    clearFields();
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Dialog open={openDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
        <DialogTitle id="form-dialog-title">{(openEdit ? t("edit_label") : t("add"))} {t('department_label')} </DialogTitle>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
          {alertBox}
          <div className={classes.dialogBox} required>
            <Typography variant="body1" className={classes.dialogText}>
              {t("department_name_field_label")}*
            </Typography>
            <TextField value={departmentName} onChange={handleDepartmentNameChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required />
          </div>
          <div className={classes.dialogBox} required>
            <Typography variant="body1" className={classes.dialogText}>
              {t("description_field_label")}
            </Typography>
            <TextField value={description} onChange={handleDescriptionChange} className={classes.dialogInput} placeholder={t("enter_description_placeholder")} autoFocus multiline rows={3} margin="dense" id="description" type="input" variant="outlined" required />
          </div>
          <div className={classes.dialogBox}>
            <Typography variant="body1" className={classes.dialogText}>
              {t("department_code_label")}*
            </Typography>
            <TextField value={code} onChange={handleCodeChange} className={classes.dialogInput} style={{ maxWidth: '20%' }} autoFocus margin="dense" id="code" type="input" variant="outlined" required />
          </div>
        </DialogContent>
        <DialogActions>
          <Tooltip title="Save">
            <IconButton onClick={handleSave} color="primary"> 
              {openEdit ? <SaveChangesIcon /> : <SaveIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title={t("Cancel")}>
            <IconButton onClick={handleClose}> 
              <CancelIcon/>
            </IconButton>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DepartmentDialog
