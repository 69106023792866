
import { Dialog, DialogTitle, DialogActions, DialogContentText, TextField, Button, DialogContent, ListItemText, Typography, Divider, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import Box from "@material-ui/core/Box";




function ViewProfileJobDialog(props) {
    const { openDialog, onDialogClose, selectedRow } = props
    const [alertBox, setAlertBox] = useState("");

    const handleClose = () => {
        onDialogClose();
    };

    return (

        <div>
            <Dialog open={openDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth="xl" minHeight="xl">
                <DialogTitle id="alert-dialog-title">Profile Summary</DialogTitle>
                {/* NOTE: selectedRow && -> checks if selectedRow is defined before rendering the data */}
                {selectedRow  && (
                    <DialogContent>
                        {alertBox}
                            {/* NOTE: selectedRow.contents? -> the question mark checks if the array is empty/exists */}
                            <Box display="flex" flexDirection="row" justifyContent="space-around">
                                <div>
                                    <Typography><strong>Name:</strong> {selectedRow.name}</Typography>
                                </div>
                                <div>
                                    <Typography><strong>Number of Samples:</strong> {selectedRow.numberOfSamples}</Typography>
                                </div>
                                <div>
                                    <Typography><strong>Package Type:</strong> {selectedRow.category.name}</Typography>
                                </div>
                            </Box>
                            <br />
                            <br />
                            <Box>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align='center'><strong>Selected Test Methods</strong></TableCell>
                                            <TableCell align='center'><strong>Sample Range</strong></TableCell>
                                            <TableCell align='center'><strong>Selected Tests</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedRow.contents.map((tm) => {
                                            return tm.selected && 
                                                <TableRow>
                                                    <TableCell align='center'>
                                                        {tm.testMethod.name}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        {tm.sampleStart} - {tm.sampleEnd}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        {tm.tests.map((test) => {
                                                            return <div>
                                                                {test.name}
                                                            </div>
                                                        })}
                                                    </TableCell>
                                                    
                                                </TableRow>                                               
                                        })}        
                                    </TableBody>
                                </Table>
                            </Box>
                            
                    </DialogContent>
                )}
                <DialogActions>
                    <Tooltip title="Close">
                        <IconButton onClick={handleClose}>
                            <CancelIcon />
                        </IconButton>
                    </Tooltip>
                </DialogActions>
            </Dialog>
        </div>
    );
}


export default ViewProfileJobDialog
