import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Departments from './Departments';
import SampleTypes from './SampleTypes';
import TestMethods from './TestMethods';
import Personnel from './Personnel'
import Equipment from './Equipment';
import Clients from './Clients';
import Category from './Category';
import JobProfilesTable from './JobProfilesTable';
import ProfileJobs from './ProfileJobs';
import { useTranslation } from 'react-i18next';
import AnalysisTemplate from './AnalysisTemplate';
import JobReasons from './JobReasons';

function TabPanel({children, value, index, text, section, ...other }) {
  const { t } = useTranslation();
  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`lab-tabpanel-${index}`}
          aria-labelledby={`lab-tab-${index}`}
          {...other}
          textalign='center'
      >
          {value === index && (
              <Box pt={2} minHeight='400px'>
                  {(index === 0 && section === "sample") && <Category text={text} />}
                  {(index === 1 && section === "sample") && <SampleTypes text={text} />}
                  {(index === 2 && section === "sample") && <JobReasons text={text} />}

                  {(index === 0 && section === "testing") && <TestMethods text={text} />}
                  {(index === 1 && section === "testing") && <ProfileJobs text={text}/>}

                  {(index === 0 && section === "lab") && <Departments text={text} />}
                  {(index === 1 && section === "lab") && <Equipment text={text} />}
                  {(index === 2 && section === "lab") && <Personnel text={text} />}
                  {(index === 3 && section === "lab") && <Clients text={text} />}
              </Box>
          )}
      </div>
  );
}

export default TabPanel


//{ (index === 2 && section === "testing") && <JobProfilesTable /> }
//{(index === 1 && section === "testing") && <AnalysisTemplate text={text} />}