import HttpService from "./HttpService";
const axios = HttpService.getAxiosClient();
const methods = HttpService.HttpMethods;
const baseURL = process.env.REACT_APP_BACKEND_URL;
const rootRequestMapping = "test";



const getTests = async () => {
    let language = localStorage.getItem("language");
    try {
        const res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/${language}`,
            method: methods.GET,
        });
        return res.data;
    } catch (err) {
        console.log(err);
        return null;
    }
};

export const updateSequence = async (testId, newSeq) => {
    let language = localStorage.getItem("language");
    try {
        const res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/swap/${testId}/${newSeq}/${language}`,
            method: methods.GET,
        });
        return res.data;
    }
    catch (err) {
        console.log(err);
        return null;
    }
}

export const setSequence = async (testIds) => {
    try {
        const res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/setsequence`,
            method: methods.POST,
            data: testIds
        });
        return res.data;
    }
    catch (err) {
        console.log(err);
        return null;
    }
}

export const resetSequence = async (tests) => {
    try {
        const res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/resetSequence`,
            method: methods.POST,
            data: tests
        });
      
        return res.data;
    }
    catch (err) {
        console.log(err);
        return tests;
    }

}

const getTestsByTestParent = async (testParent) => {
    let language = localStorage.getItem("language");
    try {
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/tests/${language}`,
            method: methods.POST,
            data: testParent,
        });

        return res.data;
    } catch (err) {
        console.log(err);
        return null;
    }
}

const addTest = async (
    addingToRow,
    id,
    testName,
    description,
    testDataType,
    unit,
    testId,
    equipments,
    fileHeader,
    calculationKey,
    calculationSeq,
    isPass,
    testMethodId,
    replica,
    decimalPlaces,
    testNameFrench,
    testNameSpanish,
    testNameItalian,
    testNameArabic,
    testNameRussian, 
    testNameEnglish) => {

    let names = getNames(equipments)
    let language = localStorage.getItem("language");

    try {
        let data = {
            name: testName,
            description,
            testDataType,
            unit,
            testId,
            equipments: equipments,
            equipmentName: names[0],
            fileHeader,
            calculationKey,
            orderInCalculation: calculationSeq === -1 ? null : calculationSeq,
            pass: isPass,
            testMethodId,
            replica,
            decimalPlaces,
            nameEnglish: testNameEnglish,
            nameFrench: testNameFrench,
            nameSpanish: testNameSpanish,
            nameItalian: testNameItalian,
            nameArabic: testNameArabic,
            nameRussian: testNameRussian
        };

        if (id != null) data["id"] = id;
        if (addingToRow != null) {
            if (id == null) {
                console.log(addingToRow);
                data['parent'] = addingToRow;
                if (addingToRow.testId == null) {
                    console.log("CHILD TEST ");
                    data['testMethodId'] = addingToRow.id;
                }
                else {
                    console.log("Grand CHILD TEST ");
                    data['parentTestId'] = addingToRow.id;
                }
            }
        }

        // enpoints corrected
        let res = await axios.request({
            url: id != null ? `${baseURL}${rootRequestMapping}/update/${language}` : `${baseURL}${rootRequestMapping}/save/${language}`,
            method: methods.POST,
            data: data,
        });

        return res;

    } catch (error) {
        console.error(error);
        return error;
    }
};

const checkCode = async (id, code, entity) => {

    try {
        let res = await axios.post(`${baseURL}uniquecheck/check`, {
            "id": id,
            "code": code,
            "entity": entity
        })
        console.log(res)
        return res.data;
    }

    catch (error) {
        console.error(error)
    }
}

const getNames = (equipments) => {
    let equipmentName = ""
    equipments.forEach((equip) => {
        if (equipments.indexOf(equip) === equipments.length - 1) {
            equipmentName += equip.name
        } else {
            equipmentName += equip.name + " + "
        }
    })

    let names = [equipmentName]
    return names
}

const getSampleTypeTestSpecifications = async (sampleTypeId) => {
    try {
        const res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/specs/${sampleTypeId}`,
            method: methods.GET,
        });

        return (res.status === 200) ? res.data : false;
    } catch (error) {
        return null;
    }
}

const updateSampleTypeTestSpecifications = async (testSpecifications) => {
    try {
        testSpecifications.forEach((ts) => {
            if (ts.na) {
                try {
                    delete ts.minValue;
                    delete ts.maxvalue;
                    delete ts.nominalValue;
                } catch (e) { }
            }
        })
        const res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/specssave`,
            method: methods.POST,
            data: testSpecifications
        });

        return res.data;
    } catch (error) {
        return null;
    }
}

const getUnits = async () => {
    try {
        const req = {
            url: `${baseURL}units/all`,
            method: methods.GET,
        };
        const res = await axios(req);
        return res.data;

    } catch (err) {
        return { req_error: err }
    }

}

const getTestByTestMethodId = async (id) => {
    let language = localStorage.getItem("language");
    try {
        const res = await axios.get(`${baseURL}${rootRequestMapping}/testsbymethodid/${id}/${language}`);
        return res.data;
    } catch (error) {
        return null;
    }
}

const getHistoricValuesByTestResultId = async (id) => {
    try {
        const res = await axios.get(`${baseURL}${rootRequestMapping}/historic/${id}`);
        return res.data;
    } catch (error) {
        return error;
    }
}

export {
    getTests,
    addTest,
    getTestsByTestParent,
    getSampleTypeTestSpecifications,
    updateSampleTypeTestSpecifications,
    getUnits,
    getTestByTestMethodId,
    checkCode,
    getHistoricValuesByTestResultId,
};
