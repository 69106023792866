

import HttpService from "./HttpService";



const axios = HttpService.getAxiosClient();
const HttpMethods = HttpService.HttpMethods;
const baseURL =  process.env.REACT_APP_BACKEND_URL;
const rootRequestMapping = "sampleType"

const getSampleTypes = async () => {
  try {
    let language = localStorage.getItem("language");
    const res = await axios.request({
      url: `${baseURL}${rootRequestMapping}/all/${language}`,
      method: HttpMethods.GET,
    });
     
    return (res.status === 200) ? res.data : false;
  } catch (error) {
    return null;
  }
}

const getSampleTypesWithCategoryId = async () => {
  let language = localStorage.getItem("language");
  try {
    const res = await axios.request({
        url: `${baseURL}${rootRequestMapping}/category/${language}`,
      method: HttpMethods.GET,
    });

    return (res.status === 200) ? res.data : false;
  } catch (error) {
    return null;
  }
}



const addSampleType = async (data) => {
  

    try {
      let res = await axios.request({
        url: `${baseURL}${rootRequestMapping}/save`,
        method: HttpMethods.POST,
        data: data
      });
      console.log(res)
      return res;
    }
    catch(error){
      return error;
    }
}

const addSampleTypeFromJob = async (data) => {
  try {
    let res = await axios.request({
      url: `${baseURL}${rootRequestMapping}/save`,
      method: HttpMethods.POST,
      data: data
    });
    console.log(res)
    return res;
  }
  catch(error){
    return error;
  }
}

const copySampleType = async(id, name, code) =>{
  let data = {
    "name": name,
    "code": code,
    "id": id
  }
  try {
    let res = await axios.request({
      url: `${baseURL}${rootRequestMapping}/copy`,
      method: HttpMethods.POST,
      data: data
    });
    return res;
  }
  catch(error){
    return error;
  }

}

const deleteSampleType = async id => {
  await axios.request({ url: `${baseURL}${rootRequestMapping}/delete/${id}`, method: HttpMethods.GET });
}


const getRegions = async () => {
    try {
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/regions`,
            method: HttpMethods.GET,
        })
        return res.data;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}

export { getSampleTypes, getSampleTypesWithCategoryId, addSampleType, deleteSampleType, copySampleType, addSampleTypeFromJob, getRegions }