import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ClippedDrawer from '../app_bar';
import AlertContextProvider from './Contexts/AlertContext/AlertContextProvider';
import SwitchRoutes from './Routes/SwitchRoutes';
import store from '../store/store';
import { Provider } from 'react-redux';

const Home = (props) => {

    return (
        <Provider store={store}>
        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
            <AlertContextProvider>
                <ClippedDrawer>
                    <SwitchRoutes />
                </ClippedDrawer>
            </AlertContextProvider>
            </BrowserRouter>
        </Provider>
    )
}

export default Home;