

import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles(theme => ({


    pageHeading: {
        display: "flex",
        alignItems: "center",
        // columnGap: "1rem",
    },
    title: {
        flex: "1 1",
        whiteSpace: "nowrap",
        marginBottom: "1rem"
    },
    tableHeader: {
        display: "flex",
        alignItems: "center",
        columnGap: "1rem",
        paddingTop: "1rem",
        overflow: "visible",
        marginBottom: "0.3rem",
    },
    lastUpdateText: {
        color: grey[600]
    },
    root: {
        //  width: "100%",

        marginBottom: theme.spacing(2),
    },
    paper: {
        marginBottom: theme.spacing(2),
    },
    table: {
        //  minWidth: 750,
        // width: "30%"
    },
    rowButton: {
        color: "black",
        background: "lightGrey",
        minWidth: 120
    },
    navButton1: {
        color: "black",
        background: "lightGrey",
        "&:hover": { backgroundColor: "lightGrey" },
        minWidth: 120,
        borderRadius: "5px",
        padding: "10px"
    },
    navButton2: {
        color: "black",
        background: "#ADCBE5",
        "&:hover": { backgroundColor: "#ADCBE5" },
        minWidth: 120,
        borderRadius: "5px",
        padding: "10px"
    },
    plink: {

        fontSize: "large",
        cursor: "pointer",
    },
    tabBar: {
        marginTop: "6px",
        backgroundColor: '#ADCBE5',
        color: 'black',
        borderRadius: "5px",
        boxShadow: "none",
        overflow: 'hidden',
        maxWidth: '350px',
        alignItems: 'center',
        marginLeft: "10px"
    }
}));