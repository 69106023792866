/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Box, makeStyles, Button, TextField, Typography, Divider, withStyles, Card} from '@material-ui/core';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { ListAlt } from "@material-ui/icons";
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import PrivateRoute from '../KeyCloakComponents/PrivateRoute';
import {NavLink } from "react-router-dom";
import Testing from './Testing';
import { getMeanCompletionTime, getTopReasons, getMostUsedTests, getRiskiestTests, getJobsOverview } from '../../services/DashboardService';
import DashboardJobs from '../DashboardJobs';
import DashboardHome from './DashboardHome';
import CircularProgress from '@mui/material/CircularProgress';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {  CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Bar } from "react-chartjs-2";
import { useTranslation } from 'react-i18next';
import UserService from "../../keycloak";
import  IDocQueueTable  from '../Testing/IDocQueueTable'
import AnalyticsPage from '../Analytics/AnalyticsPage';
import KPIDashboard from '../Analytics/KPIDashboard'
import ExcelLinkPage from '../Reports/ExcelLinkPage'

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title,);

const useStyles = makeStyles(theme =>({
    root: {
        marginBottom: theme.spacing(2),
    },
    pageHeading: {
        display: "flex",
        flex: 1,
        justifyContent: 'center',
        alignItems: "center",
        marginBottom:'2%'
    },
    title: {
        // flex: "1",
        whiteSpace: "nowrap",
        marginBottom: "1rem",
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center'
    },
    paper: {
        marginBottom: theme.spacing(2),
        padding:"15px"
    },
   
}));

function DashboardAnalutics(props){
    const classes = useStyles();

   

    return (
        //removed dense prop
        <DashboardAnalyticsTables history={props.history} classes={classes}/>
    )
}

export const DashboardAnalyticsTables= (props) => {
    const classes = props.classes;
    const { t, i18n } = useTranslation()
    const backgroundColor = [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ]
    const borderColor = [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ]
    //top reasons
    const topReasons = props.topReasons;
    const [topReasonshartData, setTopReasonsChartData] = useState({
        labels: [], datasets: []
        });

    const topReasonsChartOptions={
        plugins: {
            legend: { 
                display:false, 
                position: "bottom"
            },
            title: {
                display: true,
                text: t("Top Reasons for Submitting a Sample")
            },
            datalabels: {
                display: true,
                color: "#fff9c4",
            },
            tooltips: {
                backgroundColor:"#fff9c4",
            },
        }
    }

    useEffect(() => {
        if(topReasons){
            setTopReasonsChartData({
                labels: topReasons.map((reason) => reason.name), 
                datasets:[
                    {label: 'Packlims Reasons', 
                    data: topReasons.map((reason) => reason.value),
                    backgroundColor: backgroundColor,
                    borderColor: borderColor,
                    borderWidth: 1,
                  }
                ]
            });
        }
    }, [topReasons])

    //mostPopularTests
    const [mostPopularTestChartData, setMostPopularTestChartData] = useState({
        labels: [], datasets: []
    });
    const mostPopularTests = props.mostPopularTests;
    const mostPopularTestChartOptions={
        plugins: {
            legend: { 
                display:false, 
                position: "bottom"
            },
            title: {
                display: true,
                text: t("Top Ten Tests")
            },
            datalabels: {
                display: true,
                color: "#fff9c4",
            },
            tooltips: {
                backgroundColor:"#fff9c4",
            },
        }
    }

    useEffect(() => {
        if(mostPopularTests){
            const topTenTests = mostPopularTests.slice(0, 10);
            setMostPopularTestChartData({
                labels: topTenTests.map((test) => test.name),
                datasets:[
                    {label: 'dataset 1', 
                    data: topTenTests.map((test) => test.value),
                    backgroundColor: backgroundColor,
                    borderColor: borderColor,
                    borderWidth: 1,
                  }
                ]
            });
        }
    }, [mostPopularTests]);


    return (
        <div  className={classes.root} style={{display:'flex', justifyContent:'space-between'}}>
             <Card style={{ flex: '2', maxWidth:'49%', padding:"15px"}}>
                <Box mx={2} className={classes.pageHeading}>
                    <Typography className={classes.title} style={{fontWeight: 550, fontSize: "20px" }}>KPI</Typography>
                </Box>
             </Card>
             <Card style={{ flex: '1', maxWidth:'49%', padding:"15px"}}>
                <Box mx={2} className={classes.pageHeading}>
                    <Typography className={classes.title} style={{ fontWeight: 550, fontSize: "20px" }}>{t('Activity Overview')}</Typography>
                </Box>
                <div style={{ display:'flex', flex:'1', justifyContent: 'center', alignItems: "center",}}>
                    <Box style={{ flex:'1', maxWidth:'49%', border: "1px solid lightGrey", padding:'2%'}}>
                        <div style={{ justifyContent: 'center', alignItems: "center",}}>
                            {/* <div style={{flex:'1', justifyContent: 'center', alignItems: "center",}}>
                                <Typography style={{display:'flex', justifyContent: 'center', alignItems: "center", fontWeight: 550, fontSize: "15px", padding:'1%' }}>Top Reasons for Submitting a Sample</Typography>
                            </div> */}
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center",}}>
                                <Pie data={topReasonshartData} options={topReasonsChartOptions}/>
                            </div>
                        </div>
                    </Box>
                    <Box style={{ flex:'1', maxWidth:'49%', border: "1px solid lightGrey", padding:'2%'}}>
                        <div style={{ justifyContent: 'center', alignItems: "center",}}>
                            {/* <div style={{flex:'1', justifyContent: 'center', alignItems: "center",}}>
                                <Typography style={{display:'flex', justifyContent: 'center', alignItems: "center", fontWeight: 550, fontSize: "15px", padding:'1%' }}>Most Used Tests</Typography>
                            </div> */}
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center",}}>
                                <Pie data={mostPopularTestChartData} options={mostPopularTestChartOptions}/>
                            </div>
                        </div>
                    </Box>
                    {/* <Box style={{ flex:'1', maxWidth:'32%', border: "1px solid lightGrey", padding:'2%'}}>
                        <div style={{ justifyContent: 'center', alignItems: "center",}}>
                            <div style={{flex:'1', justifyContent: 'center', alignItems: "center",}}>
                                <Typography style={{display:'flex', justifyContent: 'center', alignItems: "center", fontWeight: 550, fontSize: "15px", padding:'1%' }}>Most Used Tests</Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center",}}>
                                <Pie data={mostPopularTestChartData} options={mostPopularTestChartOptions}/>
                            </div>
                        </div>
                    </Box> */}
                </div>
             </Card>
        </div>
    )
}

export default withRouter(DashboardAnalutics);