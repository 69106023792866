/* eslint-disable react-hooks/exhaustive-deps */

import { Pie } from 'react-chartjs-2';
import Paper from '@mui/material/Paper';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
// import { graphBorders, graphBackgrounds } from '../Colors'
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState} from 'react'

ChartJS.register(ArcElement, Tooltip, Legend)



const PieChart = (props) => {

    const { title, data } = props;
    const spinner = <span> Loading... <CircularProgress size="1rem" /></span>

    const noResultMessage = <span>No Results</span>

    const [noData, setNoData] = useState(spinner)

    const options = {
        plugins: { legend: { display: true } },
    }
   
  

    useEffect(() => {
        if (data && data.datasets.length === 0 && data.labels.length === 0) {
            setNoData(noResultMessage);
        }
        else {
            setNoData(spinner);
        }
        
      //  setTimeout(() => { setNoData("( No data )")}, 1000)
    }, [data])

    return  <Paper style={{ padding: "20px" }}>
        <div style={{marginBottom:"20px"}}><b>{title ? title: ""}</b></div>
        {data && data.labels.length > 0 ? <Pie className='analyticsChart' id={title} data={props.data} options={options} /> : noData}
    
    </Paper>
}
export default PieChart


