/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { getAnalyticsData } from '../../services/DashboardService'
import BarChart from './chartTypes/BarChart';
import { graphBorders, graphBackgrounds } from './Colors'
import { useTranslation } from 'react-i18next';
import ExportAnalytics from '../Export/ExportAnalytics';


const SampleTypesCharts = (props) => {
    const { t } = useTranslation();

    const { selectedFiltersString } = props;
    const [selectedFilters, setSelectedFilters] = useState(JSON.parse(selectedFiltersString));
    const [results, setResults] = useState(null);
    const [sampleTypeSizesData, setSampleTypeSizesData] = useState(null);
    const [sampleTypeCategoryData, setSampleTypeCategoryData] = useState(null);
    const [sampleTypeNeckFinishData, setSampleTypeNeckFinishData] = useState(null);
    const [sampleTypeDesignData, setSampleTypeDesignData] = useState(null);
    
   
    const analytics = [8, 9, 10, 11]

    useEffect(() => {
        console.log(selectedFiltersString)
        setSelectedFilters(JSON.parse(selectedFiltersString));
        
       
       // setChanged(!changed)
    }, [selectedFiltersString])

    useEffect(() => {
        setResults(null)
        var promises = analytics.map((i) => {
            return getAnalyticsData({
                analytic: i,
                category: selectedFilters.category,
                neckFinish: selectedFilters.neckFinish,
                design: selectedFilters.design,
                bottleSize: selectedFilters.bottleSize,
                supplier: selectedFilters.supplier,
                region: selectedFilters.region,
                dateFrom: selectedFilters.fromDate,
                dateTo: selectedFilters.toDate
            })
        })

        Promise.all(promises).then((res) => {
            // const byName = (a, b) => { return a.name < b.name ? -1 : 1 }
            // const byVal = (a, b) => { return a.value > b.value ? -1 : 1 }
            

            setResults({
                size: res[0],
                category: res[1],
                neckfinish: res[2],
                design: res[3]
            });

        });
        
    }, [selectedFilters])



    const getSizeDataset = () => {
        if (results && results.size) {
            const labels = results.size.map((r) => { return r.name })
            const values = results.size.map((r) => { return r.value })
            const data = {
                labels: labels,
                datasets: [
                    {
                        label: "Occurrences",
                        data: values,
                        backgroundColor: graphBackgrounds[0],
                        borderColor: graphBorders[0],
                        borderWidth: 1
                    }
                ]
            }
            setSampleTypeSizesData(data);
        }
        else setSampleTypeSizesData(null);

    }


    const getCategoryDataset = () => {
        if (results && results.category) {

            const labels = results.category.map((r) => { return r.name })
            const values = results.category.map((r) => { return r.value })
            const data = {
                labels: labels,
                datasets: [
                    {
                        label: "Occurrences",
                        data: values,
                        backgroundColor: graphBackgrounds[1],
                        borderColor: graphBorders[1],
                        borderWidth: 1
                    }
                ]
            }
            setSampleTypeCategoryData(data);
        }
        else setSampleTypeCategoryData(null);

    }


    const getNeckFinishDataset = () => {
        if (results && results.neckfinish) {

            const labels = results.neckfinish.map((r) => { return r.name })
            const values = results.neckfinish.map((r) => { return r.value })
            const data = {
                labels: labels,
                datasets: [
                    {
                        label: "Occurences",
                        data: values,
                        backgroundColor: graphBackgrounds[2],
                        borderColor: graphBorders[2],
                        borderWidth: 1
                    }
                ]
            }
            setSampleTypeNeckFinishData(data);
        }
        else setSampleTypeNeckFinishData(null);

    }


    const getDesignDataset = () => {
        if (results && results.design) {

            const labels = results.design.map((r) => { return r.name })
            const values = results.design.map((r) => { return r.value })
            const data = {
                labels: labels,
                datasets: [
                    {
                        label: "Occurences",
                        data: values,
                        backgroundColor: graphBackgrounds[3],
                        borderColor: graphBorders[3],
                        borderWidth: 1
                    }
                ]
            }
            setSampleTypeDesignData(data);
        }
        else setSampleTypeDesignData(null);
    }




    useEffect(() => {
        getSizeDataset();
        getCategoryDataset();
        getNeckFinishDataset();
        getDesignDataset();
    }, [results])


    return (<div>

        <ExportAnalytics selectedFilters={selectedFilters} title="Analytics Dashboard - Sample Types Charts"></ExportAnalytics>

        <div style={{ display: "flex", marginBottom: "30px" }}>
            <div style={{ flex: 1 }}>
                <BarChart title={t("Sample type size")} data={sampleTypeSizesData} axisLabels={{ x: "Size", y: "Occurrences" }} />
            </div>


            <div style={{ flex: 1 }}>
                <BarChart title={t("Number of Jobs per package type") + ":"} data={sampleTypeCategoryData} axisLabels={{ x: "Package Type", y: "Occurrences" }} />
            </div>

        </div>
        
        <div style={{ display: "flex", marginBottom: "30px" }}>
            <div style={{ flex: 1}}>
                <BarChart title={t("Sample type neck finish")} data={sampleTypeNeckFinishData} axisLabels={{ x: "Neck finish type", y: "Occurrences" }} />
            </div>



            <div style={{ flex: 1 }}>
                <BarChart title={t("Sample type design")} data={sampleTypeDesignData} axisLabels={{ x: "Sample type", y: "Occurrences" }} />
            </div>

        </div>
        
        {/*<pre>*/}
        {/*  {JSON.stringify(results, null, 2)}*/}
        {/*</pre>*/}


    </div>)

}
export default SampleTypesCharts;