import { makeStyles } from "@material-ui/core/styles";
import AnalysisTemplatesDialog from "../Dialogs/AnalysisTemplatesDialog";
import { useState, useContext } from "react";
import { LabSetUpTabContext } from "../Contexts/LabSetUpTabs/LabSetUpTabContext";
import { useTranslation } from "react-i18next";
import AnalysisTemplatesTable from "./AnalysisTemplatesTable";
import {  disableTemplate,  getAllTemplates, getAllTemplatesSetup } from "../../services/AnalysisTemplateService";
import { AlertContext } from "../Contexts/AlertContext/AlertContext";
import { Tooltip, Typography } from "@material-ui/core";
import EquipmentPopup from './EquipmentPopup'


const useStyles = makeStyles((theme) => ({
    tooltipText: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
}));

function AnalysisTemplate(props) {
    const { text } = props;
    const [rows, setRows] = useState([]);
    const { t } = useTranslation();
    const classes = useStyles();
    const [dense, setDense] = useContext(LabSetUpTabContext);
    const [alertContext, setAlertContext] = useContext(AlertContext);



    const [EquipmentPopupState, setEquipmentPopupState] = useState({
        shown: false,
        templateName: "",
        list: [],
        callback: () => { }
    })


    const getEquipmentBtn = (row) => {
        return <button onClick={() => {
            setEquipmentPopupState({
                shown: true,
                list: row.equipmentList.split(', '),
                templateName: row.templateName,
                callback: () => {
                    setEquipmentPopupState({
                        shown: false,
                        templateName: "",
                        list: [],
                        callback: () => { }
                    })
                }
            })
        }}>view</button>
    }


    // <tooltip title={row.equipmentList}>{row.equipmentList}</tooltip>
    const columns = [
        { selector: (row) => row.templateName, width:"300px",sortable: true, name: t("template_name_field_label"), type: "text", key: "templateName" },
        { selector: (row) => row.templateID, width: "200px",sortable: true, name: t("template_id_field_label"), type: "text", key: "templateID" },
        { selector: (row) => row.category.name, width: "200px",sortable: true, name: t("Category"), type: "text", key: "category" },
        { selector: (row) => "" + row.sampleCount, width: "200px",sortable: true, name: t("No of samples"), type: "text", key: "sampleCount",  },
        { selector: (row) => getEquipmentBtn(row), width: "200px",sortable: true, name: t("equipment_label"), type: "text", key: "equipmentList" },
        
    ];


    const updateTable = async (callback) => {
        let data = await getAllTemplatesSetup();
        if (!(data instanceof Array)) {
            setAlertContext({ ...alertContext, open: true });
            return;
        }


        setRows(data);
        console.log("templates", data);
        callback();
    };



    const handleDelete = async (selected) => {
        if (window.confirm('Are you sure you wish to delete ?')) {
            let ids = selected.map((item) => { return item.id })
            let promises = ids.map((id) => { return disableTemplate(id) })
            await Promise.all(promises)
        }
    }

    return (
        <>
            <AnalysisTemplatesTable
                dense={dense}
                keyColumn={{
                    key: "templateID",
                    name: t("template_id_field_label"),
                }}
                setDense={setDense}
                updateTable={updateTable}
                Dialog={AnalysisTemplatesDialog}
                rows={rows}
                columns={columns}
                text={text}
                deleteCallback={handleDelete} />

            <EquipmentPopup
                show={EquipmentPopupState.shown}
                templateName={EquipmentPopupState.templateName}
                list={EquipmentPopupState.list}
                callback={EquipmentPopupState.callback}
            />

        </>
    );
}

export default AnalysisTemplate;
