import HttpService from "./HttpService";
const axios = HttpService.getAxiosClient();
const HttpMethods = HttpService.HttpMethods;
const baseURL = process.env.REACT_APP_BACKEND_URL;
const rootRequestMapping = "jobreason";

const getReasons = async () => {
    try {
      let language = localStorage.getItem("language");
      const res = await axios.request({
        url: `${baseURL}${rootRequestMapping}/all/${language}`,
        method: HttpMethods.GET,
      });
  
      return (res.status === 200) ? res.data : false;
    } catch (error) {
      return null;
    }
  }

  const deleteReason = async (id) => {
    try {
      let res = await axios.request({
        url: `${baseURL}${rootRequestMapping}/delete/${id}`,
        method: HttpMethods.GET,
      })
    }
    catch (error) {
      console.error(error);
    }
    return false;
  }
  
  const addReason = async (reason, categories) => {
    try {
      let language = localStorage.getItem("language");
      let res = await axios.post(`${baseURL}${rootRequestMapping}/save/${language}`, {
        "name": reason,
        "categories": categories,
        "active": true
      })
      return res;
    }
  
    catch (error) {
      console.error(error)
      return error;
    }
  }
  
  const updateReason = async (reason, categories, id) => {
    try {
      let language = localStorage.getItem("language");
      let res = await axios.post(`${baseURL}${rootRequestMapping}/save/${language}`, {
        "name": reason,
        "categories": categories,
        "id": id
      })
      return res;
    }
  
    catch (error) {
      console.error(error)
      return error;
    }
  }

  export { getReasons, addReason, updateReason, deleteReason }