import { Box, makeStyles } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Breadcrumbs } from '@material-ui/core';
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { FormatAlignJustify } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { getJobById } from '../../services/RegisterJobService';
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EditIcon from "@material-ui/icons/Edit";

const defaultState = () => {
    return {
        active: true,
        addDate: new Date(),
        addedBy: null,
        batchNumber: 0,
        bottleDesign: "None",
        bottleWeight: 0,
        clientName: null,
        createdBy: null,
        dueDate: new Date(),
        id: 0,
        jobID: "",
        lastUpdate: new Date(),
        manufacturer: "None",
        notes: "None",
        reasonForSubmission: "None",
        sampleCount: 0,
        sampleIDs: "",
        sampleTypeName: "None",
        state: "None",
        version: 0
    }
}

const useStyles = makeStyles({
    rootContainer: {
        display: "flex",
        flexDirection: "column",
        rowGap: "1rem"
    },
    cardContainer: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        padding: "1rem",
        rowGap: "1rem"
    },
    titleCard: {
        flex: "0 1 15%"
    },
    infoContainer: {
        flex: "0 1 65%",
        display: "flex",
        flexWrap: "wrap",
        '& *': {
            flex: "1 1 33%",
            textTransform: "capitalize"
        },
        '& p': {
            fontSize: "0.9rem"
        }
    },
    title: {
        flex: "1 1",
        whiteSpace: "nowrap",
      },
    barcode: {
        transform: "rotate(90deg)"
    }
});

function ViewJob(props) {
    const params = useParams();
    const classes = useStyles();
    const [jobDetails, setJobDetails] = useState(defaultState);
    const [dense, setDense] = useState(false);
    const [sampleRows, setSampleRows] = useState([]);
    const { t } = useTranslation();

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    useEffect(() => {
        const getJobData = async () => {
            let data = await getJobById(params.id);
            setJobDetails(data);
        }

        // modeled as a "result" object which would have a reference to a Test object which has references to sub tests and is owned by an Analysis Template.
        var resultsRows = [{"id": 22, "result": true, "analysis_template_id": 3, "dataEntryMethod": "iDoc",
            "test": {"id": 1, "name": "NR Bottle Dims", "description": "description of test...", "equipmentName": "equipment1 + equipment2", "testDataType": "A", "unit": "B", "testId": "t1", "maxUnit": 1.5, "minUnit": 0.5, "lessThanTolerance": 10, "greatherThanTolerance": 20,
                "tests": [
                    {"id": 2, "name": "subtest1", "description": "description of subtest1...","equipmentName": "equipment2", "testDataType": "B", "unit": "D", "testId": "subt1", "maxUnit": 3, "minUnit": 6, "lessThanTolerance": 10, "greatherThanTolerance": 35, "tests": {}},
                    {"id": 3, "name": "subtest2", "description": "description of subtest2...","equipmentName": "equipment1", "testDataType": "A", "unit": "C", "testId": "subt2", "maxUnit": 1, "minUnit": 9, "lessThanTolerance": 32, "greatherThanTolerance": 60, "tests": {}},
                ],
        }},
        {"id": 21, "result": false, "analysis_template_id": 4, "dataEntryMethod": "Import",
            "test": {"id": 2, "name": "Test Method 9", "description": "description of test...", "equipmentName": "equipment2 + equipment3", "testDataType": "A", "unit": "B", "testId": "t1", "maxUnit": 1.5, "minUnit": 0.5, "lessThanTolerance": 10, "greatherThanTolerance": 20,
                "tests": [],
        }},
        ]

        setSampleRows(resultsRows);

        getJobData();
    }, []);

    const columns = [
        {
            name: t("results_added_label"),
            cell: (row) => (
                row.result ? <CheckCircleSharpIcon style={{marginLeft: 25, marginRight: 25}} fontSize="large" htmlColor="#008000"/> : <RadioButtonUncheckedIcon style={{marginLeft: 25, marginRight: 25}} fontSize="large" htmlColor="#800000"/>
            ),
            sortable: false,
        },
        { selector: row => row.test.name, sortable: true, name: t("test_method_tab") },
        { selector: row => row.test.equipmentName, sortable: true, name: t("equipment_tab") },
        { selector: row => row.dataEntryMethod, sortable: true, name: t("data_entry_label") },
        {
            name: t("actions_label"),
            cell: (row) => (
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => console.log(row.id)}
                    aria-label="add"
                    startIcon={row.result ? <AssignmentIcon style={{marginLeft: "30%"}}/> : <EditIcon style={{marginLeft: "30%"}}/>}
                    >
                    {/*{row.result ? t("view_results_label") : t("import_results_job_label")}*/}
                </Button>
            ),
            sortable: false,
        },
    ]

    const subColumns = [
        {
            // *Width added to mimic expandable rows dropdown icon width provided by Data Table compoennt. (Added  to maintain allignment with parent row).
            width: "46.75px" 
        },
        {
            // *Icon is disabled, (added to maintain allignment with parent row).
            cell: (row) => (
                <CheckCircleSharpIcon style={{visibility: "hidden"}} fontSize="large" htmlColor="#008000"/>
            ),
            sortable: false,
        },
        {
            selector: (row) => row.name,
            sortable: true,
        },
        {
            selector: (row) => row.equipmentName,
            sortable: true,
        },
        {
            selector: (row) => "-",
            sortable: true,
        },
        {
            // *Button is disabled, (added to maintain allignment with parent row).
            cell: (row) => (
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{visibility: "hidden"}}
                    onClick={() => console.log(row.id)}
                    aria-label="add"
                    startIcon={row.result ? <AssignmentIcon/> : <EditIcon />}
                    >
                    {row.result ? t("view_results_label") : t("import_results_job_label")}
                </Button>
            ),
            sortable: false,
        },
      ];

    return (
        <Box className={classes.rootContainer}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link to={"/registerSamples"}>
                    {t("register_samples_title")}
                </Link>
                <Typography color="textPrimary">{jobDetails.jobID}</Typography>
            </Breadcrumbs>
            <Card className={classes.cardContainer}>
                <Typography className={classes.titleCard} variant="h5" color="textPrimary">{jobDetails.jobID}</Typography>
                <Box className={classes.infoContainer}>
                    <Typography>{t("added_by_label")}: {`${jobDetails.addedBy}`}</Typography>
                    <Typography>{t("sample_count_label")}: {jobDetails.sampleCount}</Typography>
                    <Typography>{t("client_label")}: {jobDetails.clientName}</Typography>
                    <Typography>{t("add_date_label")}: {new Date(jobDetails.addDate).toLocaleString().split(',')[0]}</Typography>
                    <Typography>{t("sample_type_label")}: {jobDetails.sampleTypeName}</Typography>
                    <Typography>{t("last_update_label")}: {new Date(jobDetails.lastUpdate).toLocaleString().split(',')[0]}</Typography>
                    <Typography>{t("due_date_label")}: {new Date(jobDetails.dueDate).toLocaleString().split(',')[0]}</Typography>
                    <Typography>{t("state_label")}: {jobDetails.state}</Typography>
                </Box>
                <Button startIcon={<FormatAlignJustify className={classes.barcode}  style={{marginLeft: "30%"}} />} variant="contained"color="primary" size="medium" >
                    {/*{t("print_barcode_label")}*/}
                </Button>
            </Card>
                <Paper className={classes.paper}>
                    <Box mx={2} className={classes.tableHeader}>
                        <Typography
                            className={classes.title}
                            style={{ fontSize: "25px", margin: 15 }}>
                            Scheduled Tests
                        </Typography>
                    </Box>
                    <DataTable
                        pagination
                        expandableRows
                        expandableRowDisabled={(row) => row.test.tests != null && row.test.tests.length > 0 ? false : true}
                        expandOnRowClicked
                        columns={columns}
                        data={sampleRows}
                        expandableRowsComponent={(row) => 
                            <DataTable
                                noTableHead
                                columns={subColumns}
                                data={row.data.test.tests}
                                dense={dense}
                                paginationComponentOptions={{
                                    rowsPerPageText: t("rows_per_page_label"),
                                    rangeSeparatorText: t("range_seperator_label"),
                                }}
                            />}
                        dense={dense}
                        paginationComponentOptions={{
                            rowsPerPageText: t("rows_per_page_label"),
                            rangeSeparatorText: t("range_seperator_label"),
                        }}
                    />
                </Paper>
                <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label="Dense padding"
                />
        </Box>
    )
}

export default ViewJob;