
import { useEffect, useState } from 'react'
import { getAnalyticsData } from '../../services/DashboardService'
import PieChart from './chartTypes/PieChart';
import BarChart from './chartTypes/BarChart';
import { graphBorders, graphBackgrounds} from './Colors'
import { useTranslation } from 'react-i18next';
import ExportAnalytics from '../Export/ExportAnalytics';


const JobsCharts = (props) => {
    const { t } = useTranslation();

    const { selectedFiltersString } = props;
    const emptyData = {
        datasets: [],
        labels: []
    }
   
    const [results, setResults] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState(JSON.parse(selectedFiltersString));

    const [jobsPerMonthData, setJobsPerMonthData] = useState(null);
    const [reasonsData, setReasonsData] = useState(null);
    const [testsPerJobData, setTestsPerJobData] = useState(null);
    const [samplesPerJobData, setSamplesPerJobData] = useState(null);
   // const [changed, setChanged] = useState(false);
    const analytics = [0, 1, 2, 3];

    useEffect(() => {
        console.log(selectedFiltersString)
        setSelectedFilters(JSON.parse(selectedFiltersString));
    }, [selectedFiltersString])


    useEffect(() => {
        setResults(null)
        var promises = analytics.map((i) => {
            return getAnalyticsData({
                analytic: i,
                category: selectedFilters.category,
                neckFinish: selectedFilters.neckFinish,
                design: selectedFilters.design,
                bottleSize: selectedFilters.bottleSize,
                supplier: selectedFilters.supplier,
                region: selectedFilters.region,
                dateFrom: selectedFilters.fromDate,
                dateTo: selectedFilters.toDate
            })
        })

        Promise.all(promises).then((res) => {
            console.log(">>>> requests done!")

            const byName = (a, b) => { return a.name < b.name ? -1 : 1 }
            const byVal = (a, b) => { return a.value > b.value ? -1 : 1 }

           // if (res[0]) res[0].sort(byVal);
           // if (res[1]) res[1].sort(byVal);
            if (res[2]) res[2].sort(byName);
            if (res[3]) res[3].sort(byName);
           
           
            setResults({
                jobsPerMonth: res[0],
                testsPerJob: res[1],
                samplesPerJob: res[2],
                reasons: res[3]
            });

        });
       
       // setChanged(!changed)
    }, [selectedFilters])


   

    // bar chart
    const getJobsPerMonthDataset = () => {
        if (results && results.jobsPerMonth) {
            const labels = results.jobsPerMonth.map((r) => { return r.name })
            const values = results.jobsPerMonth.map((r) => { return r.value })
            const data = {
                labels: labels,
                datasets: [
                    {
                        label: "No of Jobs",
                        data: values,
                        backgroundColor: graphBackgrounds[0],
                        borderColor: graphBorders[0],
                        borderWidth: 1
                    }
                ]
            }
            setJobsPerMonthData(data);
        }
        else setJobsPerMonthData(null)
    }

    // pie chart
    const getReasonsDataset = () => {
        if (results && results.reasons) {
            let reasonsData = emptyData;
            
            if (results.reasons.length > 0) {
                const labels = results.reasons.map((r) => { return r.name })
                const values = results.reasons.map((r) => { return r.value })
                const bgcs = results.reasons.map((r, idx) => { return graphBackgrounds[idx] })
                
                const data = {
                    labels: labels,
                    datasets: [
                        {
                            data: values,
                            backgroundColor: bgcs,
                        }
                    ]
                }
                reasonsData = data;
            }
            
            // setReasonsData(data);
            setReasonsData(reasonsData);
        }
        else setReasonsData(null)
    }

    // bar chart
    const getTestsPerJobDataset = () => {
        if (results && results.testsPerJob) {
            const labels = results.testsPerJob.map((r) => { return r.name })
            const values = results.testsPerJob.map((r) => { return r.value })
            const data = {
                labels: labels,
                datasets: [
                    {
                        label: "No of Tests",
                        data: values,
                        backgroundColor: graphBackgrounds[1],
                        borderColor: graphBorders[1],
                        borderWidth: 1
                    }
                ]
            }
            setTestsPerJobData(data);
        }
        else setTestsPerJobData(null);
    }

    // pie chart
    const getSamplesPerJobDataset = () => {
        if (results && results.samplesPerJob) {
            let samplesPerJobData = emptyData;
            if (results.samplesPerJob.length > 0) {
                const labels = results.samplesPerJob.map((r) => { return r.name })
                const values = results.samplesPerJob.map((r) => { return r.value })
                const bgcs = labels.map((r, idx) => { return graphBackgrounds[idx] })
                const data = {
                    labels: labels,
                    datasets: [
                        {
                            data: values,
                            backgroundColor: bgcs
                        }
                    ]
                }

                samplesPerJobData = data;
            }
            setSamplesPerJobData(samplesPerJobData);
        }
        else setSamplesPerJobData(null)
    }


    useEffect(() => {
        getJobsPerMonthDataset();
        getReasonsDataset();
        getTestsPerJobDataset();
        getSamplesPerJobDataset();
    }, [results])


    return (<div>

        <ExportAnalytics selectedFilters={selectedFilters} title="Analytics Dashboard - Job Charts"></ExportAnalytics>

        <div style={{display:"flex"}}>
    
            <div style={{ width: "500px", height: "500px", marginBottom: "90px"}}>
                <PieChart title={t("reason_for_submission_label") + ":"} data={reasonsData} />
            </div>

            <div style={{ width: "500px", height: "500px", marginBottom: "90px", marginLeft: "60px" }}>
                <PieChart title={t("Samples per job")} data={samplesPerJobData} />
            </div>
            
        </div>

        <div style={{ marginBottom: "30px" }}>
            <BarChart title={t("Jobs per month")} data={jobsPerMonthData} axisLabels={{ x: t("Months"), y: t("No. of Jobs") }} />
        </div>

        <div style={{ display: "flex" }}>

            <div style={{ flex: 1 }}>
                <BarChart title={t("Number of Tests executed per job") + ":"} data={testsPerJobData} axisLabels={{ x: t("jobs_label"), y: t("No. of Tests") }} />
            </div>

            <div style={{ flex: 1 }}></div>

        </div>
       
           

    </div>)
}


export default JobsCharts;




