import HttpService from "./HttpService";
const axios = HttpService.getAxiosClient();
const HttpMethods = HttpService.HttpMethods;
const baseURL = process.env.REACT_APP_BACKEND_URL;
const rootRequestMapping = "file"



const uploadPreformDrawingFile = async (file) => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/import/image`,
            method: HttpMethods.POST,
            data: formData
        })
        return res.data;
    }
    catch (err) {
        console.log(err)
    }
   
  }


const uploadResultsFile = async (id, file) => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/import/${id}`,
            method: HttpMethods.POST,
            data: formData
        })
        return res.data;
    } catch (err) {
        let msg = "There was an error parsing the file!"
        if (err.response && err.response.data && err.response.data.message) {
            msg = err.response.data.message;
        }
        return {req_error:msg}
    }
  }

const uploadResultImage = async (testResultId, file, universal) => {
    console.log(`${baseURL}${rootRequestMapping}/report/${testResultId}/${universal}`)
    try {
        const formData = new FormData();
        formData.append('file', file);
        let res = await axios.request({
            //url: `${baseURL}${rootRequestMapping}/report/${testResultId}`,
            url: `${baseURL}${rootRequestMapping}/report/${testResultId}/${universal}`,
            method: HttpMethods.POST,
            data: formData
        })
        return (res.status === 200) ? res.data : false;
    } catch (err) {
       // console.log(err)
        return false;
    }

}

const deleteResultImage = async (imageId) => {
    try {
        let res = await axios.request({
            url: `${baseURL}testing/deleteimage/${imageId}`,
            method: HttpMethods.GET
        })
        return res.data
    }
    catch (err) {
      //  console.log(err)
        return { req_error: err }
    }

}

const deleteResultVideo = async (videoId) => {
    try {
        let res = await axios.request({
            url: `${baseURL}testing/deletevideo/${videoId}`,
            method: HttpMethods.GET
        })
        return res.data
    }
    catch (err) {
        //  console.log(err)
        return { req_error: err }
    }

}


const getResultImages = async (iDocId) => {
    try {
        let res = await axios.request({
            url: `${baseURL}testing/imagessession/${iDocId}`,
            method: HttpMethods.GET
        })
       
        return  res.data 
    }
    catch (err) {
        console.log(err)
        return { req_error: err }
    }
}


const getResultVideos = async (iDocId) => {
    try {
        let res = await axios.request({
            url: `${baseURL}testing/videossession/${iDocId}`,
            method: HttpMethods.GET
        })
        return res.data
    }
    catch (err) {
        console.log(err)
        return { req_error: err }
    }
}

const getHelpVideo = async (id) => {
    try {
        let res = await axios.request({
            url: `${baseURL}helpvideos/${id}`,
            method: HttpMethods.GET
        })
        return res.data
    }
    catch (err) {
        return false;
    }

}


const uploadCalibrationCertFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    let res = await axios.request({
        url: `${baseURL}${rootRequestMapping}/import/image`,
      method: HttpMethods.POST,
      data: formData
    })
    return res.data;
  }

  const getSavedFile = async(path) => {
    try {
        let res = await axios.post(`${baseURL}${rootRequestMapping}/image`, {
        "key": path
        })
        console.log(JSON.stringify(path, null, 2))
      console.log(JSON.stringify(res.data, null, 2))
      return res.data;
    }
    catch (error) {
      //console.error(error)
    }
  }

  const getSavedPdf = async(path) => {
    try {
        let res = await axios.post(`${baseURL}${rootRequestMapping}/pdf`, {
        "key": path
      })
      return res.data;
    }
    catch (error) {
      //console.error(error)
    }
  }

export { uploadPreformDrawingFile, uploadResultsFile, uploadCalibrationCertFile, getSavedFile, getSavedPdf, uploadResultImage, getResultImages, getResultVideos, deleteResultImage, deleteResultVideo, getHelpVideo }