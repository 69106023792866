

import { Button, IconButton,  Paper,  Switch, Tooltip } from '@material-ui/core';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { useStyles } from "./styles"
import ViewIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import { useTranslation } from 'react-i18next';
import { deleteResultImage } from '../../services/FileService'
import OKCancelDialog from '../Dialogs/OKCancelDialog'



const ImagesComponent = (props) => {

    const [imagesShown, setImagesShown] = useState(true)
    const [previewShown, setPreviewShown] = useState(false)
    const [imagePreview, setImagePreview] = useState({ image: null, name: "" })
  //  const [imgToDelete, setImgToDelete] = useState(null)
    const [OKCancelDialogState, setOKCancelDialogState] = useState({ shown: false })
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const { t } = useTranslation();


    const showPreview = (img) => {
       
        var i = new Image();
        i.onload = () => {
            var aspect = i.height / i.width 
            if (i.width > 600) {
                i.width = 600;
                i.height = i.width * aspect;
            }
            setImagePreview({ image: i, name: img.name})
        };
        i.src = `data:image/jpeg;base64,${img.bytes}`
        setPreviewShown(true);
    }


    const confirmDeleteImg = (img) => {
     //   setImgToDelete(img)
        setOKCancelDialogState({
            shown: true,
            title: "Confrim delete...",
            message: "Are you sure you want to delete this image? (No undo)",
            flavour: "warning",
            okText: "Delete",
            callback: (res) => {
                if (res === "OK") deleteImage(img);
                setOKCancelDialogState({shown:false})
            }
        })
    }


    const deleteImage = (i) => {
      //  if (imgToDelete === null) return;
        deleteResultImage(i.id).then((res) => {
            if (!res.req_error) {
                let imgs = res.map((img) => {
                    let ext = img.key.split('.').pop();
                    let temp = ".";
                    if (img.sequence > 1) temp = "(" + img.sequence + ")."
                    let fileName = img.uniqueId + "." + img.testName + temp + ext
                    return { bytes: img.image, key: img.key, name: fileName, id: img.id }

                })
                props.setImages(imgs)
            }
            else console.log(res.req_error)
          //  setImgToDelete(null)
        })
    }


    useEffect(() => {
        if (imagePreview.image !== null) {
            if (document.getElementById("image") !== null) {
                document.getElementById("image").appendChild(imagePreview.image)
            }
        }
    }, [imagePreview])

    
    useEffect(() => {
        const images = props.images;
        let temp = images.map((img) => {
            return (
                <div style={{display:'flex'}}>
                <div style={{maxWidth:"60%", overflowX:"hidden", flex:1}}><p>{img.name}</p></div>
                <IconButton onClick={() => { showPreview(img) }}><ViewIcon /></IconButton>
                <IconButton onClick={() => { confirmDeleteImg(img) }}>< DeleteIcon /></IconButton>
                </div>
            )

        })
        setRows(temp)
    }, [props.images])

    const showHideImages= (e) => {
        setImagesShown(e.target.checked);
    }


    return (
        <div>
            <Paper className={classes.paper} style={{ width: "100%", padding: "25px", textAlign: "center" }}>
                <table>
                    <tr>
                        <td style={{ width: "50px" }}>
                            <div></div>
                        </td>
                        <td style={{ width: "200px" }}><span ><b>{t('Images')}</b></span></td>
                        <td><Tooltip followCursor={true} title={"Show/Hide"}>
                            <Switch checked={imagesShown} onChange={showHideImages} />
                        </Tooltip></td>
                    </tr>
                </table>
                <div style={{ height: "380px", overflowY: "auto", overflowX: "auto"}}>
                {imagesShown ? rows.length > 0 ? <div>{rows}</div> :
                    t("No images to show yet. To upload an image relating to a particular result, click on that result in the table.") :
                        t("Images will not be shown for this test method.")}
                </div>
            </Paper>
            <Dialog
                open={previewShown}
                fullWidth={true} maxWidth={'md'}
                onClose={(event, reason) => {
                    if (reason && reason == "backdropClick") return;
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    { imagePreview.name }
                </DialogTitle>
                <DialogContent >
                    <div id="image" style={{width:"100%", textAlign:"center"}}></div>
                </DialogContent>
                <DialogActions>
                    <div style={{ marginBottom: "15px", marginRight: "15px" }}>
                        <Button variant="contained" onClick={() => {
                            setPreviewShown(false)
                        }}>OK<CancelIcon style={{ marginLeft: "7px" }} /></Button>
                       
                    </div>
                </DialogActions>
            </Dialog>
            <OKCancelDialog
                show={OKCancelDialogState.shown}
                title={OKCancelDialogState.title}
                message={OKCancelDialogState.message}
                flavour={OKCancelDialogState.flavour}
                okText={OKCancelDialogState.okText}
                callback={OKCancelDialogState.callback}
            />
        </div>
        
    )

}

export default ImagesComponent;