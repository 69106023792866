
import { useContext, useState } from 'react';
import { LabSetUpTabContext } from '../Contexts/LabSetUpTabs/LabSetUpTabContext';
import { useTranslation } from 'react-i18next';
import { getEquipment, deleteEquipment } from '../../services/EquipmentService';
import EquipmentDialog from '../Dialogs/EquipmentDialog';
import GenericTable from '../GenericTable';
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import { Tooltip, Typography, Box } from '@material-ui/core';
import { style } from '@mui/system';
import moment from "moment"

function Equipment(props) {
    const { text } = props;
    const [rows, setRows] = useState([]);
    const { t } = useTranslation();
    const [dense, setDense] = useContext(LabSetUpTabContext);
    const [alertContext, setAlertContext] = useContext(AlertContext);


    //  (<Tooltip title={row.name}><Typography>{}</Typography></Tooltip>)
    const columns = [
        { selector: (row) => row.name, sortable: true, name: t("equipment_name_label"), type: "text", key: "name", grow: 1.5 },
        { selector: (row) => row.description, sortable: true, name: t("description_field_label"), type: "text", key: "description", grow: 2.5 },
        { selector: (row) => row.manufacturer, sortable: true, name: t("manufacturer_label"), type: "text", key: "manufacturer" },
        { selector: (row) => row.department.name, sortable: true, name: t("department_label"), type: "text", key: "departmentName" },
        { selector: (row) => row.serialNumber, sortable: true, name: t("serial_number_field_label"), type: "text", key: "serialNumber" },
        { selector: (row) => row.code, sortable: true, name: t("asset_code_field_label"), type: "text", key: "code" },
        { selector: (row) => <Box>{row.calibrationDue !== null ? parseDate(row.calibrationDate, 'DD/MM/YYYY') : null}</Box>, sortable: true, name: t("calibration_date_label"), type: "text", key: "calibrationDate" },
        {
            selector: (row) =>
                <Box>
                    {row.calibrationDue !== null && new Date().toISOString() > row.calibrationDue ? // calibration due date is not null & has expired
                        <div style={{ color: "red", fontWeight: "bold" }}>
                            {parseDate(row.calibrationDue, 'DD/MM/YYYY')}
                        </div>

                        : row.calibrationDue !== null ? // calibration due date is not null & has not expired
                            parseDate(row.calibrationDue, 'DD/MM/YYYY')

                            : null}

                </Box>,

            sortable: true, name: t("calibration_due_label"), type: "text", key: "calibrationDue"
        },
    ];

    const parseDate = (date, format) => {
        if (date === null) return "<unknown>"
        try {
            let rtn = null;
            let temp = moment(date).format(format)
            if (temp !== "Invalid date") rtn = temp;
            if (rtn === null || rtn === "") rtn = "<unknown>"
            return rtn;
        }
        catch (err) {
            return "<unknown>"
        }
    }

    const updateTable = async callback => {
        let data = await getEquipment();
        if (!(data instanceof Array)) {
            setAlertContext({ ...alertContext, open: true });
            return;
        }
        setRows(data);
        callback();
    }

    //const handleDelete = async selected => {
    //    if (window.confirm('Are you sure you wish to delete ?')) {
    //        for (var id in selected)
    //            await deleteEquipment(selected[id].id);
    //    }
    //}

    const handleDelete = async (selected) => {
        if (window.confirm('Are you sure you wish to delete ?')) {
            let ids = selected.map((item) => { return item.id })
            let promises = ids.map((id) => { return deleteEquipment(id) })
            await Promise.all(promises)
        }
    }

    return <GenericTable
        dense={dense}
        keyColumn={{
            key: "name",
            name: t("equipment_name_label")
        }}
        setDense={setDense}
        updateTable={updateTable}
        Dialog={EquipmentDialog}
        rows={rows}
        columns={columns}
        text={text}
        deleteCallback={handleDelete} />;
}

export default Equipment;