import React from 'react';
import RegisterSamples from '../../Routes/RegisterSamples';
import RegisterSampleContextProvider from './RegisterSampleContextProvider';

function RegisterSamplesWrapper(props){
    return (
        <RegisterSampleContextProvider>
            <RegisterSamples />
        </RegisterSampleContextProvider>
    )
}

export default RegisterSamplesWrapper;