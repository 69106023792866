import HttpService from "./HttpService";
const axios = HttpService.getAxiosClient();
const HttpMethods = HttpService.HttpMethods;
const baseURL = process.env.REACT_APP_BACKEND_URL;
const rootRequestMapping = "category";

const getCategories = async (language) => {
    let lang = localStorage.getItem("language");
    try {
      const res = await axios.request({
        url: `${baseURL}${rootRequestMapping}/all/${lang}`,
        method: HttpMethods.GET,
      });
  
      return (res.status === 200) ? res.data : false;
    } catch (error) {
      return null;
    }
  }


const checkCode = async (id, code, entity) => {
   
  try {
    let res = await axios.post(`${baseURL}uniquecheck/check`, {
      "id": id,
      "code": code,
      "entity": entity
    })
    console.log(res)
    return res.data;
  }

  catch (error) {
    console.error(error)
  }
}

  const getCategoryById = async (categoryId) => {
    try {
      const res = await axios.request({
          url: `${baseURL}${rootRequestMapping}/${categoryId}`,
        method: HttpMethods.GET,
      });
  
      return (res.status === 200) ? res.data : false;
    } catch (error) {
      return null;
    }
  }

  const deleteCategory = async (id) => {
    try {
      let res = await axios.request({
        url: `${baseURL}${rootRequestMapping}/delete/${id}`,
        method: HttpMethods.GET,
      })
    }
    catch (error) {
      console.error(error);
    }
    return false;
  }
  
  const addCategory = async (name, description, shortCode, categoryNameFrench, categoryNameSpanish, categoryNameItalian, categoryNameArabic, categoryNameRussian, categoryNameEnglish) => {
    try {
      let language = localStorage.getItem("language");
      let res = await axios.post(`${baseURL}${rootRequestMapping}/save/${language}`, {
        "name": name,
        "description": description,
        "shortCode": shortCode,
        "active": true,
        "nameEnglish": categoryNameEnglish,
        "nameFrench": categoryNameFrench,
        "nameSpanish": categoryNameSpanish,
        "nameItalian": categoryNameItalian,
        "nameArabic": categoryNameArabic,
        "nameRussian": categoryNameRussian
      })
      console.log(name, description, shortCode)
      console.log(res)
      return res;
    }
  
    catch (error) {
      console.error(error)
      return error;
    }
  }
  
  const updateCategory = async (name, description, shortCode, id, categoryNameFrench, categoryNameSpanish, categoryNameItalian, categoryNameArabic, categoryNameRussian, categoryNameEnglish) => {
    try {
      let language = localStorage.getItem("language");
      let res = await axios.post(`${baseURL}${rootRequestMapping}/update/${language}`, {
        "name": name,
        "description": description,
        "shortCode": shortCode,
        "id": id,
        "nameEnglish": categoryNameEnglish,
        "nameFrench": categoryNameFrench,
        "nameSpanish": categoryNameSpanish,
        "nameItalian": categoryNameItalian,
        "nameArabic": categoryNameArabic,
        "nameRussian": categoryNameRussian
      })
      return res;
    }
  
    catch (error) {
      console.error(error)
      return error;
    }
  }

  const getReasonsByCategoryId = async (id) => {
    try {
      let language = localStorage.getItem("language");
      let res = await axios.request({
        url: `${baseURL}${rootRequestMapping}/reasons/${id}/${language}`,
        method: HttpMethods.GET,
      }
      )
      const data = await res.data;
      return data;
    } catch (error) {
     
        console.error(error)
        return false;
    }
  }

  const canDeleteCategory = async id => {
    let language = localStorage.getItem("language");
    try{
      let res = await axios.request({
        url: `${baseURL}${rootRequestMapping}/candelete/${id}/${language}`,
        method: HttpMethods.GET,
      })
      return res;
    }
    catch(error){
      console.error(error);
    }
    return null;
}



export { getCategories, getCategoryById, addCategory, updateCategory, deleteCategory, getReasonsByCategoryId, checkCode, canDeleteCategory}