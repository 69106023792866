import React, { createContext, useState } from 'react';
import { RegisterContext } from "./RegisterSampleContext";

const defaultState = {
    tableRows: [],
    dialogOpen: false,
}

function RegisterSampleContextProvider(props){
    const [rsContext, setRsContext] = useState(defaultState);

    return (
        <RegisterContext.Provider value={[rsContext, setRsContext]}>
            {props.children}
        </RegisterContext.Provider>
    )
}

export default RegisterSampleContextProvider;