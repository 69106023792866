
import { Dialog, DialogTitle, DialogActions, DialogContentText, TextField, Tooltip, Button, DialogContent } from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from "@mui/icons-material/Delete";

const TestMethodCommentDlg = (props) => {
    
    const [comment, setComment] = useState("");
   
    const { t } = useTranslation();
   
    useEffect(() => {
        setComment(props.comment);
    }, [props.show])


    const handleCommentChange = (e) => {
        setComment(e.target.value);
    }

    return (
        <div>

            <Dialog
                open={props.show}

                onClose={(event, reason) => {

                    if (reason && reason == "backdropClick") return;
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {t('Add / Edit / View comments...') }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description"></DialogContentText>

                    <form onSubmit={
                        (e) => {
                            e.preventDefault();
                            let _comment = comment;
                            setComment("")
                            props.callback(_comment, "ok_clicked")

                        }}>
                       
                        
                        <TextField
                            inputProps={{ style: { padding: 10, width: 320 } }}
                            multiline={true}
                            required={true}
                            maxRows={5}
                            id="comment"
                            placeholder={t('Type some comments here...')}
                            type="text"
                            value={comment}
                            variant="outlined"
                            onChange={handleCommentChange}
                        />


                        <div style={{ marginTop: "55px", marginBottom: "10px", textAlign:"right"}}>
                           
                            <IconButton variant="contained" type="submit" autoFocus>
                               {props.okText ? props.okText : <SaveIcon />}
                            </IconButton>
                            
                            <IconButton variant="contained" onClick={() => {  props.callback(-1, "cancel_clicked") }} >
                               {props.cancelText ? props.cancelText : <CancelIcon />}
                            </IconButton>

                            <IconButton variant="contained" onClick={() => {  props.callback(-1, "delete_clicked") }} >
                               {props.deleteText ? props.deleteText : <DeleteIcon />}
                            </IconButton>
                           
                        </div>

                        <div style={{ marginBottom: "25px" }}></div>
                       
                    </form>
                </DialogContent>
               
            </Dialog>

        </div>
    );
}

export default TestMethodCommentDlg;