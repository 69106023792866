import axios from "axios";

const KEYCLOAK_API_BASE = process.env.REACT_APP_KEYCLOAK_API_BASE;
const KEYCLOAK_ADMIN = process.env.REACT_APP_KEYCLOAK_ADMIN;
const KEYCLOAK_ADMIN_PASS = process.env.REACT_APP_KEYCLOAK_ADMIN_PASS;
const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM;


export const getAPItoken = async () => {
    try {
        const params = new URLSearchParams();
        params.append('username', KEYCLOAK_ADMIN);
        params.append('password', KEYCLOAK_ADMIN_PASS);
        params.append('client_id', "admin-cli");
        params.append('grant_type', "password");
        const res = await axios.post(`${KEYCLOAK_API_BASE}/realms/master/protocol/openid-connect/token`, params);
        return res.data.access_token;
    } catch (error) {
        return null;
    }
}

export const getRealmUsers = async () => {
    try {
        let token = await getAPItoken()
        if (token === null) return null;
        let res = await axios.get(`${KEYCLOAK_API_BASE}/admin/realms/${KEYCLOAK_REALM}/users`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        return res.data;
    }
    catch (error) {
        console.log(error)
        return [];
    }
}


export const createUser = async (user) => {
    try {
        let token = await getAPItoken()
        if (token === null) return [];
        let res = await axios.post(`${KEYCLOAK_API_BASE}/admin/realms/${KEYCLOAK_REALM}/users`, user, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        )
        return res.data;
    }
    catch (error) {
        console.log(error)
        return {req_error: error};
    }
}

export const updateUser = async (userID, user) => {
    try {
        let data = JSON.parse(JSON.stringify(user))
        if (data.username) delete data.username
        let token = await getAPItoken()
        if (token === null) return [];
        let res = await axios.put(`${KEYCLOAK_API_BASE}/admin/realms/${KEYCLOAK_REALM}/users/${userID}`, user, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
        return res.data;
    }
    catch (error) {
        console.log(error)
        return { req_error: error };
    }

}

export const deleteUser = async (userID) => {
    try {
        let token = await getAPItoken()
        if (token === null) return [];
        let res = await axios.delete(`${KEYCLOAK_API_BASE}/admin/realms/${KEYCLOAK_REALM}/users/${userID}`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
            
        })
        return res.data;
    }
    catch (error) {
        console.log(error)
        return [];
    }
}



export const getUserRoles = async (keycloak_user_id) => {
    try {
        let token = await getAPItoken()
        if (token === null) return [];
        let res = await axios.get(`${KEYCLOAK_API_BASE}/admin/realms/${KEYCLOAK_REALM}/users/${keycloak_user_id}/role-mappings/realm`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        let roles = [];
        if (res.data.length > 0) {
            roles = res.data.filter((role) => {
                return (role.name.includes("default-roles") === false) &&
                    (role.name !== "offline_access") &&
                    (role.name !== "uma_authorization")

            })
           
           // roles = roles.map((r) => { return { id: r.id, name: r.name } })
        }
        
        return roles;

    }
    catch (error) {
        console.log(error)
        return [];
    }
}

export const addUserRoles = async (keycloak_user_id, roles) => {
    try {
        let token = await getAPItoken()
        if (token === null) return [];
        let res = await axios.post(`${KEYCLOAK_API_BASE}/admin/realms/${KEYCLOAK_REALM}/users/${keycloak_user_id}/role-mappings/realm`, roles , {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
        
        return res.data;

    }
    catch (error) {
        console.log(error)
        return {req_error: error};
    }
}

export const deleteUserRoles = async (keycloak_user_id, roles) => {
    try {
        let token = await getAPItoken()
        if (token === null) return [];
        let res = await axios.delete(`${KEYCLOAK_API_BASE}/admin/realms/${KEYCLOAK_REALM}/users/${keycloak_user_id}/role-mappings/realm`,  {
      
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: 'Bearer ' + token
            },
            data: roles
            
        })

        return res.data;

    }
    catch (error) {
        console.log(error)
        return [];
    }
}




export const getAllRealmRoles = async () => {
    try {
        let token = await getAPItoken()
        if (token === null) return null;
        let res = await axios.get(`${KEYCLOAK_API_BASE}/admin/realms/${KEYCLOAK_REALM}/roles`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        let roles = [];

        if (res.data.length > 0) {
            roles = res.data.filter((role) => {
                return (role.name.includes("default-roles") === false) &&
                    (role.name !== "offline_access") &&
                    (role.name !== "uma_authorization")

            })
          //  roles = roles.map((r) => { return { id: r.id, name: r.name } })
        }
        return roles;

    }
    catch (error) {
        console.log(error)
        return [];
    }

}

// /{realm}/users/{userId}/role-mappings/realm

