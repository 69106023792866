import { makeStyles } from "@material-ui/core/styles";
import SampleTypeDialog from '../Dialogs/SampleTypeDialog';
import { useState, useContext, useEffect } from 'react';
import { LabSetUpTabContext } from '../Contexts/LabSetUpTabs/LabSetUpTabContext';
import { getSampleTypes, deleteSampleType } from '../../services/SampleTypesService';
import { useTranslation } from 'react-i18next';
import GenericTable from '../GenericTable';
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import { Tooltip, Typography } from '@material-ui/core';
import { Button } from '@material-ui/core/';
import CopySampleTypeDialog from '../Dialogs/CopySampleTypeDialog';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const useStyles = makeStyles((theme) => ({
    tooltipText: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
}));

function SampleTypes(props) {
    const { text } = props;
    const [rows, setRows] = useState([]);
    const { t } = useTranslation();
    const classes = useStyles();
    const [dense, setDense] = useContext(LabSetUpTabContext);
    const [alertContext, setAlertContext] = useContext(AlertContext);
    const [openCopySampleTypeDialog, setOpenCopySampleTypeDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [rowCodes, setRowCodes] = useState([]);
    const [responseForConfirmationMessage, setResponseForConfirmationMessage] = useState("");


    const getWeight = (row) => {
        if (!row.weight || row.weight === "null") return ""
        let unit = row.unitOfWeight ? " " + row.unitOfWeight : " g"
        if (unit == " ounces") unit = " oz"
        return row.weight + unit
    }

    const getTooltip = (text) => {
        return <tooltip title={text}><span>{text}</span></tooltip>
    }

    const columns = [
        { selector: (row) => row.name, sortable: true, name: t("sample_type_name_label"), type: "text", key: "name", width: "200px" },
        { selector: (row) => row.category.name, sortable: true, name: t("category_name_field_label"), type: "text", key: "categoryName" },
        { selector: (row) => row.description, sortable: true, name: t("description_field_label"), type: "text", key: "description", width: "450px" },
        { selector: (row) => getWeight(row), sortable: true, name: t("weight_label"), type: "text", key: "weight", width: "100px" },
        { selector: (row) => getTooltip(row.resin ? row.resin : ""), sortable: true, name: t("Resin"), type: "text", key: "" },
        { selector: (row) => row.code, sortable: true, name: t("filling_type_field_label"), type: "text", key: "code" },
        { selector: (row) => row.design, sortable: true, name: t("bottle_design_label"), type: "text", key: "design" },
        { selector: (row) => row.manufacturer, sortable: true, name: t("manufacturer_label"), type: "text", key: "manufacturer" },
        { selector: (row) => row.batchNumber, sortable: true, name: t("batch_number_label"), type: "text", key: "batchNumber" },
        {
            selector: (row) => (
                <Tooltip title={t("Copy Sample Type")}>
                    <Button
                        onClick={() => handleOpenCopySampleTypeDialog(row)}
                        aria-label="Add" color="primary" variant="contained">
                        <ContentCopyIcon />
                        {/* Copy */}
                    </Button>
                </Tooltip>
            ),
            sortable: false,
            name: t("actions_label"),
        },
    ];

    const updateTable = async callback => {
        let data = await getSampleTypes();

        if (!(data instanceof Array)) {
            setAlertContext({ ...alertContext, open: true });
            return;
        }
        setRows(data);
        callback();
    }

    useEffect(() => {
        if (rows.length > 0) {
            let tempRows = [];
            rows.forEach(row => {
                tempRows.push(row.code)
            })
            setRowCodes(tempRows);
        }
    }, [rows])

    //  const handleDelete = async (selected) => {
    //	if (window.confirm('Are you sure you wish to delete ?'))
    //	{
    //    for (var id in selected) {
    //      await deleteSampleType(selected[id].id);
    //    }
    //}
    //  }
    const handleDelete = async (selected) => {
        if (window.confirm('Are you sure you wish to delete ?')) {
            let ids = selected.map((item) => { return item.id })
            let promises = ids.map((id) => { return deleteSampleType(id) })
            await Promise.all(promises)
        }
    }

    const handleOpenCopySampleTypeDialog = (row) => {
        console.log("copy clicked")
        setOpenCopySampleTypeDialog(true)
        setSelectedRow(row);
    }

    const handleCloseCopySampleTypeDialog = (res) => {
        setOpenCopySampleTypeDialog(false);
        setSelectedRow({});
        setResponseForConfirmationMessage(res);
    };

    const handleResetForConfirmationMessage = (event) => {
        setResponseForConfirmationMessage(event);
    };

    return <GenericTable
        dense={dense}
        keyColumn={{
            key: "name",
            name: t("sample_type_name_label")
        }}
        setDense={setDense}
        updateTable={updateTable}
        Dialog={openCopySampleTypeDialog ? CopySampleTypeDialog : SampleTypeDialog}
        openCopySampleTypeDialog={openCopySampleTypeDialog}
        onCopySampleTypeDialogClose={handleCloseCopySampleTypeDialog}
        responseForConfirmationMessage={responseForConfirmationMessage}
        resetForConfirmationMessage={handleResetForConfirmationMessage}
        selectedRow={selectedRow}
        rowCodes={rowCodes}
        rows={rows}
        columns={columns}
        text={text}
        deleteCallback={handleDelete} />;
}

export default SampleTypes;