/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */


import { Box, IconButton, Tooltip, makeStyles, Button, TextField, Typography, Divider, Paper, Tabs, Tab, FormControlLabel, Switch, CircularProgress } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import { postIdocComment } from '../../services/iDocSessionsService'
import DashIcon from '@mui/icons-material/HorizontalRule';
import { useStyles } from "./styles"
import CommentIcon from '@mui/icons-material/ChatBubbleOutline';

import AddIcon from '@mui/icons-material/Add';
import FailIcon from '@mui/icons-material/Close';
import PassIcon from '@mui/icons-material/Check';

import CommentDialog from './TestMethodCommentDlg'
import { retest } from "../../services/ReportsService"
//import RedoIcon from '@mui/icons-material/Redo';
import RedoIcon from '@mui/icons-material/Replay';
import OKDialog from '../Dialogs/OKDialog'
import OKCancelDialog from '../Dialogs/OKCancelDialog'
import RetestDialog from './RetestDialog'
import moment from "moment"


const ResultsSummaryTable = (props) => {
    const dense = props.dense;
    const history = props.history;
    const classes = useStyles();
    const { t } = useTranslation();
    const [rows, setRows] = useState([]);

    const [timeoutElapsed, setTimeoutElapsed] = useState(false);
    const [alertContext, setAlertContext] = useContext(AlertContext);

    const [commentDialogState, setCommentDialogState] = useState({shown: false})
    const [OKDLGState, setOKDLGState] = useState({ shown: false });
    const [OK_CancelDLGState, setOK_CancelDLGState] = useState({ shown: false });
    const [retestDLGState, setRetestDLGState] = useState({shown: false})
    //const [rowToRestest, setrowToRestest] = useState(null);

    useEffect(() => {
        setRows(props.iDocs);
      
    }, [props.iDocs])


    const getStatus = (row) => {
        switch (row.result) {
            case "passed":
                return (<PassIcon style={{ color: "green" }} />)
                break;
            case "rejected":				
                return (<FailIcon style={{ color: "red" }} />)
                break;
				case 	"failed_average":
				    return (<FailIcon style={{ color: "#8B0000" }} />)
				    break;
            case "received": case "pending": case "removed":
                return <span style={{ color: "lightgrey" }}><DashIcon /></span>
                break;
        }
    }

    const getTestMethod = (row) => {
        return <tooltip title={row.testMethod}>
            <a style={{ cursor: "pointer" }} key={row.testMethod}
                onClick={() => { props.setTabValue(row.tabValue); }}>{row.testMethod}
            </a>
        </tooltip>
    }

    const refreshTable = () => {
        setRows(JSON.parse(JSON.stringify(rows)));
    }


    const openCommentDialog = (row) => {

        let origComment = "";
        // try { origComment = JSON.parse(row.comments).text; } catch (err) {}
        if (row.comments !== null) origComment = row.comments;
     
        setCommentDialogState({
            shown: true,
            comment: origComment,
            callback: (newComment, res) => {
                setCommentDialogState({shown:false})
                if (res === "ok_clicked") {
                    // row.comments = JSON.stringify({ text: newComment });
                    refreshTable();
                    postIdocComment(newComment, row.id).then((res) => {
                        if (res.req_error) {
                            alert(t("error saving comment"))
                        }
                    })   //postIdoc    
                }
                if (res === "delete_clicked") {
                    // row.comments = JSON.stringify({ text: newComment });
                    refreshTable();
                    postIdocComment("", row.id).then((res) => {
                        if (res.req_error) {
                            alert(t("error deleting comment"))
                        }
                    })   //postIdoc    
                }
            }
        })
    }



    const getCommentBtn = (row) => {
        if (row.comments && row.comments.length > 0) return (
                <Tooltip title={<div style={{ fontSize: "12px" }}>{t("Edit or view comments for this test method.")}</div>}>
                    <div>
                        <IconButton onClick={() => { openCommentDialog(row) }}><CommentIcon style={{ fontSize: 'medium' }} /></IconButton>
                    {t("View") + "/" + t("Edit")}
                     </div>
                </Tooltip>
            )
        else return (
                <Tooltip title={<div style={{ fontSize: "12px" }}>{t("Add comments for this test method.")}</div>}>
                    <span style={{cursor:'pointer'}} onClick={() => { openCommentDialog(row) }}>
                        <IconButton onClick={() => { openCommentDialog(row) }}><AddIcon style={{ fontSize: 'medium' }} /></IconButton>
                    </span>
                </Tooltip>
               
            )
    }
    

    const getRetestBtn2 = (row) => {
        if (row.result === "passed" || row.result === "rejected") {
            return (
                <div style={{ cursor: "pointer", textDecoration: "underline" }} onClick={
                    () => {
                        retest(row.id).then((res) => {
                            if (res === false) {
                                alert(t("Request error!"))
                            }
                            else {
                                
                            }

                        })
                    }
                }>{t("Request a retest")}</div>
            )
        }
        else return <span style={{ color: "lightgrey" }}><DashIcon /></span>;
    }

    const getUpdatedBy = (updatedBy) => {
        return (updatedBy === null ? <span style={{ color: "lightgrey" }}><DashIcon /></span> : updatedBy)
    }

    //setOK_CancelDLGState({
    //    shown: true,
    //    title: "Are you sure?",
    //    message: "WARNING: This will clear all existing results for all tests of this test method and you will need to start over. Proceed with retest request?",
    //    flavour: "warning",
    //    callback: () => {
    //        retest(row.id).then((res) => {
    //            if (res === false) alert("Request error!")
    //            else {
    //                setOKDLGState({
    //                    shown: true,
    //                    title: "Done!",
    //                    message: "This test method has been submitted for a retest.",
    //                    flavour: "success"
    //                })
    //            }
    //        })
    //    }
    //})

    const getRetestBtn = (row) => {
        if (row.result === "passed" || row.result === "rejected") {
            return (
                 <Tooltip title={<div style={{padding:"7px", fontSize:"13px"}}>
                     Enables the re-performing of specific tests of this test method on specific samples. Or, you may request to retest everything.
                 </div>}>
                    <div>
                        <IconButton onClick={() => {
                            console.log(JSON.stringify(row, null, 2))
                            setRetestDLGState({
                                shown: true,
                                iDoc: row
                            })
                        }}>
                            <RedoIcon/>
                        </IconButton>
                    </div>
                 </Tooltip>
            )
        }
        else return <IconButton style={{ color: "lightgrey" }}><DashIcon /></IconButton>;
    }

    const parseDate = (date, format) => {
        if (date === null) return <span style={{ color: "lightgrey" }}><DashIcon /></span>
        try {
            let rtn = null;
            let temp = moment(date).format(format)
            if (temp !== "Invalid date") rtn = temp;
            if (rtn === null || rtn === "") rtn = <span style={{ color: "lightgrey" }}><DashIcon /></span>
            return rtn;
        }
        catch (err) {
            return <span style={{ color: "lightgrey" }}><DashIcon /></span>
        }
    }

    const cols = [
        { selector: row => getTestMethod(row), sortable: true, name: t("test_method_tab"), type: "text", key: "testMethod" },
        { selector: row => getStatus(row), sortable: true, type: "text", key: "status", width: '60px' }, // Pass/Fail column
        { selector: row => getRetestBtn(row), sortable: true, type: "text", key: "action", width: '70px' }, // Revalidate column
        { selector: row => parseDate(row.updatedAt, 'DD/MM/YYYY @ HH:mm'), sortable: true, name: t("Updated At"), type: "text", key: "action" },
        { selector: row => getUpdatedBy(row.updatedBy), sortable: true, name: t("Updated By"), type: "text", key: "action" },
        { selector: row => getCommentBtn(row), sortable: true, name: t("Comments"), type: "text", key: "comment", width: '130px' },
    ]

    const sortFunc = (r, selector, direction) => {
        return r.sort((a, b) => {
            let comparison = 0;
            const aField = selector(a)
            const bField = selector(b)
            console.log(aField)
            if (aField.key !== null) {
                if (aField.key > bField.key) {
                    comparison = 1;
                } else if (aField.key < bField.key) {
                    comparison = -1;
                }
            }
            else {
                if (aField > bField) {
                    comparison = 1;
                } else if (aField < bField) {
                    comparison = -1;
                }
            }
            return direction === 'desc' ? comparison * -1 : comparison;
        })
    }

    //<Typography
    //    className={classes.title}
    //    style={{ fontWeight: 400, fontSize: "22px", margin: "25px" }}>
    //    {`Summary of Results:`}
    //</Typography>

    return (
        <Paper className={classes.paper} style={{ width: "100%" }}>
            <Box mx={2} className={classes.tableHeader}>
                <p style={{ color: "grey" }}>{t('results_summary_msg')}</p>
            </Box>
            {rows.length > 0 || timeoutElapsed === true ?
                <DataTable id="iDocTable2"
                    striped
                    customStyles={{
                       
                        headCells: { style: { fontWeight: 'bold', fontSize: "15px" }, }
                    }}
                    columns={cols}
                    data={rows}
                    dense={+dense}
                    sortFunction={sortFunc}
                    clearSelectedRows={() => { }} />
                : <div style={{ textAlign: "center", marginBottom: "20px" }}><p>{t("Loading table data")}...</p> <CircularProgress style={{ marginBottom: "50px" }} /></div>}

            <CommentDialog
                show={commentDialogState.shown}
                comment={commentDialogState.comment}
                callback={commentDialogState.callback }
            />
            <OKDialog
                show={OKDLGState.shown}
                title={OKDLGState.title}
                message={OKDLGState.message}
                flavour={OKDLGState.flavour}
                callback={() => {
                    setOKDLGState({shown: false})
                }}
            />
            <RetestDialog
                show={retestDLGState.shown}
                iDoc={retestDLGState.iDoc}
                callback={() => {
                    setRetestDLGState({shown: false})
                }}
            />
            <OKCancelDialog
                show={OK_CancelDLGState.shown}
                title={OK_CancelDLGState.title}
                message={OK_CancelDLGState.message}
                flavour={OK_CancelDLGState.flavour}
                callback={(res) => {
                    setOK_CancelDLGState({ ...OK_CancelDLGState, shown: false })
                    if (res === "OK") {
                        OK_CancelDLGState.callback();
                    }
                }}
            />
        </Paper>

           
    )
}

export default ResultsSummaryTable