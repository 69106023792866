

import React, { useContext, useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Card } from '../../../node_modules/@material-ui/core/index';
import HelpVideoDialog from '../Dialogs/HelpVideoDialog';
import { useTranslation } from 'react-i18next';

const Tutorials = () => {
    const { t } = useTranslation();

    const topics = [t("department_tab"), t("equipment_tab"), t("test_method_tab"), t("register_samples_title"), `${t("testing_title")} & ${t("reports_title")}`];
    
    return (
        <div>
            <h2>{t("Tutorial Videos")}:</h2>
            <Card style={{ padding: "30px", paddingRight: "80px", marginLeft:"20px",width: "fit-content" }}>
                <table>
                    {topics.map((topic, idx) => {
                        return <>
                            {idx == 0 && <tr><td style={{ paddingBottom: "15px" }}><b>{t("system_set_up_title")}:</b></td></tr>}
                            {idx == 3 && <tr><td style={{ paddingBottom: "15px", paddingTop: "15px" }}><b>{t("Other sections")}:</b></td></tr>}
                            <tr >
                                <td style={{ paddingBottom: "15px", paddingRight: "40px", paddingLeft: "20px" }}>{idx + 1}. {idx === 3 && t("Create Job") + " & "} {topic} {idx===0 && " & " + t("personnel_tab")}:</td>
                                <td style={{ paddingBottom: "15px" }}><HelpVideoDialog topic={topic} /></td>
                            </tr>
                        </>
                    })}
                </table>
            </Card>
        </div>
    )
   
}

export default withRouter(Tutorials);