import HttpService from "./HttpService";
const axios = HttpService.getAxiosClient();
const methods = HttpService.HttpMethods;
const baseURL = process.env.REACT_APP_BACKEND_URL;
const rootRequestMapping = 'profilejob'

// async function getAllRegisteredJobs() {
//     try {
//         let res = await axios.request({
//             url: `${baseURL}${rootRequestMapping}/all`,
//             method: methods.GET
//         });

//         return (res.status === 200) ? res.data : false;
//     }
//     catch (error) {
//         console.error(error);
//         return null;
//     }
// }



// const checkCode = async (id, code, entity) => {
   
//     try {
//       let res = await axios.post(`${baseURL}uniquecheck/check`, {
//         "id": id,
//         "code": code,
//         "entity": entity
//       })
//       console.log(res)
//       return res.data;
//     }
  
//     catch (error) {
//       console.error(error)
//     }
//   }

const getProfileJobs = async () => {
  let language = localStorage.getItem("language");
    try {
        let res = await axios.request({
            url: `${baseURL}profilejob/all/${language}`,
            method: methods.GET
        });

        return (res.status === 200) ? res.data : false;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}


const getProfileJobsByCatID = async (id) => {
  let language = localStorage.getItem("language");
    try {
        let res = await axios.request({
            url: `${baseURL}profilejob/category/${id}/${language}`,
            method: methods.GET
        });

        return (res.status === 200) ? res.data : null;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}



const addProfileJob = async (id, name, numberOfSamples, category, selected) => {
  // console.log('formTestMethodId',formTestMethodId)
    let language = localStorage.getItem("language");
    try { 
      let data = {
        "name": name,
        "numberOfSamples":  numberOfSamples,
        "category": category,
        "contents": selected
      }
  
      if (id != null) data["id"] = id;
  
      let res = await axios.request({
        url: `${baseURL}${rootRequestMapping}/save/${language}`,
        method: methods.POST,
        data: data
      });
      
        
      // if (res.status === 200 || res.status === 201)
      return res;
  
      // return false;
    }
  
    catch (error) {
      console.error(error)
      return error;
    }
  }

  const getProfileJobById = async (categoryId, profileJobId) => {
    let language = localStorage.getItem("language");
    try {
      let res = await axios.request({
          url: `${baseURL}profilejob/${categoryId}/${profileJobId}/${language}`,
          method: methods.GET
      });
      console.log(res);

      return (res.status === 200) ? res.data : false;
  }
  catch (error) {
      console.error(error);
      return null;
  }
  }

  const deleteProfileJob = async (id) => {
    let language = localStorage.getItem("language");
    try{
      let res = await axios.request({
        url: `${baseURL}${rootRequestMapping}/delete/${id}/${language}`,
        method: methods.GET,
      })
      console.log(res)
    }
    catch(error){
      console.error(error);
    }
    return false;
  }

  const canDeleteProfileJob = async id => {
    let language = localStorage.getItem("language");
    try{
      let res = await axios.request({
        url: `${baseURL}${rootRequestMapping}/candelete/${id}/${language}`,
        method: methods.GET,
      })
      return res;
    }
    catch(error){
      console.error(error);
    }
    return null;
  }

export { getProfileJobs, addProfileJob, getProfileJobById, getProfileJobsByCatID, deleteProfileJob, canDeleteProfileJob };
