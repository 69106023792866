/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Grid,Box, makeStyles, Button, TextField, Typography, Divider, withStyles, Card} from '@material-ui/core';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { ListAlt } from "@material-ui/icons";
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import PrivateRoute from '../KeyCloakComponents/PrivateRoute';
import {NavLink } from "react-router-dom";
import Testing from './Testing';
import { getMeanCompletionTime, getTopReasons, getMostUsedTests, getRiskiestTests, getJobsOverview } from '../../services/DashboardService';
import DashboardJobs from '../DashboardJobs';
import SchedulerByJob from './SchedulerByJob';
import CircularProgress from '@mui/material/CircularProgress';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {  CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Bar } from "react-chartjs-2";
import { useTranslation } from 'react-i18next';
import UserService from "../../keycloak";
import  IDocQueueTable  from '../Testing/IDocQueueTable'
import AnalyticsPage from '../Analytics/AnalyticsPage';
import KPIDashboard from '../Analytics/KPIDashboard'
import ExcelLinkPage from '../Reports/ExcelLinkPage'

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title,);

const useStyles = makeStyles(theme =>({
    root: {
        marginBottom: theme.spacing(2),
    },
    pageHeading: {
        display: "flex",
        flex: 1,
        justifyContent: 'center',
        alignItems: "center",
        marginBottom:'2%'
    },
    title: {
        // flex: "1",
        whiteSpace: "nowrap",
        marginBottom: "1rem",
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center'
    },
    paper: {
        marginBottom: theme.spacing(2),
        padding:"15px"
    },
   
}));

function DashboardHome(props){
    const classes = useStyles();

   

    return (
        //removed dense prop
        <DashboardJobsTable history={props.history} classes={classes}/>
    )
}




export const DashboardJobsTable = (props) => {
    const classes = props.classes;
    const turnAroundTime = props.turnAroundTime;
    const jobStatusOverview = props.jobStatusOverview;
    const colors = props.colors;
    const { t, i18n } = useTranslation()
    const [admin, setAdmin] = useState([])
    const [manager, setManager] = useState([])
    const [analyst, setAnalyst] = useState([])

    const jobStatuses = [t('state_received_label'), t('state_inProgress_label'), t('state_onHold_label'), t('state_completedAccepted_label'),
        t('state_completedPending_label'), t('state_overdue_label'), t('state_notConfigured_label'), t('state_removed_label'),
        t('state_importResults_label'), t('state_completedNotAccepted_label'), t('state_incomplete_label'), t('state_retest_label')]

   




    useEffect(() => {
        if(jobStatusOverview){
            let jobStatusColors = [];
            jobStatusOverview.forEach(status=>{               
                jobStatusColors.push(colors[status.id])
            });
            let jobStatusLabels = [];
            jobStatusOverview.forEach(status=>{               
                jobStatusLabels.push(jobStatuses[status.id])
            });
        }
    }, [jobStatusOverview])
//['LAB_MANAGER', 'LAB_SUPERVISOR', 'ADMIN', 'EOLAS_USER', "ANALYST","TECHNICIAN"]
    useEffect(() => {
		getUserRole("ANALYST").then((role) => {
		setAnalyst(role);
		    });
    }, ['analyst'])
    useEffect(() => {
		getUserRole("LAB_MANAGER").then((role) => {
		setManager(role);
		    });
    }, ['analyst'])
    useEffect(() => {
		getUserRole("ADMIN").then((role) => {
		setAdmin(role);
		    });
    }, ['admin'])

const getUserRole= async (role) => {
		var t1=await UserService.hasRole([role]);
		return t1;
    };
    return (
        <div className={classes.root} style={{display: 'flex', width: '100%', padding:"1px", justifyContent: 'space-between' }}>
  <Grid item md={12} xs={12} style={{"padding-left":"0px",width: '100%'}}>
 	<Box mx={2} style={UserService.hasRole(['LAB_MANAGER', 'LAB_SUPERVISOR', 'ADMIN']) ? { clear:'both', flex: '1', justifyContent: 'center', alignItems: "center" } : { display: "hidden"}} >
				 <div style={{height: '100%', width: '100%'}}>
					 { manager || admin ? <SchedulerByJob />  : null }				    
				 </div>
    </Box>
        
                <Box mx={2} style={UserService.hasRole(['LAB_MANAGER', 'LAB_SUPERVISOR', 'ADMIN', 'EOLAS_USER', "ANALYST","TECHNICIAN"]) ? { display: 'flex', flex: '1', justifyContent: 'center', alignItems: "center" } : null} >
                                				 { analyst ? <DashboardJobs />:null} 
                </Box>
        
        <Box mx={2} style={{ width: '100%'}}>
				 <div style={{width: '100%'}}>
        				 { analyst ? <IDocQueueTable history={props.history} classes={classes} dense={true} /> : null }
        				 </div>
	    </Box>
        
        
             </Grid>
        </div>
    )
}



export default withRouter(DashboardHome);