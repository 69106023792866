import { makeStyles } from "@material-ui/core/styles";
import JobReasonsDialog from '../Dialogs/JobReasonsDialog';
import { useState, useContext } from 'react';
import { LabSetUpTabContext } from '../Contexts/LabSetUpTabs/LabSetUpTabContext';
import { getReasons, deleteReason} from '../../services/JobReasonService';
import { useTranslation } from 'react-i18next';
import GenericTable from '../GenericTable';
import { AlertContext } from '../Contexts/AlertContext/AlertContext';

const useStyles = makeStyles((theme) => ({
    tooltipText: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  }));

  function JobReasons(props){
    const { text } = props;
    const [rows, setRows] = useState([]);
    const { t } = useTranslation();
    const classes = useStyles();
    const [dense, setDense] = useContext(LabSetUpTabContext);
    const [alertContext, setAlertContext] = useContext(AlertContext);

    const columns = [
        { selector: (row) => row.name, sortable: true, name: t("reason_field_label"), type: "text", key: "name", grow:0.225 },
        { selector: (row) => row.categoriesNames, sortable: true, name: t("reason_categories_field_label"), type: "text", key: "categoriesNames", grow:1 },
      ];

      const updateTable = async callback => {
        let data = await getReasons();
    
        if(!(data instanceof Array)){
          setAlertContext({...alertContext, open: true});
          return;
        }

        data.forEach(reason=>{
          let categoriesNames = "";
          let index = 1;
          reason.categories.forEach(category=>{
            if( index < reason.categories.length){
              categoriesNames += category.name + ", "				
              }
            else{
              categoriesNames += category.name
              }
              index++;
          })
          reason['categoriesNames'] = categoriesNames;
        });

        setRows(data);
        callback();
      }

      //const handleDelete = async (selected) => {
	
      //  if (window.confirm('Are you sure you wish to delete ?'))
      //  {
      //      for (var id in selected) 
      //      {
      //            await deleteReason(selected[id].id);
      //      }
      //  }
      //}

      const handleDelete = async (selected) => {
          if (window.confirm('Are you sure you wish to delete ?')) {
              let ids = selected.map((item) => { return item.id })
              let promises = ids.map((id) => { return deleteReason(id) })
              await Promise.all(promises)
          }
      }

    return <GenericTable
        dense={dense}
        keyColumn={{
            key: "name",
            name: t("reason_field_label")
        }}
        setDense={setDense}
        updateTable={updateTable}
        Dialog={JobReasonsDialog}
        rows={rows}
        columns={columns}
        text={text}
        deleteCallback={handleDelete} 
        />;
  }

  export default JobReasons;