/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useContext } from 'react';
import { Grid, Paper,Box, makeStyles, Button, TextField, Typography, Divider, withStyles, Card} from '@material-ui/core';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import PrivateRoute from '../KeyCloakComponents/PrivateRoute';
import {NavLink } from "react-router-dom";
import Testing from './Testing';
import { getSchedule,getMeanCompletionTime, getTopReasons, getMostUsedTests, getRiskiestTests, getJobsOverview } from '../../services/DashboardService';
import DashboardJobs from '../DashboardJobs';
import CircularProgress from '@mui/material/CircularProgress';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import UserService from "../../keycloak";
import {Link} from "react-router-dom";

const useStyles = makeStyles(theme =>({
    root: {
        marginBottom: theme.spacing(2),
    },
    pageHeading: {
        display: "flex",
        flex: 1,
        justifyContent: 'center',
        alignItems: "center",
        marginBottom:'2%'
    },
    title: {
        // flex: "1",
        whiteSpace: "nowrap",
        marginBottom: "1rem",
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center'
    },
    paper: {
        marginBottom: theme.spacing(2),
        padding:"15px"
    },

	table:{
	"border-collapse": "collapse",
    "font-family": "Tahoma, Geneva, sans-serif"
    }   ,
    
    thead: {
	"background-color": "#54585d",
	"color": "#ffffff",
	"font-weight": "bold",
	"font-size": "12px",
	"border": "1px solid #cccccc",
	"min-width":"95px"	,
	"text-align":"center"
	},
    jobmain: {
	"background": "#54585d",
	"color": "#ffffff",
	"font-weight": "bold",
	"font-size": "13px",
	"border": "1px solid #54585d",
	"min-width":"150px"	
	},
    tmmain: {
	"background": "#54585d",
	"color": "#ffffff",
	"font-weight": "bold",
	"font-size": "12px",
	"border": "1px solid #54585d",
	"white-space": "nowrap"
	},
	
	progressoperator:{
		"background":"navy", 
		"font-size":"10px", 
		"color":"white",
		"font-weight":"bold", 
		"textAlign": "center", 
		"paddingLeft": "5px" 
	},
	
	progressnooperator:{
		"background":"blue", 
		"color":"white", 
		"textAlign": "center", 
		"paddingLeft": "5px" 
	},
	
	na:{
		"width":"100%",
		"height":"100%",
		"background":"#AAAAAA", 
		"color":"blue", 
		"textAlign": "center", 
		"paddingLeft": "5px" ,
		"font-size":"10px", 
	},
	notstarted:{
		"background":"white", 
		"color":"black",
		"font-style":"italic",
		"font-size":"10px", 
		"textAlign": "center", 
		"paddingLeft": "5px" 
	},
	
	rejected:{
		"width":"100%",
		"height":"100%",
		"background":"red", 
		"color":"white", 
		"textAlign": "center", 
		"paddingLeft": "5px" ,
		"font-size":"10px" 
	},
	passed:{
		"width":"100%",
		"height":"100%",
		"background":"green", 
		"color":"white", 
		"textAlign": "center", 
		"paddingLeft": "5px" ,
		"font-size":"10px" 
	},
	link:{
		"color":"white",
		"textDecoration":"none",
		"cursor":"pointer"
	},
	
}));


const SchedulerByJob= (props) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const [dlg, setDlg] = useState({ show: false })
    const [jobs, setJobs] = useState([])
    const [testMethods, setTestMethods] = useState([])
  


    const getCellStyle = (session) => {
		
//		console.log(session.result+" "+session.operator);
		var res=session.result;
		var status=session.status;
		
		if (res=='na')         return (<td  className={classes.na} >&nbsp;</td>);
		if (res=='rejected')   return (<td className={classes.rejected} >&nbsp;{session.operator}</td>);
		if (res=='passed')     return (<td className={classes.passed} >&nbsp;{session.operator}</td>);
		if (res=='pending') {
			if (session.testsCompleted==null || session.testsCompleted==0)
			{
				if (session.operator==null)
				{
				    return (<td className={classes.notstarted} >Unassigned</td>);
				}
				else
				{
				    return (<td className={classes.notstarted}>&nbsp;{session.operator}</td>);
				}
			}
			else
			{
			    return (<td className={classes.progressoperator}>{session.operator}</td>);					
			}
}
		console.log(session);

        return (<td style={{ textAlign: "left", paddingLeft: "5px" }}>DEFAULT{session.operator}</td>)
    }
    const getCellStyleByTestMethod = (sessions, testMethod) => {

//		sessions.map((session) => {
	for (var i=0;i<sessions.length;i++){
		var session=sessions[i];
			if (session.testMethod==testMethod)
			{
				return getCellStyle(session); 
			}
		}
//		);
    }


    const getJobStyle= (job,thisKey) => {
        return (<td className={classes.jobmain}>{job.jobID}</td>)
    }
    const getTM= (tm) => {
        return (<td className={classes.tmmain}>{tm}</td>)
    }

   

    const getJobs = async () => {
        const jobs = await getSchedule();

        if (jobs) {
            var rtn = await Promise.all(jobs.map(async (job) => {
                return job;
            }))
        }
        return rtn;
    }


    useEffect(() => {
        getJobs().then((jobs) => {

            setJobs(jobs);
            
			var tms=[];
            var sessions=jobs[0].sessions;
            for (var i=0;i<sessions.length;i++)
            {
			tms.push(sessions[i].testMethod);
			}
			tms.sort((a,b) => 
			   {
		       const aName = a.toLowerCase(), bName = b.toLowerCase();
       		return ((aName > bName) ? 1 : ((bName > aName) ? -1 : 0))
		   })
			setTestMethods(tms);			
        });
    }, [])



    return (
        <div style={{ width: "100%" }}>
                <Box mx={2} className={classes.tableHeader}>
                    <Typography style={{ fontWeight: 400, fontSize: "20px" }}>                    
                    		{t("Jobs in progress")}
            		</Typography>                  
                </Box>
               <Box mx={2} className={classes.tableHeader}>
{		jobs.length>0? 
			<table border="1" className={classes.table}>
			<thead>
				<tr>
				<td className={classes.jobmain} >GPTR</td>
				{
				jobs.map((job,thisKey) => {
						return(
							<td className={classes.thead} >
							<div><Link  to={"reports/?open=true&jobId="+job.job.id} style={{ 'textDecoration':'none','color': '#FFF' }}>{job.job.jobID}</Link></div></td>
								)
						})
				}
				</tr>
			</thead>
			<tbody>

               {	
				   			   
				testMethods.map((tm)  => {
                	return(
						<tr>{getTM(tm)} 
						{
							jobs.map((job,thisKey) => {
								return(
									getCellStyleByTestMethod (job.sessions, tm)
									   )
							  })
						}
								</tr>
									)
							})
				}						
			</tbody>
			</table>
:<div style={{ textAlign: "center", marginBottom: "20px" }}><p>{t("Loading Scheduling data")}...</p> <CircularProgress style={{ marginBottom: "50px" }} /></div>}
			
			 </Box>
                 <div>
                </div>
        </div>
        
    )

}
export default withRouter(SchedulerByJob);