import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';
import MenuLinks, { menuFilter } from './components/MenuLinks';
import AccountButton from './components/AccountButton';
import { Grid } from '@material-ui/core';
import HelpIcon from '@mui/icons-material/Help';
import Link from '@mui/material/Link';

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: "100vw",
        height: "100vh"
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        marginTop: "20px",
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginTop: '4rem',
    },
    pointer: {
        cursor: "pointer",
    },
    toolBar: {
        display: "flex",
        justifyContent: "space-between"
    }
}));



const ClippedDrawer = (props) => {

    const classes = useStyles();
    const links = menuFilter(MenuLinks());

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolBar}>
                    <Typography variant="h6" noWrap className={classes.pointer} onClick={event => window.location.href = '/'}>
                        PackLIMS
                    </Typography>
                    <AccountButton />
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{ paper: classes.drawerPaper, }}>
                <Toolbar />
                <div className={classes.drawerContainer}>
                    {Object.keys(links).map((key, index) => (
                        <NavLink key={index} to={links[key].path} exact={true}
                            style={{ display: 'inline-block', minWidth: "100%", marginBottom: "10px", color: "black", textDecoration: "none" }}
                            activeStyle={{ backgroundColor: '#ADCBE5', color: 'black', fontWeight: "bold" }}>
                            <div style={{ fontSize: "16px" }}>
                                <Grid container direction="row" alignItems="center"  >
                                    <Grid item style={{ marginTop: "6px" }}><span style={{ marginLeft: "20px" }}>{links[key].icon}</span></Grid>
                                    <Grid item><span style={{ marginLeft: "10px" }}> {links[key].text} </span></Grid>
                                </Grid>
                            </div>
                        </NavLink>
                    ))}
                    <Link href='https://cool-harbour.helpjuice.com/' target="_blank" style={{ display: 'inline-block', minWidth: "100%", marginBottom: "10px", color: "black", textDecoration: "none", fontSize: "16px" }}
                         activeStyle={{ backgroundColor: '#ADCBE5', color: 'black', fontWeight: "bold" }}>
                            <div  style={{ fontSize: "16px" }}>
                                <Grid container direction="row" alignItems="center"  >
                                    <Grid item style={{ marginTop: "6px" }}><span style={{ marginLeft: "20px" }}><HelpIcon /></span></Grid>
                                    <Grid item><span style={{ marginLeft: "10px" }}>Help</span></Grid>
                                </Grid> 
                            </div>
                    </Link>
                </div>
            </Drawer>
            <main className={classes.content}>
                {props.children}
            </main>
        </div>

    );
}

export default ClippedDrawer;
