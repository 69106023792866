import HttpService from "./HttpService";

const axios = HttpService.getAxiosClient();
const methods = HttpService.HttpMethods;
const baseURL = process.env.REACT_APP_BACKEND_URL;
const rootRequestMapping = 'templates'

async function getAllTemplates() {
    try {
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/all`,
            method: methods.GET
        });

        return (res.status === 200) ? res.data : false;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}

const getTemplatesByCategory = async (catId) =>{
    try {
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/category/${catId}`,
            method: methods.GET
        });

        return (res.status === 200) ? res.data : false;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}

async function disableTemplate(id) {
    try {
        await axios.request({
            url: `${baseURL}${rootRequestMapping}/${id}`,
            method: methods.DELETE
        })
    } catch (error) {
        console.error(error);
        return;
    }
}

const checkCode = async (id, code, entity) => {
   
    try {
      let res = await axios.post(`${baseURL}uniquecheck/check`, {
        "id": id,
        "code": code,
        "entity": entity
      })
      console.log(res)
      return res.data;
    }
  
    catch (error) {
      console.error(error)
    }
  }
  

async function addTemplate(data) {
    try {
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}`,
            method: methods.POST,
            data: data
        });

        return  res;
    }
    catch (error) {
        console.error(error);
        return error;
    }
}

async function getAllTemplatesSetup() {
    try {
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/setup`,
            method: methods.GET
        });

        return (res.status === 200) ? res.data : false;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}

export { getAllTemplates, addTemplate, disableTemplate, getAllTemplatesSetup, checkCode, getTemplatesByCategory};