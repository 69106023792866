import SendIcon from '@mui/icons-material/Send';

import { Dialog, DialogTitle, DialogActions, DialogContentText, TextField, Button, DialogContent } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@mui/material/Checkbox';
import RetestTable from "./RetestTable"
import { retest } from "../../services/ReportsService"
import OKDialog from "../Dialogs/OKDialog"

const RetestDialog = (props) => {

    const [checkedIds, setCheckedIds] = useState([]);
    const [OKDlgState, setOKDlgState] = useState({ shown: false });
    const { t } = useTranslation();



    //console.log("----> " + JSON.stringify(props.iDoc, null, 2))

    const sendRequest = () => {

     
        if (checkedIds.length === 0) {
            setOKDlgState({
                shown: true,
                title: t("Nothing selected..."),
                msg: t("You must check at least one box in order to submit a request."),
                flavour: "warning", callback: () => { setOKDlgState({ ...OKDlgState, shown:false})}
            })
            return;
        }

        retest(checkedIds).then((res) => {
            if (res) {

                setOKDlgState({
                    shown: true,
                    title: t("Retest request submitted..."),
                    msg: t("The selected test / sample cmobinations have been submitted for retest."),
                    flavour: "success", callback: () => { setOKDlgState({ ...OKDlgState, shown: false }) }
                })
              

            }

            else {
                setOKDlgState({
                    shown: true,
                    title: t("Request error!"),
                    msg: t("The request was not successful. Check network connection."),
                    flavour: "error", callback: () => { setOKDlgState({ ...OKDlgState, shown: false }) }
                })
               

            }

            props.callback();

        })
       
    }
   

    return (
        <div>

            <Dialog
                open={props.show}
                fullWidth={true} maxWidth={'md'}
                onClose={(event, reason) => {
                    if (reason && reason == "backdropClick") return;
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                   {t("Request a retest")}
                </DialogTitle>
                <DialogContent>
                    {props.iDoc && <>
                        <div>{t("test_method_tab")}: <b>{props.iDoc.testMethod}</b></div>
                        <div style={{ color: "grey" }}>( {t("The table shows the result for each test on each sample. Use the checkboxes to select which to retest.")} )</div>
                    </>}
                   
                    <RetestTable iDoc={props.iDoc} checkedIds={checkedIds} setCheckedIds={setCheckedIds} /> 
                </DialogContent>
                <DialogActions>
                    <div style={{ marginBottom: "15px", marginRight:"15px" }}>
                        <Button variant="contained" onClick={() => {props.callback("cancel") }}>{t("Cancel")}<CancelIcon style={{ marginLeft: "7px" }}/></Button>
                        <Button variant="contained" style={{ marginLeft: "15px" }} onClick={() => { sendRequest() }}>{t("Send Request")}<SendIcon style={{marginLeft:"7px"}}/></Button>
                    </div>
                </DialogActions>
            </Dialog>
            <OKDialog show={OKDlgState.shown}
                callback={OKDlgState.callback}
                title={OKDlgState.title}
                message={OKDlgState.msg}
                flavour={OKDlgState.flavour}/>
        </div>
    );
}

export default RetestDialog;