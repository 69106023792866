import HttpService from "./HttpService";
const axios = HttpService.getAxiosClient();
const methods = HttpService.HttpMethods;
const baseURL = process.env.REACT_APP_BACKEND_URL;
const rootRequestMapping = 'registerJob'

async function getAllRegisteredJobs() {
    let language = localStorage.getItem("language");
    try {
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/all/${language}`,
            method: methods.GET
        });

        return (res.status === 200) ? res.data : false;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}
//countbycat

const getJobCountPerYear = async (year) => {
    try {
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/count/${year}`,
            method: methods.GET
        });
        return  res.data;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}

const getJobCountByCat = async (year, catId) => {
    try {
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/countbycat/${year}/${catId}`,
            method: methods.GET
        });
        return res.data;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}


const getJobsByCat = async (year, catId) => {
    try {
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/getbycat/${year}/${catId}`,
            method: methods.GET
        });
        return res.data;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}


const getJobsPerMonth = async (year) => {
    try {
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/getjobspermonth/${year}`,
            method: methods.GET
        });
        return res.data;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}


const getJobsperRegion = async (year) => {
    try {
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/getjobsperregion/${year}`,
            method: methods.GET
        });
        return res.data;
    }
    catch (error) {
        console.error(error);
        return null;
    }

}



const getJobFromProfile = async (profileId, jobId) => {
    let language = localStorage.getItem("language");
    try {
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/fromprofile/${profileId}/${jobId}/${language}`,
            method: methods.GET
        });

        return (res.status === 200) ? res.data : null;
    }
    catch (error) {
        console.error(error);
        return null;
    }


}
// /registerJob/comments/3434


const checkCode = async (id, code, entity) => {
   
    try {
      let res = await axios.post(`${baseURL}uniquecheck/check`, {
        "id": id,
        "code": code,
        "entity": entity
      })
      console.log(res)
      return res.data;
    }
  
    catch (error) {
      console.error(error)
    }
  }

async function getJobById(id){
    let language = localStorage.getItem("language");
    try {
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/${id}/${language}`,
            method: methods.GET
        });

        return (res.status === 200) ? res.data : false;
    }
    catch (error) {
        console.error(error);
        return false;
    }
}

async function disableJob(id) {
    let language = localStorage.getItem("language");
    try {
        await axios.request({
            url: `${baseURL}${rootRequestMapping}/delete/${id}/${language}`,
            method: methods.GET
        })
    } catch (error) {
        console.error(error);
        return;
    }
}


async function addJob(data) {
    let language = localStorage.getItem("language");
    try {
        if(data.state == null)  data.state = "Received";
        if (data.jobChanged) delete data.jobChanged
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/${language}`,
            method: methods.POST,
            data: data,
        });

        return (res.status === 200) ? res.data : false;
    }
    catch (error) {
        console.error(error);
        return false;
    }
}

const savejobPage1 = async (data) => {
    let language = localStorage.getItem("language");
    try {

        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/pageone/${language}`,
            method: methods.POST,
            data: data
        });

        return res.data;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}



const saveJobAsyncStream = async (data) => {
    try {
        if (data.state == null) data.state = "Received";
        if (data.jobChanged) delete data.jobChanged
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/savejobasyncstream`,
            method: methods.POST,
            data: data,
        });

        return res.data;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}


const ssetest = async (data) => {
    try {
       
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/ssetest`,
            method: methods.POST,
            data: data
        });

        return res.data;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}


const savejobStage = async (stage, data) => {
    const stages = [
        "savestageone", "savestagetwo",
        "savestagethree", "savestagefour",
        "savestagefive", "savestagesix"
    ]
    try {

        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/${stages[stage -1]}`,
            method: methods.POST,
            data: data
        });
        console.log("step: " + stage)
        console.log(res.data);
        return res.data;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}












const getJobProfiles = async () => {
    try {
        let res = await axios.request({
            url: `${baseURL}jobprofile/all`,
            method: methods.GET
        });

        return (res.status === 200) ? res.data : false;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}


const addJobProfile = async (profile) => {
    try {
        let res = await axios.request({
            url: `${baseURL}jobprofile`,
            method: methods.POST,
            data: profile
        });

        return (res.status === 200) ? res.data : false;
    }
    catch (error) {
        console.error(error);
        return false;
    }

}

const deleteJobProfile = async (id) => {
    try {
        let res = await axios.request({
            url: `${baseURL}jobprofile/delete/${id}`,
            method: methods.GET
        });

        return (res.status === 200) ? res.data : false;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}


const saveJobComments = async (comment, jobId) => {
    let language = localStorage.getItem("language");
    try {
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/comments/${jobId}/${language}`,
            method: methods.POST,
            data: { text: comment}
        });

        return (res.status === 200) ? res.data : false;
    }
    catch (error) {
        console.error(error);
        return false;
    }

}

const getOpenReports = async () => {
    let language = localStorage.getItem("language");
    try {
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/list/open/${language}`,
            method: methods.GET
        });

        return (res.status === 200) ? res.data : false;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}

// closed reports.
const getClosedReports = async () => {
    let language = localStorage.getItem("language");
    try {
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/list/closed/${language}`,
            method: methods.GET
        });

        return (res.status === 200) ? res.data : false;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}

// closed reports.
const getLegacyReports = async () => {
    let language = localStorage.getItem("language");
    try {
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/list/legacy/${language}`,
            method: methods.GET
        });

        return (res.status === 200) ? res.data : false;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}


export const downloadReport = async (type, jobId) => {
    try {
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/${type}/${jobId}`,
            method: methods.GET,
            responseType: 'arraybuffer'
        });

        return (res.status === 200) ? res.data : false;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}


function convertRowData(data) {
    let rows;

    rows = data.map(item => {
        return {
            id: item.id,
            jobID: (item.JobID != null) ? item.JobID : "",
            addDate: (item.AddDate != null) ? item.AddDate : "",
            dueDate: (item.DueDate != null) ? item.DueDate : "",
            sampleCount: (item.SampleCount != null) ? item.SampleCount : "",
            sampleType: (item.SampleTypeName != null) ? item.SampleTypeName : "",
            client: (item.Client != null) ? item.Client : "",
            department: (item.Department != null) ? item.Department : "",
            state: (item.State != null) ? item.State : "",
            lastUpdate: (item.lastUpdate != null) ? item.lastUpdate : "",
            addedBy: (item.addedBy != null) ? item.addedBy : ""
        }
    });

    return rows;
}

export {
    getAllRegisteredJobs,
    getJobCountPerYear,
    getJobCountByCat,
    getJobsByCat,
    getJobsPerMonth,
    getJobsperRegion,
    addJob, saveJobAsyncStream, savejobPage1,
    ssetest, savejobStage, convertRowData,
    disableJob, getJobById, getJobProfiles, addJobProfile,
    deleteJobProfile, getOpenReports, getClosedReports, getLegacyReports,
    checkCode, saveJobComments, getJobFromProfile
};