
import Keycloak from 'keycloak-js';


// Setup Keycloak instance...
// Load initialization options from 'keycloak.json'. 
// (Alternative is to pass in options as an object)

const keycloak = Keycloak("/keycloak.json");



const initKeycloak = (renderApp) => {
   
    keycloak.init({
        onLoad: 'login-required', //'check-sso', //pkceMethod: 'S256',
    }).then((authenticated) => {
        renderApp(authenticated);
    })
};


keycloak.onAuthSuccess = () => {
    console.log('Keycloak token aquired!');
}

keycloak.onTokenExpired = () => {
    console.log('Keycloak token expired!');
    keycloak.updateToken(30).then(() => {
        console.log('Keycloak token refresh succeeded!');
    }).catch(() => {
        console.log("Keycloak token refresh failed!");
    });
}

const doLogin = () => {
    keycloak.login();
}

const doLogout = () => {
    keycloak.logout()
};

const getToken = () => {
    return keycloak.token;
}

const isLoggedIn = () => {
   return !!keycloak.token;
}

//const updateToken = (successCallback) =>
//    keycloak.updateToken(5)
//        .then(successCallback)
//        .catch(doLogin);

const getUsername = () => keycloak.tokenParsed?.preferred_username;


const hasRole = (roles) => {
    return roles.some((role) => keycloak.hasRealmRole(role));
}


const getUserProfile = async () => {
    return await keycloak.loadUserProfile()
}

const getUserInfo= async () => {
    return await keycloak.loadUserInfo()
}

const UserService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    //updateToken,
    getUsername,
    hasRole,
    getUserProfile,
    getUserInfo
};

export default UserService;
