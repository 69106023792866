

import { Button, IconButton, Paper, Switch, Tooltip } from '@material-ui/core';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { useStyles } from "./styles"
import ViewIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import { useTranslation } from 'react-i18next';
import OKCancelDialog from '../Dialogs/OKCancelDialog'
import { deleteResultVideo } from '../../services/FileService'


const VideosComponent = (props) => {

    const [videosShown, setVideosShown] = useState(true)
    const [video, setVideo] = useState(null)
    const [videoSrc, setVideoSrc] = useState(null)
  //  const [vidToDelete, setVidToDelete] = useState(null)
    const [OKCancelDialogState, setOKCancelDialogState] = useState({ shown: false })
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const { t } = useTranslation();
    
   
    useEffect(() => {
        if (video) setVideoSrc(`data:video/mp4;base64,${video.bytes}`)
        else setVideoSrc(null)
    }, [video])


    const confirmDeletevid = (vid) => {
      //  setVidToDelete(vid)
        setOKCancelDialogState({
            shown: true,
            title: t("Confirm delete") + "...",
            message: t("Are you sure you want to delete this video? (No undo)"),
            flavour: "warning",
            okText: t("delete_label"),
            callback: (res) => {
                if (res === "OK") deleteVideo(vid);
                setOKCancelDialogState({ shown: false })
            }
        })
    }


    const deleteVideo = (v) => {
       // if (vidToDelete === null) return;
        deleteResultVideo(v.id).then((res) => {
            if (!res.req_error) {
                let vids = res.map((vid) => {
                    let ext = vid.key.split('.').pop();
                    let temp = ".";
                    if (vid.sequence > 1) temp = "(" + vid.sequence + ")."
                    let fileName = vid.uniqueId + "." + vid.testName + temp + ext
                    return { bytes: vid.image, key: vid.key, name: fileName, id: vid.id }
                })
                props.setVideos(vids)
            }
            else console.log(res.req_error)
          //  setVidToDelete(null)
        })
    }





    useEffect(() => {
        const videos = props.videos;
        let temp = videos.map((vid) => {
            return (
                <div style={{ display: 'flex' }}>
                    <div style={{ maxWidth: "60%", overflowX: "hidden", flex: 1 }}><p>{vid.name}</p></div>
                    <IconButton onClick={() => { setVideo(vid); console.log(JSON.stringify(vid.key, null, 2))}}><ViewIcon /></IconButton>
                    <IconButton onClick={() => { confirmDeletevid(vid) }}>< DeleteIcon /></IconButton>
                </div>
            )

        })
        setRows(temp)
    }, [props.videos])



    const showHideVideos = (e) => {
        setVideosShown(e.target.checked);
    }


    return (
        <div>
            <Paper className={classes.paper} style={{ width: "100%", padding: "25px", textAlign: "center" }}>
                <table>
                    <tr>
                        <td style={{ width: "50px" }}>
                            <div></div>
                        </td>
                        <td style={{ width: "200px" }}><span ><b>{t("Videos")}</b></span></td>
                        <td><Tooltip followCursor={true} title={t("Show / Hide")}>
                            <Switch checked={videosShown} onChange={showHideVideos} />
                        </Tooltip></td>
                    </tr>
                </table>
                <div style={{ height: "380px", overflowY: "auto", overflowX: "auto" }}>
                    {videosShown ? rows.length > 0 ? <div>{rows}</div> :
                        t("No videos to show yet. Videos may be uploaded via the iDoc app") :
                        t("Videos will not be shown for this test method.")}
                </div>
              
            </Paper>
            <Dialog
                open={video !== null}
                fullWidth={true} maxWidth={'md'}
                onClose={(event, reason) => {
                    if (reason && reason == "backdropClick") return;
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {video !== null ? video.name : null}
                </DialogTitle>
                <DialogContent >
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <video type="video/mp4" src={videoSrc} autoPlay controls loop  />
                    </div>
                </DialogContent>
                <DialogActions>
                    <div style={{ marginBottom: "15px", marginRight: "15px" }}>
                        <Button variant="contained" onClick={() => {
                            setVideo(null)
                        }}>OK<CancelIcon style={{ marginLeft: "7px" }} /></Button>

                    </div>
                </DialogActions>
            </Dialog>
            <OKCancelDialog
                show={OKCancelDialogState.shown}
                title={OKCancelDialogState.title}
                message={OKCancelDialogState.message}
                flavour={OKCancelDialogState.flavour}
                okText={OKCancelDialogState.okText}
                callback={OKCancelDialogState.callback}
            />
        </div>

    )

}

export default VideosComponent;