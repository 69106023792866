/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import { addProfileJob, getProfileJobById } from '../../services/ProfileJobsService';
import { Alert, AlertTitle } from '@material-ui/lab';
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import { getCategories } from '../../services/CategoryService';
import { SingleSelector } from "../SelectComponents"
import CancelIcon from '@mui/icons-material/CancelOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import SaveChangesIcon from '@mui/icons-material/SaveAsOutlined';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Divider, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Slider from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';
import TestMethodTestsDLG from './TestMethodTestsDLG'


const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        backgroundColor: 'paper',

    },
    dialog: {
        width: '400px',
        display: 'flex'
    },
    dialogBox: {
        display: 'flex',
        alignItems: 'center'
    },
    dialogText: {
        flex: '0.4',
    },
    dialogInput: {
        flex: '0.5',

    },
    formControl: {
        margin: theme.spacing(2),
        flex: '0.5',
        maxWidth: 300,

    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    dialogCheckbox: {
        flex: '0.04'
    },
    testMethodDialog: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1em'
    }
}));



function ProfileJobDialog(props) {
    
    const classes = useStyles();
    const { t } = useTranslation();
    const { openDialog, openEdit, selectedItems, text, updateTable, onDialogClose } = props;
    const [profileJobName, setProfileJobName] = useState("");
    const [numberOfSamples, setNumberOfSamples] = useState(6);
    const [testMethods, setTestMethods] = useState([]);
    const [categoryDetails, setCategoryDetails] = useState([]);
    const [category, setCategory] = useState({});
    const [alertBox, setAlertBox] = useState("");
    const [alertContext, setAlertContext] = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const [emptyFlag, setEmptyFlag] = useState(false)
    const [marks, setMarks] = useState(null);
    const [zeroTests, setZeroTests] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(false)
    const redAsterisk = <span style={{ color: "red" }}><b>*</b></span>

    const getLoadingSpinner = () => {
        var msg = <span>Loading available test methods....</span>;
        if (category && category.name) msg = <span>Loading available test methods for <b>{category.name}</b> package type. Please wait...</span>
        return (
            <div style={{ marginTop: "20px" }}>
                <CircularProgress size="1rem" />
                <span style={{ marginLeft: "10px" }}>{msg}</span>
            </div>
        )
    }

  
    useEffect(() => {
        getCategories(localStorage.getItem("language")).then((data) => {
            if (!(data instanceof Array)) {
                setAlertContext({ ...alertContext, open: true });
                return;
            }
            if (data.length === 0) setEmptyFlag(true);
            else setEmptyFlag(false)
            setCategoryDetails(data);
        })
    }, []);


    useEffect(() => {
        if (openEdit) {
            setProfileJobName(selectedItems.name)
            setNumberOfSamples(selectedItems.numberOfSamples)
            setCategory(selectedItems.category);
            
        }
    }, [openEdit])


    const fetchData = async (profileId) => {
        setLoading(true);
        let data = await getProfileJobById(category.id, profileId);
        setLoading(false);
        if (!data) {
            setAlertContext({ ...alertContext, open: true });
            return;
        }
        setTestMethods(data.contents);
    }


    useEffect(() => {
        
        if (category && category.id) {

            if (openEdit && selectedItems.id && testMethods.length === 0 && emptyFlag === false) {
                setTestMethods([])
                fetchData(selectedItems.id)
                
            }
            else {
                setTestMethods([])
                fetchData(-1);
            } 
        }
    }, [category]);


    const clearFields = () => {
        console.log("clear fields")
        setAlertBox("");
        setProfileJobName("");
        setNumberOfSamples(6);
        setCategory({});
        setTestMethods([]);
        setZeroTests(false)
    };

    const handleCancel = () => {
        updateTable();
        onDialogClose();
        clearFields();
    };


    const handleAlertBox = (messages) => {
        setAlertBox(<div style={{marginBottom:"20px"}}>
            <Alert severity="warning">
                <AlertTitle>Missing (or invalid) required data: </AlertTitle>
                {messages.map((message, index) => {
                    return <li key={index}>{message}</li>
                })
                }
            </Alert>
        </div>)
    };


    const handleSave = async () => {
        let alertMessages = [];
     
        if (profileJobName.length === 0) alertMessages.push('Please provide a job profile Name')
        if (Object.keys(category).length === 0) alertMessages.push('Please provide a package type');
        if (numberOfSamples <= 0) alertMessages.push('Please provide a sample number greater than 0');

        if (zeroTests) {
            setSaveDisabled(true);
            return
        }
        if (alertMessages.length !== 0) handleAlertBox(alertMessages);
        else {
            let res;
            if (openEdit) {
                res = await addProfileJob(selectedItems.id, profileJobName, numberOfSamples, category, testMethods);
            }
            else {
                res = await addProfileJob(null, profileJobName, numberOfSamples, category, testMethods);
            }
            updateTable();
            onDialogClose(res);
            clearFields();
        }
    };

    useEffect(() => {
        if (!zeroTests) setSaveDisabled(false)
    }, [zeroTests])


    const onCheckChange = (e, tm) => {
        if (e.target.checked) {
            tm.selected = true;
            if (!tm.sampleStart) tm.sampleStart = 1;
            if (!tm.sampleEnd) tm.sampleEnd = tm.testMethod.defaultSamples != null ? tm.testMethod.defaultSamples : numberOfSamples;//(tm.testMethod.defaultSamples != null && tm.testMethod.defaultSamples < numberOfSamples) ? tm.testMethod.defaultSamples : numberOfSamples
        }
        else {
            tm.selected = false;
        }

        checkForZeroTests();

        setTestMethods(JSON.parse(JSON.stringify(testMethods)))
    }


    const onSliderChange = (e, tm) => {
        tm.sampleStart = e.target.value[0];
        tm.sampleEnd = e.target.value[1];
        setTestMethods(JSON.parse(JSON.stringify(testMethods)))
    };


    useEffect(() => {
        var arr = []
        arr.push({ value: 1, label: `1` })
        if (numberOfSamples < 21) {
            for (var i = 2; i <= numberOfSamples; i++) {
                arr.push({ value: i, label: `${i}` })
            }
        }
        else if (numberOfSamples < 100) {
            for (var i = 5; i <= numberOfSamples; i += 5) {
                arr.push({ value: i, label: `${i}` })
            }
        }
        else arr.push({ value: numberOfSamples, label: `${numberOfSamples}` })

        setMarks(arr)

    }, [numberOfSamples])



    const onNumberSampleChange = (numSamples) => {
        
        testMethods.forEach((tm) => {
            if (numSamples) {
                if (tm.sampleStart > numSamples) {
                    tm.sampleStart = numSamples;
                }
                if (tm.sampleEnd > numSamples) {
                    tm.sampleEnd = numSamples;
                }
            }
            
        });

        setNumberOfSamples(numSamples)

    }

    const applySelectedTests = (testMethodId, selectedTests) => {
        var tm = testMethods.find((t) => { return testMethodId === t.testMethod.id })
        if (tm) tm.tests = selectedTests;
        checkForZeroTests();
        setTestMethods(JSON.parse(JSON.stringify(testMethods)))
    }

    const getNumberTestsSelected = (tm) => {
        let numberSelected = 0;
        if (tm.selected && tm.tests.length > 0) {
            numberSelected = tm.tests.length;
        }
        return numberSelected;
    }


    const checkForZeroTests = () => {
        let temp = false;
        testMethods.forEach((item) => {
            if (item.selected && item.tests.length === 0) {
                temp = true;
            }
        })
        setZeroTests(temp);
    }

   

    return (
        <div className={classes.root}>
            <Dialog open={openDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth="lg" minHeight="xl">{/* sm, xs, md, lg, xl*/}
                <DialogTitle id="form-dialog-title">{(openEdit ? t("edit_profile_job") :  t("add_profile_job"))} </DialogTitle>
              
                <DialogContent>
                    {alertBox}
                    <div className={classes.dialogBox}>
                        <Box display="flex" flexDirection="row">

                            <div>
                                <Typography variant="body1" className={classes.dialogText}>
                                    {t("name_label")} *
                                </Typography>
                                <TextField
                                    style={{ marginTop: "16px" }}
                                    value={profileJobName}
                                    onChange={(e) => { setProfileJobName(e.target.value) }}
                                    className={classes.dialogInput}
                                    autoFocus margin="dense" id="name"
                                    type="input" variant="outlined"
                                    required
                                    placeholder={t("profile_job_name")} />
                            </div>
                            
                            <div style={{marginLeft:"25px"}}>
                                <Typography variant="body1" className={classes.dialogText}>
                                    {t("profile_job_sample_number")} *
                                </Typography>
                                <TextField
                                    style={{ marginTop: "16px" }}
                                    value={numberOfSamples ? numberOfSamples : ""}
                                    onChange={(e) => { onNumberSampleChange(e.target.value); }}
                                    className={classes.dialogInput}
                                    autoFocus margin="dense" id="name" type="number" variant="outlined"
                                    InputProps={{ inputProps: { min: 1, max: Number.MAX_SAFE_INTEGER } }}
                                    required
                                    placeholder={t("profile_job_sample_number")} />
                            </div>


                            <div style={{ marginLeft: "25px" }}>

                                <div style={{marginLeft:"15px"}}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("Category")} {Object.keys(category).length > 0 && testMethods.length > 0 ? "*": redAsterisk}
                                    </Typography>
                                </div>

                               
                                <FormControl className={classes.dialogInput} variant='outlined' className={clsx(classes.formControl, classes.noLabel)}>  
                                <SingleSelector width={275} isMulti={false} placeholder={t("type_to_find_categories")}
                                    value={ { value: category, label: category.name } }
                                    options={categoryDetails.map((c) => ({ value: c, label: c.name }))}
                                    handleSelect={(e) => { setCategory(e.value);}}/>
                                </FormControl>
                               


                            </div>

                            <div style={{ marginTop: "50px", marginLeft: "25px" }}>( * {t("required fields")} )</div>
                        </Box>

                    </div>

                    
                   
                    {loading ? getLoadingSpinner() : testMethods.length > 0 ? <div style={{ flex: '100%' }}>
                        <br />
                        <Divider />
                        <br />
                        <p id="form-dialog-title">{t("Select from available Test Methods in")} <b>{category.name ? `${category.name} ` : ""}</b>{t("Category")}...</p>
                        <table style={{ width: "100%", textAlign: "left" }}>
                            <colgroup>
                                <col span="1" style={{ width: "10%" }} />
                                <col span="1" style={{ width: "20%" }} />
                                <col span="1" style={{ width: "54%" }} />
                                <col span="1" style={{ width: "5%" }} />
                                <col span="1" style={{ width: "11%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>Selected</th>
                                    <th>Test Method</th>
                                    <th style={{ textAlign: "center" }}>Sample Subset</th>
                                    <th></th>
                                    <th>Tests to Include</th>
                                </tr>
                                {testMethods.map((tm) => {
                                   
                                    return (
                                        <tr>
                                            <td>
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    value={tm.testMethod.id}
                                                    onChange={(e) => { onCheckChange(e, tm) }}
                                                    checked={tm.selected}
                                                />
                                            </td>
                                            <td><div >{tm.testMethod.name}</div></td>
                                            <td>
                                                <div style={{ marginTop: "30px", marginLeft: "30px", marginRight: "20px" }}>
                                                    <Slider
                                                        disabled={!tm.selected}
                                                        aria-label={() => 'Sample Range'}
                                                        min={1}
                                                        max={numberOfSamples}
                                                        value={[tm.sampleStart, tm.sampleEnd]}
                                                        step={1}
                                                        valueLabelDisplay={tm.selected ? "auto" : "off"}
                                                        marks={marks ? marks : true}
                                                        onChange={(e) => onSliderChange(e, tm)} />
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{ marginTop: "20px" }}>
                                                   
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{marginLeft:"5px"}}>
                                                    <TestMethodTestsDLG
                                                        testMethodId={tm.testMethod.id}
                                                        testMethodName={tm.testMethod.name}
                                                        callback={(res) => { applySelectedTests(tm.testMethod.id, res) }}
                                                        enabled={tm.selected}
                                                        checkedTests={ openEdit ? tm.tests : null}
                                                        numberSelected={ getNumberTestsSelected(tm) }
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>


                    </div> :
                        <div style={{ marginTop: "20px" }}>
                            {Object.keys(category).length > 0 ? <span>{redAsterisk} {t("No test methods available for this category yet. Please choose another")}.</span> :
                                <span>{redAsterisk} {t("Select")} <b>{t("Category")}</b> {t("to view and choose from its available")} <em>{t("test_method_tab")}</em>.</span>}
                        </div>
                    }

                   
                </DialogContent>
                <DialogActions>
                    
                    {saveDisabled && <span style={{ color: "red", marginRight: "70px" }}>
                        There are one or more checked test methods that have 0 tests. You must address this before you can save.</span>}
                    <Tooltip title="Save">
                        <IconButton disabled={saveDisabled} onClick={handleSave} color="primary">
                            {openEdit ? <SaveChangesIcon /> : <SaveIcon />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Cancel">
                        <IconButton onClick={handleCancel}>
                            <CancelIcon />
                        </IconButton>
                    </Tooltip>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ProfileJobDialog