import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import TabPanel from '../SystemSetup/TabPanel'
import { useTranslation } from 'react-i18next';
import UserService from "../../keycloak";

function a11yProps(index) {
  console.log({
    id: `lab-tab-${index}`,
    'aria-controls': `lab-tabpanel-${index}`,
  });
  return {
    id: `lab-tab-${index}`,
    'aria-controls': `lab-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    backgroundColor: 'paper',
    overflow: 'auto',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
      backgroundColor: '#ADCBE5',//'paper',
      color: "black",
      boxShadow: "none",
      borderRadius: "5px",
      overflow: 'hidden',
      maxWidth: '700px',
  },
  secondAppBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
      marginTop:"6px",
      backgroundColor: 'lightGrey',
      color: 'black',
      borderRadius: "5px",
      boxShadow: "none",
      overflow: 'hidden',
      maxWidth: '700px',
      alignItems: 'center',
     
     
  },
}));

export default function LabSetUpTabs() {
  const classes = useStyles();
  const [secondRowTabValue, setSecondRowTabValue] = useState(1);
  const [labSetUpvalue, setLabSetUpValue] = useState(0);
  const [sampleSetUpvalue, setSampleSetUpValue] = useState(1);
  const [testingSetUpValue, setTestingSetUpValue] = useState(0);
  const { t } = useTranslation();
  const [userPermitted, setUserPermitted] = useState(true);

  const handleLabSetUpTabChange = (event, newValue) => {
    setLabSetUpValue(newValue);
  };

  const handleSampleSetUpChange = (event, newValue) => {
    setSampleSetUpValue(newValue);
  };

  const handleTestingSetUpChange = (event, newValue) => {
    setTestingSetUpValue(newValue);
  };

  const handlePrimaryTabChange = (event, newValue) => {
    setLabSetUpValue(0);
    setSampleSetUpValue(1);
    setTestingSetUpValue(0);
    setSecondRowTabValue(newValue);
  };

  useEffect(() => {
      if (UserService.hasRole(['LAB_MANAGER', 'LAB_SUPERVISOR']) && !UserService.hasRole(['ADMIN'])){
      setUserPermitted(false);
    }
  }, []);

  const isEolasUser = UserService.hasRole(['EOLAS_USER']) && !UserService.hasRole(["ADMIN", "LAB_SUPERVISOR", 'LAB_MANAGER', 'ANALYST', 'TECHNICIAN']);

  const primaryLabels = [t("lab_setup_title"), t("Sample Set-Up"), t("Testing Set-Up")]
  const labSetUpLabels = [ t("department_tab"),t("equipment_tab"), t("personnel_tab"), t("client_tab")];
  const sampleSetUpLabels = [t("category_label_plural"),t("sample_type_tab"), t("job_reasons_tab")];
  const testingSetUpLabels = [t("test_method_tab"), t("profile_jobs_tab")];

  return (
    <div className={classes.root}>
          <AppBar position="static" className={classes.appBar}>
              <Tabs value={secondRowTabValue} onChange={handlePrimaryTabChange} aria-label="lab set-up tabs" className={classes.tabs}>
                  <Tab label={primaryLabels[0]}  {...a11yProps(0)} className={classes.tab} />
                  <Tab label={primaryLabels[1]} {...a11yProps(1)} className={classes.tab} />
                  <Tab label={primaryLabels[2]} {...a11yProps(2)} className={classes.tab} />
              </Tabs>
          </AppBar>

          {secondRowTabValue == 0 && <AppBar index={0} position="static" className={classes.secondAppBar}>
              <Tabs value={labSetUpvalue} onChange={handleLabSetUpTabChange} aria-label="lab set-up tabs" className={classes.tabs}>
                  <Tab label={labSetUpLabels[0]} {...a11yProps(4)} className={classes.tab} />
                  <Tab label={labSetUpLabels[1]} {...a11yProps(5)} className={classes.tab} />
                  <Tab label={labSetUpLabels[2]} {...a11yProps(6)} className={classes.tabs} />
                  <Tab label={labSetUpLabels[3]} {...a11yProps(6)} className={classes.tab} />
              </Tabs>
          </AppBar>}

          {secondRowTabValue == 1 && <AppBar value={secondRowTabValue} position="static" className={classes.secondAppBar}>
              <Tabs value={sampleSetUpvalue} onChange={handleSampleSetUpChange} aria-label="lab set-up tabs" className={classes.tabs}>
                  <Tab label={sampleSetUpLabels[0]}  {...a11yProps(0)} className={classes.tab} />
                  <Tab label={sampleSetUpLabels[1]} {...a11yProps(2)} className={classes.tab} />
                  <Tab label={sampleSetUpLabels[2]} {...a11yProps(2)} className={classes.tab} />{/* figure out why a11yprops numbers are what they are, 1 here is nominal */}
              </Tabs>
          </AppBar>}

          {secondRowTabValue == 2 && <AppBar value={secondRowTabValue} position="static" className={classes.secondAppBar}>
              <Tabs value={testingSetUpValue} onChange={handleTestingSetUpChange} aria-label="lab set-up tabs" className={classes.tabs}>
                  <Tab label={testingSetUpLabels[0]} {...a11yProps(1)} className={classes.tab} />
                  <Tab label={testingSetUpLabels[1]} className={classes.tab} />
              </Tabs>
          </AppBar>}



          {/* Lab setup panels */}
          {secondRowTabValue === 0 && <TabPanel value={labSetUpvalue} index={0} text={labSetUpLabels[0]} section="lab" />}
          {secondRowTabValue === 0 && <TabPanel value={labSetUpvalue} index={1} text={labSetUpLabels[1]} section="lab" />}
          {secondRowTabValue === 0 && !isEolasUser && <TabPanel value={labSetUpvalue} index={2} text={labSetUpLabels[2]} section="lab" />}
          {secondRowTabValue === 0 && <TabPanel value={labSetUpvalue} index={3} text={labSetUpLabels[3]} section="lab" />}

          {/* Sample setup tab panels */ }
          {secondRowTabValue === 1 && <TabPanel value={sampleSetUpvalue} index={0} text={sampleSetUpLabels[0]} section="sample" />}
          {secondRowTabValue === 1 && <TabPanel value={sampleSetUpvalue} index={1} text={sampleSetUpLabels[1]} section="sample" />}
          {secondRowTabValue === 1 && <TabPanel value={sampleSetUpvalue} index={2} text={sampleSetUpLabels[2]} section="sample" />}

          { /* Testing setup tab panels */}
          {secondRowTabValue === 2 && <TabPanel value={testingSetUpValue} index={0} text={testingSetUpLabels[0]} section="testing" />}
          {secondRowTabValue === 2 && <TabPanel value={testingSetUpValue} index={1} text={testingSetUpLabels[1]} section="testing" /> }
          
        

    </div>
  );
}



 //<Tab label={testingSetUpLabels[1]} {...a11yProps(3)} className={classes.tab} />
  //                <Tab label={testingSetUpLabels[2]} className={classes.tab} />

//t("templates_tab"), t('Job Profiles'),