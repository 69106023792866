const Color = require('color');
let colors = [
    Color('#FF0000'), Color('#FF8C00'), Color('#FFD700'), Color('#008000'),
    Color('#0000FF'), Color('#40E0D0'), Color('#FF1493'), Color('#808000'),
    Color('#008080'), Color('#800080'), Color('#3CB371'), Color('#A0522D'),
    Color('#20B2AA'), Color('#4B0082')
]
let temp = colors.map((c) => { return c.negate() })
colors = colors.concat(temp);
export const graphBorders = colors.map((c) => { return c.hexa() })
export const graphBackgrounds = colors.map((c) => { return c.alpha(0.3).hexa() })