import { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Box } from '@material-ui/core'
import { validate } from 'react-email-validator'
import { addClient, updateClient, checkCode } from '../../services/ClientsService';
import { Alert, AlertTitle } from '@material-ui/lab';
import MaterialUiPhoneNumber from 'material-ui-phone-number';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import IconButton from '@material-ui/core/IconButton';
import SaveChangesIcon from '@mui/icons-material/SaveAsOutlined';
import { useTranslation } from 'react-i18next';

function ClientDialog(props) {
  const { openDialog, openEdit, selectedItems, text, updateTable, onDialogClose } = props;
  const [open, setOpen] = useState(false);
  const [clientName, setClientName] = useState("");
  const [description, setDescription] = useState("");
  const [contactFirstName, setContactFirstName] = useState("");
  const [contactLastName, setContactLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [clientId, setClientId] = useState("");
  const [alertBox, setAlertBox] = useState("");
  const { t } = useTranslation();

  const handleClientNameChange = (event) => {
    setClientName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleContactFirstNameChange = (event) => {
    setContactFirstName(event.target.value);
  };

  const handleContactLastNameChange = (event) => {
    setContactLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  const handleClientIdChange = (event) => {
    setClientId(event.target.value);
  };

  useEffect(() => {
    if (openEdit) {
      setClientName(selectedItems.name)
      setDescription(selectedItems.description)
      setContactFirstName(selectedItems.contact.split(" ")[0])
      setContactLastName(selectedItems.contact.split(" ")[1])
      setEmail(selectedItems.email)
      setPhone(selectedItems.phone)
      setClientId(selectedItems.clientId)
    }
  }, [openEdit])

  useEffect(() => {
    if (openDialog === true)
      setOpen(true);
    else if (openDialog === false)
      setOpen(false);
  }, [openDialog]);

  const clearFields = () => {
    setClientName("");
    setDescription("");
    setContactFirstName("");
    setContactLastName("");
    setEmail("");
    setPhone("");
    setClientId("");
    setAlertBox("");
  };

  const handleAlertBox = (messages) => {
    setAlertBox(<Alert severity="warning">
    <AlertTitle>{openEdit ? "Could not edit Client" : "Could not create Client"}</AlertTitle>
    { messages.map((message, index)=>{
      return <li key={index}>{message}</li>
    })    
    }
  </Alert>)
  };

  const handleSave = async () => {
    let alertMessages = [];

    if (clientName.length === 0) alertMessages.push('Provide a Client Name')
    if (contactFirstName.length === 0) alertMessages.push('Provide a First Name')
    console.log(contactLastName)
    if (contactLastName.length === 0) alertMessages.push('Provide a Last Name')
    if (email.length === 0) alertMessages.push('Provide an email')
    if (clientId.length === 0) alertMessages.push('Provide a Client ID')
    if (!validate(email)) alertMessages.push("Provide a valid email");

    if (openEdit) {
      if(await checkCode(selectedItems.id, clientId, "Client")) {
        alertMessages.push("Client ID must be unique");
      } 
    }

    if (!openEdit) {
      if(await checkCode(null, clientId, "Client")) {
        alertMessages.push("Client ID must be unique");
      }
    }

    if (alertMessages.length !== 0) handleAlertBox(alertMessages);

    else {
      let res;
      if(openEdit) res = await updateClient(selectedItems.id, clientName, description, contactFirstName, contactLastName, email, phone, clientId)
      else res = await addClient(clientName, description, contactFirstName, contactLastName, email, phone, clientId)
      updateTable();
      onDialogClose(res);
      clearFields();
    }
  };

  const handleCancel = () => {
    updateTable();
    onDialogClose();
    clearFields();
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      flex: 1,
      backgroundColor: 'paper',
    },
    dialog: {
      width: '400px',
      display: 'flex'
    },
    dialogBox: {
      display: 'flex',
      alignItems: 'center'
    },
    dialogText: {
      flex: '0.3'
    },
    dialogInput: {
      flex: '0.5'
    },
  }));

  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">{/* sm, xs, md, lg, xl*/}
        <DialogTitle id="form-dialog-title">{(openEdit ? t("edit_label") : t("add"))} {t('client_label')} </DialogTitle>
        <DialogContent>
          {alertBox}
          <Box className={classes.dialogBox}>
            <Typography variant="body1" className={classes.dialogText}>
              {t("client_name_field_label")}*
            </Typography>

            <TextField value={clientName} placeholder={t("client_name_field_label")} onChange={handleClientNameChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required />
          </Box>
          <Box className={classes.dialogBox}>
            <Typography variant="body1" className={classes.dialogText}>
              {t("description_field_label")}
            </Typography>

            <TextField value={description} onChange={handleDescriptionChange} className={classes.dialogInput} placeholder={t("enter_description_placeholder")} autoFocus multiline rows={3} margin="dense" id="description" type="input" variant="outlined" required />
          </Box>
          <Box className={classes.dialogBox}>
            <Typography variant="body1" className={classes.dialogText}>
              {t("contact_label")}*
            </Typography>
            <Box className={classes.dialogInput} style={{ flex: 'row'}}>
                <TextField value={contactFirstName} placeholder={t("first_name_label")} onChange={handleContactFirstNameChange} className={classes.dialogInput} style={{ marginRight: "5%", maxWidth: '45%' }} autoFocus margin="dense" id="name" type="input" variant="outlined" required />
                <TextField value={contactLastName} placeholder={t("last_name_label")} onChange={handleContactLastNameChange} className={classes.dialogInput} style={{ maxWidth: '45%' }} autoFocus margin="dense" id="name" type="input" variant="outlined" required />
            </Box>
          </Box>
          <Box className={classes.dialogBox}>
            <Typography variant="body1" className={classes.dialogText}>
              {t("email_label")}*
            </Typography>

            <TextField value={email} placeholder={t("email_address_label")} onChange={handleEmailChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required />
          </Box>
          <Box className={classes.dialogBox}>
            <Typography variant="body1" className={classes.dialogText}>
              {t("phone_label")}
            </Typography>

            <MaterialUiPhoneNumber disableAreaCodes={true} defaultCountry={'us'} className={classes.dialogInput} value={phone} onChange={handlePhoneChange}/>
          </Box>
          <Box className={classes.dialogBox}>
            <Typography variant="body1" className={classes.dialogText}>
              ID*
            </Typography>

            <TextField value={clientId} placeholder={t("client_id_field_label")} onChange={handleClientIdChange} className={classes.dialogInput} style={{ maxWidth: '20%' }} autoFocus margin="dense" id="name" type="input" variant="outlined" required />
          </Box>
        </DialogContent>
        <DialogActions>
          <Tooltip title="Save">
            <IconButton onClick={handleSave} color="primary">
              {openEdit ? <SaveChangesIcon /> : <SaveIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title={t("Cancel")}>
            <IconButton onClick={handleCancel}> 
              <CancelIcon/>
            </IconButton>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ClientDialog;