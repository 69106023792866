import React, { useContext, useState, useEffect, useRef } from 'react';
import moment from "moment"
import { useTranslation } from 'react-i18next';
import DashIcon from '@mui/icons-material/HorizontalRule';

export const parseDate = (date, format) => {
    if (date === null) return <span style={{ color: "lightgrey" }}><DashIcon /></span>
    try {
        let rtn = null;
        let temp = moment(date).format(format)
        if (temp !== "Invalid date") rtn = temp;
        if (rtn === null || rtn === "") rtn = <span style={{ color: "lightgrey" }}><DashIcon /></span>
        return rtn;
    }
    catch (err) {
        return <span style={{ color: "lightgrey" }}><DashIcon /></span>
    }
}


const JobInfoTable = (props) => {
    const { t } = useTranslation();
    const [job, setJob] = useState(null)

    useEffect(() => {
        parseJob(JSON.parse(JSON.stringify(props.job)));
    }, [])

    const parseDate = (date, format) => {
        if (date === null) return "N/A"
        try {
            let rtn = null;
            let temp = moment(date).format(format)
            if (temp !== "Invalid date") rtn = temp;
            if (rtn === null || rtn === "") rtn = "N/A"
            return rtn;
        }
        catch (err) {
            return "N/A"
        }
    }
    const getJobStates = () => {

        const states = {
            0: { text: t('state_received_label'), color: "#ADCBE5" },
            1: { text: t('state_inProgress_label'), color: "#F7C377" },
            2: { text: t('state_onHold_label'), color: "#F7C377"},
            3: { text: t('state_completedAccepted_label'), color: "#77C780" },
            4: { text: t('state_completedPending_label'), color: "#BEE8C4" },
            5: { text: t('state_overdue_label'), color: "#DD7677" },
        }
        return states;
    }
    //const jobStates = {
    //    0: { text: "Received", color: "#ADCBE5" },
    //    1: { text: "In progress", color: "#F7C377" },
    //    2: { text: "On hold", color: "#F7C377" },
    //    3: { text: "Completed (Accepted)", color: "#77C780" },
    //    4: { text: "Completed (Pending)", color: "#BEE8C4" },
    //    5: { text: "Overdue", color: "#DD7677" },
    //}

    const getProgress = (row) => {
        var progress = Math.floor((row.testsCompleted / (row.numberOfTests === null ? 0 : row.numberOfTests)) * 100)
        if (isNaN(progress)) progress = 0;
        return (progress)
    }

    const getState = (j) => {
        let progress = getProgress(j)
        if (j === null) return ""
        return <span id={j.state} style={{
            border: "1px solid",
            backgroundColor: getJobStates()[j.state].color,
            padding: "2px"
        }}>{" " + getJobStates()[j.state].text + " - " + progress + "%"}
        </span>
    }

    const parseJob = (j) => {
       
        if (j.addedBy == null) j.addedBy = "<unknown>"
        j.addDate = parseDate(j.addDate, 'DD/MM/YYYY')
        j.updatedAt = parseDate(j.updatedAt, 'DD/MM/YYYY @ HH:mm')
        j.dueDate = parseDate(j.dueDate, 'DD/MM/YYYY')
        try {
            var names = j.analysisTemplateNames;
            let temp = names.length > 1 ? ` & ${names.length - 1} more...` : ""
            j.analysisTemplate = names[0] + temp;
        }
        catch (err2) { j.analysisTemplate = "<unknown>" }

        if (j.client && j.client.name && (j.client.name !== null)) j.clientName = j.client.name
        else j.clientName = "<unknown>"
        setJob(j);
    }


    if (job !== null) return (
        <table style={{ width: "100%" }}>
            <tr>

                <td>{t('Added by')}:<b> {job.addedBy}</b></td>
                <td>{t('No of samples')}: <b>{job.sampleCount}</b></td>
                <td>{t('Client')}:<b> {job.clientName}</b></td>
            </tr>
            <tr>
                <td>{t('Date added')}:<b> {job.addDate}</b></td>
                <td>{t('Sample Type')}:<b> {job.sampleTypeName}</b></td>
                <td>{t('Last updated')}:<b> {job.updatedAt}</b></td>
            </tr>
            <tr>
                <td>{t('Due date')}:<b> {job.dueDate}</b></td>
                <td>{t('Based on Job Profile')}:<b> {job.jobProfile && job.jobProfile.name ? job.jobProfile.name: "n/a"}</b> </td>
                <td>{t('State')}: {getState(job)}</td>
            </tr>
        </table>)
    return null;
}

export default JobInfoTable