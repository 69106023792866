
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import { Button, Tooltip } from "@material-ui/core";
import { getHelpVideo } from '../../services/FileService'
import VideoIcon from '@mui/icons-material/OndemandVideo';

const HelpVideoDialog = (props) => {

    const { topic } = props;
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState(topic);
    const [vidAvailable, setVidAvailable] = useState(false);
    const [vidSrc, setVidSrc] = useState(null);
    const { t } = useTranslation();

    const topics = [t("department_tab"), t("equipment_tab"), t("test_method_tab"), t("register_samples_title"), `${t("testing_title")} & ${t("reports_title")}`];


    // Determine if a video is available for the topic.
    useEffect(() => {
        setVidAvailable(topics.includes(topic))
    }, [])


    // If so, get it from the back-end...
    useEffect(() => {
        if (vidAvailable) getVid();
    }, [vidAvailable])



    const getVid = () => {

        // Map the topic to its video ID.
        let id = -1;
        switch (topic) {
            case topics[0]: case topics[2]:
                id = 0; setTitle(t("Departments & Personnel")); break;
            case topics[1]:
                id = 1; break;
            case topics[3]:
                id = 3; break;
            case topics[4]:
                id = 2; setTitle(t("Create Job & Register Samples")); break;
            case topics[5]:
                id = 4; break;
            default:  break;
        }

        // Get video by ID and load it into video tag.
        if (id > -1) {
            getHelpVideo(id).then((res) => {
                if (res) setVidSrc(`data:video/mp4;base64, ${res.video}`)
                else setVidSrc(null)
            })
        }
    }

    
    return (<>
        {vidAvailable &&   // The video button is only shown if a video is available for the topic.
            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', flex: '25%' }}>
                <Tooltip title={t("Watch a tutorial video for this section")}>
                    <Button
                        size="small"
                        onClick={() => { setOpen(true) }}
                        aria-label="videos"
                        variant="contained"
                        startIcon={<VideoIcon style={{ marginLeft: "10px" }} />}>
                        
                    </Button>
                </Tooltip>
            </div>
        }

        <Dialog
            open={open}
            fullWidth={true} maxWidth={'lg'}
            onClose={(e, reason) => { if (reason && reason == "backdropClick") return; }}>
            <DialogTitle>{t("Instructional Video")}: {title}
                <div style={{ position: 'absolute', top: "15px", right: "20px" }}>
                    <Tooltip title={t("Close")}>
                    <span style={{cursor:'pointer', color: 'red'}} onClick={() => { setOpen(false) }}> 
                        <CancelIcon/>
                        </span>
                    </Tooltip>
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{ textAlign: "center" }}>
                    <video
                        width="1100"
                        height="619"
                        type="video/mp4"
                        src={vidSrc}
                        autoPlay controls loop/>
                </div>
            </DialogContent>
            <DialogActions>
                <div style={{ marginTop: "45px" }}></div>
            </DialogActions>
        </Dialog>

    </>

    )

}

export default HelpVideoDialog;
