import { useState, useEffect, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@material-ui/core';
import { addTemplate, checkCode } from '../../services/AnalysisTemplateService';
import { AlertContext } from '../Contexts/AlertContext/AlertContext'; 
import { getTestMethodsByCategoryId } from '../../services/TestMethodsService';
import { getCategories } from '../../services/CategoryService';
import { SingleSelector } from "../SelectComponents"
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import SaveChangesIcon from '@mui/icons-material/SaveAsOutlined';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    backgroundColor: 'paper',
  },
  dialog: {
    width: '400px',
    display: 'flex'
  },
  dialogBox: {
    display: 'flex',
    alignItems: 'center'
  },
  dialogText: {
    flex: '0.4'
  },
  dialogInput: {
    flex: '0.5'
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    maxWidth: 300,
    flex: '0.5'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(2),
  },
}));

function AnalysisTemplatesDialog(props) {
  const { openDialog, openEdit, selectedItems, text, updateTable, onDialogClose } = props;
  const { t } = useTranslation();
  const [alertBox, setAlertBox] = useState("");
  const [templateID, setTemplateId] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState({});
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [sampleCount, setSampleCount] = useState(0);
  const [testMethods, setTestMethods] = useState([]);
  const [testMethodDetails, setTestMethodDetails] = useState([]);
  const [alertContext, setAlertContext] = useContext(AlertContext);



  const handleSampleCountChange = event => {
    let tmpValue = event.target.value;
    if (tmpValue.length === 0)
      tmpValue = '';
    if (tmpValue.length != 0) {
      let regEx = /^[0-9\b]+$/
      if (!regEx.test(tmpValue))
        tmpValue = 0;
      if (tmpValue < 0)
        tmpValue = 0;
      tmpValue = parseInt(tmpValue);
    }
    setSampleCount(tmpValue);
  }

  const handleTemplateIdChange = event => {
    setTemplateId(event.target.value)
  }

  const handleTemplateNameChange = event => {
    setTemplateName(event.target.value)
  }

  const handleDescriptionChange = event => {
    setDescription(event.target.value);
  };

  const handleCategoryChange = event => {
    setTestMethods([]);
    getTestMethodsByCategoryId (event.value.id).then(data=>{
      if(!(data)){
        setAlertContext({...alertContext, open: true});
        return;
      }
      setTestMethodDetails(data);
    });
  };

  const handleTestMethodChange = event => {
    setTestMethods(event.target.value);
  }

  useEffect(() => {
    if (openEdit) {
      setTemplateName(selectedItems.templateName);
      setTemplateId(selectedItems.templateID);
      setDescription(selectedItems.description);
      setSampleCount(selectedItems.sampleCount);
      let temp = categoryDetails.find((c) => { return c.id === selectedItems.category.id })
      if (temp) setCategory(temp);
    }
  }, [openEdit]);

  useEffect(() => {
    const fetchData = async () => {
      let data = await getCategories();

      if(!(data instanceof Array)){
        setAlertContext({...alertContext, open: true});
        return;
      }
      setCategoryDetails(data);
    }

    fetchData();
  }, []);


  useEffect(() => {
    if(category.id != undefined){
      let categoryId = category.id;
      const fetchData = async () => {
        let data = await getTestMethodsByCategoryId (categoryId);
        if(!(data)){
          setAlertContext({...alertContext, open: true});
          return;
        }
        setTestMethodDetails(data);
      }
  
      fetchData();

    }
  }, [category]);

  useEffect(() => {
    if (openEdit && testMethodDetails.length > 0) {
      let tmpTestMethods = [];
      let testMethodIds = [];
      selectedItems.testMethods.forEach(testMethod=>{
        testMethodIds.push(testMethod.id);
      });
      testMethodDetails.forEach(testMethod=>{
        testMethodIds.forEach(id=>{
          if(id == testMethod.id)
            tmpTestMethods.push(testMethod);
        })
      });
      setTestMethods(tmpTestMethods);
    }
  }, [testMethodDetails]);

  const clearFields = () => {
    setTemplateName("");
    setTemplateId("");
    setDescription("");
    setCategory({});
    setSampleCount(0);
    setAlertBox("");
    setTestMethods([]);
    setTestMethodDetails([]);
  };

  const handleAlertBox = (messages) => {
    setAlertBox(<Alert severity="warning">
      <AlertTitle>Could not create Analysis Template</AlertTitle>
      {messages.map((message, index) => {
        return <li key={index}>{message}</li>
      })}
    </Alert>)
  };

  const handleSave = async () => {
    let alertMessages = [];

    if (templateName.length === 0) alertMessages.push("Provide a Template Name");
    if (templateID.length === 0) alertMessages.push("Provide a Template ID");
    if (Object.keys(category).length === 0) alertMessages.push("Provide a Package Type");
    if (testMethods.length === 0) alertMessages.push("Provide a Test Method");

    
    if (openEdit) {
      if(await checkCode(selectedItems.id, templateID, "AnalysisTemplate")) {
        alertMessages.push("Template ID must be unique");
      } 
    }

    if (!openEdit) {
      if(await checkCode(null, templateID, "AnalysisTemplate")) {
        alertMessages.push("Template ID must be unique");
      }
    }

    if (alertMessages.length > 0) handleAlertBox(alertMessages);

    else {
      let res;
      if (openEdit) res = await addTemplate({
        id: selectedItems.id,
        templateName: templateName,
        templateID: templateID,
        description: description, 
        category: category,
        sampleCount: sampleCount,
        testMethods: testMethods,
      });
      else res = await addTemplate({
        templateName: templateName,
        templateID: templateID,
        description: description, 
        category: category,
        sampleCount: sampleCount,
        testMethods: testMethods,
      })
      updateTable();
      onDialogClose(res);
      clearFields();
    }
  };

  const handleClose = () => {
    clearFields();
    updateTable();
    onDialogClose();
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Dialog open={openDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
        <DialogTitle id="form-dialog-title">{(openEdit ? t("edit_label") : t("add"))} {t('analysis_template_label')} </DialogTitle>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
          {alertBox}
          <div className={classes.dialogBox} required>
            <Typography variant="body1" className={classes.dialogText}>
              {t("template_name_field_label")}*
            </Typography>
            <TextField value={templateName} onChange={handleTemplateNameChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required />
          </div>
          <div className={classes.dialogBox} required>
            <Typography variant="body1" className={classes.dialogText}>
              {t("template_id_field_label")}*
            </Typography>
            <TextField value={templateID} onChange={handleTemplateIdChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required />
          </div>
          <div className={classes.dialogBox} required>
            <Typography variant="body1" className={classes.dialogText}>
              {t("description_field_label")}
            </Typography>
            <TextField value={description} onChange={handleDescriptionChange} className={classes.dialogInput} placeholder={t("enter_description_placeholder")} autoFocus multiline rows={3} margin="dense" id="description" type="input" variant="outlined" required />
          </div>
          <div className={classes.dialogBox}>
            <Typography variant="body1" className={classes.dialogText}>
            {t("category_label")}*
            </Typography>
                      <FormControl  style={{ flex: '0.525' }} variant='outlined' className={clsx(classes.formControl, classes.noLabel)}>
                          <SingleSelector placeholder={"Start typing to find package type..."} width={275}
                              value={{ value: category, label: category.name }}
                              options={categoryDetails.map((c) => ({ value: c, label: c.name }))}
                              handleSelect={(e) => { setCategory(e.value); handleCategoryChange(e)}}>
                          </SingleSelector>
                      </FormControl>      
          </div>

          <div className={classes.dialogBox} required>
            <Typography variant="body1" className={classes.dialogText}>
              {t("sample_count_label")}
            </Typography>
            <TextField value={sampleCount} onChange={handleSampleCountChange} className={classes.dialogInput} placeholder={t("enter_sample_count")} autoFocus margin="dense" id="sampleCount" variant="outlined" required />
          </div>
          <div className={classes.dialogBox}>
            <Typography variant="body1" className={classes.dialogText}>
            {t("test_methods_label")}*
            </Typography>

            <FormControl  style={{ flex: '0.525' }} variant='outlined' className={clsx(classes.formControl, classes.noLabel)}>
              <SingleSelector width={275} isMulti={true} placeholder={category.id!=null?t("select_all_that_apply"):t("select_category_first")} 
                            value={testMethods.map((testMethod)=>{return { value: testMethod, label: testMethod.name }})}
                            options={testMethodDetails.map((testMethod) => ({ value: testMethod, label: testMethod.name }))}
                            handleSelect={(e) => {
                                var temp = e.map((obj) => { return obj.value });
                                setTestMethods(temp);
                            }}>
                        </SingleSelector>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
        <Tooltip title="Save">
            <IconButton onClick={handleSave} color="primary"> 
              {openEdit ? <SaveChangesIcon /> : <SaveIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title={t("Cancel")}>
            <IconButton onClick={handleClose}> 
              <CancelIcon/>
            </IconButton>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AnalysisTemplatesDialog;
