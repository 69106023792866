/* eslint-disable react-hooks/exhaustive-deps */


import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslation } from "react-i18next";
import { pink, yellow } from "@material-ui/core/colors";
import { Box, Button, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DataTable from "react-data-table-component";
import TestDialog from "../Dialogs/TestDialog";
import OKDialog from "../Dialogs/OKDialog";
import CircularProgress from '@mui/material/CircularProgress';
import HelpVideoDialog  from '../Dialogs/HelpVideoDialog'
import { getCategories } from '../../services/CategoryService'
import { SingleSelector } from '../../components/SelectComponents'
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from "@material-ui/icons/Search";
import FilterIcon from '@mui/icons-material/FilterList';
const customStyles = {
    
    headCells: {
        style: {
            fontSize: ".7rem"
        },
    },
    cells: {
        style: {
            fontSize: ".7rem"
        },
    },
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    paper: {
        marginBottom: theme.spacing(2),
    },

    table: {
        width: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    tableHeader: {
        display: "flex",
        alignItems: "center",
        columnGap: "1rem",
        paddingTop: "1rem",
        overflow: "visible",
        marginBottom: "0.3rem",
        maxWidth:"80vw"
    },
    selectedCount: {
        flex: "2 2 90%",
    },
    title: {
        flex: "1 1",
        whiteSpace: "nowrap",
    },
    editButton: {
        backgroundColor: yellow[800],
        borderColor: yellow[800],
        color: "white",
        "&:hover": {
            backgroundColor: yellow[900],
        },
    },
    filterButton: {

        backgroundColor: pink[600],
        borderColor: pink[600],
        color: "white",
        "&:hover": {
            backgroundColor: pink[700],
        },
    },
    searchBar: {
        flex: "1 1 30%",
    },
}));

const TestMethodsTable = (props) => {
    const {
        text,
        Dialog,
        tableProps,
        openTestDialog,
        onTestDialogClose,
        updateTable,
        paginationPerPage,
        columns,
        rows,
        selectedRow,
        deleteCallback,
        dense,
        keyColumn,
        parentAdd,
        parentEdit,
        selectedItem,
        useDialog,
        responseForConfirmationMessage,
        resetForConfirmationMessage
    } = props;

    const [openDialog, setOpenDialog] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [clearSelectedRows, setClearSelectedRows] = useState(false);
    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const { t } = useTranslation();
    const byCatPlaceholder = { value: null, label: "Filter by package type..." };
    const byNamePlaceHolder = { value: null, label: `Search by ${keyColumn.key}...` }
    const [categoriesOpts, setCategoriesOpts] = useState([]);
    const [selectedCat, setSelectedCat] = useState(byCatPlaceholder)
    const [filteredRows, setFilteredRows] = useState([]);
    const [nameOpts, setNameOpts] = useState([]);
    const [selectedName, setSelectedName] = useState(byNamePlaceHolder)


    useEffect(() => {
        updateTable(clearOptions);
        if (categoriesOpts.length === 0) {
            getCategories().then((res) => {
                if (res) {
                    var opts = res.map((c) => { return { value: c, label: c.name } })
                    setCategoriesOpts(opts)
                }
            })
        }
       
    }, []);


    useEffect(() => {
        setFilteredRows(rows)
        setNameOpts(rows.map((r) => { return { value: r , label: r.name} }))
    }, [rows])



    useEffect(() => {
        if (selectedCat.value) {
            setSelectedName(byNamePlaceHolder)
            var cat = selectedCat.value
            var temp = rows.filter((r) => {
                var catIds = r.categories.map((c) => { return c.id})
                return catIds.includes(cat.id)
                
            })
            if (temp.length > 0) setFilteredRows(temp);
            else {
                setSelectedCat(byCatPlaceholder)
                alert(`No results for '${cat.name}' package type'. Click OK to show all...`)
                setFilteredRows(rows)
            }
        }
    }, [selectedCat])



    useEffect(() => {
        if (selectedName.value) {
            setSelectedCat(byCatPlaceholder)
            var row = selectedName.value
            var temp = rows.find((r) => { return r.id === row.id})
            if (temp) setFilteredRows([temp]);
        }
    }, [selectedName])



    const [OKDialogState, setOKDialogState] = useState({
        shown: false, title: "", message: "", flavour: "success"
    })

   

    const onDialogClose = (event) => {
        if (useDialog == null) {
            setOpenDialog(false);
            setOpenEdit(false);
            if (event) handleStatusCode(event);
        }
        else {
            parentAdd[1](false);
            parentEdit[1](false);
        }
    };

    const handleStatusCode = (event) => {
        console.log("!!!!!!!!!!")
        switch (event.status) {
            case 200:
                setOKDialogState({
                    shown: true,
                    title: responseForConfirmationMessage ? "Test Saved" : text.substring(text.length - 1) === "s" ? text.substring(0, text.length - 1) + " Saved" : text + " Saved",
                    message: event.data.name + ` saved successfully`,
                    flavour: "success",
                    callback: resetMessage
                })
                break;
            default:
                setOKDialogState({
                    shown: true,
                    title: "Failure",
                    message: `failed to save, try later`,
                    flavour: "error",
                    callback: resetMessage
                });
                break;
        }
    };

    useEffect(() => {
        if (responseForConfirmationMessage) {
            onDialogClose(responseForConfirmationMessage)
        }
    }, [responseForConfirmationMessage]);

    const resetMessage = () => {
        if (responseForConfirmationMessage) {
            resetForConfirmationMessage(null);
        }
    }


    const handleClearSelectedRows = () => {
        setClearSelectedRows(!clearSelectedRows);
    };

    const clearOptions = () => {
        setSelected([]);
        handleClearSelectedRows();
    };

    const handleDelete = async () => {
        await deleteCallback(selected);
        updateTable(clearOptions);
    };

    const handleEdit = () => {
        if (useDialog == null) {
            setOpenDialog(true);
            setOpenEdit(true);
        }
        else {
            selectedItem[1](selected[0]);
            parentAdd[1](true);
            parentEdit[1](true);
        }
    };

    const handleAdd = () => {
        if (useDialog == null)
            setOpenDialog(true);
        else
            parentAdd[1](true);
    };

    const selectedItemText = () => {
        if (selected.length === 0) return "";
        if (selected.length === 1) return `1 ${t("one_row_selected")}`;
        if (selected.length > 1 && selected.length < rows.length)
            return `${selected.length} ${t("rows_selected")}`;
        if (selected.length === rows.length) return t("all_rows_selected");

        return "";
    };

    

    



    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Box mx={2} className={classes.tableHeader}>
                    <Typography
                        className={classes.title}
                        style={{ fontWeight: 400, fontSize: "22px" }}
                    >
                        {text}
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', flex: '30%' }}>
                        <Tooltip title={t("add") + " " + (text.substring(text.length - 1) === "s" ? text.substring(0, text.length - 1) : text)}>
                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={handleAdd}
                                aria-label="add"
                                startIcon={<AddIcon style={{ marginLeft: "30%" }} />}
                            >
                                {t("add")} {text.substring(text.length - 1) === "s" ? text.substring(0, text.length - 1) : text}
                            </Button>
                        </Tooltip>
                    </div>
                   
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', flex: '5%' }}>
                        <Tooltip title={t("Edit")}>
                            <span disabled={!(selected.length > 0)}>
                                <Button
                                    disabled={selected.length !== 1}
                                    size="small"
                                    className={classes.editButton}
                                    onClick={handleEdit}
                                    startIcon={<EditIcon style={{ marginLeft: "30%" }} />}
                                    aria-label="edit"
                                    variant="contained"
                                >
                                    {/* t("filter_label") */}
                                </Button>
                            </span>
                        </Tooltip>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', flex: '5%' }}>
                        <Tooltip title={t("Delete")}>
                            <span disabled={!(selected.length > 0)}>
                                <Button
                                    disabled={!(selected.length > 0)}
                                    size="small"
                                    onClick={handleDelete}
                                    aria-label="delete"
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<DeleteIcon style={{ marginLeft: "30%" }} />}
                                >
                                    {/* t("delete_label") */}
                                </Button>
                            </span>
                        </Tooltip>
                    </div>

                    <HelpVideoDialog topic={text} />

                    <Typography
                        className={classes.selectedCount}
                        style={{ color: "grey", fontSize: "16px" }}>
                        {selectedItemText()}
                    </Typography>

                    <div style={{ display: "flex" }}>
                        <div>
                            <SingleSelector
                                options={categoriesOpts}
                                width={300}
                                value={selectedCat}
                                onInputChange={(e) => { }}
                                icon={<FilterIcon />}
                                handleSelect={(opt) => { setSelectedCat(opt); }} /> 

                            <SingleSelector
                                options={nameOpts}
                                width={300}
                                value={selectedName}
                                onInputChange={(e) => { }}
                                icon={<SearchIcon/>}
                                handleSelect={(opt) => { setSelectedName(opt); }} />

                        </div>
                       
                        <Tooltip title="Reset filters / Show all">
                            <div style={{ marginTop: "25px", marginLeft:"10px" }}>
                                <IconButton size="small" style={{ backgroundColor: "#F0F0F0", marginLeft: "5px" }}
                                    onClick={() => {
                                        setSelectedCat(byCatPlaceholder)
                                        setSelectedName(byNamePlaceHolder)
                                        setFilteredRows(rows)
                                    }}>
                                    <ClearIcon fontSize="inherit" />
                                </IconButton>
                            </div>
                        </Tooltip>

                    </div>

                   
                </Box>

                <DataTable
                    selectableRows
                    pagination
                    openDialog={
                        (<Dialog />).type === (<TestDialog />).type
                            ? openTestDialog
                            : openDialog
                    }
                    paginationPerPage={
                        typeof paginationPerPage !== 'undefined'
                            ? paginationPerPage
                            : 90
                    }
                    customStyles={customStyles}
                    striped
                    {...tableProps}
                    onSelectedRowsChange={(e) => setSelected(e.selectedRows)}
                    columns={columns}
                    data={filteredRows}
                    dense={dense}
                    noDataComponent={<div style={{ textAlign: "center", marginBottom: "20px" }}><p>{t("Loading table data")}...</p> <CircularProgress style={{ marginBottom: "50px" }} /></div>}
                    paginationRowsPerPageOptions={[30, 60, 90, 120]}
                    clearSelectedRows={clearSelectedRows}
                    paginationComponentOptions={{
                        rowsPerPageText: t("rows_per_page_label"),
                        rangeSeparatorText: t("range_seperator_label"),
                    }}
                />

            </Paper>
           
            <Dialog
                openDialog={
                    (<Dialog />).type === (<TestDialog />).type
                        ? openTestDialog
                        : openDialog
                }
                onDialogClose={
                    (<Dialog />).type === (<TestDialog />).type
                        ? onTestDialogClose
                        : onDialogClose
                }
                selectedItems={selected[0]}
                viewing={null}
                parentRow={selectedRow}
                openEdit={openEdit}
                updateTable={() => updateTable(clearOptions)}
            />
           
            <OKDialog
                show={OKDialogState.shown}
                title={OKDialogState.title}
                message={OKDialogState.message}
                flavour={OKDialogState.flavour}
                callback={
                    (res) => {
                        let callback = OKDialogState.callback;
                        setOKDialogState({ shown: false });
                        if (callback) callback(res);
                        // resetForConfirmationMessage(null);
                    }
                }
            />
         
        </div>
    );
}

export default TestMethodsTable;
