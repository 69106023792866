

import { Box, Button,  Typography,  } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { getJobById } from '../../services/RegisterJobService';
import { getIDocsInJob, } from '../../services/iDocWorkQueService'
import { getTestResultsByIdocId, getTestsByIdocId} from '../../services/iDocSessionsService'
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import Paper from "@material-ui/core/Paper";
import CircularProgress from '@mui/material/CircularProgress';
import "react-table-6/react-table.css";
import { useTranslation } from 'react-i18next';


const OpenSessionTable = (props) => {   // Test Results Table....
    const { t } = useTranslation();

    const [title, setTitle] = useState("");
    const [rows, setRows] = useState([]);
    const [cols, setCols] = useState([]);
    const [next, setNext] = useState(null);
    const [prev, setPrev] = useState(null);
    const [job, setJob] = useState(null);
    const [alertContext, setAlertContext] = useContext(AlertContext);
    const [timeoutElapsed, setTimeoutElapsed] = useState(false);
  
    const classes = props.classes
    const params = new URLSearchParams(window.location.search);

    const poller = useRef(null);

    const clrInterval = () => {
        clearInterval(poller.current);
    }


    useEffect(() => {
        populateTable();
        poller.current = setInterval(() => { populateTable() }, 5000)
        return () => { clearInterval(poller.current); }
    }, [window.location.search])

    useEffect(() => {
        if (job == null) return;
        setTitle(`${job.jobID} - ${params.get('iDocTestMethod')} Results`)
    }, [job])


    // Helper function for sorting iDocs by their sequence attribute.
    const sequenceCompare = (a, b) => {
        if (a.sequence < b.sequence) return -1;
        else if (a.sequence > b.sequence) return 1;
        else return 0;
    }

    const colors = {
        pending: "black",
        rejected: "grey",
        passed: "green",
        above_max: "red",
        below_min: "red",
        above_max_within_tolerance: "orange",
        below_min_within_tolerance: "orange"
    }

    const populateTable = () => {
        setTimeoutElapsed(false)
        setTimeout(() => { setTimeoutElapsed(true) }, 3000);

        const iDocId = params.get('iDocId')
        const jobId = params.get('jobId')

        getJobById(jobId).then((job) => {
            setJob(job);
        })


        getIDocsInJob(jobId,localStorage.getItem("language")).then((res) => {
            res.sort((a, b) => sequenceCompare(a, b));

            var nxt = null;
            var prv = null;

            var idx = res.findIndex(e => e.id + "" === iDocId)
            idx++;
            var flag = false;
            while (idx < res.length) {
                switch (res[idx].status) {
                    case "in_progress": case "completed_pending": case "completed_accepted": case "import_results": case "on_hold":
                        nxt = res[idx]
                        flag = true;
                }
                if (flag === true) break;
                idx++;
            }

            idx = res.findIndex(e => e.id + "" === iDocId)
            idx--;
            flag = false;
            while (idx > -1) {
                switch (res[idx].status) {
                    case "in_progress": case "completed_pending": case "completed_accepted": case "import_results": case "on_hold":
                        prv = res[idx]
                        flag = true;
                }
                if (flag === true) break;
                idx--;
            }

            setNext(nxt);
            setPrev(prv);
        })


        getTestsByIdocId(iDocId,localStorage.getItem("language")).then((tests) => {

            if (tests.req_error) {
                setAlertContext({ ...alertContext, open: true });
                return;
            }

            if (tests.length === 0) return;

            var testIds = tests.map((test) => { return test.id })

            var columns = tests.map((header, i) => {
                return { selector: (row) => row[i], sortable: false, name: header.name, type: "text", key: "K" + i }
            })

            columns.unshift({ selector: (row) => row["sample"], sortable: true, name: "Sample", type: "text", key: "Sample" })

            setCols(columns);

            const getCell = (obj) => {
                let result = obj.result;  // pass / fail, etc
                let value = obj.value;
                let id = obj.testResultId
                let test = tests.find((test) => { return test.id === obj.testId });

                let dataType = test.testDataType

                if (dataType === "Pass/Fail") {
                    if (value === 0) value = "fail"
                    else value = "pass"

                    if (value === "fail") result = "above_max"
                    else result = "passed"

                }

                return <span style={{ color: colors[result] }}>{value}</span>;
            }

            getTestResultsByIdocId(iDocId).then((results) => {

                if (results.req_error) {
                    setAlertContext({ ...alertContext, open: true });
                    return;
                }
                var tableRows = []

                if (results.length > 0) {
                    tableRows = results.map((r) => {
                        let row = { sample: r.sample.uniqueId };
                        testIds.forEach((testId, i) => {
                            var result = r.results.find((res) => { return res.testId === testId })
                            if (result) row[i] = getCell(result, row.sample, i + 1);
                            else row[i] = " ----";
                        })
                        return row;
                    });
                }

                if (tableRows.length > 0) {
                    setRows(tableRows);
                    console.log("Test results table refreshed!!")
                }

            });
        })

        console.log("Test results table refreshed!!")

    }

    return (
        <Paper className={classes.paper}>
            <Box mx={2} className={classes.tableHeader}>

                <Typography className={classes.title} >
                    {prev !== null ?
                        <Button variant="outlined" onClick={() => {
                            if (prev === null) return;
                            setRows([])
                            clrInterval();
                            var url = `/testing/?openSessionTable=true&jobId=${job !== null ? job.id : null}&iDocId=${prev.id}&iDocTestMethod=${prev.testMethod}`
                            props.history.push(url)
                        }}>
                            {`previous: ${prev !== null ? prev.testMethod : "n/a"}`}
                        </Button>
                        : ""}
                </Typography>

                <Typography className={classes.title} style={{ fontWeight: 400, fontSize: "20px" }}>{`${title}`}</Typography>

                <Typography>
                    {next !== null ?
                        <Button variant="outlined" onClick={() => {
                            if (next === null) return;
                            setRows([])
                            clrInterval();
                            var url = `/testing/?openSessionTable=true&jobId=${job !== null ? job.id : null}&iDocId=${next.id}&iDocTestMethod=${next.testMethod}`
                            props.history.push(url)
                        }}>
                            {`next: ${next !== null ? next.testMethod : "n/a"}`}
                        </Button>
                        : ""}
                </Typography>

            </Box>


            {rows.length > 0 || timeoutElapsed === true ?
                <DataTable id="OpenSessionTable"
                    customStyles={{
                        table: { style: { width: "85vw" } },
                        headCells: {
                            style: {
                                fontWeight: 'bold',
                                fontSize: "15px"
                            },
                        }
                    }}
                    pagination
                    paginationPerPage={90}
                    paginationRowsPerPageOptions={[30, 60, 90, 120]}
                    noDataComponent={<div style={{ margin: "50px", fontSize: "18px" }}>No results! Will retry...</div>}
                    striped
                    columns={cols}
                    data={rows}
                  
                    clearSelectedRows={() => { }}
                />
                : <div style={{ textAlign: "center", marginBottom: "50px" }}><p>{t("Loading table data")}...</p> <CircularProgress style={{ marginBottom: "50px" }} /></div>}

        </Paper>
    )

}

export default OpenSessionTable