/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import BarChart from '../chartTypes/BarChart';
import { graphBorders, graphBackgrounds } from '../Colors'
import { useTranslation } from 'react-i18next';


const JobsPerMonthChart = (props) => {
    const { data } = props;
    const [chartData, setChartData] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (data) {
            console.log(data)
            convertDataset();
        } 

    }, [data])

    const convertDataset = () => {

       

        const d = {
            labels: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
            datasets: [
                {
                    label: "",
                    data: data,
                    backgroundColor: graphBackgrounds[1],
                    borderColor: graphBorders[1],
                    borderWidth: 1
                },
               
            ]
        }
        setChartData(d);


    }

    return <div >
        <BarChart
            title={t("Total # submissions for year to date, by month") + ":"}
            data={chartData}
            showLegend={false}
            axisLabels={{ x: t("Month"), y: t("Count") }} />
    </div>

}


export default JobsPerMonthChart;