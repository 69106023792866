import HttpService from "./HttpService";
const axios = HttpService.getAxiosClient();
const methods = HttpService.HttpMethods;
const baseURL = "https://dmp.coolharbor.ie/pages/devt/JSON_out/receive_file_endpoint.php";
// const rootRequestMapping = "equipment";

// const getEquipment = async () => {
//   try {
//     const res = await axios.request({
//       url: `${baseURL}${rootRequestMapping}/all`,
//       method: methods.GET
//     });

//     if (res.status === 200)
//       return res.data;

//     return [];
//   }
//   catch (error) {
//     return null;
//   }
// }

// const checkCode = async (id, code, entity) => {
   
//   try {
//     let res = await axios.post(`${baseURL}uniquecheck/check`, {
//       "id": id,
//       "code": code,
//       "entity": entity
//     })
//     console.log(res)
//     return res.data;
//   }

//   catch (error) {
//     console.error(error)
//   }
// }

// const sendToDMP = async (file, testerEmail, testResult, recordId) => {
const sendToDMP = async (bodyData) => {
  // let data = {
  //   excel_file: file,
  //   tester_email: testerEmail,
  //   test_result: testResult,
  //   record_id: recordId
  // };

  let data = bodyData;

  console.log(data);

  // if (id !== undefined && id !== null) data["id"] = id;
  try {
    let res = await axios.request({
      url: `${baseURL}`,
      method: methods.POST,
      data: data
    });

    // if (res.status === 200 || res.status === 201) return res;
    return res;
  }
  catch(error){
    return error;

  }
};

// const deleteEquipment = async (id) => {
//   let response = await axios.request({
//     url: `${baseURL}${rootRequestMapping}/delete/${id}`,
//     method: methods.GET,
//   });

//   return response;
// };

export { sendToDMP };
