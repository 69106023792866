import { useState, useEffect, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { addPersonnel, getPersonnel, checkCode } from '../../services/PersonnelService';
import { Alert, AlertTitle } from '@material-ui/lab';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { validate } from 'react-email-validator'
import { getDepartments } from '../../services/DepartmentsService';
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import IconButton from '@material-ui/core/IconButton';
import SaveChangesIcon from '@mui/icons-material/SaveAsOutlined';
import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import { addUserRoles, createUser, updateUser, deleteUser, getUserRoles,  getRealmUsers, deleteUserRoles, getAllRealmRoles} from '../../services/KeycloakAdminAPI'
import {  SingleSelector } from "../SelectComponents";
import { connect } from 'react-redux';
import VisibilityOn from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        backgroundColor: 'paper',
    },
    dialog: {
        width: '400px',
        display: 'flex'
    },
    nameRow: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    dialogBox: {
        display: 'flex',
        alignItems: 'center',
    },
    nameText: {
        flex: '0.4'
    },
    dialogText: {
        flex: '0.2'
    },
    dialogInput: {
        flex: '0.5'
    },
    formControl: {
        margin: theme.spacing(2),
        flex: 0.5,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(2),
    },
}));



function PersonnelDialog(props) {
    const { openDialog, openEdit, onDialogClose, selectedItems, text, updateTable, realmRoles, allUsers } = props;
    const [departments, setDepartments] = useState([]);
    const [alertBox, setAlertBox] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [department, setDepartment] = useState("");
    const [allRoles, setAllRoles] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [existingRoles, setExistingRoles] = useState([])
    const [username, setUsername] = useState("");
    const [pass, setPass] = useState("")
    const [isPassTemp, setIsPassTemp] = useState(true)
    const [alertContext, setAlertContext] = useContext(AlertContext);
    const { t } = useTranslation();
    const [switchVisible, setSwitchVisible] = useState(false);

    useEffect(() => {

        if (openEdit) {
            setUsername(selectedItems.username)
            setFirstName(selectedItems.firstName);
            setLastName(selectedItems.lastName);
            let dept = selectedItems.attributes.departments[0];
            setDepartment(dept ? dept: "");
            let temp = realmRoles.filter((r) => { return selectedItems.attributes.roles.find((roleName) => { return roleName === r.name }) })
            let temp2 = JSON.parse(JSON.stringify(temp))
            setExistingRoles(temp2 ? temp2 : [])
            setSelectedRoles(temp2 ? temp2 : []);
            setEmail(selectedItems.email);
        }
    }, [openEdit]);

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value)
    }
    const handleLastNameChange = (event) => {
        setLastName(event.target.value)
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleDepartmentChange = (event) => {
        setDepartment(event.target.value);
    };



    useEffect(() => {
        const getDepartmentInfo = async () => {
            let data = await getDepartments();
            if (!(data instanceof Array)) {
                setAlertContext({ ...alertContext, open: true });
                return;
            }
            setDepartments(data.map((d) => { return d.name}));
        }
        getDepartmentInfo();

        if (realmRoles.length > 0)  setAllRoles(realmRoles);
        else setAllRoles(["ADMIN", "LAB_SUPERVISOR", 'LAB_MANAGER', 'ANALYST', 'TECHNICIAN'].map((r, idx) => { return {name:r, id: idx + 1}}))

    }, []);

    const clearFields = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setDepartment("");
        setSelectedRoles([]);
        setAlertBox("");
    };

    const handleAlertBox = (messages) => {
        setAlertBox(<Alert severity="warning">
            <AlertTitle>To save & continue, you need to: </AlertTitle>
            {messages.map((message, index) => {
                return <li key={index}>{message}</li>
            })
            }
        </Alert>);
    };

    const usernameTaken = () => {
        var temp = allUsers.find((user) => { return user.username.toUpperCase() === username.toUpperCase() })
        if (temp && temp.length > 0) return true;
        return false;
    }


    const handleSave = async () => {
        let alertMessages = [];
        if (firstName.length === 0) alertMessages.push("Provide a first name")
        if (lastName.length === 0) alertMessages.push("Provide a last name");
        if (email.length > 0 && !validate(email)) alertMessages.push("Provide a valid email");
        //if (department.length === 0) alertMessages.push("Select a department");
        if (selectedRoles.length === 0 && openEdit) alertMessages.push("Select at least one role");
        if (username.length === 0  && !openEdit) alertMessages.push("Provide a username");
        if (usernameTaken() && !openEdit) alertMessages.push("Provide a UNIQUE username (the one provided is taken)");
        if (pass.length === 0  && !openEdit) alertMessages.push("Provide a password");

        if (alertMessages.length > 0) handleAlertBox(alertMessages);

        else {
           

            let userData = {
                enabled: true, username, firstName, lastName,
                attributes: {
                    roles: selectedRoles.map((r) => { return r.name })
                },
                credentials: [{ type: "password", value: pass, temporary: isPassTemp }]
            }

            if (email.length > 0) userData.email = email;

            if (department.length > 0) {
                let depts = []
                depts.push(department)
                userData.attributes.departments = depts;
            } 

            
            if (openEdit) {
                if (pass.length === 0) delete userData.credentials;
                updateUser(selectedItems.id, userData).then((res) => {
                    if (res.req_error) {
                        onDialogClose({ status: 404 });
                        clearFields();
                        return
                    }

                    deleteUserRoles(selectedItems.id, existingRoles).then(() => {
                        addUserRoles(selectedItems.id, selectedRoles).then(() => {
                            updateTable();
                            onDialogClose({ status: 200, data: { name: "User updated and" } });
                            clearFields();

                        })
                    })

                })
            }
            else {
                createUser(userData).then((res) => {
                    if (res.req_error) {
                        onDialogClose({ status: 404 });
                        clearFields();
                        return
                    }

                    updateTable();
                    onDialogClose({ status: 200, data: { name: "New user added and" } });
                    clearFields();
                   
                })
            }
          
        }
    };


    const handleCancel = () => {
        updateTable();
        onDialogClose();
        clearFields();
    };

    const classes = useStyles();
   
    return (
        <div className={classes.root}>
            <Dialog open={openDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">{/* sm, xs, md, lg, xl*/}

                <DialogTitle id="form-dialog-title">{(openEdit ? t("edit_label") : t("add"))} {t('personnel_tab')} </DialogTitle>
             
                <DialogContent>
                    {!openEdit && <div style={{ marginBottom: "10px" }}>( N.B. Role(s) can only be assigned by editing users once they've been created. )</div>}
                    {openEdit  && <div style={{ marginBottom: "10px" }}>( Manage role(s) and / or update other user details... )</div>}
                    {alertBox}
                    <Divider />
                    <div style={{ marginTop: "15px", marginBottom: "10px" }}>
                        <Typography variant="body1" className={classes.dialogText} style={{ color: "grey" }}>
                            <div style={{ fontStyle: 'italic', textAlign: "left"}}>{t("User Details") + "..."}</div>
                        </Typography>
                    </div>

                    <div className={classes.dialogBox} >
                         <Typography variant="body1" className={classes.dialogText}>
                            {t("first_name_label")}:<span style={{ color: "red" }}> *</span>
                         </Typography>
                        <TextField value={firstName} onChange={handleFirstNameChange} className={classes.dialogInput} autoFocus margin="dense" id="firstName" type="input" variant="outlined" required />
                    </div>

                    <div className={classes.dialogBox}  >
                         <Typography variant="body1" className={classes.dialogText}>
                            {t("last_name_label")}:<span style={{ color: "red" }}> *</span>
                        </Typography>
                        <TextField value={lastName} onChange={handleLastNameChange} className={classes.dialogInput} margin="dense" id="lastName" type="input" variant="outlined" required />
                    </div>


                    <div className={classes.dialogBox}>
                        <Typography variant="body1" className={classes.dialogText}>
                            {t("email_label")}:
                        </Typography>
                        <TextField value={email} onChange={handleEmailChange} className={classes.dialogInput} margin="dense" id="email" type="input" variant="outlined" required />
                    </div>


                   
                    {openEdit && <div className={classes.dialogBox} style={{ marginTop: "7px" }}>
                        <Typography variant="body1" className={classes.dialogText}>
                            {t("role_label") + "(s)"}: <span style={{ color: "red" }}> *</span>
                        </Typography>
                        <div className={classes.dialogInput}>
                            <SingleSelector width={275} isMulti={true} placeholder={t("select") + "..."}
                                value={selectedRoles.map((role) => { return { value: role, label: role.name } })}
                                options={allRoles.map((role) => ({ value: role, label: role.name }))}
                                handleSelect={(e) => {
                                    var temp = e.map((obj) => { return obj.value });
                                    temp = temp.filter((role, idx) => {
                                        return idx === temp.findIndex((t) => (
                                            t.id === role.id
                                        ))
                                    })
                                    setSelectedRoles(temp)  //.map((r) => { return {name: r.name, id: r.id} }));
                                }}>
                            </SingleSelector>
                        </div>
                    </div>}
                   

                    <div className={classes.dialogBox}>
                        <Typography variant="body1" className={classes.dialogText}>
                            {t("department_label")}:
                        </Typography>
                        <FormControl className={classes.dialogInput} variant='outlined' className={clsx(classes.formControl, classes.noLabel)}>
                            <Select
                                displayEmpty
                                value={department}
                                onChange={handleDepartmentChange}
                                input={<Input />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <em style={{ color: "grey" }}>{t("select") + "..."}</em>;
                                    }
                                    return (<Typography>{selected}</Typography>)
                                }}>
                                {departments.map((dept, idx) => (
                                    <MenuItem key={idx} value={dept}>
                                        {dept}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <br/>
                    <Divider/>

                    <div style={{ marginTop: "15px", marginBottom: "15px"}}>
                        <Typography variant="body1" className={classes.dialogText} style={{color:"grey"}}>
                            <div style={{ fontStyle: 'italic', textAlign:"left"}}>{t("Log-in Credentials") + "..."}</div>
                        </Typography>
                    </div>

                   <div className={classes.dialogBox}>
                        <Typography variant="body1" className={classes.dialogText}>
                            {t("username")}: {openEdit === false && <span style={{ color: "red" }}> *</span>}
                        </Typography>
                        <TextField value={username} disabled={openEdit} onChange={(e) => { setUsername(e.target.value) }} className={classes.dialogInput} style={{ minWidth: '50%' }} margin="dense" id="code" type="input" variant="outlined" required />
                    </div>
                   
                    <div className={classes.dialogBox}>
                        <Typography variant="body1" className={classes.dialogText}>
                            {t("password")}: {openEdit===false && <span style={{ color: "red" }}> *</span>}
                        </Typography>
                        <TextField id="passField" value={pass}
                           
                            placeholder={openEdit ? "leave blank to keep current" : ""}
                            onChange={(e) => {
                                let val = e.target.value
                                val.length > 0 ? setSwitchVisible(true) : setSwitchVisible(false)
                                setPass(val)
                            }}
                            className={classes.dialogInput}
                            style={{  minWidth: '50%' }}
                            margin="dense" id="code"
                            type="input"
                            variant="outlined"
                        />
                        
                    </div>
                   
                    {switchVisible && 
                        <div className={classes.dialogBox} style={{marginTop:"15px"}}>
                            <Typography variant="body1">
                                Password is temporary?
                            </Typography>
                            <span style={{ marginLeft: "20px" }}>NO <Switch checked={isPassTemp} onChange={(e) => { setIsPassTemp(e.target.checked) }} /> YES</span>
                        </div>
                    }
                    
                   
                  <br/>
                    <div style={{textAlign:"left"}}>( <span style={{ color: "red" }}> *</span> {t("required info")} )</div>
                </DialogContent>

                <DialogActions>

                    <Tooltip title="Save">
                        <IconButton onClick={handleSave} color="primary">
                            {openEdit ? <SaveChangesIcon /> : <SaveIcon />}
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={t("Cancel")}>
                        <IconButton onClick={handleCancel}>
                            <CancelIcon />
                        </IconButton>
                    </Tooltip>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    return {
        allUsers: state.allUsers,
        realmRoles: state.realmRoles
    };
};
export default connect(mapStateToProps)(PersonnelDialog);

