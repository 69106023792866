import react from 'react';
import { Card, Typography, makeStyles, Button } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    title: {

    },
    table: {
        height: "100%"
    },
    container: {
        height: "95%"
    }
})

function TestByJob(props){
    const classes = useStyles();
    const { t } = useTranslation();

    const columns = [
        { selector: row => row.job, sortable: true, name: t("job_id_label"), type: "text", key: "job" },
        { selector: row => `${row.sampleType}`, sortable: true, name: t("sample_type_label"), type: "text", key: "sampleType" },
        { selector: row => `${new Date(row.dueDate).toLocaleString().split(',')[0]}`, sortable: true, name: t("due_date_label"), type: "date", key: "dueDate" },
        { selector: row => row.analysisTemplates, sortable: true, name: t("analysis_template_label_plural"), type: "text", key: "analysisTemplates" },
        { selector: row => `${row.state}`, sortable: true, name: t("state_label"), type: "text", key: "state" },
        { cell: row => <Button component={Link} to={`/viewJob/${row.id}`} variant="contained" size="small" color="primary">{t("go_to_tests_label")}</Button> }
    ];

    const data = [
        {
            job: "J010",
            sampleType: "PT-NRBOT",
            dueDate: new Date(),
            analysisTemplates: "Template 1 + 2 more",
            state: "In Progress",
        }
    ]

    return <div className={classes.container}>
        <Typography variant="h5" className={classes.title}>{t("test_by_job_label")}</Typography>
        <Card className={classes.table}>
            <DataTable data={data} columns={columns} className={classes.table}/>
        </Card>
    </div>
}

export default TestByJob;