import { useState, useEffect, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import IconButton from '@material-ui/core/IconButton';
import SaveChangesIcon from '@mui/icons-material/SaveAsOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { copySampleType } from '../../services/SampleTypesService';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
      flex: 1,
      backgroundColor: 'paper',
    },
    dialog: {
      width: '400px',
      display: 'flex'
    },
    dialogBox: {
      display: 'flex',
      alignItems: 'center'
    },
    dialogText: {
      flex: '0.4'
    },
    dialogInput: {
      flex: '0.5'
    },
    formControl: {
      margin: theme.spacing(2),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(2),
    },
  }));

  function CopySampleTypeDialog(props){
    const { t } = useTranslation();
    const { openDialog, openEdit, selectedItems, parentRow, text, updateTable, onDialogClose, rowCodes } = props;
    const [alertBox, setAlertBox] = useState("");
    const [sampleTypeName, setSampleTypeName] = useState("");
    const [title, setTitle] = useState(parentRow.name);
    const [code, setCode] = useState("");

    const handleSampleTypeNameChange = (event) => {
        setSampleTypeName(event.target.value);
      };

    const handleCodeChange = (event) => {
        setCode(event.target.value);
      };

    const handleAlertBox = (messages) => {
        setAlertBox(<Alert severity="warning">
          <AlertTitle>Could not create Package Type</AlertTitle>
          {messages.map((message, index) => {
            return <li key={index}>{message}</li>
          })}
        </Alert>)
      };

    const handleSave = async () => {
        console.log('parentRow', parentRow)
        let alertList = [];

        if (sampleTypeName.length === 0) alertList.push('You must provide a sample type name.');
        if (code.length === 0) alertList.push('You must provide a sample type code.');
        if (rowCodes.find((rowCode) => { return rowCode === code }) ) alertList.push('You must provide a unique code.'); 
        if (alertList.length !== 0) handleAlertBox(alertList);

        else {
            let res = await copySampleType(parentRow.id, sampleTypeName, code);
                updateTable();
                onDialogClose(res);
                clearFields();
        }
    }

    const handleClose = async () => {
        updateTable();
        onDialogClose();
        clearFields();
    }

    const clearFields = () => {
        setSampleTypeName("");
        setCode("");
    }
    const classes = useStyles();
      return (
        <div className={classes.root}>
          <Dialog open={openDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
            <DialogTitle id="form-dialog-title">{t("Copy Sample Type")}: {title} </DialogTitle>
            <DialogContent>
              <DialogContentText>
              </DialogContentText>
              {alertBox}
              <div className={classes.dialogBox} required>
                <Typography variant="body1" className={classes.dialogText}>
                  New Sample Type Name*
                </Typography>
                <TextField value={sampleTypeName} on="true" onChange={handleSampleTypeNameChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required />
              </div>
              <div className={classes.dialogBox}>
              <Typography variant="body1" className={classes.dialogText}>
              ID
            </Typography>

            <TextField value={code} onChange={handleCodeChange} className={classes.dialogInput} style={{ maxWidth: '20%' }} autoFocus margin="dense" id="name" type="input" variant="outlined" required />
              </div>
              
            </DialogContent>
            <DialogActions>
    
            <Tooltip title="Save">
                <IconButton onClick={handleSave} color="primary"> 
                  {openEdit ? <SaveChangesIcon /> : <SaveIcon />}
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancel">
                <IconButton onClick={handleClose}> 
                  <CancelIcon/>
                </IconButton>
              </Tooltip>
            </DialogActions>
          </Dialog>
        </div>
      )
  }

  export default CopySampleTypeDialog;