import { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import { addTest, getUnits, checkCode } from "../../services/TestsService";
import { Alert, AlertTitle } from "@material-ui/lab";
import Switch from "@material-ui/core/Switch";
import { getEquipment } from '../../services/EquipmentService';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import SaveChangesIcon from '@mui/icons-material/SaveAsOutlined';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from "react-i18next";
import { testDataTypes } from './TestDataTypes';
import OKCancelDialog from "./OKCancelDialog";
import { updateSequence, resetSequence } from '../../services/TestsService'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other} >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const DecimalPlaces = [0, 1, 2, 3, 4, 5];

const DefaultDecimalPlaceIndex = 2;

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};




function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function TestDialog(props) {
    const { openDialog, openEdit, viewing, selectedItems, parentRow, updateTable, onDialogClose } = props;
    const [editDisabled, setEditDisabled] = useState(false);
    const [buttonVisibility, setButtonVisibility] = useState("visible");
    const [testDataType, setTestDataType] = useState(null);
    const [decimalPlaces, setDecimalPlaces] = useState({});
    const [unit, setUnit] = useState({});
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [isPass, setIsPass] = useState(false);
    const [testId, setTestId] = useState([]);
    const [testName, setTestName] = useState("");
    const [equipments, setEquipment] = useState([]);
    const [equipmentDetails, setEquipmentDetails] = useState([]);
    const [alertBox, setAlertBox] = useState("");
    const [OkCancelDialogBox, setOkCancelDialogBox] = useState("");
    const [fileHeader, setFileHeader] = useState("");
    const [modifiedFileHeader, setModifiedFileHeader] = useState("");
    const [calculationKey, setCalculationKey] = useState();
    const [calculationSeq, setCalculationSeq] = useState();

    const [testNameEnglish, setTestNameEnglish] = useState("");
    const [testNameFrench, setTestNameFrench] = useState("");
    const [testNameSpanish, setTestNameSpanish] = useState("");
    const [testNameItalian, setTestNameItalian] = useState("");
    const [testNameArabic, setTestNameArabic] = useState("");
    const [testNameRussian, setTestNameRussian] = useState("");

    const sequences = [1, 2, 3, 4, 5]
    const [tabValue, setTabValue] = useState(0);
    const { t } = useTranslation();

    const [OKCancelDialogState, setOKCancelDialogState] = useState({
        shown: false, title: "", message: t("The CSV Column header contains special characters that will be removed"), flavour: "info"
    })

    const handleTabChange = (e, newValue) => {
        setTabValue(newValue);
    };

    const handleIsPass = (event) => {
        setIsPass(event.target.checked);
    };

    const handleTestNameChange = (event) => {
        setTestName(event.target.value);
    };

    const handleTestNameEnglishChange = (event) => {
        if (!viewing) {
            handleTestNameChange(event);
        }
        setTestNameEnglish(event.target.value);
    };

    const handleTestNameFrenchChange = (event) => {
        setTestNameFrench(event.target.value);
    };

    const handleTestNameSpanishChange = (event) => {
        setTestNameSpanish(event.target.value);
    };
    
    const handleTestNameItalianChange = (event) => {
        setTestNameItalian(event.target.value);
    };
    
    const handleTestNameArabicChange = (event) => {
        setTestNameArabic(event.target.value);
    };
    
    const handleTestNameRussianChange = (event) => {
        setTestNameRussian(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleTestDataTypeChange = (event) => {
        let dt = testDataTypes.find((x) => { return x.name === event.target.value})
        setTestDataType(dt);//event.target.value);
    };

    const handleTestIdChange = (event) => {
        setTestId(event.target.value);
    };

    const handleEquipmentsChange = (event) => {
        setEquipment(event.target.value)
    };

    const handleFileHeaderChange = (event) => {
        setFileHeader(event.target.value);
    }

    const handleCalculationKeyChange = (event) => {
        setCalculationKey(event.target.value);
    }

    const handleEditClick = () => {
        setEditDisabled(false);
        setTitle(t("edit_label") + " " + selectedItems.name);
        setButtonVisibility("hidden");
    };

    const [units, setUnits] = useState([])

    useEffect(() => {
       
        getUnits().then((res) => {
            if (res.req_error) {
                alert(t("Failed to get list of available unit types!"))
                return;
            }
            setUnits(res);
        })
       
    }, [])

    const handleUnitChange = (event) => {
        let description = event.target.value;
        let obj = units.find((u) => { return u.description === description })
        //alert(JSON.stringify(obj, null, 2))
        setUnit(obj);
    };

    const handleDecimalChange = (event) => {
        let value = event.target.value;
        setDecimalPlaces(value);
    };

    useEffect(() => {
        if (viewing) {
            let tmpEquipment = []
            console.log("selectedItems=" + JSON.stringify(selectedItems,null, 2) + " ---------- " + equipmentDetails);
            console.log("equipments=" + selectedItems.equipments);

            selectedItems.equipments.forEach(equipment => {
                equipmentDetails.forEach(equip => {
                    if (equipment.id === equip.id)
                        tmpEquipment.push(equip);
                });
            })
            getUnits().then((res) => {
                if (res.req_error) {
                    alert(t("Failed to get list of available unit types!"))
                    return;
                }
                setUnits(res);
            });
            console.log("UNIT", selectedItems.unit);
            setEquipment(tmpEquipment);
            setEditDisabled(true);
            setButtonVisibility("visibile");
            setTitle(t("view_label") + " " + selectedItems.name);
            setDecimalPlaces(selectedItems.decimalPlaces ? selectedItems.decimalPlaces : DecimalPlaces[DefaultDecimalPlaceIndex]);
            setTestName(selectedItems.name);
            setDescription(selectedItems.description);
            let dt = testDataTypes.find((x) => { return x.name === selectedItems.testDataType })
            setTestDataType(dt);
            setUnit(selectedItems.unit);
            setTestId(selectedItems.testId);
            setIsPass(selectedItems.pass);
            setFileHeader(selectedItems.fileHeader);
            setCalculationKey(selectedItems.calculationKey);
            setTestNameEnglish(selectedItems.nameEnglish);
            setTestNameFrench(selectedItems.nameFrench);
            setTestNameSpanish(selectedItems.nameSpanish);
            setTestNameItalian(selectedItems.naemItalian);
            setTestNameArabic(selectedItems.nameArabic);
            setTestNameRussian(selectedItems.nameRussian);

            selectedItems.orderInCalculation ? setCalculationSeq(selectedItems.orderInCalculation) : setCalculationSeq(-1)
        } else {
            setEditDisabled(false);
            setCalculationSeq(-1);
            setDecimalPlaces(DecimalPlaces[DefaultDecimalPlaceIndex]);
            setTitle(t("add_test_button_text"));
        }
    }, [viewing, equipmentDetails]);

    useEffect(() => {
        if (openDialog) {
            const getEquipmentInfo = async () => {
                let data = await getEquipment();
                console.log("equipment= ", data);
                let tmpEquipment = [];
                // console.log("parentRow="+JSON.stringify(parentRow));
                console.log("parentRow=", parentRow);

                console.log(tmpEquipment.length, tmpEquipment);
                setEquipmentDetails(data);
            }

            getEquipmentInfo();
        }
    }, [openDialog])

    const clearFields = () => {
        setAlertBox("");
        setTestName("");
        setDescription("");
        setTestDataType(null);
        setUnit({ description: "" });
        setEquipment([]);
        setTestId("");
        setIsPass(false);
        setFileHeader();
        setDecimalPlaces(DecimalPlaces[DefaultDecimalPlaceIndex]);
        setCalculationKey();
        setCalculationSeq(-1);
        setTestNameEnglish("");
        setTestNameFrench("");
        setTestNameSpanish("");
        setTestNameItalian("");
        setTestNameArabic("");
        setTestNameRussian("");
    };

    // const handleAlerts = (messages) => {
    //     handleOkCancelDialogBox()
    //     handleAlertBox(messages)
    // };

    const handleAlertBox = (messages) => {
        setAlertBox(
            <Alert severity="warning">
                <AlertTitle>{t("Could not create Test")}</AlertTitle>
                {messages.map((message, index) => {
                    return <li key={index}>{message}</li>;
                })}
            </Alert>
        );
    };

    const handleOkCancelDialogBox = () => {
        setOKCancelDialogState({ ...OKCancelDialogState, shown: true })
    }

    useEffect(() => {
      //  console.log(parentRow)
    }, [parentRow])


    
    const handleSave = async () => {
        let alertMessages = [];

        if (testName.length === 0) alertMessages.push(t("Provide a Test Name"));
        if (testDataType === null) alertMessages.push(t("Select a Test Data Type"));
        if (!unit.description || unit.description.length === 0) alertMessages.push(t("Select a Unit Type"));
        if (testId.length === 0) alertMessages.push(t("Provide a Test ID"));
        if (equipments.length === 0)
            alertMessages.push(t("Provide at least one Equipment"));

        if (viewing) {
            if (await checkCode(selectedItems.id, testId, "Test")) {
                alertMessages.push(t("Test ID must be unique"));
            }
        }

        if (!viewing) {
            if (await checkCode(null, testId, "Test")) {
                alertMessages.push(t("Test ID must be unique"));
            }
        }

        if (alertMessages.length !== 0) handleAlertBox(alertMessages);
        else {
            let res;
            if (viewing) {
                res = await addTest(
                    selectedItems,
                    selectedItems.id,
                    testName,
                    description,
                    testDataType.name,
                    unit,
                    testId,
                    equipments,
                    cleanFileHeader(),
                    calculationKey,
                    calculationSeq,
                    isPass,
                    null,
                    null,
                    decimalPlaces,
                    testNameFrench,
                    testNameSpanish,
                    testNameItalian,
                    testNameArabic,
                    testNameRussian,
                    testNameEnglish
                );
            }
            else {
                console.log("Saving test", parentRow);
                res = await addTest(
                    parentRow,
                    null,
                    testName,
                    description,
                    testDataType.name,
                    unit,
                    testId,
                    equipments,
                    cleanFileHeader(),
                    calculationKey,
                    calculationSeq,
                    isPass,
                    null,
                    null,
                    decimalPlaces,
                    testNameFrench,
                    testNameSpanish,
                    testNameItalian,
                    testNameArabic,
                    testNameRussian,
                    testNameEnglish
                );
            }
            console.log(res)
            updateTable();
            onDialogClose(res);
            clearFields();
        }
    };

    const cleanFileHeader = () => {
        // checks ascii decimal value of characters
        // Ensures that csv column header provided only has characters in utf8 (0->127)
        var newFileHeader = "";
        for (var i=0; i<fileHeader.length; i++) {
            if (fileHeader.charCodeAt(i) <= 127) {
                newFileHeader += fileHeader.charAt(i);
            }
        }
        return newFileHeader.trim();
    }

    const handleSaveClick = async () => {
        //Check if file header has any characters that need to be removed by comparing the difference.
        // console.log(fileHeader)
        // console.log(cleanFileHeader())
        if (fileHeader !== cleanFileHeader()) {
            handleOkCancelDialogBox();
        } else {
            await handleSave()
        }
    }

    const handleCancel = () => {
        updateTable();
        onDialogClose();
        clearFields();
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            flex: 1,
            backgroundColor: "paper",
        },
        dialog: {
            width: "400px",
            display: "flex",
        },
        dialogBox: {
            alignItems: "center",
            display: "flex",
        },
        dialogText: {
            flex: "0.4",
        },
        dialogInput: {
            flex: "0.5",
        },
        chips: {
            display: "flex",
            flexWrap: "wrap",
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        formControl: {
            minWidth: 120,
            maxWidth: 300,
        },
        testContainer: {
            display: "flex",
            columnGap: "5%",
            flexWrap: "wrap",
            alignItems: "center",
            width: "100%",
            rowGap: "0.5rem",
        },
    }));

    const classes = useStyles();
    const theme = useTheme();

    return (
        <Box>
            <Dialog
                open={openDialog}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="sm"
            >
                {/* sm, xs, md, lg, xl*/}
                {viewing ? (
                    <DialogTitle id="form-dialog-title">
                        {title}
                        <Button
                            variant="outlined"
                            onClick={handleEditClick}
                            style={{
                                visibility: buttonVisibility,
                                marginLeft: 20,
                                backgroundColor: "#a6f5a6",
                            }}
                        >
                            {t("edit_label")}
                        </Button>
                    </DialogTitle>
                ) : (
                    <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                )}

                <OKCancelDialog
                    show={OKCancelDialogState.shown}
                    title={OKCancelDialogState.title}
                    message={OKCancelDialogState.message}
                    flavour={OKCancelDialogState.flavour}
                    okText={ t('Continue')}
                    cancelText={t('Cancel') }
                    callback={
                        async (res) => {
                            if (res === "OK") {
                                await handleSave()
                            }
                            setOKCancelDialogState({ ...OKCancelDialogState, shown: false });
                        }
                    }
                />

                <DialogContent>
                    {alertBox}
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} textColor="secondary" indicatorColor="secondary" onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab label={t("test_dialog_tab_1")} />
                            <Tab label={t("test_dialog_tab_2")} />
                        </Tabs>
                    </Box> 

                    <TabPanel value={tabValue} index={0}>
                        <Box className={classes.dialogBox}>
                            <Typography className={classes.dialogText} variant="body1">
                                {t("Test Name")}*
                            </Typography>
                            <TextField
                                disabled={editDisabled}
                                value={testNameEnglish}
                                className={classes.dialogInput}
                                on
                                onChange={handleTestNameEnglishChange}
                                placeholder="Test Name"
                                autoFocus
                                margin="dense"
                                id="name"
                                type="input"
                                variant="outlined"
                                required
                            />
                        </Box>
                        <Box className={classes.dialogBox}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("description_field_label")}
                            </Typography>
                            <TextField
                                disabled={editDisabled}
                                value={description}
                                onChange={handleDescriptionChange}
                                className={classes.dialogInput}
                                placeholder={t("enter_description_placeholder")}
                                autoFocus
                                multiline
                                rows={3}
                                margin="dense"
                                id="description"
                                type="input"
                                variant="outlined"
                                required
                            />
                        </Box>
                        <Box className={classes.dialogBox}>
                            <Typography className={classes.dialogText} variant="body1">
                                {t("Test Data Type")}*
                            </Typography>
                            <FormControl
                                className={clsx(
                                    classes.formControl,
                                    classes.noLabel,
                                    classes.dialogInput
                                )}>
                                <Select
                                    value={testDataType ? testDataType.name: ""}
                                    disabled={editDisabled}
                                    onChange={handleTestDataTypeChange}
                                    displayEmpty
                                    className={classes.selectEmpty}
                                    inputProps={{ "aria-label": "Without label" }}>
                                    <MenuItem value="" disabled>
                                        <em style={{color:"grey"}}>{t("Select")} {t("Test Data Type")}...</em>
                                    </MenuItem>
                                    {testDataTypes.map((dt, idx) => (
                                        <MenuItem
                                            key={"dataType"+dt.name+idx}
                                            value={dt.name}
                                            style={getStyles(dt.name, testDataTypes, theme)}>
                                            <Tooltip title={
                                                <div style={{ padding: "8px", fontSize: "12px" }}>
                                                    {dt.tooltip.map((line) => { return <div>{line}</div> })}</div>}>
                                                <div>{dt.name}</div>
                                            </Tooltip>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box className={classes.dialogBox}>
                            <Typography className={classes.dialogText} variant="body1">
                                {t("units_label")}*
                            </Typography>
                            <FormControl
                                className={clsx(
                                    classes.formControl,
                                    classes.noLabel,
                                    classes.dialogInput
                                )}>

                                <Select
                                    value={unit === null ? null : unit.description}
                                    disabled={editDisabled}
                                    onChange={handleUnitChange}
                                    displayEmpty
                                    className={classes.selectEmpty}
                                    inputProps={{ "aria-label": "Without label" }}>
                                    <MenuItem value="" disabled>
                                        <em>{t("Select")} {t("units_label")}...</em>
                                    </MenuItem>
                                    {units.map((unit, idx) => (
                                        <MenuItem
                                            key={"unit" + idx}
                                            value={unit.description}
                                            style={getStyles(unit.description, units, theme)}>
                                            {unit.description}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box className={classes.dialogBox}>
                            <Typography className={classes.dialogText} variant="body1">
                                {t("equipment_label")}*
                            </Typography>
                            <FormControl
                                variant="outlined"
                                className={clsx(
                                    classes.formControl,
                                    classes.noLabel,
                                    classes.dialogInput
                                )}>
                                <Select
                                    multiple
                                    displayEmpty
                                    disabled={editDisabled}
                                    value={equipments}
                                    onChange={handleEquipmentsChange}
                                    input={<Input />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <em style={{ color: "grey" }}>{t("Select all that apply")}...</em>;
                                        }

                                        return (
                                            <div className={classes.chips}>
                                                {selected.map((value) => (
                                                    <Chip
                                                        key={value.id}
                                                        label={value.name}
                                                        className={classes.chip}
                                                    />
                                                ))}
                                            </div>
                                        );
                                    }}
                                    MenuProps={MenuProps}
                                    inputProps={{ "aria-label": "Without label" }}>
                                    {equipmentDetails.map((equipment) => (
                                        <MenuItem key={equipment.id} value={equipment}>
                                            {equipment.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box className={classes.dialogBox}>
                            <Typography className={classes.dialogText} variant="body1">
                                ID*
                            </Typography>
                            <TextField
                                disabled={editDisabled}
                                value={testId}
                                onChange={handleTestIdChange}
                                className={classes.dialogInput}
                                // style={{ maxWidth: "20%" }}
                                autoFocus
                                margin="dense"
                                id="name"
                                type="input"
                                variant="outlined"
                                required
                            />
                        </Box>
                        <Box className={classes.dialogBox}>
                            <Typography className={classes.dialogText} variant="body1">
                                {t("CSV Column Header")}
                            </Typography>
                            <TextField
                                disabled={editDisabled}
                                value={fileHeader}
                                onChange={handleFileHeaderChange}
                                className={classes.dialogInput}
                                // style={{ maxWidth: "20%" }}
                                autoFocus
                                margin="dense"
                                id="name"
                                type="input"
                                variant="outlined"
                                required
                            />
                        </Box>
                        <Box className={classes.dialogBox}>
                            <Typography className={classes.dialogText} variant="body1">
                                {t("Calculation Key")}
                            </Typography>
                            <TextField
                                disabled={editDisabled}
                                value={calculationKey}
                                onChange={handleCalculationKeyChange}
                                className={classes.dialogInput}
                                // style={{ maxWidth: "20%" }}
                                autoFocus
                                margin="dense"
                                id="name"
                                type="input"
                                variant="outlined"
                                required
                            />
                        </Box>
                        <Box className={classes.dialogBox}>
                            <Typography className={classes.dialogText} variant="body1">
                                {t("Calculation Sequence No.")}
                            </Typography>
                            <FormControl
                                className={clsx(
                                    classes.formControl,
                                    classes.noLabel,
                                    classes.dialogInput)}>

                                <Select
                                    value={calculationSeq}
                                    disabled={editDisabled}
                                    onChange={(e) => { setCalculationSeq(e.target.value); }}
                                    style={{ maxWidth: "33%" }}
                                    renderValue={(selected) => {
                                        if (selected < 1) return <em style={{color:"grey"}}>{t("Select")}...</em>;
                                        else return  <div> {selected } </div>;
                                    }}
                                    className={classes.selectEmpty}
                                    inputProps={{ "aria-label": "Without label" }}>
                                
                                
                                    {sequences.map((seq, idx) => (
                                        <MenuItem
                                            key={idx}
                                            value={seq}>
                                            {seq}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box className={classes.dialogBox}>
                            <Typography
                                className={classes.dialogText}
                                style={{ flex: 0.43 }}
                                variant="body1">
                                {t("Pass / Fail")}
                            </Typography>
                            <Switch
                                checked={isPass}
                                disabled={editDisabled}
                                onClick={handleIsPass}
                                color="primary"
                                name="checkedB"
                                inputProps={{ "aria-label": "primary checkbox" }}
                            />
                        </Box>
                        <Box className={classes.dialogBox}>
                            <Typography className={classes.dialogText} variant="body1">
                                {t("decimal_place")}*
                            </Typography>
                            <FormControl
                                className={clsx(
                                    classes.formControl,
                                    classes.noLabel,
                                    classes.dialogInput
                                )}>

                                <Select
                                    value={decimalPlaces ? decimalPlaces : DecimalPlaces[DefaultDecimalPlaceIndex]}
                                    disabled={editDisabled}
                                    onChange={handleDecimalChange}
                                    displayEmpty
                                    className={classes.selectEmpty}
                                    inputProps={{ "aria-label": "Without label" }}>
                                    <MenuItem value="" disabled>
                                        <em>{t("Select")}...</em>
                                    </MenuItem>
                                    {DecimalPlaces.map((decimal, idx) => (
                                        <MenuItem
                                            key={"decimal" + idx}
                                            value={decimal}>
                                            {decimal}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <hr style={{ marginTop: 30, marginBottom: 10 }}></hr>
                        <Box className={classes.dialogBox}>
                            <Typography
                                className={classes.dialogText}
                                style={{ flex: 0.52 }}
                                variant="body1">
                                {t("Procedure Documentation")}
                            </Typography>
                            <Button
                                disabled={editDisabled}
                                variant="contained"
                                component="label">
                                {t("Upload File")}
                                <input type="file" hidden />
                            </Button>
                        </Box>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <div className={classes.dialogBox}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("french_name")}
                            </Typography>

                            <TextField disabled={editDisabled} value={testNameFrench} onChange={handleTestNameFrenchChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required placeholder={t("french_name")} />
                        </div>

                        <div className={classes.dialogBox}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("spanish_name")}
                            </Typography>

                            <TextField disabled={editDisabled} value={testNameSpanish} onChange={handleTestNameSpanishChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required placeholder={t("spanish_name")} />
                        </div>

                        <div className={classes.dialogBox}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("italian_name")}
                            </Typography>

                            <TextField disabled={editDisabled} value={testNameItalian} onChange={handleTestNameItalianChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required placeholder={t("italian_name")} />
                        </div>

                        <div className={classes.dialogBox}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("arabic_name")}
                            </Typography>

                            <TextField disabled={editDisabled} value={testNameArabic} onChange={handleTestNameArabicChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required placeholder={t("arabic_name")} />
                        </div>

                        <div className={classes.dialogBox}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("russian_name")}
                            </Typography>

                            <TextField disabled={editDisabled} value={testNameRussian} onChange={handleTestNameRussianChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required placeholder={t("russian_name")} />
                        </div>
                    </TabPanel>
                </DialogContent>
                <DialogActions>
                    <Tooltip title={t("save")}>
                        <IconButton disabled={editDisabled} onClick={handleSaveClick} color="primary">
                            <SaveIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t("cancel")}>
                        <IconButton onClick={handleCancel}>
                            <CancelIcon />
                        </IconButton>
                    </Tooltip>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default TestDialog;
