import DateFnsUtils from '@date-io/date-fns';
import { Dialog } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import { Switch } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        backgroundColor: 'paper',
    },
    itemBreak: {
        display: 'flex',
        flexDirection: "column",
        rowGap: "1rem",
        marginBottom: "1rem"
    },
    filterHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        '& p': {
            marginBottom: 0
        },
        marginBottom: "12px"
    }
}));

const columnTypes = { TEXT: "text", NUMERIC: "numeric", DATE: "date" };

const defaultFilterValues = {
    date: {
        from: new Date(),
        to: new Date()
    },
    text: {
        includes: ""
    },
    numeric: {
        lessThan: 0,
        greaterThan: 0,
    },
}

function FilterDialog(props) {
    const { openDialog, columns, handleFilterDialogClose, keyColumn, setColumnFilters } = props;
    const { t } = useTranslation();
    const [filters, setFilters] = useState({});
    const [apply, setApply] = useState(false);
    const [errors, setErrors] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        let resObject = { ...filters }
        columns.filter(column => column.key !== keyColumn.key && column.type != null).forEach(column => {
            resObject[column.key] = {
                name: column.name,
                type: column.type,
                enabled: true
            };

            if (column.type === columnTypes.NUMERIC)
                resObject[column.key].filterValue = { ...defaultFilterValues.numeric };
            else if (column.type === columnTypes.DATE)
                resObject[column.key].filterValue = { ...defaultFilterValues.date };
            else if (column.type === columnTypes.TEXT)
                resObject[column.key].filterValue = { ...defaultFilterValues.text };
        });

        setFilters(resObject);
    }, []);

    const verifyNumericFilter = (greater, lesser) => {
        return (parseFloat(greater) <= parseFloat(lesser))
    }

    const verifyDateFilter = (startDate, endDate) => {
        return startDate <= endDate;
    }

    const verifyFilters = () => {
        let alerts = [];

        Object.keys(filters).forEach(column => {
            if (!filters[column].enabled)
                return;

            if (filters[column].type === columnTypes.NUMERIC && !verifyNumericFilter(filters[column].filterValue.greaterThan, filters[column].filterValue.lessThan))
                alerts.push(`${t("range_invalid")}${filters[column].name}`);
            else if (filters[column].type === columnTypes.DATE && !verifyDateFilter(filters[column].filterValue.from, filters[column].filterValue.to))
                alerts.push(`${t("range_invalid")}${filters[column].name}`);
        });

        setErrors(alerts);
        return alerts.length === 0;
    }

    const handleApply = () => {
        let newApply = !apply;

        if (newApply) {
            if (verifyFilters()) {
                setApply(newApply);
                setColumnFilters(filters);
                handleFilterDialogClose();
            }
        }
        else{
            setApply(newApply);
            setColumnFilters({});
        }
    }

    const setText = key => event => {
        let tempFilter = { ...filters };
        tempFilter[key].filterValue.includes = event.target.value;
        setFilters(tempFilter);
    }

    const setDate = (key, subKey) => (date) => {
        let tempFilter = { ...filters };
        tempFilter[key].filterValue[subKey] = date;
        setFilters(tempFilter);
    }

    const setNumeric = (key, subKey) => event => {
        let tempFilter = { ...filters };
        let tmpValue = event.target.value;

        tempFilter[key].filterValue[subKey] = tmpValue;

        setFilters(tempFilter);
    }

    const filterTextType = key => {
        return <TextField
            label={`${t("search_label")} ${filters[key].name}`}
            disabled={!filters[key].enabled}
            id="outlined-size-small"
            value={filters[key].filterValue.includes}
            onChange={setText(key)}
            variant="outlined"
            size="small"
        />
    }

    const filterDateType = key => {
        return <div style={{ display: "flex", columnGap: "5%" }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    disabled={!filters[key].enabled}
                    style={{ width: "47.5%" }}
                    variant="inline"
                    margin="normal"
                    id="date-picker-inline"
                    label={t("start_date")}
                    inputVariant="outlined"
                    size="small"
                    value={filters[key].filterValue.from}
                    onChange={setDate(key, "from")}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    style={{ width: "47.5%" }}
                    disabled={!filters[key].enabled}
                    disableToolbar
                    variant="inline"
                    margin="normal"
                    inputVariant="outlined"
                    size="small"
                    id="date-picker-inline"
                    label={t("end_date")}
                    value={filters[key].filterValue.to}
                    onChange={setDate(key, "to")}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
        </div>
    }

    const filterNumericType = key => {
        return <div style={{ display: "flex", columnGap: "5%" }}>
            <TextField
                label={`${t("greater_than")}`}
                disabled={!filters[key].enabled}
                id="outlined-size-small"
                value={filters[key].filterValue.greaterThan}
                onChange={setNumeric(key, "greaterThan")}
                type="number"
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
                size="small"
                style={{ width: '47.5%' }}
            />
            <TextField
                style={{ width: '47.5%' }}
                disabled={!filters[key].enabled}
                label={`${t("less_than")}`}
                id="outlined-size-small"
                value={filters[key].filterValue.lessThan}
                onChange={setNumeric(key, "lessThan")}
                type="number"
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
                size="small"
            />
        </div>
    }

    const setFilterEnabled = key => event => {
        let tempFilter = { ...filters };
        tempFilter[key].enabled = event.target.checked;
        setFilters(tempFilter);
    }

    const getInput = key => {
        if (filters[key].type === columnTypes.TEXT)
            return filterTextType(key);
        else if (filters[key].type === columnTypes.NUMERIC)
            return filterNumericType(key);
        else if (filters[key].type === columnTypes.DATE)
            return filterDateType(key);
    }

    return (
        <div className={classes.root}>
            <Dialog open={openDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm" onClose={handleFilterDialogClose}>
                <DialogTitle id="form-dialog-title">{t("filter_label")}</DialogTitle>
                {errors.length > 0 && <Alert style={{ marginBottom: "1rem", marginLeft: "1rem", marginRight: "1rem" }} severity="warning">
                    <AlertTitle>{t("unable_to_set_filters")}</AlertTitle>
                    {errors.map((message, index) => {
                        return <li key={index}>{message}</li>
                    })}
                </Alert>}
                <DialogContent>
                    {Object.keys(filters).map(column => (
                        <div key={column}>
                            <div className={classes.filterHeader}>
                                <DialogContentText>
                                    {filters[column].name}
                                </DialogContentText>
                                <FormControlLabel
                                    control={<Switch checked={filters[column].enabled} onChange={setFilterEnabled(column)} name={`enabled_${column}`} />}
                                    label={filters[column].enabled ? t("enabled_label") : t("disabled_label")}
                                />
                            </div>
                            <div className={classes.itemBreak}>
                                {getInput(column)}
                                <Divider />
                            </div>
                        </div>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleApply} color="primary">
                        {!apply ? t("apply_label") : t("clear_label")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default FilterDialog;