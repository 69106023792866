/* eslint-disable react-hooks/exhaustive-deps */

import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslation } from "react-i18next";
import { pink, yellow } from "@material-ui/core/colors";
import { Box, Button, Tooltip, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DataTable from "react-data-table-component";
import FilterDialog from "./Dialogs/FilterDialog";
import FilterListIcon from "@material-ui/icons/FilterList";
import TestDialog from "./Dialogs/TestDialog";
import CopySampleTypeDialog from './Dialogs/CopySampleTypeDialog';
import OKDialog from "./Dialogs/OKDialog";
import CircularProgress from '@mui/material/CircularProgress';
import UserService from "../keycloak";
import HelpVideoDialog from './Dialogs/HelpVideoDialog'
import ControlledCheckBox from "../components/ControlledCheckBox";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    paper: {
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    tableHeader: {
        display: "flex",
        alignItems: "center",
        columnGap: "1rem",
        paddingTop: "1rem",
        overflow: "visible",
        marginBottom: "0.3rem",
        maxWidth: "80vw"
    },
    selectedCount: {
        flex: "2 2 90%",
    },
    title: {
        flex: "1 1",
        whiteSpace: "nowrap",
    },
    editButton: {
        backgroundColor: yellow[800],
        borderColor: yellow[800],
        color: "white",
        "&:hover": {
            backgroundColor: yellow[900],
        },
    },
    filterButton: {

        backgroundColor: pink[600],
        borderColor: pink[600],
        color: "white",
        "&:hover": {
            backgroundColor: pink[700],
        },
    },
    searchBar: {
        flex: "1 1 30%",
    },
}));

const GenericTable = (props) => {
    const {
        text,
        Dialog,
        tableProps,
        openTestDialog,
        onTestDialogClose,
        updateTable,
        paginationPerPage,
        columns,
        rows,
        selectedRow,
        deleteCallback,
        dense,
        setDense,
        keyColumn,
        parentAdd,
        parentEdit,
        selectedItem,
        useDialog,
        openCopySampleTypeDialog,
        onCopySampleTypeDialogClose,
        rowCodes,
        createJobText,
        responseForConfirmationMessage,
        resetForConfirmationMessage,
    } = props;

    const [openDialog, setOpenDialog] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [clearSelectedRows, setClearSelectedRows] = useState(false);
    // const [openFilterDialog, setOpenFilterDialog] = useState(false);
    //const [columnFilters, setColumnFilters] = useState({});
    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const { t } = useTranslation();
    
    const [controlsDisabled, setControlsDisabled] = useState(false);
    const [filteredRows, setFilteredRows] = useState([]);

    const [checkFilterShown, setCheckFilterShown] = useState(false);

    const [OKDialogState, setOKDialogState] = useState({
        shown: false, title: "", message: "", flavour: "success"
    })


    const onDialogClose = (event) => {
        if (useDialog == null) {
            setOpenDialog(false);
            setOpenEdit(false);
            if (event) handleStatusCode(event);
        }
        else {
            parentAdd[1](false);
            parentEdit[1](false);
        }
    };

    useEffect(() => {
        setFilteredRows(rows)
    }, [rows])



    useEffect(() => {

        let tab = text; 
        console.log("Tab: " + tab)
      
        switch (tab) {

            case t("personnel_tab"): case t("client_tab"):
                if (!UserService.hasRole(["ADMIN"])) setControlsDisabled(true);
                break;

            case t("register_samples_title"):
                setCheckFilterShown(true)
                break;

            default:
                return;

        }
      
    }, [text])



    const handleStatusCode = (event) => {
        switch (event.status) {
            case 200:
                setOKDialogState({
                    shown: true,
                    title: responseForConfirmationMessage ? "Sample Type Cloned" : text.substring(text.length - 1) === "s" ? text.substring(0, text.length - 1) + " Saved" : text + " Saved",
                    message: event.data.name + ` saved successfully!`,
                    flavour: "success",
                    callback: resetMessage
                });
                break;
            default:
                setOKDialogState({
                    shown: true,
                    title: "Failure",
                    message: `failed to save, try later`,
                    flavour: "error",
                    callback: resetMessage
                });
                break;

        }
    };

    useEffect(() => {
        if (responseForConfirmationMessage) {
            handleStatusCode(responseForConfirmationMessage)
        }
    }, [responseForConfirmationMessage]);

    const resetMessage = () => {
        if (responseForConfirmationMessage) {
            resetForConfirmationMessage(null);
        }
    }

    //const handleFilterDialogClose = () => {
    //    setOpenFilterDialog(false);
    //};

    //const handleFilterDialogOpen = () => {
    //    setOpenFilterDialog(true);
    //};

    const handleClearSelectedRows = () => {
        setClearSelectedRows(!clearSelectedRows);
    };

    const clearOptions = () => {
        setSelected([]);
        handleClearSelectedRows();
    };

    const handleDelete = async () => {
        clearOptions();
        await deleteCallback(selected);  
    };

    const handleEdit = () => {
        if (useDialog == null) {
            setOpenDialog(true);
            setOpenEdit(true);
        }
        else {
            selectedItem[1](selected[0]); 
            parentAdd[1](true);
            parentEdit[1](true);
        }
    };

    const handleAdd = () => {
        if (useDialog == null)
            setOpenDialog(true);
        else
            parentAdd[1](true);
    };

    const selectedItemText = () => {
        if (selected.length === 0) return "";
        if (selected.length === 1) return `1 ${t("one_row_selected")}`;
        if (selected.length > 1 && selected.length < rows.length)
            return `${selected.length} ${t("rows_selected")}`;
        if (selected.length === rows.length) return t("all_rows_selected");

        return "";
    };

    useEffect(() => {
        updateTable(clearOptions);
    }, []);


    useEffect(() => {
        var term = searchTerm;
        if (term.length > 0) {
            term = term.toLowerCase().trim();
            var res = rows.filter((row) => {
                var key = `${row[keyColumn.key]}`.toLowerCase();
                if (key.includes(term)) return true;
                return false;
            })
            setFilteredRows(res);
        }
        else setFilteredRows(rows);
    }, [searchTerm])


  

    const onCheckFilterChange = (checked, setChecked) => {
        if (checked) {
            var res = rows.filter((r) => {
                return r.status === "completed_pending";
            })
            if (res.length > 0) setFilteredRows(res);
            else {
                alert("There are no jobs with 100% progress yet!")
                setChecked(false)
            }
        }
        else {
            setFilteredRows(rows)
            setSearchTerm("")
        }
    }

    return (
        <div className={classes.root}>
            
            <Paper className={classes.paper}>
                <Box mx={2} className={classes.tableHeader}>
                    <Typography
                        className={classes.title}
                        style={{ fontWeight: 400, fontSize: "22px" }}
                    >
                        {text}
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', flex: '25%' }}>
                        <Tooltip title={createJobText ? createJobText : t("add") + " " + (text.substring(text.length - 1) === "s" ? text.substring(0, text.length - 1) : text)}>
                            <Button
                                disabled={controlsDisabled}
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={handleAdd}
                                aria-label="add"
                                startIcon={<AddIcon style={{ marginLeft: "30%" }} />}
                            >
                                {createJobText ? createJobText : t("add") + " " + (text.substring(text.length - 1) === "s" ? text.substring(0, text.length - 1) : text)}
                            </Button>
                        </Tooltip>
                    </div>
                    {/*<div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', flex: '5%' }}>*/}
                    {/*    <Tooltip title={t("filter_label")}>*/}
                    {/*        <Button*/}
                    {/*            variant="contained"*/}
                    {/*            size="small"*/}
                    {/*            onClick={handleFilterDialogOpen}*/}
                    {/*            className={classes.filterButton}*/}
                    {/*            aria-label="add"*/}
                    {/*            startIcon={<FilterListIcon style={{ marginLeft: "30%" }} />}*/}
                    {/*        >*/}
                    {/*            */}{/* t("filter_label") */}
                    {/*        </Button>*/}
                    {/*    </Tooltip>*/}
                    {/*</div>*/}
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', flex: '5%' }}>
                        <Tooltip title={t("Edit")}>
                           
                                <Button
                                    disabled={selected.length !== 1 || controlsDisabled}
                                    size="small"
                                    className={classes.editButton}
                                    onClick={handleEdit}
                                    startIcon={<EditIcon style={{ marginLeft: "30%" }} />}
                                    aria-label="edit"
                                    variant="contained"
                                >
                                    {/* t("filter_label") */}
                                </Button>
                           
                        </Tooltip>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', flex: '15%' }}>
                        <Tooltip title={t("Delete")}>
                            
                                <Button
                                    disabled={selected.length === 0 || controlsDisabled}
                                    size="small"
                                    onClick={handleDelete}
                                    aria-label="delete"
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<DeleteIcon style={{ marginLeft: "30%" }} />}
                                >
                                    {/* t("delete_label") */}
                                </Button>
                           
                        </Tooltip>
                    </div>


                    <HelpVideoDialog  topic={text} />

                    {checkFilterShown && (
                        <div style={{ flex: "20%" }}>
                            <ControlledCheckBox
                                onCheckChange={onCheckFilterChange}
                                text={"Show only completed jobs"}
                            />
                        </div>
                    )}

                    <Typography
                        className={classes.selectedCount}
                        style={{ color: "grey", fontSize: "16px" }}>
                        {selectedItemText()}
                    </Typography>

                    <div className={classes.searchBar}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position={"start"}>
                                        <SearchIcon style={{ color: "grey" }} />
                                    </InputAdornment>
                                )
                            }}
                            label={`${t("search_label")} by ${keyColumn.name}`}
                            id="outlined-size-small"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            variant="outlined"
                            fullWidth
                            size="small"
                            dense
                        />
                    </div>
                    
                </Box>
                <DataTable
                    selectableRows
                    pagination
                    openDialog={
                        (<Dialog />).type === (<CopySampleTypeDialog />).type
                            ? openCopySampleTypeDialog
                            : openDialog
                    }
                    paginationPerPage={
                        typeof paginationPerPage !== 'undefined'
                            ? paginationPerPage
                            : 90
                    }

                    striped
                    {...tableProps}
                    onSelectedRowsChange={(e) => setSelected(e.selectedRows)}
                    columns={columns}
                    data={filteredRows}
                    dense={dense}
                    noDataComponent={<div style={{ textAlign: "center", marginBottom: "20px" }}><p>{t("Loading table data")}...</p> <CircularProgress style={{ marginBottom: "50px" }} /></div>}
                    paginationRowsPerPageOptions={[30, 60, 90, 120]}
                    clearSelectedRows={clearSelectedRows}
                    paginationComponentOptions={{
                        rowsPerPageText: t("rows_per_page_label"),
                        rangeSeparatorText: t("range_seperator_label"),
                    }}
                />
            </Paper>
            
            <Dialog
                openDialog={
                    (<Dialog />).type === (<CopySampleTypeDialog />).type
                        ? openCopySampleTypeDialog
                        : openDialog
                }
                onDialogClose={
                    (<Dialog />).type === (<CopySampleTypeDialog />).type
                        ? onCopySampleTypeDialogClose
                        : onDialogClose
                }
                selectedItems={selected[0]}
                viewing={null}
                parentRow={selectedRow}
                openEdit={openEdit}
                updateTable={() => updateTable(clearOptions)}
                rowCodes={rowCodes}
            />
            {/*<FilterDialog*/}
            {/*    openDialog={openFilterDialog}*/}
            {/*    columns={columns}*/}
            {/*    handleFilterDialogClose={handleFilterDialogClose}*/}
            {/*    keyColumn={keyColumn}*/}
            {/*    setColumnFilters={setColumnFilters}*/}
            {/*/>*/}
            <OKDialog
                show={OKDialogState.shown}
                title={OKDialogState.title}
                message={OKDialogState.message}
                flavour={OKDialogState.flavour}
                callback={
                    (res) => {
                        let callback = OKDialogState.callback;
                        setOKDialogState({ shown: false });
                        if (callback) callback(res);
                    }
                }
            />
           
        </div>
    );
}





export default GenericTable;