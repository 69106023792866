
import { Box, makeStyles, Button, TextField, Typography, Divider, withStyles } from '@material-ui/core';

import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

import React, { useContext, useState, useEffect, useRef } from 'react';

import { getAllIdocQueues, remIdocFromQueue } from '../../services/iDocWorkQueService'

import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import Paper from "@material-ui/core/Paper";
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import DeleteIcon from "@material-ui/icons/Delete";
import UserService from '../../keycloak'
import "react-table-6/react-table.css";
import { SingleSelector } from "../SelectComponents";
import AddQueIcon from '@mui/icons-material/AddCircleOutline';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';


const AllQueuesTable = (props) => {

    const [allRows, setAllRows] = useState([])
    const [filteredRows, setFilteredRows] = useState([])
    const classes = props.classes;
    const { t } = useTranslation();
   
    const [timeoutElapsed, setTimeoutElapsed] = useState(false);
    const [alertContext, setAlertContext] = useContext(AlertContext);
    const [selectedOperator, setSelectedOperator] = useState("ALL");

    // Helper function for sorting iDocs by their sequence attribute.
    const rowCompare = (a, b) => {
        if (a.sequence < b.sequence) return -1;
        else if (a.sequence > b.sequence) return 1;
        else return 0;
    }

    // A sort method for the state column of jobs table.
    const progressSort = (rowA, rowB) => {
        const a = rowA.progress
        const b = rowB.progress
        if (a > b) { return 1; }
        if (b > a) { return -1; }
        return 0;
    };

    // Sort rows by sequence attribute.
    const sortAndSetRows = (res) => {
        if (res.length == 0) return;
        res.sort((a, b) => rowCompare(a, b))
        setAllRows(res);
        console.log("iDoc queue tabled refreshed!!")
    }

    useEffect(() => {
        if (selectedOperator !== "ALL") {
            setFilteredRows(allRows.filter((r) => { return r.operator === selectedOperator }))
        }
        else setFilteredRows(allRows)
    }, [allRows])


    // Populates the table.
    const fetchRows = () => {
        setTimeoutElapsed(false)
        setTimeout(() => { setTimeoutElapsed(true) }, 3000);
        getAllIdocQueues().then((res) => {  // api call.
            if (!res.req_error) {
                sortAndSetRows(res)
            }
            else {
                setAlertContext({ ...alertContext, open: true });
                return;
            }
        })
    }

    // On mount, get the table data. Also set up a timer to refresh table every 5 seconds.
    var poller = null;
    useEffect(() => {
        fetchRows();
        poller = setInterval(() => { fetchRows() }, 5000);
        return () => { clearInterval(poller) }
    }, [])

    const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 7,
        borderRadius: 5,
        backgroundColor: theme.palette.grey[300],
    }));

    const getTooltipText = (row) => {
        var line1 = row.testsCompleted + " " + t("out of") + " " + (row.numberOfTests === null ? "NaN" : row.numberOfTests) + " " + t("tests completed") + ".";
        var line2 = ` - ${row.testsPassed} ${t("tests passed")}.`
        var line3 = ` - ${row.testsCompleted - row.testsPassed} ${t("tests failed")} or ignored.`
        return (<div style={{ fontSize: "12px" }}>{line1}<br />{line2}<br />{line3}</div>)
    }

    // Each row has a progress component.
    const getProgress = (row) => {
        var progress = Math.floor((row.testsCompleted / (row.numberOfTests === null ? 0 : row.numberOfTests)) * 100)
        if (isNaN(progress)) progress = 0;
        row.progress = progress;
        return (
            <Tooltip followCursor={true} title={getTooltipText(row)}>
                <div style={{ minWidth: "100px" }}>
                    {progress + "% " + t("complete")}
                    <CustomLinearProgress variant="determinate" value={progress} />
                </div>
            </Tooltip>
        )
    }
    
    const getRemoveButton = (row) => {
        return <Tooltip followCursor={true} title={t("Remove from operators's queue")}>
            <IconButton onClick={() => {  // Api call...
                remIdocFromQueue(row.id).then((res) => {
                    fetchRows();
                })
            }}>
                <DeleteIcon />
            </IconButton>
        </Tooltip>
    }

    const getEquipmentNames = (row) => {
        var names = row.equipmentNames;
        if (names === null || names.length === 0) return null;
        if (names.length === 1) return names[0];
        return `${names[0]} & ${names.length - 1}  more...`
    }

    const getTooltip = (text) => {
        return <tooltip title={text}><span>{text}</span></tooltip>
    }

    const getIcon= (typ) => {
        return (

             <Tooltip followCursor={true} title={getAddTooltip(typ)}>
			                { getIconByTyp(typ)		}
            </Tooltip>
        )
    }

const getIconByTyp= (typ) => {
	if (typ==0) return <AddQueIcon />
	if (typ==1) return <DriveFolderUploadOutlinedIcon />
	if (typ==2) return <HandymanOutlinedIcon />
	
}
const getAddTooltip= (typ) => {
	if (typ==0) return "IDoc";
	if (typ==1) return "Importing Results";
	if (typ==2) return "Manual Entry";	
}

    const cols = [
        { selector: row => getIcon(row.queueType), 		sortable: false,   name: t("Queue Type"), key: "queuetype" },
      	{ selector: row => getTooltip(row.operator), 	sortable: true,   name: t("Operator"), type: "text", key: "operator" },
        { selector: row => getTooltip(row.testMethod), 	sortable: true,   name: t("test_method_name_field_label"), type: "text", key: "testMethod" },
        { selector: row => getTooltip(row.jobId), 		sortable: true,   name: t("job_id_label"), type: "text", key: "jobID" },
        { selector: row => getTooltip(row.sampleTypeName),sortable: true, name: t("sample_type_name_label"), type: "text", key: "sampleTypeName" },
        { selector: row => row.numberOfSamples, 		sortable: true,   name: t("sample_count_label"), type: "numeric", key: "numberOfSamples" },
        { selector: row => getProgress(row), 			sortable: true, sortFunction: progressSort, name: t("Progress"), type: "text", key: "progress" },
//        { selector: row => getTooltip(getEquipmentNames(row)), sortable: false, name: t("equipment_label"), type: "text", key: "equipmentNames" },

    ]

    if (UserService.hasRole(['ADMIN', 'LAB_MANAGER', 'LAB_SUPERVISOR'])) cols.push({ selector: row => getRemoveButton(row), key: "remove" })

    const getOptions = () => {
        var operators = new Set();
        allRows.forEach((row) => { operators.add(row.operator) });
        operators = Array.from(operators);
        let rtn = operators.map((o) => { return { value: o, label: o } });
        rtn.push({ value: "ALL", label: "Show All Operators" });
        return rtn;
    }


    const handleFilterSelect = (selected) => {
        setSelectedOperator(selected.value);
        if (selected.value !== "ALL") {
            setFilteredRows(allRows.filter((row) => { return row.operator === selected.value }));
        }
        else {
            setFilteredRows(allRows)
        }
    }


    return (

        <Paper className={classes.paper}>

            <Box mx={2} className={classes.tableHeader}>
                <div className={classes.title} style={{ width: "120px" }}></div>
                <Typography
                    className={classes.title}
                    style={{ fontWeight: 400, fontSize: "22px" }}>
                    {t("Queue Items - All Users")}
                </Typography>
                {t("Filter by Operator")}:
                {/*  <FormControl variant='outlined' className={clsx(classes.formControl, classes.noLabel, classes.dialogInput)}>*/}
                <SingleSelector placeholder={t("Type or select operator name") + "..."}
                    width={450}
                    options={getOptions()}
                    handleSelect={handleFilterSelect}>
                </SingleSelector>
                {/* </FormControl>*/}

            </Box>
            {filteredRows.length > 0 || timeoutElapsed === true ?
                <DataTable id="iDocTable"
                    customStyles={{
                        table: { style: { width: "85vw" } },
                        headCells: {
                            style: {
                                fontWeight: 'bold',
                                fontSize: "15px"
                            },
                        }
                    }}
                    paginationPerPage={90}
                    paginationRowsPerPageOptions={[30, 60, 90, 120]}
                    pagination
                    striped
                    columns={cols}
                    data={filteredRows}
                    noDataComponent={<div style={{ margin: "50px", fontSize: "18px" }}>No results! Will retry...</div>}
                    clearSelectedRows={() => { }}
                    paginationComponentOptions={{
                        rowsPerPageText: t("rows_per_page_label"),
                        rangeSeparatorText: t("range_seperator_label"),
                    }} /> : <div style={{ textAlign: "center", marginBottom: "20px" }}><p>{t("Loading table data")}...</p> <CircularProgress style={{ marginBottom: "50px" }} /></div>}
        </Paper>
    )


}

export default AllQueuesTable