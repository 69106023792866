/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react'
import { downloadReport } from '../../services/RegisterJobService';
import { CircularProgress, Typography } from '@material-ui/core';
import { Button } from '@mui/material';
import { sendToDMP } from '../../services/SendDMPService';
import { useTranslation } from 'react-i18next';


const ExcelLinkPage = (props) => {

    const { t } = useTranslation();
    const { jobId , name } = props;
    const [generatingExcel, setGeneratingExcel] = useState(false);
    const [excel, setExcel] = useState(null);
    const [sendResultText, setSendResultText] = useState("");
  
    useEffect(() => {
        if (jobId && name) generateExcel();
    }, [jobId, name])

    const getExcelLink = () => {
        const fileName = (name + ".ExcelReport.xlsx").replace(/ /g, '_');
        let blob = new Blob([excel], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const link = <a href={window.URL.createObjectURL(blob)} download={fileName}>{fileName}</a>;
        return link
    }

    const getExcelBlob = () => {
        let blob = new Blob([excel], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        return blob;
    }

    const generateExcel = () => {
        if (generatingExcel) return;
        setGeneratingExcel(true);
        setExcel(null)
        downloadReport("excel", jobId).then((file) => {
            setGeneratingExcel(false);
            if (file) setExcel(file);
            else alert("Problem generating Excel file. Possible network issue. Please check and try again.")
        })
    }
    
    const sendExcelToDMP = async () => {
        console.log("SENDING TO DMP");
        console.log("JOBID: ", jobId);
        console.log("NAME: ", name);

        let metadata = {
            tester_email: "Jatinder.Clair@pepsico.com",
            test_result: 2,
            record_id: jobId
        };

        const formData = new FormData();
        formData.append('excel_file', getExcelBlob());
        formData.append("metadata", JSON.stringify(metadata));

        sendToDMP(formData).then((res) => {
            console.log(res);
            if (res && res.status === 200) {
                setSendResultText(t('Sent'));
            }
            else {
                setSendResultText(t('Failed to Send'));
            }
        });

    }

    return <div>
        <h3>Excel report for job: <em>'{name}'</em></h3>
        {generatingExcel && <>
            <div style={{ marginTop: "20px" }}>
                <CircularProgress size={15} />
                <span style={{ marginLeft: "10px" }}><b>Generating file</b> - A download link will appear here when it is ready.</span>
            </div>
            
           
            <div style={{ marginTop: "20px" }}>Excel report generation may take a matter of minutes for large jobs. Please be patient...</div>
            <div style={{ marginTop: "40px" }}><b>N.B. This is a new browser tab!</b></div>
            <p>Don't close out or navigate within <em>this</em> tab until after the link is available and you have downloaded the file.</p>
            <p>Your <b>original tab</b> remains open though, so you may use it to continue with other tasks while waiting for the link to appear here.</p>
        </>}
        
        {excel && <>
            <div style={{ marginTop: "20px" }}>File ready for download... </div>
            <div style={{ marginTop: "20px" }}>{getExcelLink()}</div>
            <div style={{ marginTop: "20px" }}>Once you've downloaded it, you may close out this tab on your browser.</div>

            <div style={{ marginTop: "20px", display: "flex", flexDirection: "row", alignItems: "center" }}>
                {/* <Button
                    disabled={selected.length === 0 || controlsDisabled}
                    size="small"
                    onClick={handleDelete}
                    aria-label="delete"
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteIcon style={{ marginLeft: "30%" }} />}
                > */}
                <Button aria-label='send' onClick={() => {
                            sendExcelToDMP();
                        }} variant='contained' color='secondary'>{t('Send to DMP')}</Button>
                <Typography style={{marginLeft: "10px"}} hidden={sendResultText === ""}>{sendResultText}</Typography>
            </div>
            
        </>}
    </div>
}

export default ExcelLinkPage;