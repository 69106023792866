import React from "react";
import { Redirect, Route } from "react-router-dom";
import UserService from "../../keycloak";


const PrivateRoute = (props) => {

    let { Component, roles, ...rest } = props;
    return (
        <Route {...rest}>
            {UserService.hasRole(roles) ? <Component /> : <Redirect to={{ pathname: "/unauthorisedAccess" }} />}
        </Route>
    );
}

export default PrivateRoute;

