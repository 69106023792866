import HttpService from "./HttpService";

const axios = HttpService.getAxiosClient();
const methods = HttpService.HttpMethods;
const baseURL = process.env.REACT_APP_BACKEND_URL;
const rootRequestMapping = 'client'

const getClients = async () => {
  try {
    const res = await axios.request({
      url: `${baseURL}${rootRequestMapping}/all`,
      method: methods.GET
    });
    const data = await res.data;

    return data;
  }
  catch (error) {
    return null;
  }
}


const checkCode = async (id, code, entity) => {
   
  try {
    let res = await axios.post(`${baseURL}uniquecheck/check`, {
      "id": id,
      "code": code,
      "entity": entity
    })
    console.log(res)
    return res.data;
  }

  catch (error) {
    console.error(error)
  }
}

const addClient = async (clientName, description, contactFirstName, contactLastName, email, phone, clientId) => {
  const contact = contactFirstName + " " + contactLastName
  console.log(contact);
  try {
    let res = await axios.post(`${baseURL}${rootRequestMapping}/save`, {
      "name": clientName,
      "description": description,
      "contact": contact,
      "email": email,
      "phone": phone,
      "clientId": clientId,
    })
    return res
  }

  catch (error) {
    console.error(error)
    return error;
  }
}

const updateClient = async (id, clientName, description, contactFirstName, contactLastName, email, phone, clientId) => {
  const contact = contactFirstName + " " + contactLastName

  try {
    let res = await axios.post(`${baseURL}${rootRequestMapping}/update`, {
      "id": id,
      "name": clientName,
      "description": description,
      "contact": contact,
      "email": email,
      "phone": phone,
      "clientId": clientId,
    })
    return res;
  }

  catch (error) {
    console.error(error)
    return error;
  }
}

const deleteClient = async (id) => {
  try {
    let res = await axios.request({
      url: `${baseURL}${rootRequestMapping}/delete/${id}`,
      method: methods.GET,
    })
    return res;
  }

  catch (error) {
    console.error(error);
  }
  return false;
}

export { getClients, addClient, updateClient, deleteClient, checkCode }