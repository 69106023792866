import { useEffect, useState } from 'react'
import { getAnalyticsData } from '../../services/DashboardService'
import PieChart from './chartTypes/PieChart';
import BarChart from './chartTypes/BarChart';
import { graphBorders, graphBackgrounds } from './Colors'
import { useTranslation } from 'react-i18next';
import ExportAnalytics from '../Export/ExportAnalytics';


const TestsCharts = (props) => {
    const { t } = useTranslation();

    const { selectedFiltersString } = props;
    const [selectedFilters, setSelectedFilters] = useState(JSON.parse(selectedFiltersString));
    const [results, setResults] = useState(null);
    const [mostPopularTestMethodsData, setMostPopularTestMethodsData] = useState(null);
    const [mostPopularTestsData, setMostPopularTestsData] = useState(null);
    const [mostSuccessfulTestsData, setMostSuccessfulTestsData] = useState(null);
    const [mostRiskyTestsData, setMostRiskyTestsData] = useState(null);
    //const [changed, setChanged] = useState(false);

    const analytics = [4, 5, 6, 7]

    useEffect(() => {
        console.log(selectedFiltersString)
        setSelectedFilters(JSON.parse(selectedFiltersString));
        
       
       // setChanged(!changed)
    }, [selectedFiltersString])


    useEffect(() => {
        setResults(null)
        var promises = analytics.map((i) => {
            return getAnalyticsData({
                analytic: i,
                category: selectedFilters.category,
                neckFinish: selectedFilters.neckFinish,
                design: selectedFilters.design,
                bottleSize: selectedFilters.bottleSize,
                supplier: selectedFilters.supplier,
                region: selectedFilters.region,
                dateFrom: selectedFilters.fromDate,
                dateTo: selectedFilters.toDate
            })
        })

        Promise.all(promises).then((res) => {
            console.log(">>>> requests done!")
            res.forEach((ds) => {
                ds.sort((a, b) => { return a.value > b.value ? -1 : 1 });
            })
            setResults({
                popularTestMethods: res[0],
                popularTests: res[1],
                successfulTests: res[2],
                riskyTests: res[3]
            });

        });
      //  setChanged(!changed)
    }, [selectedFilters])


   


   
 
    const getMostPopularTestMethodsDataset = () => {
        if (results && results.popularTestMethods) {
            const labels = results.popularTestMethods.map((r) => { return r.name })
            const values = results.popularTestMethods.map((r) => { return r.value })
            const data = {
                labels: labels,
                datasets: [
                    {
                        label: "Occurrences",
                        data: values,
                        backgroundColor: graphBackgrounds[0],
                        borderColor: graphBorders[0],
                        borderWidth: 1
                    }
                ]
            }
            setMostPopularTestMethodsData(data);
        }
        else setMostPopularTestMethodsData(null);

    }


    const getMostPopularTestsDataset = () => {
        if (results && results.popularTests) {
          
            const labels = results.popularTests.map((r) => { return r.name })
            const values = results.popularTests.map((r) => { return r.value })
            const data = {
                labels: labels,
                datasets: [
                    {
                        label: "Occurrences",
                        data: values,
                        backgroundColor: graphBackgrounds[1],
                        borderColor: graphBorders[1],
                        borderWidth: 1
                    }
                ]
            }
            setMostPopularTestsData(data);
        }
        else setMostPopularTestsData(null);

    }


    const getMostSuccessfulTestsDataset = () => {
        if (results && results.successfulTests) {
           
            const labels = results.successfulTests.map((r) => { return r.name })
            const values = results.successfulTests.map((r) => { return r.value })
            const data = {
                labels: labels,
                datasets: [
                    {
                        label: "Success rate (%)",
                        data: values,
                        backgroundColor: graphBackgrounds[2],
                        borderColor: graphBorders[2],
                        borderWidth: 1
                    }
                ]
            }
            setMostSuccessfulTestsData(data);
        }
        else setMostSuccessfulTestsData(null);

    }


    const getMostRiskyTestsDataset = () => {
        if (results && results.riskyTests) {
            
            const labels = results.riskyTests.map((r) => { return r.name })
            const values = results.riskyTests.map((r) => { return r.value })
            const data = {
                labels: labels,
                datasets: [
                    {
                        label: "Failure rate (%)",
                        data: values,
                        backgroundColor: graphBackgrounds[3],
                        borderColor: graphBorders[3],
                        borderWidth: 1
                    }
                ]
            }
            setMostRiskyTestsData(data);
        }
        else setMostRiskyTestsData(null);
    }




    useEffect(() => {
        console.log(JSON.stringify(results, null, 2))
        getMostPopularTestMethodsDataset();
        getMostPopularTestsDataset();
        getMostSuccessfulTestsDataset();
        getMostRiskyTestsDataset();
    }, [results])

  
    return (<div>

        <ExportAnalytics selectedFilters={selectedFilters} title="Analytics Dashboard - Tests Charts"></ExportAnalytics>

        <div style={{ display: "flex", marginBottom: "30px" }}>
            <div style={{ flex: 1 }}>
                <BarChart title={t("Most popular test methods")} data={mostPopularTestMethodsData} axisLabels={{ x: t("test_method_tab"), y: t("No. of occurrences") }} />
            </div>


            <div style={{ flex: 1 }}>
                <BarChart title={t("Most popular tests")} data={mostPopularTestsData} axisLabels={{ x: t("tests_field_label"), y: t("No. of occurrences") }} />
            </div>

        </div>
       
        
        
        <div style={{ display: "flex", marginBottom: "30px" }}>
            <div style={{ flex: 1 }}>
                <BarChart title={t("Least risky tests") + " ( Pass rate per test - most successful )"} data={mostSuccessfulTestsData} axisLabels={{ x: t("tests_field_label"), y: t("Pass rate (%)") }} />
            </div>


            <div style={{ flex: 1 }}>
                <BarChart title={t("Most risky tests") + " ( Test results most likely to fail )"} data={mostRiskyTestsData} axisLabels={{ x: t("tests_field_label"), y: t("Failure rate (%)") }} />
            </div>

        </div>

        
          
           
       

    </div>)

}
export default TestsCharts;


//<pre>{JSON.stringify(results, null, 2)}</pre>


//return (<table>

//    <tr style={{ verticalAlign: "top" }}>

//        <td >
//            {mostPopularTestMethodsData && (
//                <div style={{ height: "600px" }}>
//                    <BarChart title="Most populular test methods:" data={mostPopularTestMethodsData} axisLabels={{ x: "Test Methods", y: "No. of occurrences" }} />
//                </div>)}
//        </td>
//        <td>
//            {mostPopularTestsData && (
//                <div style={{ height: "600px", marginLeft: "100px" }}>
//                    <BarChart title="Most populular tests:" data={mostPopularTestsData} axisLabels={{ x: "Tests", y: "No. of occurrences" }} />
//                </div>)}
//        </td>
//    </tr>

//    <tr style={{ verticalAlign: "top" }}>
//        <td>
//            {mostSuccessfulTestsData && (
//                <div style={{ width: "700px", height: "600px" }}>
//                    <BarChart title="Most successful tests:" data={mostSuccessfulTestsData} axisLabels={{ x: "Tests", y: "Success rate (%)" }} />
//                </div>)}
//        </td>
//        <td>
//            {mostRiskyTestsData && (
//                <div style={{ width: "700px", height: "600px", marginLeft: "100px" }}>
//                    <BarChart title="Most risky tests:" data={mostRiskyTestsData} axisLabels={{ x: "Tests", y: "Failure rate (%)" }} />
//                </div>)}
//        </td>
//    </tr>

//</table>)