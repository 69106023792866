import { MenuItem, Select } from '@material-ui/core';
import i18next from 'i18next';
import React, { useState } from 'react';
import languages from "../../languages.json";

function Settings(props){
    let [language, setLanguage] = useState(localStorage.getItem("language") || "en");

    return (
        <div>
            <Select
                displayEmpty
                value={language}
                onChange={(e) => {
                    i18next.changeLanguage(e.target.value);
                    localStorage.setItem("language", e.target.value);
                    setLanguage(e.target.value);
                }}
              >
                {Object.keys(languages).map((language_code) => (
                  <MenuItem key={language_code} value={language_code}>
                    {languages[language_code].name}
                  </MenuItem>
                ))}
              </Select>
        </div>
    )
}

export default Settings;