/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import BarChart from '../chartTypes/BarChart';
import { graphBorders, graphBackgrounds } from '../Colors'
import { useTranslation } from 'react-i18next';


const PunctualityChart = (props) => {
    const { t } = useTranslation();
    const { data } = props;
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        if (data) convertDataset();
    }, [data])

    const convertDataset = () => {
    
            //const labels = results.jobsPerMonth.map((r) => { return r.name })
           
            const d = {
                labels: Object.keys(data),
                datasets: [
                    {
                        label: "% jobs late",
                        data: Object.keys(data).map((cat) => { return data[cat].percentLate}),
                        backgroundColor: graphBackgrounds[0],
                        borderColor: graphBorders[0],
                        borderWidth: 1
                    },
                    {
                        label: "% jobs on time",
                        data: Object.keys(data).map((cat) => { return data[cat].percentOnTime }),
                        backgroundColor: graphBackgrounds[3],
                        borderColor: graphBorders[3],
                        borderWidth: 1
                    },
                ]
            }
            setChartData(d);
        
        
    }

    return <div >
        <BarChart
            title={t("% of PET type jobs overdue / on-time ( std. 28 day period )") + ":"}
            data={chartData}
            showLegend={true}
            axisLabels={{ x: t("Job sample-type package type (PET types only)"), y: t("%") }} />
    </div>

}


export default PunctualityChart;