

import { Box,  Typography,  } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState, useEffect} from 'react';
import { getJobs } from '../../services/TestingJobsService';
import {  getHistoryOpenSessions, getHistoryClosedSessions } from '../../services/iDocSessionsService'
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import Paper from "@material-ui/core/Paper";
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import OpenSessionIcon from '@mui/icons-material/FormatListNumbered';
import ClosedSessionIcon from '@mui/icons-material/FindInPage';
import "react-table-6/react-table.css";
import {  parseDate } from './HelperFunctions';



const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other} >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};




const SessionsHistoryTable = (props) => {

    const classes = props.classes;
    
    const history = props.history;
    const [jobs, setJobs] = useState([]);
    const [openRows, setOpenRows] = useState([]);
    const [closedRows, setClosedRows] = useState([]);
    const { t } = useTranslation();
    const [tabValue, setTabValue] = React.useState(0);
    const [alertContext, setAlertContext] = useContext(AlertContext);
    const [timeoutElapsed, setTimeoutElapsed] = useState(false);

    const handleTabChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    const getJobId = (row) => {
        var j = jobs.find(job => job.jobID === row.jobId)
        if (j) return j.id;
        else return false;
    }

    const getActionButton = (row) => {
        const viewResultsBtn = (
            <Tooltip followCursor={true} title="View Results">
                <IconButton
                    onClick={() => {
                        let jobId = getJobId(row);
                        if (jobId === false) {
                            alert("This session can't be opened because it has been orphaned, i.e. the job it is associated with has been removed.")
                            return;
                        }
                        var url = `/testing/?openSessionTable=true&jobId=${jobId}&iDocId=${row.id}&iDocTestMethod=${row.testMethod}`
                        history.push(url)
                    }} > {tabValue === 0 ? <OpenSessionIcon /> : <ClosedSessionIcon />}

                </IconButton >
            </Tooltip>
        );
        return viewResultsBtn;
    }

    const getEquipmentNames = (row) => {
        var names = row.equipmentNames;
        if (names === null || names.length === 0) return null;
        if (names.length === 1) return names[0];
        return `${names[0]} & ${names.length - 1}  more...`
    }

    const cols = [
        { selector: row => row.jobId, sortable: true, name: t("job_id_label"), type: "text", key: "jobID" },
        { selector: row => row.testMethod, sortable: true, name: t("test_method_name_field_label"), type: "text", key: "testMethod" },
        { selector: row => getEquipmentNames(row), width: "320px", sortable: true, name: t("equipment_label"), type: "text", key: "equipmentNames" },
        { selector: row => parseDate(row.startedAt, 'DD/MM/YYYY @ HH:mm'), sortable: true, name: t("Date & Time Started"), type: "date", key: "startDateTime" },
        { selector: row => row.createdBy !== null ? row.createdBy : "<unknown>", sortable: true, name: t("User"), type: "text", key: "user" },
        { selector: row => getActionButton(row), sortable: false, key: "action" },

    ]

    useEffect(() => {
        fetchAllJobs();
    }, [])


    const fetchAllJobs = () => {

        getJobs().then((data) => {
            if (data.req_error) {
                setAlertContext({ ...alertContext, open: true });
                return;
            }
            else {
                setJobs(data);
            }
        })
    }


    useEffect(() => {
        setTimeoutElapsed(false)
        setTimeout(() => { setTimeoutElapsed(true) }, 3000);
        if (tabValue === 0) {
            getHistoryOpenSessions().then((res) => {
                console.log("open sessions: " + JSON.stringify(res, null, 2))
                setOpenRows(res);
            })
        }
        if (tabValue === 1) {
            getHistoryClosedSessions().then((res) => {
                console.log("closed sessions: " + JSON.stringify(res, null, 2))
                setClosedRows(res);
            })
        }
    }, [tabValue])


    return (
        <Paper className={classes.paper}>
            <Box mx={2} className={classes.tableHeader}>
                <Typography style={{ fontWeight: 400, fontSize: "20px" }}>{"iDoc Sessions history: "}</Typography>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} className={classes.tabBar} textColor="secondary" indicatorColor="secondary" onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Open Sessions" />
                        <Tab label="Closed Sessions" />
                    </Tabs>
                </Box>
                <Typography style={{ fontWeight: 400, fontSize: "20px", marginLeft: "80px" }}>{tabValue === 0 ? "Showing Open Sessions" : "Showing Closed Sessions"}</Typography>
            </Box>

            <TabPanel value={tabValue} index={0}>
                {openRows.length > 0 ?
                    <DataTable id="histOpen"
                        customStyles={{
                            table: { style: { width: "85vw" } },
                            headCells: {
                                style: {
                                    fontWeight: 'bold',
                                    fontSize: "15px"
                                },
                            }
                        }}
                        pagination
                        paginationPerPage={90}
                        paginationRowsPerPageOptions={[30, 60, 90, 120]}
                        striped
                        columns={cols}
                        data={openRows}
                        clearSelectedRows={() => { }}
                    />
                    : <div style={{ textAlign: "center", marginBottom: "50px" }}><p>Loading open sessions table data...</p> <CircularProgress style={{ marginBottom: "50px" }} /></div>}
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                {closedRows.length > 0 ?
                    <DataTable id="histClosed"
                        customStyles={{
                            table: { style: { width: "85vw" } },
                            headCells: {
                                style: {
                                    fontWeight: 'bold',
                                    fontSize: "15px"
                                },
                            }
                        }}
                        pagination
                        paginationPerPage={90}
                        paginationRowsPerPageOptions={[30, 60, 90, 120]}
                        striped
                        columns={cols}
                        data={closedRows}
                        clearSelectedRows={() => { }}
                    />
                    : <div style={{ textAlign: "center", marginBottom: "50px" }}><p>Loading closed sessions table data...</p> <CircularProgress style={{ marginBottom: "50px" }} /></div>}
            </TabPanel>
        </Paper>
    )
}

export default SessionsHistoryTable