import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertContext } from './Contexts/AlertContext/AlertContext';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import Paper from "@material-ui/core/Paper";
import { Box, makeStyles, Button, TextField, Typography, Divider, withStyles} from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import DataTable from 'react-data-table-component';
import SearchIcon from "@material-ui/icons/Search";
import OpenReportIcon from '@mui/icons-material/AssignmentOutlined';
import { getAllRegisteredJobs } from '../services/RegisterJobService';
import RecievedIcon from '@mui/icons-material/MailOutline';
import ProgressIcon from '@mui/icons-material/Autorenew';
import OnHoldIcon from '@mui/icons-material/PauseCircleOutline';
import CompletedAcceptedIcon from '@mui/icons-material/Done';
import CompletedPendingIcon from '@mui/icons-material/HowToReg';
import OverdueIcon from '@mui/icons-material/Alarm';
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import moment from "moment";
import InputAdornment from "@material-ui/core/InputAdornment";
import { grey } from '@material-ui/core/colors';
import { withRouter } from 'react-router-dom';


const useStyles = makeStyles(theme => ({

  
    pageHeading: {
        display: "flex",
        alignItems: "center",
       // columnGap: "1rem",
    },
    title: {
        flex: "1 1",
        whiteSpace: "nowrap",
        marginBottom: "1rem"
    },
    tableHeader: {
        display: "flex",
        alignItems: "center",
        columnGap: "0.5rem",
        paddingTop: "1rem",
        overflow: "visible",
        marginBottom: "0.3rem",
    },
    lastUpdateText: {
        color: grey[600]
    },
    root: {
      //  width: "100%",
     
        marginBottom: theme.spacing(2),
    },
    paper: {
        marginBottom: theme.spacing(2),
    },
    table: {
      //  minWidth: 750,
       // width: "30%"
    },
    rowButton: {
        color: "black",
        background: "lightGrey",
        minWidth: 120
    },
    navButton1: {
        color: "black",
        background: "lightGrey",
        "&:hover": { backgroundColor: "lightGrey"},
        minWidth: 120,
        borderRadius: "5px",
        padding: "10px"
    },
    navButton2: {
        color: "black",
        background: "#ADCBE5",
        "&:hover": { backgroundColor: "#ADCBE5" },
        minWidth: 120,
        borderRadius: "5px",
        padding: "10px"
    },
    plink: {
     
        fontSize: "large",
        cursor: "pointer",
    },
    tabBar: {
        marginTop: "6px",
        backgroundColor: '#ADCBE5',
        color: 'black',
        borderRadius: "5px",
        boxShadow: "none",
        overflow: 'hidden',
        maxWidth: '350px',
        alignItems: 'center',
        marginLeft:"10px"
    }
}));



const getJobStates = (t) => {

    const states = {
        0: { text: t('state_received_label'), color: "#ADCBE5", icon: <RecievedIcon /> },
        1: { text: t('state_inProgress_label'), color: "#F7C377", icon: <ProgressIcon /> },
        2: { text: t('state_onHold_label'), color: "#F7C377", icon: <OnHoldIcon /> },
        3: { text: t('state_completedAccepted_label'), color: "#77C780", icon: <CompletedAcceptedIcon /> },
        4: { text: t('state_completedPending_label'), color: "#BEE8C4", icon: <CompletedPendingIcon /> },
        5: { text: t('state_overdue_label'), color: "#DD7677", icon: <OverdueIcon /> },
    }
    return states;
}


const parseDate = (date, format) => {
    if (date === null) return "<unknown>"
    try {
        let rtn = null;
        let temp = moment(date).format(format)
        if (temp !== "Invalid date") rtn = temp;
        if (rtn === null || rtn === "") rtn = "<unknown>"
        return rtn;
    }
    catch (err) {
        return "<unknown>"
    }
}

function DashboardJobs(props){
    const classes = useStyles();

   

    return (
        //removed dense prop
        <JobsTable history={props.history} classes={classes}/>
    )
}


export const JobsTable = (props) => {
    const classes = props.classes;
    const { t } = useTranslation();
    // const dense = props.dense;
    const [jobsData, setJobsData] = useState([]);
    const [rows, setRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [alertContext, setAlertContext] = useContext(AlertContext);

   

    // Populates the table.....
    const fetchRows = () => {
        getAllRegisteredJobs().then((data) => {
            if (!data) {
                setAlertContext({ ...alertContext, open: true });
                return;
            }
            else {
             //   console.log(JSON.stringify(data, null, 2))
                progressFix(data);
                setJobsData(data);
                setRows(data);
            }
        })
    }

    // Populate table data on mopunt.
    useEffect(() => {
        fetchRows();
    }, []);

    // Returns a coloured button whose appearance depends on the job state. It opens the relevant job.
    // Job state: received(0), in_progress(1), on_hold(2), completed_accepted(3), completed_pending(4), overdue(5), not_configured(6) //
    const getOpenButton = (row) => {

        const button = getJobStates(t)[row.state]; //jobStates[row.state];
        
        return <Tooltip followCursor={true} title={t("Status") + ": " + button.text}>
            <div id={row.state}
                onClick={() => {
                    if (row !== null) {
                        props.history.push(`/testing/?testsTable=true&jobId=${row.id}`);
                    }
                }}
            style={{
                textAlign: "center",
                borderRadius: "5px",
                minWidth: "50px",
                cursor: "pointer",
                backgroundColor: button.color,
                padding: "5px",
                }}>
                {button.icon}
            </div>
        </Tooltip>
    }

    // A sort method for the state column of jobs table.
    const progressSort = (rowA, rowB) => {
        const a = rowA.progress
        const b = rowB.progress
        if (a > b) { return 1; }
        if (b > a) { return -1; }
        return 0;
    };






    // This gets the 'go to report' button to be displayed in the rows of the jobs table. 
    // It opens a report for the selected job.
    const getReportButton = (row) => {
        if (row.state === 0) return null;
        return <Tooltip followCursor={true} title={t("go_to_report_label")}><IconButton color="primary" 
            component={Link} to={`/reports/?open=true&jobId=${row.id}`} >
            <OpenReportIcon /></IconButton></Tooltip>   
    }

    // Handles search term change.
    const handleSearchChange = (event) => setSearchTerm(event.target.value);

    const progressFix = (res) => {
        res.forEach((r) => {
            if (!r.testsCompleted) return;
            if (!r.numberOfTests) return;
            if (r.testsCompleted > r.numberOfTests) r.numberOfTests = r.testsCompleted
        })
    }

    // Filters jobs table rows based on search term. Hooked whenever the search term changes.
    useEffect(() => {
        if (searchTerm === "" || searchTerm === null) { fetchRows(); return; }
        const res = jobsData.filter((row) => { return row.jobID.toLowerCase().startsWith(searchTerm.toLowerCase()) })
        progressFix(res)
        setRows(res);
    }, [searchTerm])


    const getTemplateNames = (row) => {
        var names = row.analysisTemplateNames;
        if (names === null || names.length === 0) return null;
        if (names.length === 1) return names[0];
        return `${names[0]} & ${names.length - 1}  more...`
    }

    // Gets progress bar tooltip text...
    const getTooltipText = (row) => {
        var line1 = row.testsCompleted + " " + t("out of") + " " + (row.numberOfTests === null ? "NaN" : row.numberOfTests) + " " + t("tests completed") + ".";
        var line2 = ` - ${row.testsPassed} ${t("tests passed")}.`
        var line3 = ` - ${row.testsCompleted - row.testsPassed} ${t("tests failed")} or ignored.`
        return (<div style={{ fontSize: "12px" }}>{line1}<br />{line2}<br />{line3}</div>)
    }

    // A custom progress indicator for progress column....
    const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 7,
        borderRadius: 5,
        backgroundColor: theme.palette.grey[300],
    }));


    // Gets the progress for a given row...
    const getProgress = (row) => {
        var progress = Math.floor((row.testsCompleted / (row.numberOfTests === null ? 0 : row.numberOfTests)) * 100)
        if (isNaN(progress)) progress = 0;
        if (progress > 100) progress = 100;
        row.progress = progress;
        return (
            <Tooltip followCursor={true} title={getTooltipText(row)}>
                <div style={{ minWidth: "100px" }}>
                    {progress + "% " + t('complete')}
                    <CustomLinearProgress variant="determinate" value={progress} />
                </div>
            </Tooltip>
        )

    }
    const cols = [
        { selector: row => row.jobID !== "" ? row.jobID : "<unknown>",   sortable: true, name: t("job_id_label"), width:"200px", key: "jobID" },
        { selector: row => parseDate(row.dueDate, 'DD/MM/YYYY'), style: { padding: "0px", textAlign: "left" },sortable: true, name: t("due_date_label"), width: "200px",key: "dueDate" },
        { selector: row => row.client.name, style: { padding: "0px", textAlign: "left" }, sortable: true, name: t("client_label"), width: "150px", key: "clientName" },
        { selector: row => getProgress(row), style: { padding: "0px", textAlign: "left" }, sortable: true,  sortFunction: progressSort, name: t("Progress"), width: "150px", key: "progress" },
        // { selector: row => getTemplateNames(row), style: { padding: "0px", textAlign: "left" }, sortable: false, name: t("templates_tab"),   key: "analysisTemplates", },
        { selector: row => getOpenButton(row), style: { padding: "0px", justifyContent: 'center', alignItems: "center", },sortable: true, sortFunction: progressSort, name: t("Click to open"), width: "160px",  key: "state" },
      //  { selector: row => getOpenButton(row), style:{marginLeft:"2%"},  sortable: false},
        // { selector: row => getReportButton(row), style:{marginLeft:"-4%"},  sortable: false}
    ];

    return (
        <Paper className={classes.paper}>
            <Box mx={2} className={classes.tableHeader}>
                <Typography
                    className={classes.title}
                    style={{ fontWeight: 400, fontSize: "22px" }}>
                    {t("jobs_label")}
                </Typography>
                <div style={{marginRight:"80px"}}>
                    <TextField
                        label={t("Search by Job ID")}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position={"start"}>
                                    <SearchIcon style={{ color: "grey" }} />
                                </InputAdornment>
                            )
                        }}
                        id="outlined-size-small"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        variant="outlined"
                        fullWidth
                        size="small"
                        // dense={+true} 
                        />
                </div>
            </Box>
            {rows.length > 0 ?
                <DataTable 
                    customStyles={{
                        table: { style: { maxWidth: "90%" } },
                        cells: {
                            style: {
                               justifyContent: 'flex-start',
                            //    marginLeft: "0px"
                               
                            },
                        },
                        headCells: {
                            style: {
                                justifyContent:"flex-start",
                             
                             
                                fontWeight: 'bold',
                                fontSize: "15px"
                            },
                        }
                    }}
                    pagination
                    paginationPerPage={15}
                    paginationRowsPerPageOptions={[15,30, 60, 90, 120]}
                    striped
                    columns={cols}
                    data={rows}
                    // dense={+dense}
                    clearSelectedRows={() => { }}
                    paginationComponentOptions={{
                        rowsPerPageText: t("rows_per_page_label"),
                        rangeSeparatorText: t("range_seperator_label"),
                    }} /> : <div style={{ textAlign: "center", marginBottom: "20px" }}><p>{t("Loading table data")}...</p> <CircularProgress style={{ marginBottom: "50px" }} /></div>}

        </Paper>
    )
}
export default withRouter(DashboardJobs);