import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { pink, yellow } from "@material-ui/core/colors";
import { Box } from "@material-ui/core";
import DataTable from "react-data-table-component";
import TestDialog from "../Dialogs/TestDialog";
import OKDialog from "../Dialogs/OKDialog";
import DragDropTable from "../DragDropTable"
import { updateSequence, resetSequence, setSequence } from '../../services/TestsService'
import { getTestMethods, deleteTestMethod, deleteTest } from '../../services/TestMethodsService'
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import ExpandIcon from '@mui/icons-material/ChevronRight';
import CollapseIcon from '@mui/icons-material/ExpandMore';
import { Button, Tooltip, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from '@material-ui/core/IconButton';


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        borderRadius: 0,
        paddingLeft: "46.75px"
    },
    paper: {
        marginBottom: theme.spacing(2),

    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    tableHeader: {
        display: "flex",
        alignItems: "center",
        columnGap: "1rem",
        paddingTop: "1rem",
        overflow: "visible",
        marginBottom: "0.3rem",
    },
    selectedCount: {
        flex: "2 2 90%",
    },
    title: {
        flex: "1 1",
        whiteSpace: "nowrap",
    },
    editButton: {
        backgroundColor: yellow[800],
        borderColor: yellow[800],
        color: "white",
        "&:hover": {
            backgroundColor: yellow[900],
        },
    },
    filterButton: {
        backgroundColor: pink[600],
        borderColor: pink[600],
        color: "white",
        "&:hover": {
            backgroundColor: pink[700],
        },
    },
    searchBar: {
        flex: "1 1 30%",
    },
}));

function TestsTable(props) {

    const { updateTable, parentRow } = props;
    const [rows, setRows] = useState([]);
    const [isViewing, setIsViewing] = useState(false);
    const [openTestDialog, setOpenTestDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [responseForConfirmationMessage, resetForConfirmationMessage] = useState();
    const classes = useStyles();
    const { t } = useTranslation();

    const [OKDialogState, setOKDialogState] = useState({
        shown: false, title: "", message: "", flavour: "success"
    })


    const getActions = (row) => {
        return (
            <Box style={{ display: 'flex', alignSelf: 'center', gap: "-10px" }}>
                <Tooltip title="Add child test"><IconButton onClick={() => handleOpenTestDialog(false, row)}><AddIcon fontSize="small" /></IconButton></Tooltip>
                <Tooltip title="View"><IconButton onClick={() => handleOpenTestDialog(true, row)}><EditIcon fontSize="small" /></IconButton></Tooltip>
                <Tooltip title="Delete"><IconButton onClick={() => removeTest(row)}><DeleteIcon fontSize="small" /></IconButton></Tooltip>
            </Box>
        )
    }


    const getCellStyle = (str) => {
        return (<div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", fontSize: "11px" }}>{str}</div>)
    }

    const getHeader = (str) => {
        return (<div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", fontSize: "11px" }}>{str}</div>)
    }

    const isExpandable = (row) => {
        return row.tests && row.tests.length > 0
    }
    const columns = [
        {
            expander: true, width: 30, Expander: ({ isExpanded, ...data }) => {
                var row = data.original;
                return isExpandable(row) ? isExpanded ? <CollapseIcon /> : <ExpandIcon /> : <ExpandIcon style={{ color: "lightGrey" }} />
            }
        },
        { accessor: row => getCellStyle(row.name), Header: getHeader(t("Test Name")), width: 200, id: "testName" },
        { accessor: row => getCellStyle(row.equipmentName), Header: getHeader(t("equipment_label")), width: 200, id: "equipment" },
        { accessor: row => getCellStyle(row.testId), Header: getHeader(t("Test ID")), width: 200, id: "testId" },
        { accessor: row => getCellStyle(row.sequence + 1), Header: getHeader(t("Sequence")), width: 200, id: "seq" },
        { accessor: row => getActions(row), Header: getHeader(t("actions_label")), width: 200, id: "actions" },
    ]

    
    const removeTest = (row) => {
        //parentRow.tests = parentRow.tests.filter((t) => { t.id !== row.id })

        deleteTest(row.id).then(() => {
            var x = rows.filter((r) => { return r.id !== row.id })
            var ids = x.map((r) => { return r.id })
            setSequence(ids).then((res) => {
                if (res) {
                    updateTable().then(() => {
                     //   console.log(parentRow.tests.map((t) => { return t.sequence }))

                    })
                }
            })
           
        })
    }

    const handleOpenTestDialog = (viewing, row) => {
        setOpenTestDialog(true);
        setSelectedRow(row);
        if (viewing) setIsViewing(true);
    };

    const onTestDialogClose = (res) => {
        setOpenTestDialog(false);
        setIsViewing(false);
        resetForConfirmationMessage(res);
    };

    const handleStatusCode = (event) => {
        switch (event.status) {
            case 200:
                setOKDialogState({
                    shown: true,
                    title: "Test Saved",
                    message: event.data.name + ` saved successfully`,
                    flavour: "success",
                    callback: resetMessage
                })
                break;
            default:
                setOKDialogState({
                    shown: true,
                    title: "Failure",
                    message: `failed to save, try later`,
                    flavour: "error",
                    callback: resetMessage
                });
                break;
        }
    };

    useEffect(() => {
        if (responseForConfirmationMessage) {
            handleStatusCode(responseForConfirmationMessage);
        }
    }, [responseForConfirmationMessage]);

    const resetMessage = () => {
        if (responseForConfirmationMessage) {
            resetForConfirmationMessage(null);
        }
    }

    // Sort rows by sequence attribute.
    const sortAndSetRows = (res) => {
        if (res.length === 0) return;
        res.sort((a, b) => rowCompare(a, b));
        setRows(res);
    }

   
   
    useEffect(() => {
        var flag = false;

        var ids = parentRow.tests.map((t, idx) => {
            //if (t.name === "foobar") {
            //    console.log(JSON.stringify(t, null , 2))
            //}
            if (t.sequence === null) flag = true;
            return t.id
        })

        if (flag) {
            setSequence(ids).then((res) => {
                sortAndSetRows(parentRow.tests)
            })

        }

        else sortAndSetRows(parentRow.tests)
    }, [])


    const rowCompare = (a, b) => {
        if (a.sequence < b.sequence) return -1;
        else if (a.sequence > b.sequence) return 1;
        else return 0;
    }

    const handleDragStart = () => {}
   
    // console.log(JSON.stringify(r, null, 2))
    const handleReorder = async (reorderedRows, rowId, newSeq) => {
      
        reorderedRows.forEach((r, idx) => { r.sequence = idx })
        sortAndSetRows(reorderedRows)

        var ids = reorderedRows.map((r) => { return r.id})
        setSequence(ids).then((res) => {})
    }

    return (
        <Box className={classes.root}>
            {rows.length > 0 ? <div style={{ border: "1px solid lightGrey", marginLeft: "1px" }}>
            <DragDropTable
                rows={rows}
                columns={columns}
                handleReorder={handleReorder}
                handleDragStart={handleDragStart}
                    subComponent={(data) => {
                        var row = data.original;
                        return row.tests && row.tests.length > 0 ? <TestsTable parentRow={row} updateTable={updateTable} /> : null
                    }}
                /></div> : null}

            <TestDialog
                openDialog={openTestDialog}
                viewing={isViewing}
                selectedItems={selectedRow}
                parentRow={isViewing ? parentRow : selectedRow}
                updateTable={() => updateTable(null)}
                onDialogClose={onTestDialogClose}
            />

            <OKDialog
                show={OKDialogState.shown}
                title={OKDialogState.title}
                message={OKDialogState.message}
                flavour={OKDialogState.flavour}
                callback={
                    (res) => {
                        let callback = OKDialogState.callback;
                        setOKDialogState({ shown: false });
                        if (callback) callback(res);
                        // resetForConfirmationMessage(null);
                    }
                }
            />
        </Box>
    );
}

export default TestsTable;