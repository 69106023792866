import { makeStyles } from "@material-ui/core/styles";
import TestDialog from "../Dialogs/TestDialog";
//import { Fragment, useEffect } from "react";
import { Box, Button, Tooltip, Typography } from "@material-ui/core";
import { useState, useContext } from "react";
import {
    getTestMethods,
    deleteTestMethod,
} from "../../services/TestMethodsService";
import TestMethodDialog from "../Dialogs/TestMethodDialog";
import { LabSetUpTabContext } from "../Contexts/LabSetUpTabs/LabSetUpTabContext";
import { useTranslation } from "react-i18next";
import TestMethodsTable from "./TestMethodsTable";
import AddIcon from "@material-ui/icons/Add";
//import { getTestsByTestParent } from "../../services/TestsService";
import TestsTable from "./TestsTable";
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    tooltipText: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
}));

const TestMethods = (props) => {

    const { text } = props;
    const [rows, setRows] = useState([]);
    const [openTestDialog, setOpenTestDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const { t } = useTranslation();
    const classes = useStyles();
    const [dense, setDense] = useContext(LabSetUpTabContext);
    const [alertContext, setAlertContext] = useContext(AlertContext);
    const [responseForConfirmationMessage, setResponseForConfirmationMessage] = useState();

    const tableProps = {
        expandableRows: text === t("test_method_tab") ? true : false,
        expandableRowDisabled: (row) =>
            text === t("test_method_tab") &&
                row.tests != null &&
                row.tests.length > 0
                ? false
                : true,
        expandableRowsComponent: ({ data }) => <TestsTable parentRow={data} isExpandable={true} updateTable={updateTable} />,
        expandOnRowClicked: text === t("test_method_tab") ? true : false
    };

    
    const columns = [{
        selector: (row) => row.name,
        sortable: true,
        name: t("test_method_name_field_label"),
        type: "text",
        key: "name",
        width: "250px"
        // grow: 1.5,
    },
    {
        selector: (row) => row.sopCode,
        sortable: true,
        name: t("test_method_internal_sop_field_label"),
        type: "text",
        key: "sopCode",
        width: "200px"
    },
    {
        selector: (row) => <tooltip title={row.categoriesNames}>{row.categoriesNames}</tooltip>,
        sortable: true,
        name: "Package Type",
        type: "text",
        key: "categoriesNames",
        width: "200px"
    },
    {
        selector: (row) => <tooltip title={row.equipmentName}>{row.equipmentName}</tooltip>,
        sortable: true,
        name: t("equipment_label"),
        type: "text",
        key: "equipmentName",
        // grow: 4.5,
        width: "200px"
    },
    {
        selector: (row) => row.departmentName,
        sortable: true,
        name: t("department_label"),
        type: "text",
        key: "departmentName",
        width: "200px"
    },
    {
        selector: (row) => row.testMethodId,
        sortable: true,
        name: t("Test ID"),
        type: "text",
        key: "testMethodId",
        width: "200px"
    },
    {
        selector: (row) => (
            <Tooltip title={t("Add Test")}>
                <IconButton
                    onClick={() => handleOpenTestDialog(row)}
                    aria-label="Add">
                    <AddIcon />
                </IconButton>
            </Tooltip>
        ),
        sortable: false,
        name: t("actions_label"),
        grow: 1.5
    },
    ];

    const handleOpenTestDialog = (row) => {
        setOpenTestDialog(true);
        setSelectedRow(row);
    };

    const handleCloseTestDialog = (res) => {
        setOpenTestDialog(false);
        setSelectedRow({});
        setResponseForConfirmationMessage(res);
    };

    const updateTable = async (callback) => {
        let data = await getTestMethods(localStorage.getItem("language"));

        if (!(data instanceof Array)) {
            setAlertContext({ ...alertContext, open: true });
            return;
        }

        data.forEach(testMethod => {
            let categoriesNames = "";
            let index = 1;
            testMethod.categories.forEach(category => {
                if (index < testMethod.categories.length) {
                    categoriesNames += category.name + ", "
                }
                else {
                    categoriesNames += category.name
                }
                index++;
            })
            testMethod['categoriesNames'] = categoriesNames;

            let equipmentNames = "";
            let testIndex = 1;
            testMethod.tests.forEach(test => {
                let testEquipmentNames = "";
                index = 1;
                test.equipments.forEach(equipment => {
                    if (index < test.equipments.length) {
                        equipmentNames += equipment.name + ", ";
                        testEquipmentNames += equipment.name + ", ";
                    }
                    else {
                        equipmentNames += equipment.name;
                        testEquipmentNames += equipment.name
                    }
                    index++;
                })
                if (testIndex < testMethod.tests.length) {
                    equipmentNames += ', '
                }
                test['equipmentName'] = testEquipmentNames;
                testIndex++;
            });
            testMethod['equipmentName'] = equipmentNames;

            testMethod['departmentName'] = testMethod.department.name;
        });

        console.log('data', data)
        setRows(data);
        if (callback != null) callback();
    };

    //const handleDelete = async (selected) => {
    //    if (window.confirm('Are you sure you wish to delete?')) {
    //        for (var id in selected) {
    //            await deleteTestMethod(selected[id].id);
    //        }
    //    }
    //};

    const handleDelete = async (selected) => {
        if (window.confirm(t("Are you sure you wish to delete?"))) {
            let ids = selected.map((item) => { return item.id })
            let promises = ids.map((id) => { return deleteTestMethod(id) })
            await Promise.all(promises)
        }
    }

    return (
        <TestMethodsTable
            dense={dense}
            setDense={setDense}
            keyColumn={{
                key: "name",
                name: t("test_method_name_field_label"),
            }}
            updateTable={updateTable}
            paginationPerPage={60}
            Dialog={openTestDialog ? TestDialog : TestMethodDialog}
            openTestDialog={openTestDialog}
            onTestDialogClose={handleCloseTestDialog}
            responseForConfirmationMessage={responseForConfirmationMessage}
            resetForConfirmationMessage={setResponseForConfirmationMessage}
            selectedRow={selectedRow}
            tableProps={tableProps}
            rows={rows}
            columns={columns}
            text={text}
            deleteCallback={handleDelete}
        />
    );
}

export default TestMethods;
