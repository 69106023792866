import HttpService from "./HttpService";
const axios = HttpService.getAxiosClient();
const methods = HttpService.HttpMethods;
const baseURL = process.env.REACT_APP_BACKEND_URL;
const rootRequestMapping = "testing/idoc";


export const getIDocWorkQue = async () => {
    try {
        const req = {
            url: `${baseURL}${rootRequestMapping}/myqueue`,
            method: methods.GET
        }
        const res = await axios(req);
        return res.data;
    } catch (err) {
        return {req_error: err}
    }
}


export const getAllIdocQueues = async () => {
    try {
        const req = {
            url: `${baseURL}${rootRequestMapping}/queueall`,
            method: methods.GET
        }
        const res = await axios(req);
        return res.data;
    } catch (err) {
        return { req_error: err }
    }


}

export const moveQueueItemUp = async (rowID) => {
    try {
        const req = {
            url: `${baseURL}${rootRequestMapping}/queue/up/${rowID}`,
            method: methods.GET,
        }
        const res = await axios(req);
        return res.data;

    } catch (err) {
        return { req_error: err }
    }
}


//export const updateSequence = async (reorderedQueue) => {
//    try {
//        const req = {
//            url: `${baseURL}${rootRequestMapping}/queue/swap`,
//            method: methods.POST,
//            data: reorderedQueue,
//            headers: { 'content-type': 'application/json' }
//        }
//        const res = await axios(req);
//        return res.data;

//    } catch (err) {
//        return { req_error: err }
//    }


//}

export const updateSequence = async (id, seq2) => {
    try {
        const req = {
            url: `${baseURL}${rootRequestMapping}/queue/swap/${id}/${seq2}`,
            method: methods.GET,
        }
        const res = await axios(req);
        return res.data;

    } catch (err) {
        return { req_error: err }
    }


}

export const moveQueueItemDown = async (rowID) => {
    try {
        const req = {
            url: `${baseURL}${rootRequestMapping}/queue/down/${rowID}`,
            method: methods.GET,
        }
        const res = await axios(req);
        return res.data;

    } catch (err) {
        return { req_error: err }
    }
}



// testing/idoc/{jobID}
export const getIDocsInJob = async (jobId, lang) => {
    try {
        const req = {
            url: `${baseURL}${rootRequestMapping}/${jobId}/${lang}`,
            method: methods.GET
        }
        const res = await axios(req);
        return res.data;
    } catch (err) {
        return { req_error: err }
    }
}


export const addIdocToQueue = async (idocId, typ) => {
    try {
        const req = {
            url: `${baseURL}${rootRequestMapping}/queue/add/${idocId}/${typ}`,
            method: methods.GET
        }
        const res = await axios(req);
        return res.data;
    } catch (err) {
        return { req_error: err }
    }
}


export const remIdocFromQueue = async (idocId) => {
    try {
        const req = {
            url: `${baseURL}${rootRequestMapping}/queue/remove/${idocId}`,
            method: methods.GET
        }
        const res = await axios(req);
        return res.data;
    } catch (err) {
        return { req_error: err }
    }
}