/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import BarChart from '../chartTypes/BarChart';
import { graphBorders, graphBackgrounds } from '../Colors'
import { useTranslation } from 'react-i18next';


const JobsPerRegionChart = (props) => {
    const { data } = props;
    const [chartData, setChartData] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (data) {
            console.log(data)
            convertDataset();
        }

    }, [data])

    const convertDataset = () => {


        var labels = Object.keys(data);
        const d = {
            labels,
            datasets: [
                {
                   
                    data: Object.keys(data).map((region) => { return data[region]}),
                    backgroundColor:  labels.map((r, idx) => { return graphBackgrounds[idx] })
                },

            ]
        }
        setChartData(d);


    }

    return <div >
        <BarChart
            title={t("Breakdown of submissions by region") + ":"}
            data={chartData}
            showLegend={false}
            axisLabels={{ x: t("Region"), y: t("Count") }} />
    </div>

}


export default JobsPerRegionChart;