/* eslint-disable react-hooks/exhaustive-deps */

import { useTranslation } from 'react-i18next';
import { SingleSelector } from "../SelectComponents"
import { getProfileJobsByCatID } from '../../services/ProfileJobsService';
import { getJobFromProfile } from '../../services/RegisterJobService'
import { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import { Divider, FormControlLabel } from '@material-ui/core';
import Slider from '@mui/material/Slider';
import Checkbox from '@material-ui/core/Checkbox';
import TestMethodTestsDLG from '../Dialogs/TestMethodTestsDLG'



const ApplyJobProfile = (props) => {

    const { shown, job, setJob, openEdit, setSaveBtnDisabled } = props;

    const { t } = useTranslation();
    const [profiles, setProfiles] = useState([])
    const [profile, setProfile] = useState(null)
    const [category, setCategory] = useState({id: -1})
    const [numberOfSamples, setNumberOfSamples] = useState(0);
    const [testMethods, setTestMethods] = useState([]);
    const [marks, setMarks] = useState(null);
    const redAsterisk = <span style={{ color: "red" }}><b>*</b></span>

    const [newProfileSelected, setNewProfileSelected] = useState(false);


    useEffect(() => {
       
        if (shown && job) {
           
            var cat = null;
            if (job.sampleType && job.sampleType.category && job.sampleType.category.id) {
                cat = job.sampleType.category;
            } 
           
            if (cat && (cat.id !== category.id)) {
                setCategory(cat)
                getProfileJobsByCatID(cat.id).then((res) => {
                    if (res) {
                        console.log("GET profiles call...")
                        setProfiles(res)
                    }
                })
            }
           
        }
    }, [job, shown])


    

    useEffect(() => {
        if (profiles.length > 0 && openEdit && job.jobProfile) {
            setProfile(profiles.find((p) => { return p.id === job.jobProfile.id}))
        }
    }, [profiles])


    const storeJob = () => {
        
        setJob(JSON.parse(JSON.stringify(job)))
    }

    const getsavedJob = (profile) => {
        console.log("GET job from profile call...")
        getJobFromProfile(profile.id, job.id).then((res) => {
         
            if (res) {
               
                if (openEdit && newProfileSelected) {
                    var items = profile.contents.map((c, idx) => {
                       
                        var item = res.profileJobAndTestMethods[idx]
                        console.log(idx, c)
                        console.log(idx, item)
                       
                        if (item) {
                            item.selected = c.selected
                            item.sampleStart = c.sampleStart
                            item.sampleEnd = c.sampleEnd
                            item.testMethod = c.testMethod
                            item.tests = c.tests
                            return item;
                       } 
                       else return null;
                    })
                    
                    items = items.filter((i) => { return i !== null })
                    console.log(items)
                    job.jobProfile = profile
                    job.profileJobAndTestMethods = items
                    job.sampleCount = profile.numberOfSamples
                    setTestMethods(items)
                    setNumberOfSamples(profile.numberOfSamples)
                    return
                }

                job.jobProfile = profile
                job.profileJobAndTestMethods = res.profileJobAndTestMethods
                job.sampleCount = res.sampleCount
                setTestMethods(res.profileJobAndTestMethods)
                setNumberOfSamples(res.sampleCount)
     
            }
        })  
    }


    useEffect(() => {
        if (profile) setSaveBtnDisabled(false)
        if (shown && profile && job.id) { 
            getsavedJob(profile)
        }
    }, [profile, shown])



    useEffect(() => {
        if (shown) {
            job.profileJobAndTestMethods = testMethods
            storeJob();
        }
    }, [testMethods, shown])


    useEffect(() => {
        if (numberOfSamples > 0) {
            var arr = []
            arr.push({ value: 1, label: `1` })
            if (numberOfSamples < 21) {
                for (var i = 2; i <= numberOfSamples; i++) {
                    arr.push({ value: i, label: `${i}` })
                }
            }
            else if (numberOfSamples < 100) {
                for (var i = 5; i <= numberOfSamples; i += 5) {
                    arr.push({ value: i, label: `${i}` })
                }
            }
            else arr.push({ value: numberOfSamples, label: `${numberOfSamples}` } )

            setMarks(arr)

            job.sampleCount = numberOfSamples
            job.profileJobAndTestMethods.forEach((tm) => {
                if (tm.sampleEnd > numberOfSamples) tm.sampleEnd = numberOfSamples
                if (tm.sampleStart > numberOfSamples) tm.sampleStart = numberOfSamples
            })

            setTestMethods(JSON.parse(JSON.stringify(testMethods)))
            
        }

    }, [numberOfSamples])


    const onCheckChange = (e, tm) => {
        if (e.target.checked) {
            tm.selected = true;
            if (!tm.sampleStart) tm.sampleStart = 1;
            if (!tm.sampleEnd) tm.sampleEnd = tm.testMethod.defaultSamples != null ? tm.testMethod.defaultSamples : numberOfSamples
        }
        else {
            tm.selected = false;
        }
        setTestMethods(JSON.parse(JSON.stringify(testMethods)))
    }


    const onSliderChange = (e, tm) => {
        tm.sampleStart = e.target.value[0];
        tm.sampleEnd = e.target.value[1];
        setTestMethods(JSON.parse(JSON.stringify(testMethods)))
    };

  
    const applySelectedTests = (testMethodId, selectedTests) => {
        var tm = testMethods.find((t) => { return testMethodId === t.testMethod.id })
        if (tm) {
            tm.tests = selectedTests;
            setTestMethods(JSON.parse(JSON.stringify(testMethods)))
        } 
      
    }

    const getNumberTestsSelected = (tm) => {
        let numberSelected = 0;
        if (tm.selected && tm.tests.length > 0) {
            numberSelected = tm.tests.length;
        }
        return numberSelected;
    }

    


    if (shown) return (
        <div>
            <Box display="flex" flexDirection="row">

                <div style={{ marginLeft: "25px" }}>

                    <Typography variant="body1">
                        {openEdit ? t("Based on profile"):t("Choose a profile")}: 
                    </Typography>
                    <div style={{ marginTop: "16px", pointerEvents: openEdit && job.jobProfile ? "auto" : "auto" }}>
                        <SingleSelector width={275} isMulti={false} placeholder={t("Job profiles")}
                            value={profile && { value: profile, label: profile.name }}
                            options={profiles.map((p) => ({ value: p, label: p.name }))}
                            handleSelect={(e) => { setNewProfileSelected(true); setProfile(e.value); }} />
                    </div>
                    
                </div>


                {profile && <div style={{ marginLeft: "25px" }}>
                    <Typography variant="body1">
                        {t("profile_job_sample_number")} *
                    </Typography>
                    <TextField
                        style={{ marginTop: "16px" }}
                        value={numberOfSamples ? numberOfSamples : ""}
                        onChange={(e) => { setNumberOfSamples(parseInt(e.target.value));}}
                        autoFocus margin="dense" id="name" type="number" variant="outlined"
                        InputProps={{ inputProps: { min: 1, max: Number.MAX_SAFE_INTEGER } }}
                        required
                        placeholder={t("profile_job_sample_number")} />
                </div>}

               
            </Box>
            <br />
            <Divider />
            <br />

            {testMethods.length > 0 ? <div style={{ flex: '100%' }}>
                <p id="form-dialog-title">Select from available Test Methods in <b>{category.name ? `${category.name} ` : ""}</b>package type...</p>
                <table style={{ width: "100%", textAlign: "left" }}>
                    <colgroup>
                        <col span="1" style={{ width: "10%" }} />
                        <col span="1" style={{ width: "20%" }} />
                        <col span="1" style={{ width: "40%" }} />
                        <col span="1" style={{ width: "10%" }} />
                        <col span="1" style={{ width: "20%" }} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>Selected</th>
                            <th>Test Method</th>
                            <th style={{ textAlign: "center" }}>Sample Subset</th>
                            <th></th>
                            <th>Tests to Include</th>
                        </tr>
                        {testMethods.map((tm) => {

                            return (
                                <tr>
                                    <td>
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            value={tm.testMethod.id}
                                            onChange={(e) => { onCheckChange(e, tm) }}
                                            checked={tm.selected}
                                        />
                                    </td>
                                    <td><div >{tm.testMethod.name}</div></td>
                                    <td>
                                        <div style={{ marginTop: "30px", marginLeft: "30px", marginRight: "20px" }}>
                                            <Slider
                                                disabled={!tm.selected}
                                                aria-label={() => 'Sample Range'}
                                                min={1}
                                                max={numberOfSamples}
                                                value={[tm.sampleStart, tm.sampleEnd]}
                                                step={1}
                                                valueLabelDisplay={tm.selected ? "auto" : "off"}
                                                marks={marks ? marks : true}
                                                onChange={(e) => onSliderChange(e, tm)} />
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{ marginTop: "20px" }}>
                                           
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{ marginLeft: "5px" }}>
                                            <TestMethodTestsDLG
                                                testMethodId={tm.testMethod.id}
                                                testMethodName={tm.testMethod.name}
                                                callback={(res) => { applySelectedTests(tm.testMethod.id, res) }}
                                                enabled={tm.selected}
                                                checkedTests={tm.tests}
                                                numberSelected={getNumberTestsSelected(tm)}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div> :
                <div style={{ marginTop: "20px" }}>
                    {profile != null? <span>{redAsterisk} No test methods available for this package type yet. Please choose another.</span> :
                        <span>{redAsterisk} Select a <b>Package Type</b> to view and choose from its available <em>test methods</em>.</span>}
                </div>}

        </div>
    )

    else return <></>

}


export default ApplyJobProfile;


