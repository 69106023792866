import { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { addCategory, updateCategory, deleteCategory, checkCode } from '../../services/CategoryService';
import { Alert, AlertTitle } from '@material-ui/lab';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SaveChangesIcon from '@mui/icons-material/SaveAsOutlined';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from "@material-ui/core/Box";


const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    backgroundColor: 'paper',
  },
  dialog: {
    width: '400px',
    display: 'flex'
  },
  dialogBox: {
    display: 'flex',
    alignItems: 'center'
  },
  dialogText: {
    flex: '0.4'
  },
  dialogInput: {
    flex: '0.5'
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(2),
  },
}));



const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other} >
          {value === index && (
              <Box sx={{ p: 3 }}>
                  <Typography>{children}</Typography>
              </Box>
          )}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function CategoryDialog(props) {
  const { openDialog, openEdit, selectedItems, text, updateTable, onDialogClose } = props;
  const [alertBox, setAlertBox] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [description, setDescription] = useState("");
  const [shortCode, setShortCode] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const { t } = useTranslation();

  const [categoryNameEnglish, setCategoryNameEnglish] = useState("");
  const [categoryNameFrench, setCategoryNameFrench] = useState("");
  const [categoryNameSpanish, setCategoryNameSpanish] = useState("");
  const [categoryNameItalian, setCategoryNameItalian] = useState("");
  const [categoryNameArabic, setCategoryNameArabic] = useState("");
  const [categoryNameRussian, setCategoryNameRussian] = useState("");

  const handleCategoryNameChange = (event) => {
    setCategoryName(event.target.value)
  }

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleShortCodeChange = (event) => {
    setShortCode(event.target.value);
  };

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const handleCategoryNameEnglishChange = (event) => {
    if (!openEdit) {
        handleCategoryNameChange(event);
    }
    setCategoryNameEnglish(event.target.value);
};

  const handleCategoryNameFrenchChange = (event) => {
    setCategoryNameFrench(event.target.value);
  };

  const handleCategoryNameSpanishChange = (event) => {
    setCategoryNameSpanish(event.target.value);
  };

  const handleCategoryNameItalianChange = (event) => {
    setCategoryNameItalian(event.target.value);
  };

  const handleCategoryNameArabicChange = (event) => {
    setCategoryNameArabic(event.target.value);
  };

  const handleCategoryNameRussianChange = (event) => {
    setCategoryNameRussian(event.target.value);
  };

  useEffect(() => {
    if(openEdit){
      setCategoryName(selectedItems.name);
      setDescription(selectedItems.description);
      setShortCode(selectedItems.shortCode);

      setCategoryNameEnglish(selectedItems.nameEnglish)
      setCategoryNameFrench(selectedItems.nameFrench);
      setCategoryNameSpanish(selectedItems.nameSpanish);
      setCategoryNameItalian(selectedItems.nameItalian);
      setCategoryNameArabic(selectedItems.nameArabic);
      setCategoryNameRussian(selectedItems.nameRussian);
    }
  }, [openEdit]);

  const clearFields = () => {
    setCategoryName("");
    setDescription("");
    setAlertBox("");
    setShortCode("");

    setCategoryNameEnglish("");
    setCategoryNameFrench("");
    setCategoryNameSpanish("");
    setCategoryNameItalian("");
    setCategoryNameArabic("");
    setCategoryNameRussian("");
  };

  const handleAlertBox = (messages) => {
    setAlertBox(<Alert severity="warning">
      <AlertTitle>Could not create Package Type</AlertTitle>
      {messages.map((message, index) => {
        return <li key={index}>{message}</li>
      })}
    </Alert>)
  };

  const handleSave = async () => {
    let alertMessages = [];
    
    if (categoryName.length === 0) alertMessages.push("Provide a package type name");

    if (openEdit) {
      if(await checkCode(selectedItems.id, shortCode, "Category")) {
        alertMessages.push("Package Type code must be unique");
      } 
    }

    if (!openEdit) {
      if(await checkCode(null, shortCode, "Category")) {
        alertMessages.push("Package Type code must be unique");
      }
    }
    
    if (alertMessages.length > 0) handleAlertBox(alertMessages);
    
    else {
      let res;
      if(openEdit) res = await updateCategory(categoryName,  description, shortCode, selectedItems.id, categoryNameFrench, categoryNameSpanish, categoryNameItalian, categoryNameArabic, categoryNameRussian, categoryNameEnglish);
      else res = await addCategory(categoryName, description, shortCode, categoryNameFrench, categoryNameSpanish, categoryNameItalian, categoryNameArabic, categoryNameRussian, categoryNameEnglish)
      updateTable();
      onDialogClose(res);
      clearFields();
    }
  };

  const handleClose = () => {
    updateTable();
    onDialogClose();
    clearFields();
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Dialog open={openDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
        <DialogTitle id="form-dialog-title">{(openEdit ? t("edit_label") : t("add"))} {t('category_label')} </DialogTitle>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
          {alertBox}

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} textColor="secondary" indicatorColor="secondary" onChange={handleTabChange} aria-label="basic tabs example">
                  <Tab label={t("category_dialog_tab_1")} />
                  <Tab label={t("category_dialog_tab_2")} />
              </Tabs>
          </Box> 

          <TabPanel value={tabValue} index={0}>
            <div className={classes.dialogBox} required>
              <Typography variant="body1" className={classes.dialogText}>
                {t("category_name_field_label")}*
              </Typography>
              <TextField value={categoryNameEnglish} onChange={handleCategoryNameEnglishChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required />
            </div>
            <div className={classes.dialogBox}>
              <Typography variant="body1" className={classes.dialogText}>
                {t("description_field_label")}
              </Typography>
              <TextField value={description} onChange={handleDescriptionChange} className={classes.dialogInput} placeholder={t("enter_description_placeholder")} autoFocus multiline rows={3} margin="dense" id="description" type="input" variant="outlined" required />
            </div>
            <div className={classes.dialogBox}>
              <Typography variant="body1" className={classes.dialogText}>
                {t("shortCode_field_label")}
              </Typography>
              <TextField value={shortCode} onChange={handleShortCodeChange} className={classes.dialogInput} placeholder={t("enter_short_code_label")} autoFocus margin="dense" id="shortCode" type="input" variant="outlined" required />
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <div className={classes.dialogBox}>
                <Typography variant="body1" className={classes.dialogText}>
                    {t("french_name")}
                </Typography>

                <TextField value={categoryNameFrench} onChange={handleCategoryNameFrenchChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required placeholder={t("french_name")} />
            </div>

            <div className={classes.dialogBox}>
                <Typography variant="body1" className={classes.dialogText}>
                    {t("spanish_name")}
                </Typography>

                <TextField value={categoryNameSpanish} onChange={handleCategoryNameSpanishChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required placeholder={t("spanish_name")} />
            </div>

            <div className={classes.dialogBox}>
                <Typography variant="body1" className={classes.dialogText}>
                    {t("italian_name")}
                </Typography>

                <TextField value={categoryNameItalian} onChange={handleCategoryNameItalianChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required placeholder={t("italian_name")} />
            </div>

            <div className={classes.dialogBox}>
                <Typography variant="body1" className={classes.dialogText}>
                    {t("arabic_name")}
                </Typography>

                <TextField value={categoryNameArabic} onChange={handleCategoryNameArabicChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required placeholder={t("arabic_name")} />
            </div>

            <div className={classes.dialogBox}>
                <Typography variant="body1" className={classes.dialogText}>
                    {t("russian_name")}
                </Typography>

                <TextField value={categoryNameRussian} onChange={handleCategoryNameRussianChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required placeholder={t("russian_name")} />
            </div>
          </TabPanel>
          
        </DialogContent>
        <DialogActions>

        <Tooltip title="Save">
            <IconButton onClick={handleSave} color="primary"> 
              {openEdit ? <SaveChangesIcon /> : <SaveIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title={t("Cancel")}>
            <IconButton onClick={handleClose}> 
              <CancelIcon/>
            </IconButton>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CategoryDialog
