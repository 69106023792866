import axios from "axios";
import keycloak from "../keycloak";

const HttpMethods = {
    GET: 'GET',
    POST: 'POST',
    DELETE: 'DELETE',
};

const configure = () => {
    _axios.interceptors.request.use(

        (config) => {
            var token = keycloak.getToken();

            const host = window.location.host;
            if (host === "localhost:3000") {
                console.log(JSON.stringify({
                    msg: `Token only appears in console when running application on ${host}`,
                    token
                }, null, 2));
            }
        
            config.headers.Authorization = `Bearer ${token}`;
            return config;
        },

        (error) => {
            return Promise.reject(error);
        });
};

const getAxiosClient = () => _axios;

const _axios = axios.create();

const HttpService = {
    HttpMethods,
    configure,
    getAxiosClient,
};

export default HttpService;