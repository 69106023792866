import { useState, useEffect, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DateFnsUtils from "@date-io/date-fns";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import { addEquipment, checkCode } from "../../services/EquipmentService";
//import Equipment from "../Equipment";
import { Alert, AlertTitle } from "@material-ui/lab";
import { getDepartments } from "../../services/DepartmentsService";
import { AlertContext } from "../Contexts/AlertContext/AlertContext";
import CancelIcon from '@mui/icons-material/CancelOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import SaveChangesIcon from '@mui/icons-material/SaveAsOutlined';
import IconButton from '@material-ui/core/IconButton';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { uploadCalibrationCertFile, getSavedPdf } from '../../services/FileService';
import Button from '@material-ui/core/Button';
import ImgsViewer from "react-images-viewer";
import { useTranslation } from 'react-i18next';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = ["bla1", "bla2", "bla3", "bla4"];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function EquipmentDialog(props) {
    const {
        openDialog,
        openEdit,
        onDialogClose,
        selectedItems,
        text,
        updateTable,
    } = props;
    const [equipmentName, setEquipmentName] = useState("");
    const [description, setDescription] = useState("");
    const [code, setCode] = useState("");
    const [alertBox, setAlertBox] = useState("");
    const [manufacturer, setManufacturerName] = useState("");
    const [department, setDepartmentName] = useState([]);
    const [departmentDetails, setDepartmentDetails] = useState([]);
    const [serial_num, setSerialNum] = useState("");
    const [calibrationDate, setCalibrationDate] = useState(new Date());
    const [calibrationDue, setCalibrationDue] = useState(new Date());
    const [alertContext, setAlertContext] = useContext(AlertContext);
    const [calibrationCert, setCalibrationCert] = useState();
    const [calibrationCertName, setCalibrationCertName] = useState("No file selected");
    const [calibrationCertPath, setCalibrationCertPath] = useState("");
   
    const [isCalibrationCertPicked, setIsCalibrationCertPicked] = useState(false);
    const [viewImage, setViewImage] = useState(false);
    const [imageSrc, setImageSrc] = useState(''); //for thumbnail if required
    const { t } = useTranslation();

    const handleManufacturerChange = (event) => {
        setManufacturerName(event.target.value);
    };

    const handleDepartmentChange = (event) => {
        setDepartmentName(event.target.value);
    };

    const handleSerialNumChange = (event) => {
        setSerialNum(event.target.value);
    };

    const handleEquipmentNameChange = (event) => {
        setEquipmentName(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

   

    const handleCodeChange = (event) => {
        setCode(event.target.value);
    };

    const handleCalibrationDateChange = (date) => {
        setCalibrationDate(date);
    };

    const handleCalibrationDueChange = (date) => {
        setCalibrationDue(date);
    };

    const handleCalibrationCertChange = (event) => {
        setCalibrationCert(event.target.files[0]);
        setCalibrationCertName(event.target.files[0].name);
        setIsCalibrationCertPicked(true);
    };

    const handleCalibrationCertNameClick = (event) => {
        var objbuilder = '';
        objbuilder += '<object width="100%" height="100%" data="';
        objbuilder += imageSrc;
        objbuilder += '" type="application/pdf" class="internal">';
        objbuilder += '<embed src="';
        objbuilder += imageSrc;
        objbuilder += '" type="application/pdf"  />';
        objbuilder += '</object>';

        var win = window.open("#", "_blank");
        var title = calibrationCertName;
        win.document.write('<html><title>' + title + '</title><body style="margin-top:0px; margin-left: 0px; margin-right: 0px; margin-bottom: 0px;">');
        win.document.write(objbuilder);
        win.document.write('</body></html>');
    }

    const handleImageClose = (event) => {
        setViewImage(false);
    }

    useEffect(() => {
        if (isCalibrationCertPicked) {
            const uploadCalibrationCert = () => {
                try {
                    uploadCalibrationCertFile(calibrationCert).then((res) => {
                        if (res.key) setCalibrationCertPath(res.key);
                        if (res.image) {
                            let base64ToString = Buffer.from(res.image, "base64")
                            let image = `data:application/pdf;base64,${base64ToString}`;
                            setImageSrc(image);
                            setIsCalibrationCertPicked(false);
                        }
                    });
                }
                catch (err) {
                    setIsCalibrationCertPicked(false)
                }
            }
            uploadCalibrationCert();
        }
    }, [isCalibrationCertPicked])

    useEffect(() => {
        const getDepartmentInfo = async () => {
            let data = await getDepartments();
            if (!(data instanceof Array)) {
                setAlertContext({ ...alertContext, open: true });
                return;
            }
            setDepartmentDetails(data);
        };

        getDepartmentInfo();
    }, []);

    useEffect(() => {
        if (openEdit === true) {
            let tmpDepartment = {};

            departmentDetails.forEach((depart) => {
                if (depart.name === selectedItems.department.name)
                    tmpDepartment = depart;
            });

            setEquipmentName(selectedItems.name);
            setDescription(selectedItems.description);
            setCode(selectedItems.code);
            setSerialNum(selectedItems.serialNumber);
            setDepartmentName(tmpDepartment);
            setManufacturerName(selectedItems.manufacturer);
            setCalibrationDate(selectedItems.calibrationDate);
            setCalibrationDue(selectedItems.calibrationDue);
            setCalibrationCertName(selectedItems.calibrationCertName);
            setCalibrationCertPath(selectedItems.calibrationCertPath);
           
        }
    }, [openEdit]);

    useEffect(() => {
        if (openEdit) {
            const getImage = async () => {
                let data = await getSavedPdf(selectedItems.calibrationCertPath);
                if (data) {
                    let image = `data:application/pdf;base64,${data}`;
                    setImageSrc(image); //for viewing pdf
                }
            }
            getImage();
        }
    }, [openEdit]);

    const clearFields = () => {
        setEquipmentName("");
        setDescription("");
        setSerialNum("");
        setDepartmentName([]);
        setManufacturerName("");
        setCode("");
        setAlertBox("");
        setCalibrationCert();
        setCalibrationCertName("No file selected")
        setIsCalibrationCertPicked(false);
        setCalibrationCertPath("");
        setViewImage(false);
        setImageSrc('');
       
    };

    const handleAlertBox = (messages) => {
        setAlertBox(
            <Alert severity="warning">
                <AlertTitle>Could not create Equipment</AlertTitle>
                {messages.map((message, index) => {
                    return <li key={index}>{message}</li>
                })}
            </Alert>
        );
    };

    useEffect(() => {
        console.log(department);
    }, [department]);

    const handleSave = async () => {
        let alertMessages = [];

        let resString = "";

        if (equipmentName.length === 0)
            alertMessages.push("You must provide an equipment name.");
        if (code == null || code.length === 0)
            alertMessages.push("You must provide an Asset code.");

        if (openEdit) {
            if (await checkCode(selectedItems.id, code, "Equipment")) {
                alertMessages.push("Asset code must be unique");
            }
        }

        if (!openEdit) {
            if (await checkCode(null, code, "Equipment")) {
                alertMessages.push("Asset code must be unique\n");
            }
        }

        if (alertMessages.length > 0) handleAlertBox(alertMessages);

        else {
            let res;
            if (openEdit === true)
                res = await addEquipment(
                    selectedItems.id,
                    equipmentName,
                    description,
                    manufacturer,
                    department,
                    serial_num,
                    code,
                    calibrationDate,
                    calibrationDue,
                    calibrationCertPath,
                    calibrationCertName,
                    
                );
            else
                res = await addEquipment(
                    null,
                    equipmentName,
                    description,
                    manufacturer,
                    department,
                    serial_num,
                    code,
                    calibrationDate,
                    calibrationDue,
                    calibrationCertPath,
                    calibrationCertName,
                   
                );
            clearFields();
            onDialogClose(res);
            updateTable();
        }
    };

    const handleCancel = () => {
        updateTable();
        onDialogClose();
        clearFields();
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            flex: 1,
            backgroundColor: "paper",
        },
        dialog: {
            width: "400px",
            display: "flex",
        },
        dialogBox: {
            display: "flex",
            alignItems: "center",
        },
        dialogText: {
            flex: "0.4",
        },
        dialogInput: {
            flex: "0.5",
        },
        formControl: {
            margin: theme.spacing(2),
            minWidth: 120,
            maxWidth: 300,
        },
        chips: {
            display: "flex",
            flexWrap: "wrap",
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(2),
        },
    }));

    const classes = useStyles();
    const theme = useTheme();

    return (
        <div className={classes.root}>
            <Dialog
                open={openDialog}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="sm"
            >
                {/* sm, xs, md, lg, xl*/}
                <DialogTitle id="form-dialog-title">
                    {(openEdit ? t("edit_label") : t("add"))} {t('equipment_label')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{/* blah blah */}</DialogContentText>
                    {alertBox}
                    <div className={classes.dialogBox}>
                        <Typography variant="body1" className={classes.dialogText}>
                            {t("equipment_name_label")} *
                        </Typography>

                        <TextField
                            value={equipmentName}
                            on
                            onChange={handleEquipmentNameChange}
                            className={classes.dialogInput}
                            autoFocus
                            margin="dense"
                            id="name"
                            type="input"
                            variant="outlined"
                            required
                        />
                    </div>
                    <div className={classes.dialogBox}>
                        <Typography variant="body1" className={classes.dialogText}>
                            {t("description_field_label")}
                        </Typography>

                        <TextField
                            value={description}
                            onChange={handleDescriptionChange}
                            className={classes.dialogInput}
                            placeholder={t("enter_description_placeholder")}
                            autoFocus
                            multiline
                            rows={3}
                            margin="dense"
                            id="description"
                            type="input"
                            variant="outlined"
                            required
                        />
                    </div>
                    <div className={classes.dialogBox}>
                        <Typography variant="body1" className={classes.dialogText}>
                            {t("manufacturer_label")}
                        </Typography>

                        <TextField
                            value={manufacturer}
                            onChange={handleManufacturerChange}
                            className={classes.dialogInput}
                            placeholder={t("enter_manufacturer_label")}
                            autoFocus
                            multiline
                            rows={3}
                            margin="dense"
                            id="manufacturer"
                            type="input"
                            variant="outlined"
                            required
                        />
                    </div>
                    <div className={classes.dialogBox}>
                        <Typography variant="body1" className={classes.dialogText}>
                            {t("department_label")}
                        </Typography>
                        <FormControl
                            className={classes.dialogInput}
                            style={{ flex: "0.525" }}
                            variant="outlined"
                            className={clsx(classes.formControl, classes.noLabel)}
                        >
                            <Select
                                displayEmpty
                                value={department}
                                onChange={handleDepartmentChange}
                                input={<Input />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <em style={{color: "grey"}}>{t("select_department_label")}</em>;
                                    }

                                    return <Typography>{selected.name}</Typography>;
                                }}
                                MenuProps={MenuProps}
                                inputProps={{ "aria-label": "Without label" }}
                            >
                                {departmentDetails.map((departmentName) => (
                                    <MenuItem key={departmentName.id} value={departmentName}>
                                        {departmentName.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.dialogBox}>
                        <Typography variant="body1" className={classes.dialogText}>
                            {t("serial_number_field_label")}
                        </Typography>

                        <TextField
                            value={serial_num}
                            onChange={handleSerialNumChange}
                            className={classes.dialogInput}
                            style={{ maxWidth: "20%" }}
                            autoFocus
                            margin="dense"
                            id="serial_num"
                            type="input"
                            variant="outlined"
                            required
                        />
                    </div>
                    <div className={classes.dialogBox}>
                        <Typography variant="body1" className={classes.dialogText}>
                            {t("asset_code_field_label") + " *"}
                        </Typography>

                        <TextField
                            value={code}
                            onChange={handleCodeChange}
                            className={classes.dialogInput}
                            style={{ maxWidth: "20%" }}
                            autoFocus
                            margin="dense"
                            id="code"
                            type="input"
                            variant="outlined"
                            required
                        />
                    </div>


                   

                    <div className={classes.dialogBox}>
                        <Typography variant="body1" className={classes.dialogText}>
                            {t("calibration_date_label")}
                        </Typography>
                        <div className={classes.dialogInput}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label={""}
                                    value={calibrationDate}
                                    onChange={handleCalibrationDateChange}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div className={classes.dialogBox}>
                        <Typography variant="body1" className={classes.dialogText}>
                            {t("calibration_due_label")}
                        </Typography>
                        <div className={classes.dialogInput}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label={""}
                                    value={calibrationDue}
                                    onChange={handleCalibrationDueChange}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div className={classes.dialogBox} style={{ display: 'flex' }}>
                        <Typography variant="body1" className={classes.dialogText} style={{ flex: imageSrc != '' ? '40%' : '60%' }}>
                            {t("calibration_cert_label")}
                        </Typography>
                        {imageSrc != '' && <Tooltip title="Click to view" >
                            <div style={{ display: 'flex', flex: '40%' }} onClick={handleCalibrationCertNameClick} >
                                <label style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: '60%', maxWidth: '45%' }}>{calibrationCertName}</label>
                            </div>
                        </Tooltip>}
                        <div className={classes.dialogBox} style={{ display: 'flex', flex: '30%' }}>
                            <Button variant="contained" component="label" style={{ flex: '100%', maxWidth: '50%' }}>
                                {t("browse_label")}
                                <input type="file" hidden className={classes.dialogText} onChange={handleCalibrationCertChange} id="calibrationCertName" />
                            </Button>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Tooltip title="Save">
                        <IconButton onClick={handleSave} color="primary">
                            {openEdit ? <SaveChangesIcon /> : <SaveIcon />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t("Cancel")}>
                        <IconButton onClick={handleCancel}>
                            <CancelIcon />
                        </IconButton>
                    </Tooltip>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default EquipmentDialog;
