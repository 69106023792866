import { useState, useEffect, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { addReason, updateReason, deleteReason } from '../../services/JobReasonService';
import { getCategories } from '../../services/CategoryService';
import { Alert, AlertTitle } from '@material-ui/lab';
import { MultiSelector, SingleSelector} from "../SelectComponents";
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import IconButton from '@material-ui/core/IconButton';
import SaveChangesIcon from '@mui/icons-material/SaveAsOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
      flex: 1,
      backgroundColor: 'paper',
    },
    dialog: {
      width: '400px',
      display: 'flex'
    },
    dialogBox: {
      display: 'flex',
      alignItems: 'center'
    },
    dialogText: {
      flex: '0.4'
    },
    dialogInput: {
      flex: '0.5'
    },
    formControl: {
      margin: theme.spacing(2),
      minWidth: 120,
      maxWidth: 300,
      flex: '0.5'
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(2),
    },
  }));

  function JobReasonsDialog(props){
    const { openDialog, openEdit, selectedItems, text, updateTable, onDialogClose } = props;
    const [alertBox, setAlertBox] = useState("");
    const [jobReason, setJobReason] = useState("");
    const [categoryDetails, setCategoryDetails] = useState([]);
    const [categories, setCategories] = useState([]);
    const [alertContext, setAlertContext] = useContext(AlertContext);
    const { t } = useTranslation();

    const handleJobReasonChange= (event) =>{
        setJobReason(event.target.value);
    }

    useEffect(() => {
        if(openEdit){
          setJobReason(selectedItems.name);
          
          let tmpCategories = [];
          selectedItems.categories.forEach(category=>{
            let temp = categoryDetails.find(c => c.id === category.id);
            if (temp) tmpCategories.push(temp);
          });

          setCategories(tmpCategories)
        }
      }, [openEdit]);

    const clearFields = () => {
    setJobReason("");
    setAlertBox("");
    setCategories([]);
    };

    useEffect(() => {
        const getCategoryInfo = async () => {
          let data = await getCategories(localStorage.getItem("language"));
    
          if(!(data instanceof Array)){
            setAlertContext({...alertContext, open: true});
            return;
          }
          setCategoryDetails(data);
        }
          getCategoryInfo();
    
         
        
      }, []);

    const handleAlertBox = (messages) => {
        setAlertBox(<Alert severity="warning">
            <AlertTitle>Could not create Reason</AlertTitle>
            {messages.map((message, index) => {
            return <li key={index}>{message}</li>
            })}
        </Alert>)
    };

    const handleSave = async () => {
        let alertMessages = [];
        
        if (jobReason.length === 0) alertMessages.push("provide a Reason");
        if (categories.length === 0) alertMessages.push('Provide at least one package type');
        
        if (alertMessages.length > 0) handleAlertBox(alertMessages);
        
        else {
          let res;
          if(openEdit) res =  await updateReason(jobReason, categories, selectedItems.id);
          else res = await addReason(jobReason, categories)
          updateTable();
          onDialogClose(res);
          clearFields();
        }
      };

    const handleClose = () => {
        updateTable();
        onDialogClose();
        clearFields();
      };

    const classes = useStyles();

    return (
        <div className={classes.root}>
          <Dialog open={openDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
            <DialogTitle id="form-dialog-title">{(openEdit ? t("edit_label") : t("add"))} {t('reason_for_submission_label')} </DialogTitle>
            <DialogContent>
              <DialogContentText>
              </DialogContentText>
              {alertBox}
              <div className={classes.dialogBox} required>
                <Typography variant="body1" className={classes.dialogText}>
                  {t("job_reasons_tab")}
                </Typography>
                <TextField value={jobReason} onChange={handleJobReasonChange} className={classes.dialogInput} multiline rows={3} autoFocus margin="dense" id="name" type="input" variant="outlined" required />
              </div>
              <div className={classes.dialogBox}>
                <Typography variant="body1" className={classes.dialogText}>
                {t("category_s")}*
                </Typography>
                    <FormControl  variant='outlined' className={clsx(classes.formControl, classes.noLabel)}>
                        <SingleSelector width={275} isMulti={true} placeholder={t("type_to_find_categories")} 
                            value={categories.map((category)=>{return { value: category, label: category.name }})}
                            options={categoryDetails.map((category) => ({ value: category, label: category.name }))}
                            handleSelect={(e) => {
                                var temp = e.map((obj) => { return obj.value });
                                setCategories(temp);
                            }}>
                        </SingleSelector>
                    </FormControl>
            </div>
            </DialogContent>
            <DialogActions>
              <Tooltip title="Save">
                <IconButton onClick={handleSave} color="primary"> 
                  {openEdit ? <SaveChangesIcon /> : <SaveIcon />}
                </IconButton>
              </Tooltip>
              <Tooltip title={t("Cancel")}>
                <IconButton onClick={handleClose}> 
                  <CancelIcon/>
                </IconButton>
              </Tooltip>
            </DialogActions>
          </Dialog>
        </div>
      )
  }

  export default JobReasonsDialog;