/* eslint-disable jsx-a11y/anchor-is-valid */

import UserService from "../../keycloak";

import { Box, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { getIDocWorkQue, remIdocFromQueue, updateSequence } from '../../services/iDocWorkQueService'
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import Paper from "@material-ui/core/Paper";
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import DeleteIcon from "@material-ui/icons/Delete";
import StartIcon from '@mui/icons-material/Start';
import DragDropTable from '../DragDropTable'
import "react-table-6/react-table.css";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { connect } from 'react-redux';
import OpenIcon from '@mui/icons-material/PageviewOutlined';
import AddQueIcon from '@mui/icons-material/AddCircleOutline';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';


const IDocQueueTable = (props) => {
    const classes = props.classes;
    const { t } = useTranslation();
    const [rows, setRows] = useState([]);
    const [alertContext, setAlertContext] = useContext(AlertContext);
    const [timeoutElapsed, setTimeoutElapsed] = useState(false);
    const [blocked, setBlocked] = useState(false);
   

    // A custom progress indicator for progress column....
    const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 7,
        borderRadius: 5,
        backgroundColor: theme.palette.grey[300],
    }));

    // Sort rows by sequence attribute.
    const sortAndSetRows = (res) => {
        if (res.length > 0) res.sort((a, b) => rowCompare(a, b))
        setRows(res);
    }

    // Populates the table.
    const fetchRows = () => {
        setTimeoutElapsed(false)
        setTimeout(() => { setTimeoutElapsed(true) }, 3000);
        getIDocWorkQue().then((res) => {  // api call.
            setBlocked(false);
            if (!res.req_error) {
                var temp = res.map((i) => { return `testMethod=${i.testMethod}, sequence=${i.sequence}` })
 
               sortAndSetRows(res)  // see above.
            }
            else {
                setAlertContext({ ...alertContext, open: true });
                return;
            }
        })
    }

    // On mount, get the table data. Also set up a timer to refresh table every 5 seconds.
    const poller = useRef(null);
    useEffect(() => {
        fetchRows();
        poller.current = setInterval(() => { fetchRows() }, 5000);
        return () => { clearInterval(poller.current) }
    }, [])


    // Helper function for sorting iDocs by their sequence attribute.
    const rowCompare = (a, b) => {
        if (a.sequence < b.sequence) return -1;
        else if (a.sequence > b.sequence) return 1;
        else return 0;
    }


    // Each row has a remove button.
    const getRemoveButton = (row) => {
        return <Tooltip followCursor={true} title={t("Remove from your queue")}>
            <IconButton onClick={() => {  // Api call...
                remIdocFromQueue(row.id).then((res) => {
                    fetchRows();
                })
            }}>
                <DeleteIcon />
            </IconButton>
        </Tooltip>
    }

    const getTooltipText = (row) => {
        var line1 = row.testsCompleted + " " + t("out of") + " " + (row.numberOfTests === null ? "NaN" : row.numberOfTests) + " " + t("tests completed") + ".";
        var line2 = ` - ${row.testsPassed} ${t("tests passed")}.`
        var line3 = ` - ${row.testsCompleted - row.testsPassed} ${t("tests failed")} or ignored.`
        return (<div style={{ fontSize: "12px" }}>{line1}<br />{line2}<br />{line3}</div>)
    }

    // Each row has a progress component.
    const getProgress = (row) => {
        var progress = Math.floor((row.testsCompleted / (row.numberOfTests === null ? 0 : row.numberOfTests)) * 100)
        if (isNaN(progress)) progress = 0;
        return (
            <Tooltip followCursor={true} title={getTooltipText(row)}>
                <div style={{ minWidth: "100px" }}>
                    {progress + "% " + t("complete")}
                    <CustomLinearProgress variant="determinate" value={progress} />
                </div>
            </Tooltip>
        )
    }

    const getEquipmentNames = (row) => {
        var names = row.equipmentNames;
        if (names === null || names.length === 0) return null;
        if (names.length === 1) return names[0];
        return `${names[0]} & ${names.length - 1}  more...`
    }

    const getHeaderStyle = (str) => {
        return (<div style={{ textAlign: "left", paddingLeft: "5px" }}><b>{str}</b></div>)
    }
    const getCellStyle = (str) => {
        return (<div style={{ textAlign: "left", paddingLeft: "5px" }}>{str}</div>)
    }

    const getLinkButton = (jobID) => {
        return (
             <Tooltip followCursor={true} title="Click to view job.">
                <a style={{ cursor: "pointer", textDecoration: "underline", color: "#551A8B"}} onClick={() => {
                var job = props.allJobs.find((j) => { return j.jobID == jobID })
                props.history.push(`/testing/?testsTable=true&jobId=${job.id}`);
                }}> {jobID} </a>
            </Tooltip>
        )
    }

    const getIcon= (typ) => {
        return (

             <Tooltip followCursor={true} title={getAddTooltip(typ)}>
			                { getIconByTyp(typ)		}
            </Tooltip>
        )
    }

const getIconByTyp= (typ) => {
	if (typ==0) return <AddQueIcon />
	if (typ==1) return <DriveFolderUploadOutlinedIcon />
	if (typ==2) return <HandymanOutlinedIcon />
	
}
const getAddTooltip= (typ) => {
	if (typ==0) return "IDoc";
	if (typ==1) return "Importing Results";
	if (typ==2) return "Manual Entry";	
}

    const cols = [
      
        { accessor: row => getIcon(row.queueType), Header: getHeaderStyle(t("Queue Type")), id: "queue" },
        { accessor: row => getCellStyle(row.testMethod), Header: getHeaderStyle(t("Test method")),  id: "testMethod" },
       // { accessor: row => getCellStyle(row.jobId), Header: getHeaderStyle(t("job_id_label")), id: "jobID" },
        { accessor: row => getLinkButton(row.jobId), Header: getHeaderStyle(t("job_id_label")), id: "linkBtn" },
        { accessor: row => getCellStyle(row.sampleTypeName), Header: getHeaderStyle(t("Sample Type")), id: "sampleTypeName" },
        { accessor: row => getCellStyle(row.numberOfSamples), Header: getHeaderStyle(t("sample_count_label")), id: "numberOfSamples" },
        { accessor: row => getCellStyle(getProgress(row)), Header: getHeaderStyle(t("Progress")), id: "progress" },
        { accessor: row => getRemoveButton(row), Header: "", id: "remove" },
    ]

   

    const handleDragStart = () => {
        clearInterval(poller.current)

    }

    const handleReorder = async (reorderedRows, iDocId, newSeq) => {
        if (blocked) return;
        // Reordering the rows before making API call to make it seem instantaneous.
        reorderedRows.forEach((r, idx) => { r.sequence = idx + 1 })
        sortAndSetRows(reorderedRows)

        
        // API call to update iDoc sequences at back-end ...
        setBlocked(true);
        updateSequence(iDocId, newSeq).then((res) => {
            
            if (res.req_error) alert("Reordering request failed!")
            else {
                console.log("Queue reordered!")
            }

            setTimeout(() => { fetchRows() }, 300);
            
            poller.current = setInterval(() => { fetchRows() }, 5000);
            
        });
    }

    
    //const subComponent = (data) => {
    //    var row = data.original;
    //    return row.isExpandable ? "expanded content" : null
    //}

    return (
        <Paper className={classes.paper}>
            <Box mx={2} className={classes.tableHeader}>
                <div className={classes.title} style={{ width: "120px" }}></div>
                <Typography
                    className={classes.title}
                    style={{ fontWeight: 400, fontSize: "22px" }}>
                    {" " + t("idoc_work_queue_label")}::{UserService.getUsername()}
                </Typography>
                {props.hideIdocSessionBtn ? <div style={{ width: "120px" }}></div> :
                    <Tooltip followCursor={true} title={t("Start")}>
                        <IconButton
                            style={{ marginRight: "85px" }}
                            onClick={() => { alert("Functionality to be added...") }}>
                            <StartIcon />
                        </IconButton>
                    </Tooltip>}
            </Box>

            {rows.length > 0 || timeoutElapsed === true ?
                <DragDropTable
                    rows={rows}
                    columns={cols}
                    handleReorder={handleReorder}
                    handleDragStart={handleDragStart}
                    defaultPageSize={100}
                    showPagination={false}
                    subComponent={null}
                    blocked={blocked}
                /> :
                <div style={{ textAlign: "center", marginBottom: "20px" }}>
                    <p>{t("Loading table data")}...</p> <CircularProgress style={{ marginBottom: "50px" }} />
                </div>}
        </Paper>
    )

}



export const IDocQueueDialog = (props) => {

    return (
        <Dialog onClose={props.onClose} open={props.open} fullWidth={true} maxWidth={'xl'}>
            <DialogContent>
                <IDocQueueTable history={props.history} classes={props.classes} dense={props.dense} hideIdocSessionBtn={true} />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={props.onClose}>Close</Button>
            </DialogActions>
        </Dialog>)
}


const mapStateToProps = (state, props) => {
    return {
        allJobs: state.allJobs,
    };
};


export default connect(mapStateToProps)(IDocQueueTable);