
import { jsPDF } from "jspdf";
import { IconButton, Tooltip } from '@material-ui/core';
import FileDownloadIcon from '@mui/icons-material/FileDownload';


const ExportAnalytics = (props) => {

    const { selectedFilters, title } = props;

    const brandingText = "PackLIMS";

    const getFilterText = () => {
        let filterText = ""

        Object.entries(selectedFilters).map(([key, value]) => {
            if (value) {
                switch(key) {
                    case "category":
                        filterText += key + ": " + value.name + "   ";
                        break;
                    case "fromDate":
                    case "toDate":
                        let date = new Date(value).toLocaleDateString();
                        console.log(date)
                        filterText += key + ": " + date + "   ";
                        break;
                    default:
                        filterText += key + ": " + value + "   ";
                        break;
                }
            }
        });

        if (filterText === "") {
            filterText = "No filters used";
        }

        return filterText;
    }

    const addChartsToDoc = (doc, yText) => {
        let x = 25;
        let xText = 20;

        let y = 30;

        let width = 160
        let height = 110

        const canvasArray = document.getElementsByClassName('analyticsChart');
        console.log(canvasArray)

        for (let i = 0; i < canvasArray.length; i++) {
            let alias = "Chart" + i;
            
            const canvasImage = canvasArray[i].toDataURL('image/png', 1.0); 

            doc.text(xText, yText, canvasArray[i].id, { align: 'left' })
            doc.addImage(canvasImage, 'PNG', x, y, width, height, alias);
            
            yText += height + 15;

            y += height + 15;

            if ((i + 1) < canvasArray.length && ((i + 1) % 2) === 0) {
                console.log("divisible");
                doc.addPage();

                y = 15;
                yText = 10

            }
            
        }
    }

    const exportPDF = () => {
        const doc = new jsPDF();

        let yText = 10;

        let fullTitle = brandingText + " - " + title; 

        // DOC TITLE/HEADER
        doc.setFont("Helvetica", "bold");
        doc.text(20, yText, fullTitle, { align: 'left' })

        yText += 7;

        // DOC FILTER TEXT
        let filterText = getFilterText();
        doc.setFontSize(14)


        let splitFilterText = doc.splitTextToSize(filterText, 170);
        doc.setFont("Helvetica", "italic");
        doc.text(20, yText, splitFilterText, { align: 'left' });

        yText += 10;

        // DOC BODY
        doc.setFontSize(12);
        doc.setFont("Helvetica", "normal");

        addChartsToDoc(doc, yText);
        
        doc.save('export.pdf');
    }



    return (<div>

        <Tooltip title={"Export to PDF"}>
            <IconButton onClick={exportPDF}>
                <FileDownloadIcon />
            </IconButton>
        </Tooltip>

    </div>)
}


export default ExportAnalytics;




