import React, { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertContext } from './AlertContext';

function AlertContextProvider(props){
    const { t } = useTranslation();

    const defaultState = {
        open: false,
        text: t("could_not_get_data")
    }
    
    const [alertContext, setAlertContext] = useState(defaultState);

    return (
        <AlertContext.Provider value={[alertContext, setAlertContext]}>
            {props.children}
        </AlertContext.Provider>
    )
}

export default AlertContextProvider;