/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { SingleSelector } from '../../components/SelectComponents'
import { getCategories } from '../../services/CategoryService';
import { getNeckFinishes, getBottleSizes, getDesigns, getRegion, getSuppliers } from '../../services/FilterService';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { IconButton, Tooltip } from '@material-ui/core';
import { Clear, ClearAll, CheckCircle } from '@material-ui/icons';


const Filters = ({filterChoice, filterCallback}) => {
    const { t } = useTranslation();

    
    const [date1, setDate1] = useState(null);
    const [date2, setDate2] = useState(null);
    const [TS1, setTS1] = useState(null);
    const [TS2, setTS2] = useState(null);

    const [categories, setCategories] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({ value: null, label: t("Filter category (showing all)") + "..." });

    const [neckFinishes, setNeckFinishes] = useState([]);
    const [neckFinishesOptions, setNeckFinishesOptions] = useState([]);
    const [selectedNeckFinish, setSelectedNeckFinish] = useState({ value: null, label: t("Filter Neck Finish (showing all)") + "..." });
    
    
    const [designs, setDesigns] = useState([]);
    const [designsOptions, setDesignsOptions] = useState([]);
    const [selectedDesign, setSelectedDesign] = useState({ value: null, label: t("Filter Designs (showing all)") + "..." });
    
    const [bottleSizes, setBottleSizes] = useState([]);
    const [bottleSizesOptions, setBottleSizesOptions] = useState([]);
    const [selectedBottleSize, setSelectedBottleSize] = useState({ value: null, label: t("Filter Bottle Size (showing all)") + "..." });
    
    const [suppliers, setSuppliers] = useState([]);
    const [suppliersOptions, setSuppliersOptions] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState({ value: null, label: t("Filter Suppliers (showing all)") + "..." });
    
    const [regions, setRegions] = useState([]);
    const [regionOptions, setRegionsOptions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState({ value: null, label: t("Filter Regions (showing all)") + "..." });

    const [filterValues, setFilterValues] = useState({});

    const  at12AM = (date) => {
        return new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
        );
    }

    const handleResetFilters = () => {
        let res = {};
        Object.keys(filterChoice).forEach(function(key) {
            if (filterChoice[key] === false) {
                res[key] = null;
            }
        });

        setFilterValues(res);

        setSelectedCategory({ value: null, label: t("Filter category (showing all)") + "..." });
        setSelectedNeckFinish({ value: null, label: ("Filter Neck Finish (showing all)") + "..." });
        setSelectedBottleSize({ value: null, label: ("Filter Bottle Size (showing all)") + "..." });
        setSelectedDesign({ value: null, label: ("Filter Design (showing all)") + "..." });
        setSelectedSupplier({ value: null, label: ("Filter Supplier (showing all)") + "..." });
        setSelectedRegion({ value: null, label: ("Filter Region (showing all)") + "..." });
        setDate1(null);
        setDate2(null);
        setTS1(null);
        setTS2(null);
    }

    useEffect(async () => {
        // setResults(null)

        console.log(filterChoice)

        handleResetFilters();

        getCategories(localStorage.getItem("language")).then((res) => {
            setCategories(res);
        });

        getNeckFinishes().then((res) => {
            console.log(res);
            setNeckFinishes(res);
        });

        getBottleSizes().then((res) => {
            console.log(res);
            setBottleSizes(res);
        });

        getDesigns().then((res) => {
            console.log(res);
            setDesigns(res);
        });

        getRegion().then((res) => {
            console.log(res);
            setRegions(res);
        });

        getSuppliers().then((res) => {
            console.log(res);
            setSuppliers(res);
        });
        
    }, [])

    useEffect(() => {
        if (categories && categories.length > 0) {
            setCategoryOptions(categories.map((c) => {
                return { value: c, label: c.name }
            }))
        }
    }, [categories])

    useEffect(() => {
        if (neckFinishes && neckFinishes.length > 0) {
            setNeckFinishesOptions(neckFinishes.map((c) => {
                return { value: c, label: c }
            }))
            console.log(neckFinishes)
        }
    }, [neckFinishes])

    useEffect(() => {
        if (bottleSizes && bottleSizes.length > 0) {
            setBottleSizesOptions(bottleSizes.map((c) => {
                return { value: c, label: c }
            }))
            console.log(bottleSizes)
        }
    }, [bottleSizes])

    useEffect(() => {
        if (designs && designs.length > 0) {
            setDesignsOptions(designs.map((c) => {
                return { value: c, label: c }
            }))
            console.log(designs)
        }
    }, [designs])

    useEffect(() => {
        if (suppliers && suppliers.length > 0) {
            setSuppliersOptions(suppliers.map((c) => {
                return { value: c, label: c }
            }))
            console.log(suppliers)
        }
    }, [suppliers])

    useEffect(() => {
        if (regions && regions.length > 0) {
            setRegionsOptions(regions.map((c) => {
                return { value: c, label: c }
            }))
            console.log(regions)
        }
    }, [regions])

    useEffect(() => {
        if (date1) setTS1(at12AM(new Date(date1)).getTime());
        if (date2) setTS2(at12AM(new Date(date2)).getTime());
    }, [date1, date2])


    useEffect(() => {
        if (TS1 && TS2 && (TS2 <= TS1)) {
            alert(t("'To' date must be after 'From' date!"))
            setDate1(null);
            setDate2(null);
            setTS1(null);
            setTS2(null);
        }
        
    }, [TS1, TS2])

    const handleSelectedCategory = (selectedOption) => {
        filterValues["category"] = selectedOption.value;

        setSelectedCategory(selectedOption);
    }

    const handleSelectedNeckFinish = (selectedOption) => {
        filterValues["neckFinish"] = selectedOption.value;
        setSelectedNeckFinish(selectedOption);
    }

    const handleSelectedDesign = (selectedOption) => {
        filterValues["design"] = selectedOption.value;
        setSelectedDesign(selectedOption);
    }

    const handleSelectedBottleSize = (selectedOption) => {
        filterValues["bottleSize"] = selectedOption.value;
        setSelectedBottleSize(selectedOption);
    }

    const handleSelectedSupplier = (selectedOption) => {
        filterValues["supplier"] = selectedOption.value;
        setSelectedSupplier(selectedOption);
    }

    const handleSelectedRegion = (selectedOption) => {
        filterValues["region"] = selectedOption.value;
        setSelectedRegion(selectedOption);
    }

    const handleSelectedDateFrom = (selectedOption) => {
        filterValues["fromDate"] = at12AM(new Date(selectedOption)).getTime();
        setDate1(selectedOption);
    }

    const handleSelectedDateTo = (selectedOption) => {
        filterValues["toDate"] = at12AM(new Date(selectedOption)).getTime();
        setDate2(selectedOption);
    }

    const handleApplyFilters = () => {
        filterCallback(filterValues);
    }


    return (<div style={{display:"flex", flexWrap:"wrap", alignItems: "flex-end"}}>
        <div style={{ marginLeft: "30px", marginTop:"21px"}} hidden={filterChoice.category}>

            <SingleSelector
                width="280px"
                options={categoryOptions}
                value={selectedCategory ? selectedCategory : null}
                placeholder={t("Select") + " " + t("category_label") + "..."}
                handleSelect={handleSelectedCategory} />
            
        </div>
        {selectedCategory.value !== null && 
            <Tooltip title={"Clear"}>
                <IconButton style={{ marginLeft: "5px", marginTop: "21px", padding: "2px" }} onClick={() => {handleSelectedCategory({ value: null, label: t("Filter category (showing all)") + "..." })}}>
                    <Clear />
                </IconButton>
            </Tooltip>
        }

        <div style={{ marginLeft: "30px", marginTop:"21px"}} hidden={filterChoice.neckFinish}>

                {<SingleSelector
                    width="280px"
                    options={neckFinishesOptions}
                    value={selectedNeckFinish ? selectedNeckFinish : null}
                    // placeholder={t("Select") + " " + t("category_label") + "..."}
                    placeholder="Neck Finishes"
                    handleSelect={handleSelectedNeckFinish}
                    />}
        </div>
        {selectedNeckFinish.value !== null && 
            <Tooltip title={"Clear"}>
                <IconButton style={{ marginLeft: "5px", marginTop: "21px", padding: "2px" }} onClick={() => {handleSelectedNeckFinish({ value: null, label: ("Filter Neck Finish (showing all)") + "..." })}}>
                    <Clear />
                </IconButton>
            </Tooltip>
        }

        <div style={{ marginLeft: "30px", marginTop:"21px"}} hidden={filterChoice.design}>
            <SingleSelector
                width="280px"
                options={designsOptions}
                value={selectedDesign ? selectedDesign : null}
                // placeholder={t("Select") + " " + t("category_label") + "..."}
                placeholder="Designs"
                handleSelect={handleSelectedDesign}
                    />
        </div>
        {selectedDesign.value !== null && 
            <Tooltip title={"Clear"}>
                <IconButton style={{ marginLeft: "5px", marginTop: "21px", padding: "2px" }} onClick={() => {handleSelectedDesign({ value: null, label: ("Filter Design (showing all)") + "..." })}}>
                    <Clear />
                </IconButton>
            </Tooltip>
        }

        
        <div style={{ marginLeft: "30px", marginTop:"21px"}} hidden={filterChoice.bottleSize}>
            <SingleSelector
                width="280px"
                options={bottleSizesOptions}
                value={selectedBottleSize ? selectedBottleSize : null}
                // placeholder={t("Select") + " " + t("category_label") + "..."}
                placeholder="Bottle Size"
                handleSelect={handleSelectedBottleSize}
                    />
        </div>
        {selectedBottleSize.value !== null && 
            <Tooltip title={"Clear"}>
                <IconButton style={{ marginLeft: "5px", marginTop: "21px", padding: "2px" }} onClick={() => {handleSelectedBottleSize({ value: null, label: ("Filter Bottle Size (showing all)") + "..." })}}>
                    <Clear />
                </IconButton>
            </Tooltip>
        }


        <div style={{ marginLeft: "30px", marginTop:"21px"}} hidden={filterChoice.supplier}>
            <SingleSelector
                width="280px"
                options={suppliersOptions}
                value={selectedSupplier ? selectedSupplier : null}
                // placeholder={t("Select") + " " + t("category_label") + "..."}
                placeholder="Suppliers"
                handleSelect={handleSelectedSupplier}
                />
        </div>
        {selectedSupplier.value !== null && 
            <Tooltip title={"Clear"}>
                <IconButton style={{ marginLeft: "5px", marginTop: "21px", padding: "2px" }} onClick={() => {handleSelectedSupplier({ value: null, label: ("Filter Supplier (showing all)") + "..." })}}>
                    <Clear />
                </IconButton>
            </Tooltip>
        }

        
        <div style={{ marginLeft: "30px", marginTop:"21px"}} hidden={filterChoice.region}>
            <SingleSelector
                width="280px"
                options={regionOptions}
                value={selectedRegion ? selectedRegion : null}
                // placeholder={t("Select") + " " + t("category_label") + "..."}
                placeholder="Regions"
                handleSelect={handleSelectedRegion}
                />
        </div>
        {selectedRegion.value !== null && 
            <Tooltip title={"Clear"}>
                <IconButton style={{ marginLeft: "5px", marginTop: "21px", padding: "2px" }} onClick={() => {handleSelectedRegion({ value: null, label: ("Filter Region (showing all)") + "..." })}}>
                    <Clear />
                </IconButton>
            </Tooltip>
        }

        <div style={{ marginLeft: "30px", marginTop: "21px" }} hidden={filterChoice.fromDate}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    disableFuture
                    variant="inline"
                    label={t("Date from") + "..."}
                    value={date1}
                    onChange={handleSelectedDateFrom}
                    InputProps={{ style: { width: "280px" } }}
                    KeyboardButtonProps={{ "aria-label": "change date", }}
                />
            </MuiPickersUtilsProvider>
        </div>

        <div style={{ marginLeft: "30px", marginTop: "21px" }} hidden={filterChoice.toDate}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    disableFuture
                    variant="inline"
                    label={t("Date to") + "..."}
                    value={date2}
                    onChange={handleSelectedDateTo}
                    InputProps={{ style: { width: "280px" } }}
                    KeyboardButtonProps={{ "aria-label": "change date", }}
                />
            </MuiPickersUtilsProvider>
        </div>

        {(TS1 !== null || TS2 !== null) && 
            <Tooltip title={"Clear Dates"}>
                <IconButton style={{ marginLeft: "5px", marginTop: "21px", padding: "2px" }} onClick={() => {
                    setDate1(null);
                    setDate2(null);
                    setTS1(null);
                    setTS2(null);

                    filterValues["fromDate"] = null;
                    filterValues["toDate"] = null;
                }}>
                    <Clear />
                </IconButton>
            </Tooltip>
        }

        <div>
            <Tooltip title={"Apply"}>
                <IconButton style={{ marginLeft: "30px", marginTop: "21px", padding: "2px" }} onClick={handleApplyFilters}>
                    <CheckCircle />
                </IconButton>
            </Tooltip>

            <Tooltip title={"Reset"}>
                <IconButton style={{ marginLeft: "30px", marginTop: "21px", padding: "2px" }} onClick={handleResetFilters}>
                    <ClearAll />
                </IconButton>
            </Tooltip>
        </div>

        

    </div>)

}
export default Filters;