/* eslint-disable */



import { Box, makeStyles, Button, TextField, Typography, Divider, Paper, Tabs, Tab, Tooltip, FormControlLabel, Switch, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import { getJobById, downloadReport, saveJobComments} from '../../services/RegisterJobService';
import { getIDocsInJob } from '../../services/iDocWorkQueService'
import { jobSignOff, jobReopen } from '../../services/ReportsService'
import JobInfoTable from '../Reports/JobInfoTable'
import ResultsSummaryTable from '../Reports/ResultsSummaryTable'
import EditableResultsTable from '../Reports/EditableResultsTable'
import PDFIcon from '@mui/icons-material/PictureAsPdf';
import CSVIcon from '@mui/icons-material/ArticleOutlined';
import IconButton from '@material-ui/core/IconButton';
import { useStyles } from "./styles"
import RetestDialog from "./RetestDialog"
import OKDialog from "../Dialogs/OKDialog";
import UploadIcon from '@mui/icons-material/Upload';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import noImage from "../../images/noImage.bmp"
import PropTypes from 'prop-types';
import UserService from "../../keycloak";
import ResultsCharts from './ResultsCharts'

//ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title, Filler);

export const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other} >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


const OpenReport = (props) => {
    const dense = props.dense;
    const history = props.history;
    const classes = useStyles();
    const setReport = props.setSelectedReport;
    const { t } = useTranslation();
    const [iDocs, setIdocs] = useState([]);
 
    const [tabValue, setTabValue] = React.useState(0);

    const [job, setJob] = useState(null);
    const [jobLoaded, setJobLoaded] = useState(false);

    const [sideBarContent, setSideBarContent] = useState(null)
    const [alertContext, setAlertContext] = useContext(AlertContext);

    const [comment, setComment] = useState("");
    const [savedComment, setSavedComment] = useState("");
    const [showSaveComment, setShowSaveComment] = useState(false);

    const [graphData, setGraphData] = useState(null);
    const [graphOptions, setGraphOptions] = useState(null);

    const [specs, setSpecs] = useState(null);

    const [generatingPdf, setGeneratingPdf] = useState(false);
    //const [generatingExcel, setGeneratingExcel] = useState(false);

    //const [excel, setExcel] = useState(null);
    const [pdf, setPdf] = useState(null);

    const [generating, setGenerating] = useState(false);


    const [RetestDialogState, setRetestDialogState] = useState({
        shown: false,
        callback: () => { }
    })

    const [OKDialogState, setOKDialogState] = useState({
        shown: false, title: "", message: "", flavour: "success"
    })


    const [isLabManagerLabSuperVisor,setIsLabManagerLabSuperVisor] = useState(false);

    var poller = null;
    useEffect(() => {
        getJobDetails();
        props.setDense(true)
        poller = setInterval(() => { getJobDetails() }, 5000);
       
        return () => { clearInterval(poller) }
    }, [])


    useEffect(() => {
        let origComment = "";
        if (jobLoaded) {
            console.log(job.comments);
            // try { origComment = JSON.parse(job.comments).text } catch (err) { }
            if (job.comments !== null)
                origComment = job.comments;
            setSavedComment( origComment )
            setComment( origComment )
        }
    }, [jobLoaded])
   

    const onCommentInputTextChange = (e) => {
        setComment(e.target.value );
    }


    useEffect(() => {
        if (comment !== savedComment) setShowSaveComment(true)
        else setShowSaveComment(false)
    }, [comment, savedComment])




    useEffect(() => {
        UserService.hasRole(['LAB_MANAGER', 'LAB_SUPERVISOR'])? setIsLabManagerLabSuperVisor(true): setIsLabManagerLabSuperVisor(false);
    }, []);

    const signOff = () => {
        jobSignOff(job.id).then((res) => {
            if (res !== false) {
                setOKDialogState({
                    shown: true,
                    title: t("Report Sign-off success"),
                    message: t("The report for this job has been signed off!"),
                    flavour: "success",
                    callback: () => {
                        history.push("/reports/?table=closed")

                    }
                })
            }
            else {
                setOKDialogState({
                    shown: true,
                    title: t("Report Sign-off failure"),
                    message: t("The report for this job was not signed off! Possible network/back-end error. Try again later"),
                    flavour: "error",
                    callback: () => {}
                })
            }
        })
    }

    const isCompletedPending = () => {
        if (job && job.status === "completed_pending") return true;
        return false;
    }


    const generatePDF = () => {
        setGenerating(true);
        if (generatingPdf) return;
        setGeneratingPdf(true);
        setPdf(null);
        downloadReport("pdf", job.id).then((file) => {
            setGeneratingPdf(false);
            setGenerating(false);
            if (file) {
                setPdf(file)
            }
            else alert(t("Problem generating PDF file. Possible network issue. Please check and try again."))
        })
    }

    //const generateExcel = () => {
    //    setGenerating(true);
    //    if (generatingExcel) return;
    //    setGeneratingExcel(true);
    //    setExcel(null)
    //    downloadReport("excel", job.id).then((file) => {
    //        setGeneratingExcel(false);
    //        setGenerating(false);
    //        if (file) {
    //            setExcel(file);
    //        }
    //        else alert(t("Problem generating Excel file. Possible network issue. Please check and try again."))
    //    })
    //}

    //const getExcelLink = () => {
    //    let blob = new Blob([excel], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    //    const link = <a href={window.URL.createObjectURL(blob)} download={job.jobID + "_report.xlsx" } >{t("Excel download ready")}...</a>;     
    //    return link

    //}

    const getPdfLink = () => {
        let blob = new Blob([pdf], { type: 'application/pdf' })
        const link = <a href={window.URL.createObjectURL(blob)} download={job.jobID + "_report.pdf"} >{t("PDF download ready")}...</a>;
        return link
    }

    // disabled={isLabManagerLabSuperVisor || !isCompletedPending()}
    const sideBarContent1 = (
        <>
            <Paper className={classes.paper} style={{ width: "100%", padding: "25px", textAlign: "center" }}><br />
               
                <Tooltip  disableHoverListener={!isLabManagerLabSuperVisor} followCursor={true} title={t("Lab Manager/Lab Supervisor does not have permission to Sign Off")}>
                    <span style={{paddingTop: "7%", paddingBottom: "2%" }}>
                        <Button variant="contained" style={{ marginBottom: "15px", width: "75%" }}  onClick={signOff}>{t("Sign Off")}</Button>
                    </span>
                </Tooltip>
                <br /><br />
               
            </Paper>
            <br /><br /><br />
            <Paper className={classes.paper} style={{ width: "100%", padding: "25px", textAlign: "center" }}>
                <div style={{ border: "1px solid lightGrey", borderRadius: "15px" }}>
                    <p style={{ textAlign: "left", marginLeft: "35px" }}>{t("Generate report as")}:</p>

                    <span>{t("PDF file")}:  </span>
                    <IconButton disabled={generating} onClick={generatePDF}>
                         <PDFIcon />
                    </IconButton>

                    <br />
                    
                   <span>{t("Excel file")}: </span>
                    <IconButton disabled={generating} onClick={
                       // generateExcel
                        () => {
                            //console.log(JSON.stringify(job, null, 2))
                            var name = encodeURIComponent(job.jobID)
                            window.open(`/?downloads=true&jobId=${job.id}&name=${name}`)
                        }
                    }>
                        <CSVIcon />
                    </IconButton>

                    <br /> <br />
                </div>
            <br /><br />

            </Paper>
        </>
    )


  


    useEffect(() => {
        if (tabValue === 0) setSideBarContent(sideBarContent1)
    }, [tabValue, job, iDocs])






    const comparer = (a, b) => {
        if (a.testMethod < b.testMethod) return -1;
        else if (a.testMethod > b.testMethod) return 1;
        else return 0;
    }

    const handleTabChange = (event, newTabValue) => {
        if (newTabValue === tabValue) return;
        setGraphData(null)
        setGraphOptions(null)
        setTabValue(newTabValue);
    };

    const getJobDetails = () => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const id = params.get('jobId')
       
        if (job === null) getJobById(id).then((res) => {
            if (res === false) {
                setAlertContext({ ...alertContext, open: true });
                return;
            }
          
            
            setJob(res);
            setReport(res);
            setJobLoaded(true);
            
        })

        getIDocsInJob(id,localStorage.getItem("language")).then((res) => {
            if (res.req_error) {
                setAlertContext({ ...alertContext, open: true });
                return;
            }
            res.sort((a, b) => comparer(a, b));
            res.forEach((r, i) => { r.tabValue = i + 1; })
            setIdocs(res);
        })
    }

   
    //<Paper className={classes.paper} style={{ width: "100%" }}>
    //    <div style={{ textAlign: "center", paddingTop: "20px" }}> Result chart(s) for: <b>{iDoc.testMethod}</b> test method.</div>
    //    {graphData !== null ? <div><Line data={graphData} options={graphOptions} /></div> :
    //        <div style={{ textAlign: "center", marginBottom: "50px" }}><p>Loading charts...</p> <CircularProgress style={{ marginBottom: "50px" }} /></div>
    //    }
    //</Paper>
    

    const tabs = iDocs.map((iDoc, i) => {
        if (iDoc.testsCompleted === iDoc.numberOfTests) {
            return <Tab label={iDoc.testMethod} style={{ backgroundColor: "#A0A0A0", fontWeight: "bold", borderRadius: "5px", margin: "2px", color:"black"}} key={`tab${i}`} />
        }

        else if ((iDoc.testsCompleted > 0) && (iDoc.testsCompleted  != iDoc.numberOfTests)) {
            return <Tab label={iDoc.testMethod} style={{ backgroundColor: "#E0E0E0", fontWeight: "bold", borderRadius: "5px", margin: "2px", color:"black"}} key={`tab${i}`} />
        }
        else {
            return <Tab label={iDoc.testMethod} style={{ backgroundColor: "white", fontWeight: "bold", borderRadius: "5px", margin: "2px", color:"black"}} key={`tab${i}`} />
        } 
        
        

    })
    //sx={{ borderBottom: 1, borderColor: 'divider', width:"100%" }}
    return (
        <div>
            <div style={{ display: "inline-block", width: "75%" }}>
                <Paper className={classes.paper} style={{ width: "100%" }}>
                 
                    <Box mx={2} className={classes.tableHeader}>
                        <Typography/>
                        {job !== null ? (<JobInfoTable  job={job} />) : null}

                    </Box>
                   
                    <Divider orientation="horizontal" style={{ marginTop: "20px" }}></Divider>
               
                    <div style={{ width: "100%", backgroundColor:"#ADCBE5"}}>
                        <Tabs value={tabValue}
                            variant="scrollable"
                            scrollButtons="auto"
                            textColor="secondary"
                            indicatorColor="secondary"
                            onChange={handleTabChange}
                            aria-label="basic tabs example">
                            {tabValue === 0 ? <Tab label={t("Summary of Results")} style={{ fontWeight: "bold" }} key="tab0" /> : <Typography flexItem></Typography>}
                            {tabValue > 0? tabs: null}
                        </Tabs>
                    </div>


                    {tabValue > 0 ? <div style={{ marginLeft: "20px", marginTop: "10px" }}>{" <<< "}
                        <a style={{ cursor: "pointer", color: "#9A1A4A" }}
                            onClick={() => {
                                setGraphData(null);
                                setGraphOptions(null);
                                setTabValue(0);}}
                            >
                            <b>{t("Back to Summary")}</b></a></div>
                        : null
                    }


                    <TabPanel value={tabValue} index={0}>

                        <ResultsSummaryTable dense={dense} setTabValue={setTabValue} iDocs={iDocs} />

                        <div style={{ margin: "10px" }}>
                            <b>{t("Job Comments")}:</b>
                            {comment.length > 0 && comment === savedComment ?
                                <span style={{color:"grey"}}>{t("  =( Type below to edit )")}</span> : null}
                        </div>

                        <form  onSubmit={
                            (e) => {
                                e.preventDefault();

                                saveJobComments(comment, job.id).then((res) => {
                                    if (res) {
                                        setSavedComment(comment);
                                        setOKDialogState({
                                            shown: true,
                                            title: t("Saved"),
                                            flavour: "success",
                                            message: t("Your comments have been saved!"),
                                        })
                                    }
                                    else {
                                        setOKDialogState({
                                            shown: true,
                                            title: t("Saved"),
                                            flavour: "error",
                                            message: t("Your comments were not saved! Check network connection and try again."),
                                        })
                                    }
                                })  
                            }}>

                            <TextField
                                inputProps={{ style: { padding: 5, width: "600px" } }}
                                multiline={true}
                              
                                id="comment"
                                placeholder={t("(Optional) Type some comments about job here") + "..."}
                                type="text"
                                value={comment}
                                variant="outlined"
                                onChange={onCommentInputTextChange} />
                           
                            <span style={{ display: showSaveComment ? "inline-block": "none"}}>
                            <IconButton style={{marginLeft:"20px"}} variant="contained" type="submit" autoFocus>
                                <SaveIcon />
                            </IconButton>
                                {t("save")}
                            </span>
                        </form>
                    </TabPanel>


                    {iDocs.map((iDoc, i) => {
                        return (
                            <TabPanel value={tabValue} index={i + 1} >
                                <ResultsCharts
                                    graphData={graphData}
                                    graphOptions={graphOptions}
                                    testMethod={iDoc.testMethod}
                                    

                                />
                                <br />
                               
                                <EditableResultsTable
                                    dense={dense}
                                    iDoc={iDoc}
                                    job={job}
                                    setSideBarContent={setSideBarContent}
                                    setGraphData={setGraphData}
                                    setGraphOptions={setGraphOptions}
                                   
                                    
                                />
                            </TabPanel>
                        )
                    })}

                </Paper>
            </div>

            <div style={{ display: "inline-block", float: "right", width: "22%", marginRight: "20px" }}>
                {sideBarContent}
                {generatingPdf && <span ><CircularProgress size={15} /><span style={{ marginLeft: "10px" }}>{t("Generating PDF file. Please wait")}...</span><br/><br/></span>}
                {pdf && <span >{getPdfLink()}<br/><br/></span>}
              
            </div>
          
            <RetestDialog
                show={RetestDialogState.shown}
                value={RetestDialogState.value}
                comment={RetestDialogState.comment}
                iDocs={iDocs}
                callback={
                    (val) => {
                        RetestDialogState.callback(val);
                        setRetestDialogState({ ...RetestDialogState, shown: false });
                    }
                }
            />
            <OKDialog
                show={OKDialogState.shown}
                title={OKDialogState.title}
                message={OKDialogState.message}
                flavour={OKDialogState.flavour}
                callback={
                    (res) => {
                        let callback = OKDialogState.callback;
                        setOKDialogState({ shown: false });
                        if (callback) callback(res);
                    }
                }
            />
        </div>
    )
}



export default OpenReport