import { makeStyles } from "@material-ui/core/styles";
import DepartmentDialog from '../Dialogs/DepartmentDialog';
import { useState, useContext } from 'react';
import { LabSetUpTabContext } from '../Contexts/LabSetUpTabs/LabSetUpTabContext';
import { deleteDepartment, getDepartments, canDeleteDepartment } from '../../services/DepartmentsService';
import { useTranslation } from 'react-i18next';
import GenericTable from '../GenericTable';
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import { Tooltip, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tooltipText: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));

function Departments(props) {
  const { text } = props;
  const [rows, setRows] = useState([]);
  const { t } = useTranslation();
  const classes = useStyles();
  const [dense, setDense] = useContext(LabSetUpTabContext);
  const [alertContext, setAlertContext] = useContext(AlertContext);
    // (<Tooltip title={row.name}><Typography className={classes.tooltipText}>  // </Typography></Tooltip>)
  const columns = [
      { selector: (row) => row.name, sortable: true, name: t("department_name_field_label"), type: "text", key: "name" },
      { selector: (row) => row.description, sortable: true, name: t("description_field_label"), type: "text", key: "description" },
      { selector: (row) => row.code, sortable: true, name: t("code_field_label"), type: "text", key: "code" },
  ];

  const updateTable = async (callback) => {
    let data = await getDepartments();

    if(!(data instanceof Array)){
      setAlertContext({...alertContext, open: true});
      return;
    }
    setRows(data);
    
    if (callback !== undefined) {
      callback();
    }
  }

  const showDeleteErrors = (error) => {
    if (error.length > 0) {
        setAlertContext({ text: error, open: true });    
    }
  }

  const handleDelete = async (selected) => {
        await selected.forEach((department) => {
          let id = department.id
          let errorMessage = "";
          canDeleteDepartment(id).then((res) => {
    
            if (res.data.length > 0) {
              errorMessage = t("department_delete_error_message_1") + department.name + t("department_delete_error_message_2");
              
              res.data.forEach((testMethod) => {
                errorMessage = errorMessage + testMethod.id + ", ";
              });

              errorMessage = errorMessage.slice(0, -2); // this is to remove the last comma and space added from the previous forEach loop
  
              errorMessage = errorMessage + t("department_delete_error_message_3");
  
            }
  
            if (errorMessage.length === 0) {
              deleteDepartment(id).then(() => {
                updateTable();
              });
            }
            else {
              showDeleteErrors(errorMessage);
            }
          });
        });
    }


  return <GenericTable
    dense={dense}
    keyColumn={{
      key: "name",
      name: t("department_name_field_label")
    }}
    setDense={setDense}
    updateTable={updateTable}
    Dialog={DepartmentDialog}
    rows={rows}
    columns={columns}
    text={text}
    deleteCallback={handleDelete} />;
}

export default Departments;
