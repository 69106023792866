import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import UserService from '../keycloak';

const useStyles = makeStyles({
    icon: {
        fontSize: 30,
        color: 'white'
    }
})

function AccountButton(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const login = () => {
        UserService.doLogin();
    };

    const logout = () => {
        UserService.doLogout();
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const isLoggedIn = (UserService.isLoggedIn());

    const handleAccountClose = () => {
        setAnchorEl(null);
        isLoggedIn ? logout() : login();
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <Box>
            <IconButton onClick={handleClick}>
                <AccountCircle className={classes.icon} />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleAccountClose}>{isLoggedIn ? "Logout" : "Login"}</MenuItem>
            </Menu>
        </Box>
    )
}

export default AccountButton;