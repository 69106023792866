import HttpService from "./HttpService";
const axios = HttpService.getAxiosClient();
const methods = HttpService.HttpMethods;
const baseURL = process.env.REACT_APP_BACKEND_URL;
const rootRequestMapping = "equipment";

const getEquipment = async () => {
  try {
    const res = await axios.request({
      url: `${baseURL}${rootRequestMapping}/all`,
      method: methods.GET
    });

    if (res.status === 200)
      return res.data;

    return [];
  }
  catch (error) {
    return null;
  }
}

const checkCode = async (id, code, entity) => {
   
  try {
    let res = await axios.post(`${baseURL}uniquecheck/check`, {
      "id": id,
      "code": code,
      "entity": entity
    })
    console.log(res)
    return res.data;
  }

  catch (error) {
    console.error(error)
  }
}

const addEquipment = async (id, name, description, manufacturer, department, serialNum, code, calibrationDate, calibrationDue, calibrationCertPath, calibrationCertName) => {
  let data = {
    name: name,
    code: code,
    description: description,
    department: department,
    departmentName: department.name,
    serialNumber: serialNum,
    manufacturer: manufacturer,
    calibrationDate: calibrationDate,
    calibrationDue: calibrationDue,
    calibrationCertPath: calibrationCertPath,
    calibrationCertName: calibrationCertName,
    
  };

  if (id !== undefined && id !== null) data["id"] = id;
  try {
    let res = await axios.request({
      url: `${baseURL}${rootRequestMapping}/save`,
      method: methods.POST,
      data: data,
    });

    // if (res.status === 200 || res.status === 201) return res;
    return res;
  }
  catch(error){
    return error;

  }
};

const deleteEquipment = async (id) => {
  let response = await axios.request({
    url: `${baseURL}${rootRequestMapping}/delete/${id}`,
    method: methods.GET,
  });

  return response;
};

export { getEquipment, addEquipment, deleteEquipment, checkCode };
