/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { disableJob, getAllRegisteredJobs } from '../../services/RegisterJobService';
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import GenericTable from '../GenericTable';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import AddEditJob from '../RegisterSamples/AddEditJob';
import ViewIcon from "@material-ui/icons/Visibility";
import IconButton from '@material-ui/core/IconButton';



const RegisterSamples = (props) => {

    const [rows, setRows] = useState([]);
    const [dense, setDense] = useState(false);
    const [alertContext, setAlertContext] = useContext(AlertContext);
    const { t } = useTranslation();
    let text = t("register_samples_title");
   
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});


    // A custom progress indicator for progress column....
    const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 7,
        borderRadius: 5,
        backgroundColor: theme.palette.grey[300],
    }));

    const sortByAddDate = (a, b) => {
        if (a.addDate < b.addDate) return 1
        else if (a.addDate > b.addDate) return -1
        else return 0
    }

    const sortByDueDate = (a, b) => {
        if (a.dueDate < b.dueDate) return 1
        else if (a.dueDate > b.dueDate) return -1
        else return 0
    }

    const fetchRows = () => {
        getAllRegisteredJobs().then((res) => {
            if (!(res instanceof Array)) {
                setAlertContext({ ...alertContext, open: true });
                return;
            }
            progressFix(res)
            res.sort(sortByAddDate)
            setRows(res)
        })
    }


    useEffect(() => {
        fetchRows();
    }, [])


    const getTooltipText = (row) => {
        var line1 = row.testsCompleted + " " + t("out of") + " " + (row.numberOfTests === null ? "NaN" : row.numberOfTests) + " " + t("tests completed") + ".";
        var line2 = ` - ${row.testsPassed} ${t("tests passed")}.`
        var line3 = ` - ${row.testsCompleted - row.testsPassed} ${t("tests failed")} or ignored.`
        return (<div style={{ fontSize: "12px" }}>{line1}<br />{line2}<br />{line3}</div>)
    }



    


    // Gets the progress for a given row...
    const getProgress = (row) => {
        var progress = Math.floor((row.testsCompleted / (row.numberOfTests === null ? 0 : row.numberOfTests)) * 100)
        if (isNaN(progress)) progress = 0;
        if (progress > 100) progress = 100;
        return (
            <Tooltip followCursor={true} title={getTooltipText(row)}>
                <div style={{ minWidth: "100px" }}>
                    {progress + "% " + t("complete")}
                    <CustomLinearProgress variant="determinate" value={progress} />
                </div>
            </Tooltip>
        )
       
    }

    /**
     * Returns a JSX Element that contains the due date, styled with a color according to the expiration date (red if expired, inherit otherwise).
     * @param row the row that contains a dueDate attribute
     * @returns a JSX element
     */
    const getDueDate = (row) => {
        const style = new Date() > new Date(row.dueDate) ? {color: "red"} : {color: "inherit"};
        return <span style={style}>{new Date(row.dueDate).toLocaleString("en-UK").split(',')[0]}</span>
    }

    /**
     * Returns a state label according to the state number argument.
     * @param {number} state the state number 
     * @returns a state label
     */
    const translateState = (state) => {
        const statesArray = [
            t("state_received_label"),
            t("state_inProgress_label"),
            t("state_onHold_label"),
            t("state_completedAccepted_label"),
            t("state_completedPending_label"),
            t("state_overdue_label"),
            t("state_notConfigured_label"),
            t("state_removed_label"),
            t("state_importResults_label"),
            t("state_completedNotAccepted_label"),
            t("state_incomplete_label"),
            t("state_retest_label"),
        ];
        if(state > statesArray.length - 1 || state < 0) return "Undefined";
        return statesArray[state];
    }

    const getTooltip = (text) => {
        return <tooltip title={text}><span>{text}</span></tooltip>
    }

    

    const columns = [
        { selector: row => getProgress(row), sortable: false, name: t("Progress"), type: "text", key: "progress", grow: 0.5 },
        { selector: row => row.jobID, sortable: true, name: t("job_id_label"), type: "text", key: "jobID", grow: 1 },
        { selector: row => new Date(row.addDate).toLocaleString("en-UK").split(',')[0], sortable: true, sortFunction: sortByAddDate, name: t("add_date_label"), type: "date", key: "addDate", grow: 0.4 },
        { selector: row => getDueDate(row), sortable: true, sortFunction: sortByDueDate, name: t("due_date_label"), type: "date", key: "dueDate", grow: 0.4 },
        { selector: row => row.sampleCount, sortable: true, name: t("sample_count_label"), type: "numeric", key: "sampleCount", grow: 0 },
        { selector: row => row.sampleType.name, sortable: true, name: t("sample_type_label"), type: "text", key: "sampleTypeName", grow: 0.7 },
        { selector: row => `${row.client.name}`, sortable: true, name: t("client_label"), type: "text", key: "clientName", grow: 0.4 },
        // { selector: row => translateState(row.state), sortable: true, name: t("state_label"), type: "text", key: "state", grow: 0.4 },
        // { selector: row => new Date(row.lastUpdate).toLocaleString().split(',')[0], sortable: true, name: t("last_update_label"), type: "date", key: "lastUpdate", grow: 0.4 },
  //      { selector: row => getTooltip(`${row.addedBy}`), sortable: true, name: t("added_by_label"), type: "text", key: "addedBy", grow: 0.5 },
  //       { selector: row => `${row.department.name}`, sortable: true, name: t("department_label"), type: "text", key: "departmentName", grow: 0.4 },
//        { cell: row => <Tooltip followCursor={true} title={t("View")}><IconButton component={Link} to={`/testing/?testsTable=true&jobId=${row.id}`}> <ViewIcon /> </IconButton></Tooltip>, grow: 1 }
    ];

    

    const progressFix = (res) => {
        res.forEach((r) => {
            if (!r.testsCompleted) return;
            if (!r.numberOfTests) return;
            if (r.testsCompleted > r.numberOfTests) r.numberOfTests = r.testsCompleted
        })
    }
  


    const handleDelete = async (selected) => {
        if (window.confirm('Are you sure you wish to delete ?')) {
            let promises = selected.map((item) => { return disableJob(item.id) })
            var temp = rows.filter((r) => { return selected.includes(r) === false })
            setRows(temp)
            await Promise.all(promises)
            fetchRows()
        }
    }

    const Dummy = (props) => { return "" }

    
    return openAdd ? <AddEditJob openAdd={openAdd} openEdit={openEdit} setOpenAdd={setOpenAdd} setOpenEdit={setOpenEdit} selectedItem={selectedItem}  rows={rows} setRows={setRows} /> :

        <GenericTable
        dense={dense}
        keyColumn={{
            key: "jobID",
            name: t("job_id_label")
        }}
        setDense={setDense}
        updateTable={() => { }}
        Dialog={Dummy}
        rows={rows}
        columns={columns}
        text={text}
        createJobText = { t('create_job')}
        deleteCallback={handleDelete}
       
        parentAdd={[openAdd, setOpenAdd]}
        parentEdit={[openEdit, setOpenEdit]}
        selectedItem={[selectedItem, setSelectedItem]}
        useDialog={false} />;

}






export default RegisterSamples;
