

import HttpService from "./HttpService";



const axios = HttpService.getAxiosClient();
const HttpMethods = HttpService.HttpMethods;
const baseURL =  process.env.REACT_APP_BACKEND_URL;
const rootRequestMapping = "filter"

const getSampleTypes = async () => {
  try {
    let language = localStorage.getItem("language");
    const res = await axios.request({
      url: `${baseURL}${rootRequestMapping}/all/${language}`,
      method: HttpMethods.GET,
    });
     
    return (res.status === 200) ? res.data : false;
  } catch (error) {
    return null;
  }
}

const getNeckFinishes = async () => {
  let language = localStorage.getItem("language");
  try {
    const res = await axios.request({
        url: `${baseURL}${rootRequestMapping}/neckFinish`,
      method: HttpMethods.GET,
    });

    console.log(res);

    return (res.status === 200) ? res.data : false;
    // return res.data
  } catch (error) {
    return null;
  }
}

const getDesigns = async () => {
  let language = localStorage.getItem("language");
  try {
    const res = await axios.request({
        url: `${baseURL}${rootRequestMapping}/design`,
      method: HttpMethods.GET,
    });

    console.log(res);

    return (res.status === 200) ? res.data : false;
    // return res.data
  } catch (error) {
    return null;
  }
}

const getSuppliers = async () => {
  let language = localStorage.getItem("language");
  try {
    const res = await axios.request({
        url: `${baseURL}${rootRequestMapping}/supplier`,
        method: HttpMethods.GET,
    });

    console.log(res);

    return (res.status === 200) ? res.data : false;
    // return res.data
  } catch (error) {
    return null;
  }
}

const getBottleSizes = async () => {
  let language = localStorage.getItem("language");
  try {
    const res = await axios.request({
        url: `${baseURL}${rootRequestMapping}/bottleSize`,
      method: HttpMethods.GET,
    });

    console.log(res);

    return (res.status === 200) ? res.data : false;
    // return res.data
  } catch (error) {
    return null;
  }
}

const getRegion = async () => {
  let language = localStorage.getItem("language");
  try {
    const res = await axios.request({
        url: `${baseURL}${rootRequestMapping}/region`,
      method: HttpMethods.GET,
    });

    console.log(res);

    return (res.status === 200) ? res.data : false;
    // return res.data
  } catch (error) {
    return null;
  }
}


export { getSampleTypes, getNeckFinishes, getBottleSizes, getDesigns, getRegion, getSuppliers }