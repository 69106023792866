/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Button, TextField, Typography } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import React, { useContext, useState, useEffect } from 'react';
import { getAllRegisteredJobs } from '../../services/RegisterJobService'
import { getIDocsInJob } from '../../services/iDocWorkQueService'
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import Paper from "@material-ui/core/Paper";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from '@material-ui/core/IconButton';
import FilledDotIcon from '@mui/icons-material/FiberManualRecord';
import OutlinedDotIcon from '@mui/icons-material/FiberManualRecordOutlined';
import FailIcon from '@mui/icons-material/Close';
import PassIcon from '@mui/icons-material/Check';
import DashIcon from '@mui/icons-material/HorizontalRule';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import OpenReportIcon from '@mui/icons-material/AssignmentOutlined';
import { getJobStates, parseDate } from './HelperFunctions';
import AssessmentIcon from '@mui/icons-material/Assessment';
import "react-table-6/react-table.css";
import { connect } from 'react-redux';


const JobsTable = (props) => {

    const classes = props.classes;
    const { t } = useTranslation();
  
    const [jobsData, setJobsData] = useState([]);
    const [rows, setRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [alertContext, setAlertContext] = useContext(AlertContext);
    const [idocsProgressDialogShow, setIdocsProgressDialogShow] = useState(false);
    const [idocRows, setIdocRows] = useState([]);
    const [dialogJob, setDialogJob] = useState("");


    // Populates the table.....
    const fetchRows = () => {
        getAllRegisteredJobs().then((data) => {
            if (!data) {
                setAlertContext({ ...alertContext, open: true });
                return;
            }
            else {
                data = data.filter((job) => { return job.jobProfile != null})
                props.dispatch({ type: "setAllJobs", payload: data })
                progressFix(data);
                setJobsData(data);
                setRows(data);
                console.log(data);
            }
        })
    }

    // Populate table data on mopunt.
    useEffect(() => {
        fetchRows();
    }, []);

    const progressFix = (res) => {
        res.forEach((r) => {
            if (!r.testsCompleted) return;
            if (!r.numberOfTests) return;
            if (r.testsCompleted > r.numberOfTests) r.numberOfTests = r.testsCompleted
        })
    }


    // Returns a coloured button whose appearance depends on the job state. It opens the relevant job.
    // Job state: received(0), in_progress(1), on_hold(2), completed_accepted(3), completed_pending(4), overdue(5), not_configured(6) //
    const getOpenButton = (row) => {

        const button = getJobStates(t)[row.state];

        return <Tooltip followCursor={true} title={t("Status") + ": " + button.text}>
            <div id={row.state}
                onClick={() => {
                    if (row !== null) {
                        props.history.push(`/testing/?testsTable=true&jobId=${row.id}`);
                    }
                }}
                style={{
                    textAlign: "center",
                    borderRadius: "5px",
                    minWidth: "100px",
                    cursor: "pointer",
                    backgroundColor: button.color,
                    padding: "5px",
                }}>
                {button.icon}
            </div>
        </Tooltip>
    }

    // A sort method for the state column of jobs table.
    const progressSort = (rowA, rowB) => {
        const a = rowA.progress
        const b = rowB.progress
        if (a > b) { return 1; }
        if (b > a) { return -1; }
        return 0;
    };


    // This gets the 'go to report' button to be displayed in the rows of the jobs table. 
    // It opens a report for the selected job.
    const getReportButton = (row) => {
        if (row.state === 0) return null;
        return <Tooltip followCursor={true} title={t("Go to") + " " + t("report")}><IconButton color="primary"
            component={Link} to={`/reports/?open=true&jobId=${row.id}`} >
            <OpenReportIcon /></IconButton></Tooltip>
    }

    // Handles search term change.
    const handleSearchChange = (event) => setSearchTerm(event.target.value);


    // Filters jobs table rows based on search term. Hooked whenever the search term changes.
    useEffect(() => {
        if (searchTerm === "" || searchTerm === null) { fetchRows(); return; }
        const res = jobsData.filter((row) => { return row.jobID.toLowerCase().startsWith(searchTerm.toLowerCase()) })
        setRows(res);
    }, [searchTerm])


    // const getTemplateNames = (row) => {
    //     var names = row.analysisTemplateNames;
    //     if ((!names) || names.length === 0) return null;
    //     if (names.length === 1) return names[0];
    //     return `${names[0]} & ${names.length - 1}  more...`
    // }

    // Gets progress bar tooltip text...
    const getTooltipText = (row) => {
        var line1 = row.testsCompleted + " " + t("out of") + " " + (row.numberOfTests === null ? "NaN" : row.numberOfTests) + " " + t("tests completed") + ".";
        var line2 = ` - ${row.testsPassed} ${t("tests passed")}.`
        var line3 = ` - ${row.testsCompleted - row.testsPassed} ${t("tests failed")} or ignored.`
        var line4 = t("Click to view job progress in Detail")
        return (<div style={{ fontSize: "12px" }}>{line1}<br />{line2}<br />{line3}<br /><br />{line4}</div>)
    }

    // A custom progress indicator for progress column....
    const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 7,
        borderRadius: 5,
        backgroundColor: theme.palette.grey[300],
    }));


    // Gets the progress for a given row...
    const getProgress = (row) => {
        var progress = Math.floor((row.testsCompleted / (row.numberOfTests === null ? 0 : row.numberOfTests)) * 100)
        if (isNaN(progress)) progress = 0;
        if (progress > 100) progress = 100;
        row.progress = progress
        return (
            <Tooltip followCursor={true} title={getTooltipText(row)}>
                <div style={{ minWidth: "100px", cursor: "pointer" }} disabled={idocsProgressDialogShow}>
                    <button style={{ border: "none", padding: "0px", cursor: "pointer", background: "none" }}
                        onClick={() => {
                            setIdocsProgressDialogShow(true)
                            setJobIdocRows(row.id)
                            setDialogJob(row)
                        }}>
                        {progress + "% " + t('complete')}
                        <CustomLinearProgress variant="determinate" value={progress} />
                    </button>
                </div>
            </Tooltip>
        )
    }

    const getState = (j) => {
        if (j === null) return ""
        return <span id={j.state} style={{
            border: "1px solid",
            backgroundColor: getJobStates(t)[j.state].color,
            padding: "2px"
        }}>{" " + getJobStates(t)[j.state].text}
        </span>
    }

    const sequenceCompare = (a, b) => {
        if (a.sequence < b.sequence) return -1;
        else if (a.sequence > b.sequence) return 1;
        else return 0;
    }

    const setJobIdocRows = (id,lang) => {
        getIDocsInJob(id,lang).then((res) => {
            if (res.req_error) {
                setAlertContext({ ...alertContext, open: true });
                return;
            }
            res.sort((a, b) => sequenceCompare(a, b));

            setIdocRows(res);
        })
    }

    const getProgressInDetail = (row) => {
        return (
            <Tooltip followCursor={true} title={"View Job progress in detail"}>
                <div id={row.jobID}
                    onClick={() => {
                        setIdocsProgressDialogShow(true)
                        setJobIdocRows(row.id,localStorage.getItem("language"))
                        setDialogJob(row);
                    }}>
                    <IconButton color="primary">
                        <AssessmentIcon />
                    </IconButton>
                </div>
            </Tooltip>
        )
    }

   

    const cols = [
        { selector: row => row.jobID !== "" ? row.jobID : "<unknown>", sortable: true, name: t("job_id_label"), key: "jobID", grow: 1 },
        { selector: row => getProgress(row), style: { padding: "0px", textAlign: "left" }, sortable: true, sortFunction: progressSort, name: t("Progress"), width: "150px", key: "progress" },
        { selector: row => row.sampleTypeName, style: { padding: "0px", textAlign: "left" }, sortable: true, name: t("sample_type_label"), width: "250px", key: "sampleTypeName" },
        { selector: row => parseDate(row.dueDate, 'DD/MM/YYYY'), style: { padding: "0px", textAlign: "left" }, sortable: true, name: t("due_date_label"), width: "200px", key: "dueDate" },
        { selector: row => row.jobProfile !== null ? row.jobProfile.name : "", style: { padding: "0px", textAlign: "left" }, sortable: false, name: t("profile_tab"), key: "profile", grow: 1 },
        { selector: row => getOpenButton(row), style: { padding: "0px", textAlign: "left" }, sortable: true, sortFunction: progressSort, name: t("Click to open"), width: "200px", key: "state" },
        { selector: row => getReportButton(row), style: { marginLeft: "-4%" }, sortable: false }
    ];

    const getCompleted = (row) => {
        var status = row.status;
        //var progress = Math.floor((row.testsCompleted / (row.numberOfTests === null ? 0 : row.numberOfTests)) * 100)
        //if (progress >= 100) status = "completed"
        switch (status) {
            case "on_hold":
                return (<FilledDotIcon style={{ color: "yellow" }} />);
                break;
            case "completed_pending": case "completed_accepted": case "completed":
                return (<FilledDotIcon style={{ color: "green" }} />);
                break;
            default:
                return (<OutlinedDotIcon style={{ color: "red" }} />);
                break;
        }
    }

    const getPassed = (row) => {
        switch (row.result) {
            case "passed":
                return (<PassIcon style={{ color: "green" }} />)
                break;
            case "rejected":
                return (<FailIcon style={{ color: "red" }} />)
                break;
            case "received": case "pending": case "removed":
                return (<DashIcon />)
                break;
            default:
                return (<DashIcon />)
                break;
        }
    }

  

    const idocCols = [
        { selector: row => row.testMethod, sortable: false, name: t("test_method_field_label"), type: "text", key: "testMethod" },
        { selector: row => getProgress(row), sortable: false, name: t("Progress"), type: "text", key: "progress" },
        { selector: row => getCompleted(row), style: { marginLeft: "1.25%" }, sortable: false, name: t("Completed"), type: "text", key: "completed" },
        { selector: row => getPassed(row), sortable: false, name: t("Passed"), type: "text", width: "120px", key: "passed" },
    ]

    return (
        <Paper className={classes.paper}>
            <Box mx={2} className={classes.tableHeader}>
                <Typography
                    className={classes.title}
                    style={{ fontWeight: 400, fontSize: "22px" }}>
                    {t("jobs_label")}
                </Typography>
                <div style={{ marginRight: "80px" }}>
                    <TextField
                        label={t("Search by Job ID")}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position={"start"}>
                                    <SearchIcon style={{ color: "grey" }} />
                                </InputAdornment>
                            )
                        }}
                        id="outlined-size-small"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        variant="outlined"
                        fullWidth
                        size="small"
                        dense={+true} />
                </div>
            </Box>
            {rows.length > 0 ?
                <DataTable
                    customStyles={{
                        table: { style: { width: "100%" } },
                        cells: {
                            style: {
                                justifyContent: 'flex-start',
                                //    marginLeft: "0px"

                            },
                        },
                        headCells: {
                            style: {
                                justifyContent: "flex-start",
								"text-align":"center",

                                fontWeight: 'bold',
                                fontSize: "14px"
                            },
                        }
                    }}
                    pagination
                    paginationPerPage={90}
                    paginationRowsPerPageOptions={[30, 60, 90, 120]}
                    striped
                    columns={cols}
                    data={rows}
                   
                    clearSelectedRows={() => { }}
                    paginationComponentOptions={{
                        rowsPerPageText: t("rows_per_page_label"),
                        rangeSeparatorText: t("range_seperator_label"),
                    }} /> : <div style={{ textAlign: "center", marginBottom: "20px" }}><p>{t("Loading table data")}...</p> <CircularProgress style={{ marginBottom: "50px" }} /></div>}

            <Dialog open={idocsProgressDialogShow} fullWidth={true} maxWidth={"md"}
                onClose={() => {
                    setIdocsProgressDialogShow(false)
                    setIdocRows([])
                    setDialogJob("")
                }}>
                <DialogTitle>
                    {t("job_id_label")}: {dialogJob.jobID}
                </DialogTitle>
                <DialogContent style={{ fontSize: "15px" }}>
                    {dialogJob.testsCompleted + " " + t("out of") + " " + (dialogJob.numberOfTests === null ? "NaN" : dialogJob.numberOfTests) + t("tests completed")}
                </DialogContent>
                <DialogContent>
                    <Paper className={classes.paper}>
                        {idocRows.length > 0 ?
                            <DataTable
                                striped
                                columns={idocCols}
                                data={idocRows}
                                noDataComponent={<div style={{ margin: "50px", fontSize: "18px" }}>No results! Will retry...</div>}
                                clearSelectedRows={() => { }}
                                paginationComponentOptions={{
                                    rowsPerPageText: t("rows_per_page_label"),
                                    rangeSeparatorText: t("range_seperator_label"),
                                }} />
                            : <div style={{ textAlign: "center", marginBottom: "20px" }}><p>{t("Loading table data")}...</p> <CircularProgress style={{ marginBottom: "50px" }} /></div>}
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined"
                        onClick={() => {
                            setIdocsProgressDialogShow(false)
                            setIdocRows([])
                            setDialogJob("")
                        }}>
                        {t("Close")}</Button>
                </DialogActions>
            </Dialog>
        </Paper>
    )
}

const mapStateToProps = (state, props) => {
    return {
        allJobs: state.allJobs,
    };
};


export default connect(mapStateToProps)(JobsTable);
