import React, { useState } from 'react';
import { LabSetUpTabContext } from "./LabSetUpTabContext";

function LabSetUpTabsContextProvider(props){
    const [dense, setDense] = useState(false);

    return (
        <LabSetUpTabContext.Provider value={[dense, setDense]}>
            {props.children}
        </LabSetUpTabContext.Provider>
    )
}

export default LabSetUpTabsContextProvider;