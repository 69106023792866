


import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import ExpandIcon from '@mui/icons-material/ChevronRight';
import CollapseIcon from '@mui/icons-material/ExpandMore';
import { useEffect } from 'react'


// Each row of the table below is wrapped in this draggable component.
// In this particular case, the table data objects must have an 'id' field.
const DragTrComponent = (props) => {


    const { children = null, rowInfo } = props;
    if (rowInfo) {
        const { original, index } = rowInfo;
        const { id } = original;
        return (
            <Draggable key={id} index={index} draggableId={"" + id}>
                {(provided, snapshot) => (
                    <div ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <ReactTable.defaultProps.TrComponent style={{ width: '100%' }}>
                            {children}
                        </ReactTable.defaultProps.TrComponent>
                    </div>
                )}
            </Draggable>
        );
    }
    else return (
        <ReactTable.defaultProps.TrComponent  >
            {children}
        </ReactTable.defaultProps.TrComponent>
    );
}


// The table body is wrapped in this droppable component.
const DropTbodyComponent = (props) => {
    const { children = null } = props;
    return (
        <Droppable droppableId="droppable">
            {(provided, snapshot) => (
                <div ref={provided.innerRef}>
                    <ReactTable.defaultProps.TbodyComponent  >
                        {children}
                    </ReactTable.defaultProps.TbodyComponent>
                </div>
            )}
        </Droppable>
    );
}



// This is the Table component which is wrappped inside a 'DragDropContext' component.
// The handler is fired when a drag & drop operation is performed.
// The handler makes a copy of the rows, re-orders them and then passes them via a callback function to the 
// parent component so that it can call a state function to update the table ( e.g. setRows() ).

const DragDropTable = (props) => {

    // 4 props passed from parent. The 'handleReorder' & ' handleDragStart' props are callback functions.
    const { rows, columns, handleReorder, handleDragStart, subComponent, defaultPageSize, showPagination, blocked} = props;

    
   

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const reorderedRows = reorder(
            rows,
            result.source.index,
            result.destination.index
        )

        let row = rows[result.source.index]

        // last two params here are optional - I needed them to make an API call with.
        handleReorder(reorderedRows, row.id, result.destination.index + 1, )  
    };


    const onDragStart = () => {
        handleDragStart();
    }

   

    return (
        <div style={{  cursor: blocked ? "wait" : "default" }}>
            <div style={{ pointerEvents: blocked ? "none" : "auto"}}>
                <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                    <ReactTable
                        TrComponent={DragTrComponent}
                        TbodyComponent={DropTbodyComponent}
                        getTrProps={(state, rowInfo) => { return { rowInfo } }}
                        data={rows}
                        columns={columns}
                        defaultPageSize={defaultPageSize ? defaultPageSize : rows.length + 1}
                        dense={true}
                        showPaginationBottom={showPagination ? showPagination : false}
                        SubComponent={subComponent ? subComponent : null}
                        className="-striped -highlight"
                    />
                </DragDropContext>
            </div>
        </div>
        
    );
}


export default DragDropTable;

