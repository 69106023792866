/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import Select, { components } from 'react-select';
import Multiselect from 'multiselect-react-dropdown';

export const SingleSelector = (props) => {

   
    const [selectedOption, setSelectedOption] = useState(null);
    const icon = props.icon
   
    const customStyles = {
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        menu: (provided, state) => ({
            ...provided,
            width: props.width ? props.width: 400,
            textAlign: "left",
            padding: 0,
        }),
        control: (styles) => ({
            ...styles,
            width: props.width ? props.width : 400,
            backgroundColor: "white"
        }),
    }

    useEffect(() => {
       
        if (props.value) setSelectedOption(props.value)
    }, props.value ? [props.value] : null)


    

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    {icon}
                </components.DropdownIndicator>
            )
        );
    };

  
    return (
        <Select styles={customStyles}
            isMulti={props.isMulti ? props.isMulti : false}
            menuPortalTarget={document.querySelector('body')}
            value={selectedOption}
            required={true}
            onInputChange={props.onInputChange ? props.onInputChange : () => { }}
            onChange={(option) => {
                setSelectedOption(option)
                props.handleSelect(option)
            }}
            components={icon ? { DropdownIndicator } : { }}
            autoComplete="off"
            options={props.options}
            placeholder={props.placeholder} />
    )
}



export const MultiSelector = (props) => {

    const styles = {
        multiselectContainer: {},
        searchBox: { width: props.width},
        chips: {
           fontSize: 12
        },
        optionContainer: {
            
            height: "130px",
            overFlowy: "auto",
            width: props.width
        }
          
    }
    const [selectedOptions, setSelectedOptions] = useState(null);
    const onSelect = (selectedList, selectedItem) => {
        setSelectedOptions(selectedList)
        props.handleSelect(selectedList)
       
    }

    const onRemove = (selectedList, removedItem) => {
        setSelectedOptions(selectedList)
        props.handleSelect(selectedList)
        
    }

    return (
        <Multiselect  style={styles}
            closeOnSelect={true}
            placeholder={"Type to find categories..."}
            menuPortalTarget={document.querySelector('body')}
            options={props.options} 
            selectedValues={selectedOptions} 
            onSelect={onSelect} 
            onRemove={onRemove} 
            displayValue="label"/>
    )
}





