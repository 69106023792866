import { makeStyles } from "@material-ui/core/styles";
import { useState, useContext } from "react";
import {
    getTestMethods,
    deleteTestMethod,
} from "../../services/TestMethodsService";
import { getProfileJobs, deleteProfileJob } from "../../services/ProfileJobsService";
// import TestMethodDialog from "../Dialogs/TestMethodDialog";
import ProfileJobDialog from "../Dialogs/ProfileJobDialog";
import { LabSetUpTabContext } from "../Contexts/LabSetUpTabs/LabSetUpTabContext";
import { useTranslation } from "react-i18next";
import TestMethodsTable from "./TestMethodsTable";
import ProfileJobsTable from "./ProfileJobsTable";
import AddIcon from "@material-ui/icons/Add";
//import { getTestsByTestParent } from "../../services/TestsService";
import TestsTable from "./TestsTable";
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    tooltipText: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
}));

function ProfileJobs(props) {
    console.log(props);
    const { text } = props;
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const { t } = useTranslation();
    const classes = useStyles();
    const [dense, setDense] = useContext(LabSetUpTabContext);
    const [alertContext, setAlertContext] = useContext(AlertContext);
    const [responseForConfirmationMessage, setResponseForConfirmationMessage] = useState();
    
    const tableProps = {
        expandableRows: text === t("profile_jobs_tab") ? true : false,
        expandableRowDisabled: (row) =>
            text === t("profile_jobs_tab") &&
                row.tests != null &&
                row.tests.length > 0
                ? false
                : true,
        expandableRowsComponent: ({ data }) => <TestsTable parentRow={data} isExpandable={true} updateTable={updateTable} />,
        expandOnRowClicked: text === t("profile_jobs_tab") ? true : false
    };

    
    // const columns = [{
    //     selector: (row) => row.name,
    //     sortable: true,
    //     name: t("profile_jobs_name_field_label"),
    //     type: "text",
    //     key: "name",
    //     // grow: 1.5,
    // },
    // {
    //     selector: (row) => row.numberOfSamples,
    //     sortable: true,
    //     name: t("sample_number_field_label"),
    //     type: "text",
    //     key: "sampleNumber",
    // },
    // {
    //     selector: (row) => <tooltip title={row.category.name}>{row.category.name}</tooltip>,
    //     sortable: true,
    //     name: "Category",
    //     type: "text",
    //     key: "category",
    // },
    // ];

    const updateTable = async (callback) => {
        let data = await getProfileJobs();
        console.log(data)

        if (!(data instanceof Array)) {
            setAlertContext({ ...alertContext, open: true });
            return;
        }

        setRows(data);
        if (callback != null) callback();
    };

    const handleDelete = async (selected) => {
        if (window.confirm('Are you sure you wish to delete ?')) {
            let ids = selected.map((item) => { return item.id })
            let promises = ids.map((id) => { return deleteProfileJob(id) })
            await Promise.all(promises)
        }
    }

    const showDeleteErrors = (errors) => {
        if (errors.length > 0) {
            errors.forEach((error) => {
                setAlertContext({ text: error, open: true });
            })    
        }
    }

    return (
        <ProfileJobsTable
            dense={dense}
            setDense={setDense}
            keyColumn={{
                key: "name",
                name: t("profile_jobs_name_field_label"),
            }}
            updateTable={updateTable}
            showDeleteErrors={showDeleteErrors}
            paginationPerPage={60}
            // Dialog={openProfileJobTestMethodDialog ? ProfileJobTestMethodDialog : ProfileJobDialog} 
            Dialog={ProfileJobDialog} 
            // openProfileJobTestMethodDialog={openProfileJobTestMethodDialog}
            // onProfileJobTestMethodDialogClose={handleCloseProfileJobTestMethodDialog}
            responseForConfirmationMessage={responseForConfirmationMessage}
            resetForConfirmationMessage={setResponseForConfirmationMessage}
            selectedRow={selectedRow}
            tableProps={tableProps}
            rows={rows}
            // columns={columns}
            text={text}
            deleteCallback={handleDelete}
        />
    );
}

export default ProfileJobs;
