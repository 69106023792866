import React from 'react';
import LabSetUpTabs from '../../Routes/LabSetUpTabs';
import LabSetUpTabsContextProvider from './LabSetUpTabsContextProvider';

function LabSetupTabsWrapper(props){
    return (
        <LabSetUpTabsContextProvider>
            <LabSetUpTabs />
        </LabSetUpTabsContextProvider>
    );
}

export default LabSetupTabsWrapper;