import { createStore } from "redux"

var savedState = sessionStorage.getItem("state");
if (savedState) savedState = JSON.parse(savedState);

const initState = savedState ? savedState : {
    newJob: {},
    analysisTemplates: [],
    jobProfiles: [],
    selectedJobProfile: null,
    jobProfileOption: true,
    user: null,
    allUsers: [],
    realmRoles: [],
    allJobs: []
}

export const reducer = (state = initState, mutator) => {

    switch (mutator.type) {

        case "setUser":
            state.user = mutator.payload;
            break;

        case "setAllUsers":
            state.allUsers = mutator.payload;
            break;

        case "setNewJob":
            state.newJob = mutator.payload;
            break;

        case "clearNewJob":
            state.newJob = {};
            break;

        case "setJobProfiles":
            if (mutator.payload instanceof Array) state.jobProfiles = mutator.payload;
            break;

        case "addJobProfile":
            state.jobProfiles.push(mutator.payload)
            break;

        case "removeJobProfile":
            state.jobProfiles = state.jobProfiles.filter(p => p.name !== mutator.payload)
            break;

        case "setSelectedJobProfile":
            state.selectedJobProfile = mutator.payload;
            break;

        case "setAnalysisTemplates":
            state.analysisTemplates = mutator.payload;
            break;

        case "setJobProfileOption":
            state.jobProfileOption = mutator.payload;
            break;

        case "setRealmRoles":
            state.realmRoles = mutator.payload;
            break;

        case "setAllJobs":
            state.allJobs = mutator.payload;
            break;

        default:
            return state;

    }

    // Saving state to session storage in case of page refresh.
    var json = JSON.stringify(state)
    sessionStorage.setItem("state", json);

    // Returning a clone of state to ensure that the changes are propagated.
    return JSON.parse(json)

}


let store = createStore(reducer);
export default store;