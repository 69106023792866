
import moment from "moment"
import RecievedIcon from '@mui/icons-material/MailOutline';
import ProgressIcon from '@mui/icons-material/Autorenew';
import OnHoldIcon from '@mui/icons-material/PauseCircleOutline';
import CompletedAcceptedIcon from '@mui/icons-material/Done';
import CompletedPendingIcon from '@mui/icons-material/HowToReg';
import OverdueIcon from '@mui/icons-material/Alarm';


export const getJobStates = (t) => {

    const states = {
        0: { text: t('state_received_label'), color: "#ADCBE5", icon: <RecievedIcon /> },
        1: { text: t('state_inProgress_label'), color: "#F7C377", icon: <ProgressIcon /> },
        2: { text: t('state_onHold_label'), color: "#F7C377", icon: <OnHoldIcon /> },
        3: { text: t('state_completedAccepted_label'), color: "#77C780", icon: <CompletedAcceptedIcon /> },
        4: { text: t('state_completedPending_label'), color: "#BEE8C4", icon: <CompletedPendingIcon /> },
        5: { text: t('state_overdue_label'), color: "#DD7677", icon: <OverdueIcon /> },
    }
    return states;
}

export const parseDate = (date, format) => {
    if (date === null) return "<unknown>"
    try {
        let rtn = null;
        let temp = moment(date).format(format)
        if (temp !== "Invalid date") rtn = temp;
        if (rtn === null || rtn === "") rtn = "<unknown>"
        return rtn;
    }
    catch (err) {
        return "<unknown>"
    }
}