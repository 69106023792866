
import { Dialog, DialogTitle, DialogActions, DialogContentText, TextField, Tooltip, Button, DialogContent } from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import IconButton from '@material-ui/core/IconButton';


const InsertCellDialog = (props) => {
  
    const [comment, setComment] = useState(null);
    const [value, setValue] = useState(null);
    const [msg, setMsg] = useState(null)
    const { t } = useTranslation();
    const [tabClicked, setTabClicked] = useState(false);
    const [valueInput, setValueInput] = useState(null);



    useEffect(() => {
        if (valueInput) valueInput.focus();
    }, [valueInput])


    useEffect(() => {
        setComment(props.comment);
        if (props.dataType === "Pass/Fail") {
            if (props.value === "fail") setValue(0);
            else setValue(1)
        }
        else setValue(props.value);
    }, [props.show])


    useEffect(() => {
        if (tabClicked === true) {
            document.getElementById("submit2").click();
        }
    }, [tabClicked])


    const handleCommentChange = (e) => {
        setComment(e.target.value);
    }

    const handleValueChange = (e) => {
        setMsg(null)
        setValue(e.target.value);
    }

    return (
        <div>

            <Dialog
                open={props.show}
                onKeyDown={(e) => {
                    if (e.key === "Escape") {
                        setTabClicked(true);
                    }
                }}
                onClose={(event, reason) => {
                    if (reason && reason == "backdropClick") return;
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                   
                </DialogTitle>
                <DialogContent>
                    <div> <b>{'Insert a result for:'}</b></div><br/>
                    <div>{props.info}</div><br />
                    <form onSubmit={
                        (e) => {
                            e.preventDefault();
                            let _tabClicked = tabClicked;
                            setTabClicked(false);
                            let parsedVal = parseFloat(value)
                            if (isNaN(parsedVal)) {
                               
                                setMsg(<p style={{ color: "red" }}>{t('Invalid value specified. It must be a number!')}</p>)
                                return;
                            }
                            let _comment = comment;
                            setComment(null)
                            setValue(null)
                            props.callback(parsedVal, _comment, _tabClicked)
                        }}>
                        <div style={{ marginBottom: "10px" }}>{t('Result value...')} {props.dataType === "Pass/Fail" ? t("( 1 = pass, 0 = Fail )"):"" }</div>
                        <TextField
                            inputProps={{ style: { padding: 10, width: 320 } }}
                           
                            id="profile name"
                            placeholder={t("Type result value...")}
                            type="text"
                            value={value}
                            variant="outlined"
                            onChange={handleValueChange}
                            inputRef={(el) => { setValueInput(el) }}
                        />
                        <div style={{ marginTop: "25px", marginBottom: "10px" }}>{t('Add comment...')}</div>
                        <TextField
                            inputProps={{ style: { padding: 10, width: 320 } }}
                           
                            id="comment"
                            placeholder={t('Type a comment...')}
                            type="text"
                            value={comment}
                            variant="outlined"
                            onChange={handleCommentChange}
                        />

                        <div style={{ marginTop: "55px", marginBottom: "10px", textAlign:"right"}}>
                           
                            <IconButton variant="contained" type="submit" id="submit2">
                               {props.okText ? props.okText : <SaveIcon />}
                            </IconButton>
                            
                            <IconButton variant="contained" onClick={() => {  props.callback(-1, "cancel_clicked") }} >
                               {props.cancelText ? props.cancelText : <CancelIcon />}
                            </IconButton>
                           
                        </div>
                        <div style={{ marginBottom: "25px" }}></div>
                        {msg}
                    </form>
                </DialogContent>
               
            </Dialog>

        </div>
    );
}

export default InsertCellDialog;