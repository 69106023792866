/* eslint-disable react-hooks/exhaustive-deps */

import {useState, useEffect } from 'react';
import {Box, Checkbox, FormControlLabel  } from "@material-ui/core";;

const ControlledCheckBox = (props) => {
        const { onCheckChange, text } = props;
        const [checked, setChecked] = useState(false);
        useEffect(() => { onCheckChange(checked, setChecked) }, [checked])
        const label = <Box component="div" fontSize={12}>{text}</Box>
        const cb = <Checkbox checked={checked} onChange={(e) => { setChecked(e.target.checked) }} />
        return <FormControlLabel label={label} control={cb} />
}

export default ControlledCheckBox;