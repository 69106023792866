/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import BarChart from '../chartTypes/BarChart';
import { graphBorders, graphBackgrounds } from '../Colors'
import { useTranslation } from 'react-i18next';


const JobReasonsChart = (props) => {
    const { data } = props;
    const [chartData, setChartData] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (data) {
            console.log(data)
            convertDataset();
        }

    }, [data])

    const convertDataset = () => {


        var labels = data.map((i) => { return i.name});
        const d = {
            labels,
            datasets: [
                {

                    data: data.map((d) => { return d.value }),
                    backgroundColor: labels.map((r, idx) => { return graphBackgrounds[idx] })
                },

            ]
        }
        setChartData(d);


    }

    return <div >
        <BarChart
            title={t("Breakdown of reasons for submission") + ":"}
            data={chartData}
            showLegend={false}
            axisLabels={{ x: t("Reason"), y: t("Count") }}
        />
    </div>

}


export default JobReasonsChart;