/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-comment-textnodes */
import { Lock, Help } from '@material-ui/icons';
import React, { useContext, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import RegisterSamplesWrapper from '../Contexts/RegisterSamples/RegisterSamplesWrapper';
import ErrorPage from '../ErrorPage';
import PrivateRoute from '../KeyCloakComponents/PrivateRoute';
import MenuLinks from '../MenuLinks';
import LabSetupTabsWrapper from '../Contexts/LabSetUpTabs/LabSetupTabWrapper';
import { useTranslation } from 'react-i18next';
import Settings from './Settings';
import ViewJob from './ViewJob';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import { Card } from '@material-ui/core';
import Testing from './Testing';
import TestByJob from './TestByJob';
import Reports from "./Reports"
import Dashboard from './Dashboard';
import Tutorials from './Tutorials'
import UserService from '../../keycloak.js';
import { connect } from 'react-redux';


const SwitchRoutes = (props) => {

   
    const [alertContext, setAlertContext] = useContext(AlertContext);
    const { t } = useTranslation();

    const handleClose = () => setAlertContext({ ...alertContext, open: false });

    useEffect(() => {
        UserService.getUserProfile().then((p) => {
           props.dispatch({ type: "setUser", payload: p })
        })
    }, [])

    
    const routeRoles = {
        dashboard:       ["ADMIN", "LAB_SUPERVISOR", 'LAB_MANAGER', 'ANALYST', 'TECHNICIAN', 'EOLAS_USER'],
        registerSamples: ["ADMIN", "LAB_SUPERVISOR", 'LAB_MANAGER', 'ANALYST', 'TECHNICIAN', 'EOLAS_USER'],
        settings:        ["ADMIN", "LAB_SUPERVISOR", 'LAB_MANAGER', 'ANALYST', 'TECHNICIAN'],
        testing:         ["ADMIN", "LAB_SUPERVISOR", 'LAB_MANAGER', 'ANALYST', 'TECHNICIAN'],
        labSetup:        ["ADMIN", "LAB_SUPERVISOR", 'LAB_MANAGER', 'EOLAS_USER'],
        reports:         ["ADMIN", "LAB_SUPERVISOR", 'LAB_MANAGER', "ANALYST"],
        testByJob:       ["ADMIN", "LAB_SUPERVISOR", 'LAB_MANAGER'],
        viewJob:         ["ADMIN", "LAB_SUPERVISOR", 'LAB_MANAGER'],
    }

    return (
        <div style={{height: "100%"}}>
            <Switch>
                <PrivateRoute path={"/"} Component={Dashboard} roles={routeRoles.dashboard} exact />
                <PrivateRoute path={"/labSetup"} Component={LabSetupTabsWrapper} roles={routeRoles.labSetup} exact />
                <PrivateRoute path={"/registerSamples"} Component={RegisterSamplesWrapper} roles={routeRoles.registerSamples} exact />
                <PrivateRoute path={"/settings"} Component={Settings} roles={routeRoles.settings} exact />
                <PrivateRoute path={"/testing"} Component={Testing} roles={routeRoles.testing} exact />
                <PrivateRoute path={"/reports"} Component={Reports} roles={routeRoles.reports} exact />
                <Route path={"/tutorials"} exact><Tutorials /></Route>

                 // 2 routes ( no longer used ? ) ===========================================================
                <PrivateRoute path={"/testByJob"} Component={TestByJob} roles={routeRoles.testByJob} exact />
                <PrivateRoute path={"/viewJob/:id"} Component={ViewJob} roles={routeRoles.viewJob} exact />
                // =============================================================================================

               
                <Route path={"/unauthorisedAccess"}>  <ErrorPage text={t("missing_permission")} Icon={Lock} /> </Route>
                <Route> <ErrorPage text={t("missing_resource")} Icon={Help} /> </Route>

               

            </Switch>
            <Snackbar open={alertContext.open} autoHideDuration={6000} onClose={handleClose}>
                <Card>
                    <Alert onClose={handleClose} severity="error">
                        {alertContext.text}
                    </Alert>
                </Card>
            </Snackbar>
        </div>
    )
}



export default connect()(SwitchRoutes);
