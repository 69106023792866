
// Entry point component for app
import { useEffect } from 'react';
import './App.css';
import Home from './components/Home';
import UserService from './keycloak';

const App = () => {

  if (!UserService.isLoggedIn()) UserService.doLogin();
  

  useEffect(() => {
      document.title = process.env.REACT_APP_NAME;
   
  }, []);
  
  return (
      <Home />
  );
}

export default App
