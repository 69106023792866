import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslation } from "react-i18next";
import { pink, yellow } from "@material-ui/core/colors";
import { Box, Button, Tooltip, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DataTable from "react-data-table-component";
import FilterDialog from "../Dialogs/FilterDialog";
import FilterListIcon from "@material-ui/icons/FilterList";
import TestDialog from "../Dialogs/TestDialog";
import OKDialog from "../Dialogs/OKDialog";
import CircularProgress from '@mui/material/CircularProgress';
import HelpVideoDialog  from '../Dialogs/HelpVideoDialog'
import { canDeleteProfileJob } from "../../services/ProfileJobsService";
import ViewProfileJobDialog from '../Dialogs/ViewProfileJobDialog';
import ViewIcon from "@material-ui/icons/Visibility";
import IconButton from '@material-ui/core/IconButton';


const customStyles = {
    
    headCells: {
        style: {
            fontSize: ".7rem"
        },
    },
    cells: {
        style: {
            fontSize: ".7rem"
        },
    },
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    paper: {
        marginBottom: theme.spacing(2),
    },

    table: {
        width: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    tableHeader: {
        display: "flex",
        alignItems: "center",
        columnGap: "1rem",
        paddingTop: "1rem",
        overflow: "visible",
        marginBottom: "0.3rem",
    },
    selectedCount: {
        flex: "2 2 90%",
    },
    title: {
        flex: "1 1",
        whiteSpace: "nowrap",
    },
    editButton: {
        backgroundColor: yellow[800],
        borderColor: yellow[800],
        color: "white",
        "&:hover": {
            backgroundColor: yellow[900],
        },
    },
    filterButton: {

        backgroundColor: pink[600],
        borderColor: pink[600],
        color: "white",
        "&:hover": {
            backgroundColor: pink[700],
        },
    },
    searchBar: {
        flex: "1 1 30%",
    },
}));

function ProfileJobsTable(props) {
    const {
        text,
        Dialog,
        tableProps,
        // openProfileJobTestMethodDialog,
        // onProfileJobTestMethodDialogClose,
        updateTable,
        showDeleteErrors,
        paginationPerPage,
        // columns,
        rows,
        selectedRow,
        deleteCallback,
        dense,
        setDense,
        keyColumn,
        parentAdd,
        parentEdit,
        selectedItem,
        useDialog,
        responseForConfirmationMessage,
        resetForConfirmationMessage
    } = props;

    const [openDialog, setOpenDialog] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [clearSelectedRows, setClearSelectedRows] = useState(false);
    const [openFilterDialog, setOpenFilterDialog] = useState(false);
    const [columnFilters, setColumnFilters] = useState({});
    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const { t } = useTranslation();
    const [openViewProfileDialog, setOpenViewProfileDialog] = useState(false);
    const [selectedViewProfile, setSelectedViewProfile] = useState(false);

    
    const columns = [{
        selector: (row) => row.name,
        sortable: true,
        name: t("profile_jobs_name_field_label"),
        type: "text",
        key: "name",
        width: "350px"
    },
    {
        selector: (row) => row.numberOfSamples,
        sortable: true,
        name: t("sample_number_field_label"),
        type: "text",
        key: "sampleNumber",
        width: "250px"
    },
    {
        selector: (row) => <tooltip title={row.category.name}>{row.category.name}</tooltip>,
        sortable: true,
        name: "Package Type",
        type: "text",
        key: "category",
        width: "350px"
    },
    {
        name: 'Actions',
        button: true,
        width: "250px",
		// cell: (row) => <button type="button" onClick={() => viewProfile(row)}>View</button>
		cell: (row) => <Tooltip followCursor={true} title={t("View")}><IconButton onClick={() => { viewProfile(row) } }> <ViewIcon /> </IconButton></Tooltip>
    },
    ];
    

    const [OKDialogState, setOKDialogState] = useState({
        shown: false, title: "", message: "", flavour: "success"
    })

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const onDialogClose = (event) => {
        if (useDialog == null) {
            setOpenDialog(false);
            setOpenEdit(false);
            if (event) handleStatusCode(event);
        }
        else {
            parentAdd[1](false);
            parentEdit[1](false);
        }
    };

    const handleStatusCode = (event) => {
        console.log("!!!!!!!!!!")
        switch (event.status) {
            case 200:
                setOKDialogState({
                    shown: true,
                    title: responseForConfirmationMessage ? "Test Saved" : text.substring(text.length - 1) === "s" ? text.substring(0, text.length - 1) + " Saved" : text + " Saved",
                    message: event.data.name + ` saved successfully`,
                    flavour: "success",
                    callback: resetMessage
                })
                break;
            default:
                setOKDialogState({
                    shown: true,
                    title: "Failure",
                    message: `failed to save, try later`,
                    flavour: "error",
                    callback: resetMessage
                });
                break;
        }
    };

    useEffect(() => {
        if (responseForConfirmationMessage) {
            onDialogClose(responseForConfirmationMessage)
        }
    }, [responseForConfirmationMessage]);

    const resetMessage = () => {
        if (responseForConfirmationMessage) {
            resetForConfirmationMessage(null);
        }
    }

    const handleFilterDialogClose = () => {
        setOpenFilterDialog(false);
    };

    const handleViewProfileDialogClose = () => {
        setOpenViewProfileDialog(false);
    };

    const handleFilterDialogOpen = () => {
        setOpenFilterDialog(true);
    };

    const handleClearSelectedRows = () => {
        setClearSelectedRows(!clearSelectedRows);
    };

    const clearOptions = () => {
        setSelected([]);
        handleClearSelectedRows();
    };

    const handleDelete = async () => {
        let profilesUsedErrorMessages = []

        for await (const item of selected){
            let res = await canDeleteProfileJob(item.id)
            
            if (res.data.length > 0) {
                let errorMessage = "You cannot delete job profile '" + item.name + "' because it is used on jobs in progress: ";

                res.data.forEach((job, idx) => {
                    errorMessage = errorMessage + job.jobID;
                    if (idx < res.data.length - 1) errorMessage += ", "
                    else errorMessage +=". "
                })

                errorMessage = errorMessage + "Please finish or delete these jobs first.";

                profilesUsedErrorMessages.push(errorMessage);
            }

        };
 

        if (profilesUsedErrorMessages.length == 0) {
            await deleteCallback(selected);
            updateTable(clearOptions);
        }
        else {
            showDeleteErrors(profilesUsedErrorMessages);
        }

        
    };

    const handleEdit = () => {
        if (useDialog == null) {
            setOpenDialog(true);
            setOpenEdit(true);
        }
        else {
            selectedItem[1](selected[0]);
            parentAdd[1](true);
            parentEdit[1](true);
        }
    };

    const handleAdd = () => {
        if (useDialog == null)
            setOpenDialog(true);
        else
            parentAdd[1](true);
    };

    const selectedItemText = () => {
        if (selected.length === 0) return "";
        if (selected.length === 1) return `1 ${t("one_row_selected")}`;
        if (selected.length > 1 && selected.length < rows.length)
            return `${selected.length} ${t("rows_selected")}`;
        if (selected.length === rows.length) return t("all_rows_selected");

        return "";
    };

    useEffect(() => {
        updateTable(clearOptions);
    }, []);

    const filterTerm = (event) => setSearchTerm(event.target.value);

    const rangeFilter = (greaterThan, value, lessThan) => {
        if (value >= greaterThan && value <= lessThan) return true;

        return false;
    };

    const viewProfile = async (row) => {
        setSelectedViewProfile(row);
        setOpenViewProfileDialog(true);
    }

    const filterRows = () => {
        let res = rows.filter((row) => {
            if (!`${row[keyColumn.key]}`.toLowerCase().includes(searchTerm.toLowerCase()))
                return false;

            let isValid = true;

            Object.keys(columnFilters).forEach((filterKey) => {
                let filter = columnFilters[filterKey];
                if (!filter.enabled) return;

                if (filter.type === "text") {
                    
                    if (!`${row[filterKey]}`.toLowerCase().includes(filter.filterValue.includes.toLowerCase())) {
                        isValid = false;
                    }
                    return;
                    
                } else if (filter.type === "numeric") {
                    if (
                        !rangeFilter(
                            filter.filterValue.greaterThan,
                            row[filterKey],
                            filter.filterValue.lessThan
                        )
                    )
                        isValid = false;
                    return;
                } else if (filter.type === "date") {
                    if (
                        !rangeFilter(
                            filter.filterValue.from,
                            new Date(row[filterKey]),
                            filter.filterValue.to
                        )
                    )
                        isValid = false;
                    return;
                }
            });

            return isValid;
        });
        return res;
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Box mx={2} className={classes.tableHeader}>
                    <Typography
                        className={classes.title}
                        style={{ fontWeight: 400, fontSize: "22px" }}
                    >
                        {text}
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', flex: '20%' }}>
                        <Tooltip title={t("add_profile_job")}>
                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={handleAdd}
                                aria-label="add"
                                startIcon={<AddIcon style={{ marginLeft: "30%" }} />}
                            >
                                {t("add_profile_job")}
                            </Button>
                        </Tooltip>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', flex: '5%' }}>
                        <Tooltip title={t("filter_label")}>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={handleFilterDialogOpen}
                                className={classes.filterButton}
                                aria-label="add"
                                startIcon={<FilterListIcon style={{ marginLeft: "30%" }} />}
                            >
                                {/* t("filter_label") */}
                            </Button>
                        </Tooltip>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', flex: '5%' }}>
                        <Tooltip title={t("Edit")}>
                            <span disabled={!(selected.length > 0)}>
                                <Button
                                    disabled={selected.length !== 1}
                                    size="small"
                                    className={classes.editButton}
                                    onClick={handleEdit}
                                    startIcon={<EditIcon style={{ marginLeft: "30%" }} />}
                                    aria-label="edit"
                                    variant="contained"
                                >
                                    {/* t("filter_label") */}
                                </Button>
                            </span>
                        </Tooltip>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', flex: '5%' }}>
                        <Tooltip title={t("Delete")}>
                            <span disabled={!(selected.length > 0)}>
                                <Button
                                    disabled={!(selected.length > 0)}
                                    size="small"
                                    onClick={handleDelete}
                                    aria-label="delete"
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<DeleteIcon style={{ marginLeft: "30%" }} />}
                                >
                                    {/* t("delete_label") */}
                                </Button>
                            </span>
                        </Tooltip>
                    </div>

                    <HelpVideoDialog topic={text} />

                    <Typography
                        className={classes.selectedCount}
                        style={{ color: "grey", fontSize: "16px" }}
                    >
                        {selectedItemText()}
                    </Typography>
                    <div className={classes.searchBar}>
                        <TextField
                            label={`${t("search_label")} ${keyColumn.name}`}
                            id="outlined-size-small"
                            value={searchTerm}
                            onChange={filterTerm}
                            variant="outlined"
                            fullWidth
                            size="small"
                            dense
                        />
                    </div>
                   
                </Box>

                <DataTable
                    selectableRows
                    pagination
                    // openDialog={
                    //     (<Dialog />).type === (<ProfileJobTestMethodDialog />).type
                    //         ? openProfileJobTestMethodDialog
                    //         : openDialog
                    // }
                    openDialog={openDialog}
                    paginationPerPage={
                        typeof paginationPerPage !== 'undefined'
                            ? paginationPerPage
                            : 90
                    }
                    customStyles={customStyles}
                    striped
                    {...tableProps}
                    onSelectedRowsChange={(e) => setSelected(e.selectedRows)}
                    columns={columns}
                    data={filterRows()}
                    dense={dense}
                    noDataComponent={<div style={{ textAlign: "center", marginBottom: "20px" }}><p>Loading table data...</p> <CircularProgress style={{ marginBottom: "50px" }} /></div>}
                    paginationRowsPerPageOptions={[30, 60, 90, 120]}
                    clearSelectedRows={clearSelectedRows}
                    paginationComponentOptions={{
                        rowsPerPageText: t("rows_per_page_label"),
                        rangeSeparatorText: t("range_seperator_label"),
                    }}
                />

            </Paper>

            <Dialog
                // openDialog={
                //     (<Dialog />).type === (<ProfileJobTestMethodDialog />).type
                //         ? openProfileJobTestMethodDialog
                //         : openDialog
                // }
                // onDialogClose={
                //     (<Dialog />).type === (<ProfileJobTestMethodDialog />).type
                //         ? onProfileJobTestMethodDialogClose
                //         : onDialogClose
                // }
                openDialog={openDialog}
                onDialogClose={onDialogClose}
                selectedItems={selected[0]}
                viewing={null}
                parentRow={selectedRow}
                openEdit={openEdit}
                updateTable={() => updateTable(clearOptions)}
            />
            <FilterDialog
                openDialog={openFilterDialog}
                columns={columns}
                handleFilterDialogClose={handleFilterDialogClose}
                keyColumn={keyColumn}
                setColumnFilters={setColumnFilters}
            />
            <ViewProfileJobDialog
                openDialog={openViewProfileDialog}
                onDialogClose={handleViewProfileDialogClose}
                selectedRow={selectedViewProfile}
            />
            <OKDialog
                show={OKDialogState.shown}
                title={OKDialogState.title}
                message={OKDialogState.message}
                flavour={OKDialogState.flavour}
                callback={
                    (res) => {
                        let callback = OKDialogState.callback;
                        setOKDialogState({ shown: false });
                        if (callback) callback(res);
                    }
                }
            />
         
        </div>
    );
}

export default ProfileJobsTable;
