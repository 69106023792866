import React, { useState, useEffect } from "react";
import { getTestsByIdocId, getTestResultsByIdocId } from "../../services/iDocSessionsService"
import { useStyles } from "./styles"
import { Checkbox, Paper, CircularProgress} from '@material-ui/core';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

const RetestTable = (props) => {
    const { t } = useTranslation();
    
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false)

    const checkedIds = props.checkedIds;
    const setCheckedIds = props.setCheckedIds;

    const handleChange = (e, id) => {
        let temp = JSON.parse(JSON.stringify(checkedIds))
        let checked = e.target.checked
        let temp2 = temp.find((_id) => { return _id === id })
        if (checked === true && !temp2) {
            temp.push(id)
           
        }
        else if (checked === false && temp2) {
            temp = temp.filter((_id) => { return _id !== id; })
        }
        setCheckedIds(temp)
    }

    const getChecked = (id) => {
        let temp = checkedIds.find((_id) => { return _id === id })
        if (temp) return true;
        return false;
    }

    const getCheckBox = (row) => {
        return <Checkbox checked={getChecked(row.testResultId)} onChange={(e) => { handleChange(e, row.testResultId) }}/>
    }

    const cols = [
        
       // { selector: row => row.testMethod, sortable: true, name: "Test Method Name", type: "text", key: "tmn" },
        
        { selector: row => row.sampleId, sortable: true, name: "Sample ID", type: "text", key: "sampleId" },
        { selector: row => row.testName, sortable: true, name: "Test", type: "text", key: "test" },
        { selector: (row) => { return <span style={{ color: "red" }}>{row.value}</span> }, sortable: true, name: "Test Result", type: "text", key: "result" },
        { selector: row => getCheckBox(row), sortable: true, name: "Submit for Retest", type: "text", key: "cb" }
    ]


    useEffect(() => {
        populateTable();
    }, [])


    useEffect(() => {
        if (selectAllChecked === true) setCheckedIds (rows.map((row) => { return row.testResultId }))
        else setCheckedIds([]);
    }, [selectAllChecked])



    const populateTable = () => {
        const iDoc = props.iDoc

        setRows([])

        getTestsByIdocId(iDoc.id,localStorage.getItem("language")).then((data) => {
            let headers = null;
            if (!data.req_error) {
                headers = data.map((header) => { return header.name })
            }

            getTestResultsByIdocId(iDoc.id).then((trs) => {
                if (!trs.req_error) {
                    console.log(trs)
                    trs.forEach((tr) => {
                        let sampleId = tr.sample.uniqueId;
                        tr.results.forEach((r, i) => {

                            let temp = {
                                value: r.value,
                                testMethod: iDoc.testMethod,
                                testName: headers && headers[i] ? headers[i] : "",
                                sampleId: sampleId,
                                testResultId: r.testResultId
                            }
                            setRows(oldval => oldval.concat(temp))

                        })
                    });
                }

            })
        })

    }




    useEffect(() => {
     //   alert(JSON.stringify(rows, null, 2))
    }, [rows])


    return <Paper className={classes.paper}>
       
        {rows.length > 0 ?
            <React.Fragment>
                <div style={{ textAlign: "right", margin: "15px", }}>
                    <Checkbox checked={selectAllChecked} onChange={() => {
                        setSelectAllChecked(oldVal => !oldVal)
                    }} /> Toggle Select All
                </div>
            <DataTable id="repportsClosed"
                customStyles={{
                    headCells: {
                        style: {
                            fontWeight: 'bold',
                            fontSize: "15px"
                        },
                    }
                }}
                pagination
                striped
                noDataComponent="No failed tests to show..."
                columns={cols}
                data={rows}
                dense={true}
                clearSelectedRows={() => { }}
                /> </React.Fragment>:
            <div style={{ textAlign: "center", marginTop: "20px" }}><p style={{ fontSize: "18px", marginTop: "20px"}}>{t("Loading table data")}...</p> <CircularProgress style={{ marginBottom: "50px" }} /></div>
            
        }
        

    </Paper>

}

export default RetestTable;