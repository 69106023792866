import { makeStyles } from "@material-ui/core/styles";
import CategoryDialog from '../Dialogs/CategoryDialog';
import { useState, useContext } from 'react';
import { LabSetUpTabContext } from '../Contexts/LabSetUpTabs/LabSetUpTabContext';
import { getCategories, deleteCategory, canDeleteCategory} from '../../services/CategoryService';
import { useTranslation } from 'react-i18next';
import GenericTable from '../GenericTable';
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import { Tooltip, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tooltipText: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));

function Category(props) {
  const { text } = props;
  const [rows, setRows] = useState([]);
  const { t } = useTranslation();
  const classes = useStyles();
  const [dense, setDense] = useContext(LabSetUpTabContext);
  const [alertContext, setAlertContext] = useContext(AlertContext);

    // (<Tooltip title={row.name}><Typography className={classes.tooltipText}>{}</Typography></Tooltip>)
  const columns = [
    { selector: (row) => row.name, sortable: true, name: t("category_name_field_label"), type: "text", key: "name" },
    { selector: (row) => row.description, sortable: true, name: t("description_field_label"), type: "text", key: "description" },
    { selector: (row) => row.shortCode, sortable: true, name: t("shortCode_field_label"), type: "text", key: "shortCode" }
  ];

  const updateTable = async callback => {
    let data = await getCategories(localStorage.getItem("language"));

    if(!(data instanceof Array)){
      setAlertContext({...alertContext, open: true});
      return;
    }
    setRows(data);

    if (callback !== undefined) {
      callback();
    }
  }

 // const handleDelete = async (selected) => {
	
	//if (window.confirm('Are you sure you wish to delete ?'))
	//{
	//    for (var id in selected) 
	//	{
	//	      await deleteCategory(selected[id].id);
	//    }
	//}
 // }

    const showDeleteErrors = (error) => {
      if (error.length > 0) {
          setAlertContext({ text: error, open: true });
      }
    }

    const handleDelete = async (selected) => {
      if (window.confirm('Are you sure you wish to delete ?')) {
        await selected.forEach((category) => {
          let id = category.id;
          let errorMessage = "";
          canDeleteCategory(id).then((res) => {
            if (res.data.length > 0) {  
              errorMessage = t("category_delete_error_message_1") + category.name + t("category_delete_error_message_2");
              
              res.data.forEach((testMethod) => {
                errorMessage = errorMessage + testMethod.id + ", ";
              });

              errorMessage = errorMessage.slice(0, -2); // this is to remove the last comma and space added from the previous forEach loop
  
              errorMessage = errorMessage + t("category_delete_error_message_3");
    
            }
  
            if (errorMessage.length === 0) {
              deleteCategory(id).then(() => {
                updateTable();
              });
            }
            else {
              showDeleteErrors(errorMessage);
            }
          });
          errorMessage = "";
        });
      }
    }

  return <GenericTable
    dense={dense}
    keyColumn={{
      key: "name",
      name: t("category_name_field_label")
    }}
    setDense={setDense}
    updateTable={updateTable}
    Dialog={CategoryDialog}
    rows={rows}
    columns={columns}
    text={text}
    createJobText={`${t("add")} ${t('category_label')}`}
    deleteCallback={handleDelete} />;
}

export default Category;
