/* eslint-disable react-hooks/exhaustive-deps */

import {
    Box,
    Button,
    Card,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
} from "@material-ui/core";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { addJob, savejobStage, getJobById, savejobPage1 } from "../../services/RegisterJobService"
import AddJobPageOne from "./AddJobPageOne";
import ApplyJobProfile from "./ApplyJobProfile";
import OKDialog from "../Dialogs/OKDialog";
import OKCancelDialog from "../Dialogs/OKCancelDialog";
import CircularProgress from '@mui/material/CircularProgress';
import EventListener from '../../services/EndpointListener';


const useStyles = makeStyles({
    footer: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    filler: {
        flexGrow: 1,
    },
    navBtn: {
        marginLeft: "15px",
    }
});

 


const AddEditJob = (props) => {

    const {
        openAdd,
        openEdit,
        setOpenAdd,
        setOpenEdit,
        selectedItem,
        //updateTable,
        rows,
        setRows
    } = props;

    const get4weeksFromNow = () => {
        let now = new Date();
        now.setDate(now.getDate() + 28);
        return now;
    }

    const emptyJob = {
        version: null,
        createdBy: null,
        active: true,
        id: null,
        numberOfTests: null,
        testsCompleted: 0,
        testsPassed: 0,
        status: null,
        jobID: "",
        sampleTypeName: "",
        dueDate: get4weeksFromNow(),
        analysisTemplateNames: [],
        state: null,
        addDate: new Date(),
        sampleCount: 0,
        sampleType: {},
        client: {},
        sampleIDs: [],
        reason: null,
        notes: "",
        analysisTemplates: [],
        lastUpdate: null,
    }

    const [page, setPage] = React.useState(0);
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(true)
    const classes = useStyles();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [job, setJob] = useState(emptyJob)
    const [saveMsg, setSaveMsg] = useState("Saving job...")
   

    const getSavingMsg = (msg) => {
        const spinner = <div style={{ marginTop: "20px" }}>
            <span style={{ marginRight: "15px", fontSize: "large" }}>{msg}</span>
            <CircularProgress size="1.5rem" />
        </div>
        return spinner;
    }

    useEffect(() => {
        if (openEdit) {
            if (selectedItem && selectedItem.id) {
                getJobById(selectedItem.id).then((res) => {
                    if (res) {
                        setJob(res)
                    }
                })
            }
        }
    }, [openEdit])

 

    const [OKDialogState, setOKDialogState] = useState({
        shown: false, title: "", message: "", flavour: "success"
    })

    const [OKCancelDialogState, setOKCancelDialogState] = useState({
        shown: false, title: "", message: "", flavour: "success"
    })


    const revisitPage1 = () => { setPage(1); }


    const validateFields = () => {
        var j = job

        const isObjectEmpty = (obj) => {
            return Object.keys(obj).length === 0
        }

        if (!j.jobID || j.jobID === "") {
            setOKDialogState({
                shown: true, title: t("Empty ID field!"),
                message: t(`You need to add a Job ID!`),
                flavour: "error", callback: revisitPage1
            })
            return false;
        }

        if (!j.client || isObjectEmpty(j.client)) {
            setOKDialogState({
                shown: true, title: t("Empty client field!"),
                message: page === 1 ? t("You need to specify a client!") :
                    t("You need to specify a client!") + " (" + t('Job Details Section') + ')',
                flavour: "error", callback: revisitPage1
            })
            return false;
        }
        if (!j.department || isObjectEmpty(j.department)) {
            setOKDialogState({
                shown: true, title: t("Empty department field!"),
                message: page === 1 ? t("You need to specify a department (NEW)!") :
                    t("You need to specify a department!") + " (" + t('Job Details Section') + ')',
                flavour: "error", callback: revisitPage1
            })
            return false;
        }

        if (!j.sampleType || isObjectEmpty(j.sampleType)) {
            setOKDialogState({
                shown: true, title: t("Empty Sample Type field!"),
                message: page === 1 ? t(`You need to specify a sample type!`) :
                    t(`You need to specify a sample type!`) + " (" + t('Job Details Section') + ')',
                flavour: "error", callback: revisitPage1
            })
            return false;
        }

        if (!j.sampleCount || j.sampleCount < 1) {
            setOKDialogState({
                shown: true, title: t("Sample count field!"),
                message: page === 1 ? t(`You need to specify a sample count of more than zero!`) :
                    t(`You need to specify a sample count of more than zero!`) + " (" + t('Job Details Section') + ')',
                flavour: "error", callback: revisitPage1
            })
            return false;
        }

        if (!j.reason || isObjectEmpty(j.reason)) {
            setOKDialogState({
                shown: true, title: t("Reason for submission field is empty!"),
                message: page === 1 ? t(`You need to select a submission reason from the dropdown!`) :
                    t("You need to select a submission reason in the Job Details section!") + " (" + t('Job Details Section') + ')',
                flavour: "error", callback: revisitPage1
            })
            return false;
        }

        return true;
    }



    const getSampleIDs = (job) => {
        if (job && job.sampleIDs) {
            if (job.sampleIDs.length === 1) return `${job.sampleIDs[0]}`
            if (job.sampleIDs.length === 2) return `${job.sampleIDs[0]}, ${job.sampleIDs[1]}`
            else if (job.sampleIDs.length > 2) return `${job.sampleIDs[0]}, ..., ${job.sampleIDs[job.sampleIDs.length - 1]}`
            else return "";
        }

    };


    const cleanUpJSON = () => {
        var j = JSON.parse(JSON.stringify(job));
        j.sampleIDs = getSampleIDs(j);
        delete j.jobChanged;
        if (j.tests == null) delete j.tests;
        return j;
    }

    

    const updateTable = (j) => {
        if (j.id) {
            var temp = rows.filter((r) => { return r.id !== j.id })
            temp.push(j)
            temp.sort((x, y) => {
                var a = x.addDate;
                var b = y.addDate;
                return a < b ? 1 : a > b ? -1 : 0;
            })
            setRows(temp)
            return
        }
    }
    

    //EventListener.listen({

    //    url: `${process.env.REACT_APP_BACKEND_URL}registerJob/savejobasyncstream`,
    //    httpMethod: "POST",
    //    contentType: "application/json",
    //    data: j,

    //    responseCallback: (res) => {
    //        console.log(JSON.stringify(res, null, 2))
    //        if (res.status !== 200) alert("Job couldn't be saved! Back-end error!");
    //    },

    //    messageCallback: (message) => {
    //        if (message === "completed") {
    //            setSaving(false);
    //            setSaveMsg("Saving job...")
    //            setOpenEdit(false);
    //            setOpenAdd(false);
    //        }
    //        else {
    //            setSaveMsg(message)
    //            console.log(message);
    //        }
    //    }
    //})


    //addJob(j).then((res) => {
                //    setSaving(false);
                //    if (!res) alert("Job couldn't be saved! Back-end error!");
                //    updateTable( res);
                //    setOpenEdit(false);
                //    setOpenAdd(false);
                //})




    



    const saveJob = async (goToProfilePage) => {
       
        let j = cleanUpJSON();
        
        setSaving(true);
        if (page === 1) {
            var res = await savejobPage1(j);
            setSaving(false);
            if (!res) {
                alert("Job couldn't be saved! Back-end error!");
                return;
            }
          //  console.log(res);
            if (goToProfilePage) {
                setJob(res);
                setPage(2);
            }
            else {
                updateTable(res);
                setOpenEdit(false);
                setOpenAdd(false);
            }
        }
        else {
            console.log(j);
            var res2 = await addJob(j);
            setSaving(false);
            if (!res2) {
                alert("Job couldn't be saved! Back-end error!");
                return;

            }
            updateTable(res2);
            setOpenEdit(false);
            setOpenAdd(false);
        }
    }


    const handleSaveClick = async (goToProfilePage) => {
        if (validateFields()) {
            setSaveBtnDisabled(true)
            await saveJob(goToProfilePage);
        } 
    };


    const close = () => {
        setOpenEdit(false);
        setOpenAdd(false);
    }

    const handleClose = () => {
        
        var j = job
        if (j && j.jobChanged === true) {
            setOKCancelDialogState({
                shown: true,
                title: t("Are you sure?"),
                okText: "Yes",
                cancelText: "No",
                message: t(`Discard unsaved changes and cancel?`),
                flavour: "warning",
                callback: (res) => { if (res === "OK") { close() } }
            })
        }
        else {
            close();
        }
    }

   
    return (
        <div>
        <Box>
            <Card style={{padding:"15px"}}>
                <DialogTitle id="form-dialog-title">{openEdit ? t('Edit Job') : t("create_job")}</DialogTitle>
                <DialogContent>
                        {job && 
                            <>
                            <AddJobPageOne
                                shown={page === 1}
                                job={job}
                                setJob={setJob}
                                setSaveBtnDisabled={setSaveBtnDisabled}
                                page={page}
                                setPage={setPage}
                            />

                            <ApplyJobProfile
                                shown={page === 2}
                                job={job}
                                setJob={setJob}
                                openEdit={openEdit}
                                setSaveBtnDisabled={setSaveBtnDisabled} />
                            </>
                      }
                </DialogContent>
                <DialogActions>
                    <div className={classes.footer}>
                        <div className={classes.filler} />
                            
                            {saving ? getSavingMsg(saveMsg) : <>
                                {page === 2 && <Button className={classes.navBtn} onClick={revisitPage1} variant="contained" > {t("back")}</Button>}
                                {page === 1 && <Button
                                    className={classes.navBtn}
                                    onClick={() => { handleSaveClick(false) }}
                                    disabled={saveBtnDisabled}
                                    variant="contained" > {"save core job details and exit"}</Button>}
                                <Button
                                    className={classes.navBtn}
                                    onClick={() => { handleSaveClick(true) }}
                                    type="submit" variant="contained"
                                    disabled={saveBtnDisabled}>
                                    {page === 2 ? t("Save and Finish") : openEdit ? t("next - edit profile details") : t("next - add a job profile")}
                                </Button>
                                <Button className={classes.navBtn} onClick={handleClose} variant="contained" > {t("cancel")}</Button>
                            </>}
                 
                    </div>
                </DialogActions>
            </Card>
         </Box>
        


         <OKDialog
                show={OKDialogState.shown}
                title={OKDialogState.title}
                message={OKDialogState.message}
                flavour={OKDialogState.flavour}
                callback={
                    (res) => {
                        let callback = OKDialogState.callback;
                        setOKDialogState({ shown: false });
                        if (callback) callback(res);
                    }
                }
            />

            <OKCancelDialog
                show={OKCancelDialogState.shown}
                title={OKCancelDialogState.title}
                message={OKCancelDialogState.message}
                flavour={OKCancelDialogState.flavour}
                okText={OKCancelDialogState.okText}
                cancelText={OKCancelDialogState.cancelText }
                callback={
                    (res) => {
                        OKCancelDialogState.callback(res)
                        setOKCancelDialogState({ ...OKCancelDialogState, shown: false });
                    }
                }
            />
       
        </div>
    );
}


export default AddEditJob;





