
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react'
import JobsCharts from './JobsCharts'
import TestsCharts from './TestsCharts'
import SampleTypesCharts from './SampleTypesCharts'
import { useTranslation } from 'react-i18next';

import Filters from './Filters';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other} >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const AnalyticsPage = (props) => {
    const filterChoice = {
        category: false,
        neckFinish: false,
        design: false,
        bottleSize: false,
        supplier: false,
        region: false,
        fromDate: false,
        toDate: false,
    };

    const { t } = useTranslation();

    const [tabValue, setTabValue] = useState(0);

    const [selectedFiltersString, setSelectedFiltersString] = useState(JSON.stringify({
        category: null,
        neckFinish: null,
        design: null,
        bottleSize: null,
        supplier: null,
        region: null,
        fromDate: null,
        toDate: null,
    }));


    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const filterCallback = (filterValues) => {
        console.log(filterValues);
        setSelectedFiltersString(JSON.stringify(filterValues));
    }

    return (
        <div>
            <div style={{ marginBottom: "15px" }}>{t("You may filter by category and / or select a date interval")}:</div>
            

            <Filters filterChoice={filterChoice} filterCallback={filterCallback}></Filters>

            { true &&

                <div style={{marginTop:"20px"}}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} textColor="secondary" indicatorColor="secondary" onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab label={t("jobs_label")} />
                            <Tab label={t("tests_field_label")} />
                            <Tab label={t("sample_type_tab")} />
                        </Tabs>
                    </Box>

                    <TabPanel value={tabValue} index={0}>
                       <JobsCharts selectedFiltersString={selectedFiltersString}/>
                    </TabPanel>

                    <TabPanel value={tabValue} index={1}>
                       <TestsCharts selectedFiltersString={selectedFiltersString}/>
                </TabPanel>

                <TabPanel value={tabValue} index={2}>
                    <SampleTypesCharts selectedFiltersString={selectedFiltersString}/>
                </TabPanel>

                </div>
            }
        </div>
        
    )

}
export default AnalyticsPage;


//selectedCategory.value && TS1 && TS2 && (TS2 > TS1)