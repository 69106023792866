/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */


import React, { useState, useEffect  } from 'react';
import { Typography  } from '@material-ui/core';
import SubsIcon from '@mui/icons-material/Assignment';
import RPetIcon from '@mui/icons-material/Recycling';
import MRuleIcon from '@mui/icons-material/Language';
import { getJobCountPerYear, getJobCountByCat, getJobsByCat, getJobsperRegion } from '../../services/RegisterJobService';
import { getCategories } from '../../services/CategoryService'
import PunctualityChart  from './KPICharts/PunctualityChart'
import JobsPerMonthChart from './KPICharts/JobsPerMonthChart'
import JobsPerRegionChart from './KPICharts/JobsPerRegionChart'
import JobReasonsChart from './KPICharts/JobReasonsChart'
import JobsByCategoryChart from './KPICharts/JobsByCategoryChart'
import { getAnalyticsData, getMRule, getTestsCount, getSamplesReceived } from '../../services/DashboardService'
import TestsCompletedIcon from '@mui/icons-material/Grading';
import ReceivedIcon from '@mui/icons-material/CallReceived';



const KPIDashboard = (props) => {

    const [totalSubs, setTotalSubs] = useState(0)
    const [totalPET, setTotalPET] = useState(0)
    const [totalMRule, setTotalMRule] = useState(0)
    const [totalSamples, setTotalSamples] = useState(0)
    const [totalTestsCompleted, setTotalTestsCompleted] = useState(0)

    const [punctualityData, setPunctualityData] = useState(null)
    const [jobsPerMonthData, setJobsPerMonthData] = useState(null);
    const [jobsPerRegionData, setJobsPerRegionData] = useState(null);
    const [jobReasonsData, setJobReasonsData] = useState(null);
    const [jobsByCategoryData, setJobsByCategoryData] = useState(null);

    const currentDate = new Date();
    const [year, setYear] = useState(currentDate.getFullYear());
    


    useEffect(() => {
        if (year)  fetchData(year) 
    }, [year])
    

    const fetchData = () => {
        //setTotalSubs(0)
        //setTotalPET(0)
        //setTotalMRule(0)
        //setTotalSamples(0)
        //setTotalTestsCompleted(0)

        setJobsByCategoryData({})
        setJobsPerRegionData({})
        setPunctualityData(null)
        setJobsPerMonthData(null)
        setJobReasonsData(null)

        getJobCountPerYear(year).then((res) => {
            setTotalSubs(res ? res : 0);
        })

        getMRule(year).then((res) => {
            setTotalMRule(res.value ? res.value : 0)
        })

        getTestsCount(year).then((res) => {
            setTotalTestsCompleted(res.value ? res.value : 0)
        })

        getSamplesReceived(year).then((res) => {
            console.log(res)
            setTotalSamples(res.value ? res.value : 0)
        })

        getCategories().then((categories) => {

            if (categories) {

                var promises1 = []
                var promises2 = []
                var promises3 = [];

                var PETCats = categories.filter((c) => { return c.name.includes("PET") })

                PETCats.forEach((c) => {
                    promises1.push(getJobCountByCat(year, c.id))
                    promises2.push(getJobsByCat(year, c.id))
                })

                Promise.all(promises1).then((res) => {
                    var count = 0;
                    res.forEach((c) => { if (c || c === 0) count += c })
                    setTotalPET(count)
                })

                var data = {}
                Promise.all(promises2).then((res) => {
                    res.forEach((c) => {
                        if (c) {
                            c.forEach((job, idx) => {
                                var j = { addDate: job.addDate, signOffDate: job.signOffDate }
                                var cat = job.sampleType.category.name;
                                if (!data[cat]) data[cat] = [j];
                                else data[cat].push(j);
                            })
                        }
                    })

                    const jobIsLate = (job) => {
                        var twentyEightDays = 28 * 24 * 60 * 60 * 1000;
                        var dueDate = job.addDate + twentyEightDays;
                        if (!job.signOffDate) {
                            var now = new Date().getTime();
                            if (now > dueDate) return true;
                        }
                        else {
                            if (job.signOffDate > dueDate) return true;
                        }
                        return false;
                    }

                    const result = {}
                    Object.keys(data).forEach((cat) => {
                        var jobs = data[cat];
                        var onTimeCount = 0;
                        var lateCount = 0;
                        jobs.forEach((job) => {
                            if (jobIsLate(job)) lateCount++;
                            else onTimeCount++;
                        })
                        var percentLate = (lateCount / jobs.length) * 100;
                        var percentOnTime = (onTimeCount / jobs.length) * 100;
                        result[cat] = { percentLate, percentOnTime }
                    })

                    console.log(JSON.stringify(result, null, 2))
                    setPunctualityData(result);
                })


                categories.forEach((c) => {
                    promises3.push(getJobsByCat(year, c.id))
                })

                var data2 = {}
                Promise.all(promises3).then((res) => {
                    res.forEach((a) => {
                        if (a != null && a.length > 0) {
                            var key = a[0].sampleType.category.name
                            data2[key] = a.length;
                        }
                    })
                    setJobsByCategoryData(data2)
                })
                
            }
        })


        getAnalyticsData({
            analytic: 0,
            category: null,
            dateFrom: new Date(year, 0, 1).getTime(),
            dateTo: null
        }).then((res) => {

            // Fill in months where count is zero...

            if (res) {
                res = res.map((r) => {
                    return {
                        month: parseInt(r.name.substring(0, 2)),
                        value: r.value
                    }
                })

                for (var i = 1; i <= 12; i++) {
                    if (!res.find((r) => { return r.month === i })) {
                        res.push({ month: i, value: 0 })
                    }
                }

                res.sort((a, b) => {
                    if (a.month < b.month) return -1;
                    else if (a.month > b.month) return 1;
                    return 0;
                });

                setJobsPerMonthData(res.map((r) => { return r.value }));
            }
        })


        getJobsperRegion(year).then((res) => {
            console.log(JSON.stringify(res, null, 2))
            if (res) {
                Object.keys(res).forEach((k) => {
                    if (res[k] === 0) delete res[k];
                })
                setJobsPerRegionData(res);
            }
        })


        getAnalyticsData({
            analytic: 3,
            category: null,
            dateFrom: new Date(year, 0, 1).getTime(),
            dateTo: null
        }).then((res) => {
            if (res) {
                res = res.filter((r) => { return r.name && r.name.length > 0 })
                setJobReasonsData(res);
            }
        })


    }

   
    useEffect(() => {}, [totalSubs])

  

    return <div>

        <Typography style={{ fontWeight: 550, fontSize: "30px", textAlign: "center" }}>
            {"KPI Dashboard"}, {year}
            <div>
                <span><button onClick={() => { setYear(year - 1) }}>{"< previous year"}</button></span>
                {year !== currentDate.getFullYear() && <span><button onClick={() => { setYear( year + 1) }}>{" next year >"}</button></span>}
            </div>
         
        </Typography>



        <div style={{ display: "flex" }}>

            <div style={{ flex: 1, backgroundColor: "#58ADD280", margin: "5px", padding: "10px", textAlign: "center" }}>
                <SubsIcon />
                <div style={{ fontSize: "large" }}><b>{totalSubs}</b></div>
                <div><b>{"GTPR test datapack submissions"} </b></div>
            </div>

            <div style={{ flex: 1, backgroundColor: "#58ADD280", margin: "5px", padding: "10px", textAlign: "center" }}>
                <RPetIcon />
                <div style={{ fontSize: "large" }}><b>{totalPET}</b></div>
                <div><b>{"submissions of PET category "} </b></div>
            </div>

            <div style={{ flex: 1, backgroundColor: "#58ADD280", margin: "5px", padding: "10px", textAlign: "center" }}>
                <MRuleIcon />
                <div style={{ fontSize: "large" }}><b>{totalMRule}</b></div>
                <div><b>{"MRule-CO2 simulations"} </b></div>
            </div>

            <div style={{ flex: 1, backgroundColor: "#58ADD280", margin: "5px", padding: "10px", textAlign: "center" }}>
                <ReceivedIcon />
                <div style={{ fontSize: "large" }}><b>{totalSamples}</b></div>
                <div><b>{"samples received"} </b></div>
            </div>

            <div style={{ flex: 1, backgroundColor: "#58ADD280", margin: "5px", padding: "10px", textAlign: "center" }}>
                <TestsCompletedIcon />
                <div style={{ fontSize: "large" }}><b>{totalTestsCompleted}</b></div>
                <div><b>{"tests completed"} </b></div>
            </div>
           
        </div>




        <div style={{ display: "flex", marginTop:"30px" }}>
            <div style={{ flex: 1, maxWidth: "45%", margin: "15px"}}> <PunctualityChart data={punctualityData} /></div>
            <div style={{ flex: 1, maxWidth: "45%", margin: "15px"}}>  <JobsPerMonthChart data={jobsPerMonthData} /></div>
        </div>
       
      
        <div style={{ display: "flex" }}>
            <div style={{ flex: 1,  maxWidth: "45%", margin: "15px"}}><JobsPerRegionChart data={jobsPerRegionData} /></div>
            <div style={{ flex: 1,  maxWidth: "45%", margin: "15px"}}><JobsByCategoryChart data={jobsByCategoryData} /></div>
        </div>

        <div style={{ display: "flex" }}>
            <div style={{ flex: 1, maxWidth: "45%", margin: "15px"}}>  <JobReasonsChart data={jobReasonsData} /></div>
            <div style={{ flex: 1, maxWidth: "45%", margin: "15px"}}></div>
        </div>
      
        
       
    </div>

}

export default KPIDashboard; 