import { makeStyles } from "@material-ui/core/styles";
import PersonnelDialog from '../Dialogs/PersonnelDialog';
import { useState, useContext, useEffect } from 'react';
import { LabSetUpTabContext } from '../Contexts/LabSetUpTabs/LabSetUpTabContext';
//import { getDepartments } from '../../services/DepartmentsService';
import { useTranslation } from 'react-i18next';
import GenericTable from '../GenericTable';
//import { deletePersonnel, getPersonnel } from '../../services/PersonnelService';
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
//import { Tooltip, Typography } from '@material-ui/core';
import { getAPItoken, getRealmUsers, getUserRoles, getAllRealmRoles } from '../../services/KeycloakAdminAPI'
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    tooltipText: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
}));


const  Personnel = (props) => {
    const { text, dispatch } = props;
    const [rows, setRows] = useState([]);
    const { t } = useTranslation();
    const classes = useStyles();
    const [dense, setDense] = useContext(LabSetUpTabContext);
    const [realmRoles, setRealmRoles] = useState([])
    const [alertContext, setAlertContext] = useContext(AlertContext);


    useEffect(() => {
        getAllRealmRoles().then((res) => {
          
            if (res) {
               
                dispatch({ type: "setRealmRoles", payload: res })
            }
        })
    }, [])


    const getRoles =  (row) => {
        let rtn = "";
        row.attributes.roles.forEach((role) => { rtn += role + ", " })
        if (rtn.length >= 2) rtn = rtn.slice(0, -2);
        return rtn;
    }

    const getDepts = (row) => {
        let rtn = "";
        row.attributes.departments.forEach((dept) => { rtn += dept + ", " })
        if (rtn.length >= 2) rtn = rtn.slice(0, -2);
        return rtn;
    }

    const getUserCode = (user, users) => {
        if (!users) return "";
        let code = "";
        let fullName = user.firstName + "'" + user.lastName
        let parts = fullName.split("'");
        parts.forEach((part) => {
            if (part.length > 0) code += part[0].toUpperCase();
        })
        return code;
    }

    const getName = (row) => {
        let name = row.firstName + " " + row.lastName
        if (name.split(" ").length <= 1) return row.username
        else return name;
    }

    const columns = [
        { selector: (row) => getName(row), sortable: true, name: t("name_label"), type: "text", key: "name" },
        { selector: (row) => row.username, sortable: true, name: t("Username"), type: "text", key: "username" },
        { selector: (row) => getRoles(row), sortable: true, name: t("role_label"), type: "text", key: "roles" },
        { selector: (row) => row.attributes.userCode, sortable: true, name: t("code_field_label"), type: "text", key: "userCode" },
        { selector: (row) => row.email, sortable: true, name: t("email_label"), type: "text", key: "email" },
        { selector: (row) => getDepts(row), sortable: true, name: t("department_label"), type: "text", key: "departmentName" },
    ];


    const getUsers = async (callback) => {
        let users = await getRealmUsers();
        if (users && users.length > 0) {
            users.forEach((user) => {
                if (!user.firstName) user.firstName = "";
                if (!user.lastName) user.lastName = "";
                if (!user.email) user.email = "";
                if (!user.attributes) user.attributes = {};
                if (!user.attributes.departments) user.attributes.departments = []
                if (!user.attributes.roles) user.attributes.roles = []
                if (!user.attributes.userCode) user.attributes.userCode = [getUserCode(user, users)];
            })
            dispatch({ type: "setAllUsers", payload: users })
            setRows(users);
        }
        else {
           
            setRows([])
        } 
        callback();
    }


   


    const handleDelete = async (selected) => {
        alert("This operation is restricted to SUPER ADMIN users.\n")
    }

    return <GenericTable
        dense={dense}
        keyColumn={{
            key: "name",
            name: t("name_label")
        }}
        setDense={setDense}
        updateTable={getUsers}
        Dialog={PersonnelDialog}
        rows={rows}
        columns={columns}
        text={text}
        deleteCallback={handleDelete} />;
}

export default connect()(Personnel);
