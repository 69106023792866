import React from 'react';
import ReactDOM from 'react-dom';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import UserService from './keycloak';
import HttpService from './services/HttpService';
import languages from "./languages.json"





i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: languages,
    language: localStorage.getItem("language") || "en",
    fallbackLng: localStorage.getItem("language") || "en",

    interpolation: {
      escapeValue: false
    }
  });


const renderApp = (authenticated) => {
      return ReactDOM.render(
          <App />, document.getElementById('root')
      );
 
}

UserService.initKeycloak(renderApp);
HttpService.configure();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
