import { Paper,  CircularProgress } from '@material-ui/core';
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, LineController, LineElement, PointElement, LinearScale, Title, Filler } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation'
import { useTranslation } from 'react-i18next';

ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title, Filler, annotationPlugin);




// Graph colours (Border & background)
const Color = require('color');

let colors = [
    Color('#FF0000'), Color('#FF8C00'), Color('#FFD700'), Color('#008000'),
    Color('#0000FF'), Color('#40E0D0'), Color('#FF1493'), Color('#808000'),
    Color('#008080'), Color('#800080'), Color('#3CB371'), Color('#A0522D'),
    Color('#20B2AA'), Color('#4B0082')
]

// Creating some additional colours.
let temp = colors.map((c) => { return c.negate() })  
colors = colors.concat(temp);

const graphBorders = colors.map((c) => { return c.hexa() })
const graphBackgrounds = colors.map((c) => { return c.alpha(0.2).hexa() })


// Some 'state' variables used by the main function.
let scales = {}
let scalesShown = new Array(500).fill(false);
scalesShown[0] = true;
let testMethod = null;
let selectedItem = null;
let annotations = {}

// Helper function. Resets to initial state.
const reset = () => {
    scales = {}
    scalesShown = new Array(500).fill(false);
    scalesShown[0] = true;
    testMethod = null;
    selectedItem = null;
    annotations = {};
}


// Helper function. Transposes a 2D array.
const transpose = (mat) => {
   
    var rtn = [];
    for (var i = 0; i < mat[0].length; i++) {
        var temp = [];
        for (var j = 0; j < mat.length; j++) {
            temp.push(mat[j][i]);
        }
        rtn.push(temp);
    }
    return rtn;
    

}

const splice = (arr1, arr2) => {
   var result = [arr1, arr2]
        .reduce((r, a) => (a.forEach((a, i) => (r[i] = r[i] || []).push(a)), r), [])
        .reduce((a, b) => a.concat(b));
    return result
}



// Main graph generating function. Creates graph datasets and graph options, then uses the 
// setGraphData & setGraphOptions callbacks to apply them to the Line graph component.

export function generateChartData(tests, results, setGraphData, setGraphOptions, testMethodName, specs, callback, t) {  //
    if (testMethodName !== testMethod) {
        reset();
        testMethod = testMethodName;
    }

    
    
    var sampleNames = []

    let sampleResults = results.map((r) => {
        let values = [];
        sampleNames.push(r.sample.uniqueId)
        tests.forEach((test) => {
            var resultObj = r.results.find((res) => { return res.testId === test.id })
            if (resultObj) {
                let value = resultObj.hasOwnProperty('value') ? resultObj.value : null;
                if (resultObj.hasOwnProperty('result') && !resultObj.ignore)
                {
	                values.push(value);
                }
                else 
                {
					values.push(null);
				}
            }
            else values.push(null);
        })
        return values;
    });
    
    sampleResults = transpose(sampleResults);

    //if (Object.keys(annotations).length === 0) {
    //    tests.forEach((test) => {
    //        var minUnit = specs[test.id] ? specs[test.id].minUnit ? specs[test.id].minUnit : 0 : 0;
    //        var maxUnit = specs[test.id] ? specs[test.id].maxUnit ? specs[test.id].maxUnit : 0 : 0;

    //        annotations["min_" + test.id] = {
    //            type: 'line',
    //            yMin: minUnit,
    //            yMax: minUnit,
    //            borderColor: 'rgb(255, 99, 132)',
    //            borderWidth: 2,
    //            drawTime: null
    //        }

    //        annotations["max_" + test.id] = {
    //            type: "line",
    //            yMin: maxUnit,
    //            yMax: maxUnit,
    //            borderColor: 'rgb(255, 99, 132)',
    //            borderWidth: 2,
    //            drawTime: null
    //        }

    //    })
    //}
    

    const roundTo2 = (num) => {
        return Math.round(num * 100) / 100
        //return num;
    }

    var datasets = tests.map((test, idx) => {

        var temp = sampleResults[idx].filter((v) => { return v !== null })

        var max = temp.length > 0 ? Math.max(...temp) : 0
        var min = temp.length > 0 ? Math.min(...temp) : 0
        
        var stepSize = roundTo2((max - min) / 10)
//        console.log(JSON.stringify({max, min, stepSize}, null, 2))

        
        var minUnitSpecified = specs[test.id] && specs[test.id].minUnit
        var maxUnitSpecified = specs[test.id] && specs[test.id].maxUnit

        var minUnit = minUnitSpecified ? specs[test.id].minUnit : 0;
        var maxUnit = maxUnitSpecified ? specs[test.id].maxUnit : 0;

        if (minUnitSpecified && (min >= minUnit)) {
            min = minUnit - (stepSize * 2);
        } 
        else {
            min = min -= stepSize
        }
        if (maxUnitSpecified && (max <= maxUnit)) {
            max = maxUnit + (stepSize * 2);
        } 
        else {
            max = max += stepSize
        }
        
     
        console.log()


       
      

        return {
            label: test.name,
            data: sampleResults[idx],
            dataType: test.testDataType,
            fill: true,
            hidden: !scalesShown[idx],
            borderDash: [10, 5],
            borderWidth: 1,
            lineTension: 0.2,
            pointBackgroundColor: graphBorders[idx],
            borderColor: graphBorders[idx],
            backgroundColor: graphBackgrounds[idx],
            yAxisID: `y${idx}`,
            testId: test.id,
            scale: test.testDataType === "Pass/Fail" ? {

                id: `y${idx}`,
                max: 1,
                min: 0,
                display: scalesShown[idx],
                title: {
                    display: true,
                    text: t("Result")
                },
                ticks: {
                    stepSize : stepSize,
                    callback: (value) => {
                        if (value === 1) return 'Pass';
                        else if (value === 0) return 'Fail';
                        else return '';
                    }
                }
        
            } :
            {
                    id: `y${idx}`,
                    max: max,
                    min: min,
                    display: scalesShown[idx],
                    title: {
                        display: true,
                        text: t("Result")
                    },
                    ticks: {
                        stepSize: stepSize,
                        beginAtZero: false,
                        callback: (value) => {
                            return `${roundTo2(value)}`;
                        }
                    }

            }

        }
    })



    
    const minDatasets = datasets.map((ds, idx) => {
    
       
        var minUnitSpecified = specs[ds.testId] && specs[ds.testId].minUnit
        var minUnit = minUnitSpecified ? specs[ds.testId].minUnit : Number.MIN_SAFE_INTEGER;
       
        
        return {
            
            label: "Min",
            data: sampleResults[0].map((e, idx) => { return minUnit }),
            fill: false,
            hidden: (!scalesShown[idx]) || ds.dataType === "Pass/Fail" || sampleResults[idx].filter((v) => { return v !== null }).length === 0,
            borderWidth: 2,
            lineTension: 0.2,
            borderDash: [5, 5],
            animation: { duration: 0 },
            pointBackgroundColor: "grey",
            borderColor: "blue",
            pointStyle: 'line',
            yAxisID: `yMin${idx}`,
            scale: {
                id: `yMin${idx}`,
                max: ds.scale.max,
                min: ds.scale.min,
                position: 'right',
                display: scalesShown[idx],
               
                ticks: {
                    stepSize: ds.scale.stepSize,
                    callback: (value) => {
                       
                        return '';
                    }
                }

            }
        }
    })

    const maxDatasets = datasets.map((ds, idx) => {

        var maxUnitSpecified = specs[ds.testId] && specs[ds.testId].maxUnit
        var maxUnit = maxUnitSpecified ? specs[ds.testId].maxUnit : Number.MAX_SAFE_INTEGER

        return {

            label: "Max",
            data: sampleResults[0].map((e, idx) => { return maxUnit }),
            fill: false,
            hidden: (!scalesShown[idx]) || sampleResults[idx].filter((v) => { return v !== null }).length === 0,
            borderWidth: 2,
            lineTension: 0.2,
            borderDash: [5, 5],
            animation: { duration: 0 },
            pointBackgroundColor: "grey",
            borderColor: "orange",
            pointStyle: 'line',
            yAxisID: `yMax${idx}`,
            scale: {
                id: `yMax${idx}`,
                max: ds.scale.max,
                min: ds.scale.min,
              
                display: false,
               
                ticks: ds.scale.ticks
            }
        }
    })

  

    datasets = datasets.concat(minDatasets).concat(maxDatasets)

    datasets.forEach((ds) => {
        scales[ds.yAxisID] = ds.scale;
    })


    let graphData = {
        labels: sampleNames,
        datasets: datasets
    }

    if (selectedItem === null && graphData.datasets.length > 0) selectedItem = graphData.datasets[0].label
 

    // X-axis scale.
    scales['x'] = { title: { display: true, text: t("Sample") } }


    // This function hides all charts except for the one whose legend label was clicked.
    const onLegendClick = (e, legendItem, legend) => {
       
        e.native.target.style.cursor = 'default'
        selectedItem = legendItem.text;
        const index = legendItem.datasetIndex;
        const chart = legend.chart;
        let scaleIDs = Object.keys(chart.options.scales);

        chart.data.datasets.forEach((ds, i) => {
            if (i >= tests.length) return;
            let shown = i === index;
            chart.getDatasetMeta(i).hidden = !shown;
            chart.options.scales[scaleIDs[i]].display = shown;
            scalesShown[i] = shown;
        })

       
        
        chart.update();
        callback();
    }


    // Show a pointer cursor when hovering over unselected legend labels.
    const onLegendHover = (e, legendItem) => {
        selectedItem === legendItem.text ?
            e.native.target.style.cursor = 'default' :
            e.native.target.style.cursor = 'pointer';
    }

    const onLegendLeave = (e) => { e.native.target.style.cursor = 'default'; }

    // Custom legend labels.
    const getLegendLabels = (chart) => {
        return chart.data.datasets.map((ds, i) => {
            return {
                text: ds.label,
                fillStyle: chart.isDatasetVisible(i) ? ds.backgroundColor : "white",
                strokeStyle: chart.isDatasetVisible(i) ? ds.borderColor : "grey",
                fontColor: chart.isDatasetVisible(i) ? ds.borderColor : "grey",
                lineWidth: 2,
                datasetIndex: i
            }
        })
    }


   
    

   
    const options = {
        responsive: true,
        scales: scales,
        animation: { duration: 350 },
        
        plugins: {
            legend: {
                position: 'top',
                onHover: onLegendHover,
                onLeave: onLegendLeave,
                onClick: onLegendClick,
                labels: {
                    filter:  (legendItem, chartData) => {
                        if (legendItem.text === "Max" || legendItem.text === "Min") return false;
                        else return true;
                    },
                    boxWidth: 15, boxHeight: 15, 
                    usePointStyle: false,
                    generateLabels: getLegendLabels
                }
            },           
            title: {
                display: true,
                text: tests.length > 1 ? `${tests.length} ${t("in total:     ( Click on label to select chart )")}` :
                    `${tests.length} ${t("testing_label")}:`,
            },
            annotation: {
                annotations: {}
            }
        },
        
    }

    setGraphData(graphData)
    setGraphOptions(options)
    //return { graphData, options}
}




const ResultsCharts = ({ graphData, graphOptions, testMethod }) => {
    const { t } = useTranslation();

    return (<>
        {graphData !== null ?
            <Paper style={{ width: "100%" }}>
                <div style={{ textAlign: "center", paddingTop: "20px" }}> {t("Result chart(s) for")}: <b>{testMethod}</b>.</div>
                <div><Line data={graphData} options={graphOptions} /></div>
                <div style={{ textAlign: "center" }}><span style={{ color: "blue", fontSize: "small" }}><b>---  {t("min")}.</b></span>
                    <span style={{ color: "orange", fontSize: "small", marginLeft: "15px" }}><b>---  {t("max")}.</b></span></div>
            </Paper> :
            <div style={{ textAlign: "center", marginBottom: "50px" }}><p>{t("Loading")} ...</p> <CircularProgress style={{ marginBottom: "50px" }} /></div>}
        </>
    )
}


export default ResultsCharts;


