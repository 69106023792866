import HttpService from "./HttpService";
const axios = HttpService.getAxiosClient();
const methods = HttpService.HttpMethods;
const baseURL = process.env.REACT_APP_BACKEND_URL;
const rootRequestMapping = "testing/jobs";


export const getJobs = async () => {
    try {
        const req = {
            url: `${baseURL}${rootRequestMapping}/list`,
            method: methods.GET,
        }
        const res = await axios(req);
        return res.data;
    } catch (err) {
        return { req_error: err }
    }
};



//export const openJob = async (jobId) => {
//    try {
//        const req = {
//            url: `${baseURL}${rootRequestMapping}/open/${jobId}`,
//            method: methods.GET,
//        };
//        const res = await axios(req);
//        return res.data;

//    } catch (err) {
//        return { req_error: err }
//    }
    
//};


