import { Box, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',
        width: "100%",
        height: "100%",
        flexDirection: "column",
        color: grey[600],
        rowGap: "2rem"
    },
    icon: {
        width: 150,
        height: 150
    }
});

function ErrorPage(props) {
    let { text, Icon } = props;
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Icon className={classes.icon} />
            <Typography variant="h5">
                {text}
            </Typography>
        </Box>
    )
}

export default ErrorPage;