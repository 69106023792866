import HttpService from "./HttpService";
const axios = HttpService.getAxiosClient();
const HttpMethods = HttpService.HttpMethods;
const baseURL = process.env.REACT_APP_BACKEND_URL;
const rootRequestMapping = "dashboard";


const getMeanCompletionTime = async () => {
    try {
      const res = await axios.request({
        url: `${baseURL}${rootRequestMapping}/meancompletiontime`,
        method: HttpMethods.GET,
      });
  
      return (res.status === 200) ? res.data : false;
    } catch (error) {
      return false;
    }
  }

  const getTopReasons = async () => {
    try {
      const res = await axios.request({
        url: `${baseURL}${rootRequestMapping}/topreasons`,
        method: HttpMethods.GET,
      });
  
      return (res.status === 200) ? res.data : false;
    } catch (error) {
      return null;
    }
  }

  const getMostUsedTests = async () => {
    try {
      const res = await axios.request({
        url: `${baseURL}${rootRequestMapping}/testpopular`,
        method: HttpMethods.GET,
      });
  
      return (res.status === 200) ? res.data : false;
    } catch (error) {
      return null;
    }
  }

  const getRiskiestTests= async () => {
    try {
      const res = await axios.request({
        url: `${baseURL}${rootRequestMapping}/testrisky`,
        method: HttpMethods.GET,
      });
  
      return (res.status === 200) ? res.data : false;
    } catch (error) {
      return null;
    }
  }

  const getJobsOverview = async () => {
    try {
      const res = await axios.request({
        url: `${baseURL}${rootRequestMapping}/jobstatusoverview`,
        method: HttpMethods.GET,
      });
  
      return (res.status === 200) ? res.data : false;
    } catch (error) {
      return null;
     }

}


const getAnalyticsData = async (data) => {
    try {
        const res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/analytics`,
            method: HttpMethods.POST,
            data: data
        });

        return (res.status === 200) ? res.data : false;
    } catch (error) {
        return null;
    }

}

const getMRule = async (year) => {
    try {
        const res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/mrule/${year}`,
            method: HttpMethods.GET,
        });

        return res.data
    } catch (error) {
        return null;
    }

}

const getTestsCount = async (year) => {

    try {
        const res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/testscompleted/${year}`,
            method: HttpMethods.GET,
        });

        return res.data
    } catch (error) {
        return null;
    }


}

const getSamplesReceived = async (year) => {

    try {
        const res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/samples/${year}`,
            method: HttpMethods.GET,
        });

        return res.data
    } catch (error) {
        return null;
    }
}


const getSchedule= async (year) => {

    try {
        const res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/main`,
            method: HttpMethods.GET,
        });

        return res.data
    } catch (error) {
        return null;
    }


}




export { getSchedule,getMeanCompletionTime, getTopReasons, getMostUsedTests, getRiskiestTests, getJobsOverview, getAnalyticsData, getMRule, getTestsCount, getSamplesReceived }