/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Box, makeStyles, Button, TextField, Typography, Divider, withStyles, Card} from '@material-ui/core';
import { ListAlt } from "@material-ui/icons";
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import PrivateRoute from '../KeyCloakComponents/PrivateRoute';
import {NavLink } from "react-router-dom";
import Testing from './Testing';
import { getMeanCompletionTime, getTopReasons, getMostUsedTests, getRiskiestTests, getJobsOverview } from '../../services/DashboardService';
import DashboardJobs from '../DashboardJobs';
import DashboardAnalytics from './DashboardAnalytics';
import DashboardHome from './DashboardHome';
import CircularProgress from '@mui/material/CircularProgress';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {  CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { useTranslation } from 'react-i18next';
import UserService from "../../keycloak";
import AnalyticsPage from '../Analytics/AnalyticsPage';
import KPIDashboard from '../Analytics/KPIDashboard'
import ExcelLinkPage from '../Reports/ExcelLinkPage'

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title,);

const useStyles = makeStyles(theme =>({
    root: {
        marginBottom: theme.spacing(2),
    },
    pageHeading: {
        display: "flex",
        flex: 1,
        justifyContent: 'center',
        alignItems: "center",
        marginBottom:'2%'
    },
    title: {
        // flex: "1",
        whiteSpace: "nowrap",
        marginBottom: "1rem",
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center'
    },
    paper: {
        marginBottom: theme.spacing(2),
        padding:"15px"
    },
   
}));

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other} >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


const Dashboard = (props) => {
    const classes = useStyles();
    const [alertContext, setAlertContext] = useContext(AlertContext);
    const [page, setPage] = useState(1);
    const [turnAroundTime, setTurnAroundTime] = useState(0);
    //const [topReasons, setTopReasons] = useState([]);
    //const [mostPopularTests, setMostPopularTests] = useState([]);
    //const [riskiestTests, setRiskiestTests] = useState([]);
    const [jobStatusOverview, setJobStatusOverview] = useState([]);
    const colors = ['#348ae6', '#f6f607', '#f19c08', '#11a211', '#6bbc6b', '#f61369']
    const [tabValue, setTabValue] = useState(0);
    const [showDownloadPage, setShowDownloadPage] = useState(false);
    const [jobId, setJobId] = useState(null);
    const [jobName, setJobName] = useState(null);
    const { t, i18n } = useTranslation()


    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.get("downloads") === "true") {
            var jobid = params.get("jobId");
            var name = params.get("name");
            if (jobid && name) {
                name = decodeURIComponent(name);
                setJobId(jobid)
                setJobName(name);
                setShowDownloadPage(true);
                return;
            } 
        }

        const getTurnAroundTime = async () => {
          let data = await getMeanCompletionTime();
          if (!data) {
            setAlertContext({ ...alertContext, open: true });
            return;
          }
          let convertedFromMilliSeconds = (data.value/1000/60/60/24).toFixed(2)
          setTurnAroundTime(convertedFromMilliSeconds);
        };
    

        const getJobsStatus = async () => {
            let data = await getJobsOverview();
            if (!data) {
                setAlertContext({ ...alertContext, open: true });
                return;
            }
            setJobStatusOverview(data);
        };

        getTurnAroundTime();
        //getPopularReasons();
        //getPopularTests();
        //getMostFailingTests();
        getJobsStatus();

      }, []);

    
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    if (showDownloadPage) return <ExcelLinkPage jobId={jobId} name={jobName} />
    else return (
        <Card style={{ padding: "15px", }}>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} textColor="secondary" indicatorColor="secondary" onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Daily Dashboard" />
                    <Tab label="KPI Dashboard" />
                    <Tab label="Analytics Dashboard" />
                </Tabs>
            </Box>


            <TabPanel value={tabValue} index={0}>
                <div className={classes.root}>
                    <DashboardHome classes={classes} colors={colors} turnAroundTime={turnAroundTime} jobStatusOverview={jobStatusOverview} />
                </div>
            </TabPanel>
            
            <TabPanel value={tabValue} index={1}>
                <KPIDashboard/>
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
                <AnalyticsPage />
            </TabPanel>

        
        </Card>
        
    )
}




export default withRouter(Dashboard);