import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslation } from "react-i18next";
import { pink, yellow } from "@material-ui/core/colors";
import { Box, Button, Tooltip, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DataTable from "react-data-table-component";
import FilterDialog from "../Dialogs/FilterDialog";
import FilterListIcon from "@material-ui/icons/FilterList";
import TestDialog from "../Dialogs/TestDialog";
import OKDialog from "../Dialogs/OKDialog";
import CircularProgress from '@mui/material/CircularProgress';

const customStyles = {
  headCells: {
    style: {
      fontSize: ".7rem"
    },
  },
  cells: {
    style: {
      fontSize: ".7rem"
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  paper: {
    marginBottom: theme.spacing(2),
  },

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableHeader: {
    display: "flex",
    alignItems: "center",
    columnGap: "1rem",
    paddingTop: "1rem",
    overflow: "visible",
    marginBottom: "0.3rem",
  },
  selectedCount: {
    flex: "2 2 90%",
  },
  title: {
    flex: "1 1",
    whiteSpace: "nowrap",
  },
  editButton: {
    backgroundColor: yellow[800],
    borderColor: yellow[800],
    color: "white",
    "&:hover": {
      backgroundColor: yellow[900],
    },
  },
  filterButton: {
    
    backgroundColor: pink[600],
    borderColor: pink[600],
    color: "white",
    "&:hover": {
      backgroundColor: pink[700],
    },
  },
  searchBar: {
    flex: "1 1 30%",
  },
}));

function AnalysisTemplatesTable(props) {
  const {
    text,
    Dialog,
    tableProps,
    openTestDialog,
    onTestDialogClose,
    updateTable,
    paginationPerPage,
    columns,
    rows,
    selectedRow,
    deleteCallback,
    dense,
    setDense,
    keyColumn,
    parentAdd,
    parentEdit,
    selectedItem,
    useDialog
  } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [columnFilters, setColumnFilters] = useState({});
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const { t } = useTranslation();

  const [OKDialogState, setOKDialogState] = useState({
    shown: false, title: "", message: "", flavour: "success"
    })

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const onDialogClose = (event) => {
    if(useDialog == null){
      setOpenDialog(false);
      setOpenEdit(false);
      if(event) handleStatusCode(event);
    }
    else{
      parentAdd[1](false);
      parentEdit[1](false);
    }
  };

  const handleStatusCode = (event) =>{
    switch (event.status){
      case 200:
        console.log("!!!!!!!!!!!!!!!!!!!!")
        setOKDialogState({
          shown: true, 
          title: "Saved Completed",
          message: event.data.name? event.data.name + ` saved successfully`: event.data.templateName + ` saved successfully`,
          flavour: "success", 
          // callback: revisitPage1
        })
        break;
      default:
        setOKDialogState({
          shown: true, 
          title: "Failure",
          message:  `failed to save, try later`,
          flavour: "error", 
          // callback: revisitPage1
        });
        break;
       
    }
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };

  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleClearSelectedRows = () => {
    setClearSelectedRows(!clearSelectedRows);
  };
  
  const clearOptions = () => {
    setSelected([]);
    handleClearSelectedRows();
  };

  const handleDelete = async () => {
    await deleteCallback(selected);
    updateTable(clearOptions);
  };

  const handleEdit = () => {
    if(useDialog == null){
      setOpenDialog(true);
      setOpenEdit(true);
    }
    else{
      selectedItem[1](selected[0]);
      parentAdd[1](true);
      parentEdit[1](true);
    }  
  };

  const handleAdd = () => {
    if(useDialog == null)
      setOpenDialog(true);
    else
        parentAdd[1](true);
  };

  const selectedItemText = () => {
    if (selected.length === 0) return "";
    if (selected.length === 1) return `1 ${t("one_row_selected")}`;
    if (selected.length > 1 && selected.length < rows.length)
      return `${selected.length} ${t("rows_selected")}`;
    if (selected.length === rows.length) return t("all_rows_selected");

    return "";
  };

  useEffect(() => {
    updateTable(clearOptions);
  }, []);

  const filterTerm = (event) => setSearchTerm(event.target.value);

  const rangeFilter = (greaterThan, value, lessThan) => {
    if (value >= greaterThan && value <= lessThan) return true;

    return false;
  };

  const filterRows = () => {
    let res = rows.filter((row) => {
      if (!`${row[keyColumn.key]}`.toLowerCase().includes(searchTerm.toLowerCase()))
        return false;

      let isValid = true;
      
      Object.keys(columnFilters).forEach((filterKey) => {
        let filter = columnFilters[filterKey];
        if (!filter.enabled) return;

        if (filter.type === "text") {
          //console.log(progress)

          //if (columnFilters[filterKey].name == "Category Name" && columnFilters[filterKey].filterValue.includes != ""){
            //if(!row.category.name.toLowerCase().includes(filter.filterValue.includes.toLowerCase())){
              //isValid = false;
            //}
            //return;

          //} else if (columnFilters[filterKey].name == "Progress" && columnFilters[filterKey].filterValue.includes != ""){
            //var progress = Math.floor((row.testsCompleted / (row.numberOfTests === null ? 0 : row.numberOfTests)) * 100)
            //console.log("row progress: ", progress, " | filter progress: ", filter.filterValue.includes)
            //if(progress != filter.filterValue.includes){
              //isValid = false;
            //}
           //return;

          //} else if (columnFilters[filterKey].name == "Client" && columnFilters[filterKey].filterValue.includes != ""){
            //if(!row.client.name.toLowerCase().includes(filter.filterValue.includes.toLowerCase())){
              //isValid = false;
            //}
            //return;

          //} else if (columnFilters[filterKey].name == "Department" && columnFilters[filterKey].filterValue.includes != ""){
            //if(!row.department.name.toLowerCase().includes(filter.filterValue.includes.toLowerCase())){
              //isValid = false;
            //}
            //return;
            
          //} else {
          if(!`${row[filterKey]}`.toLowerCase().includes(filter.filterValue.includes.toLowerCase())){
            isValid = false;
          }
          return;
        //}
    
        } else if (filter.type === "numeric") {
          if (
            !rangeFilter(
              filter.filterValue.greaterThan,
              row[filterKey],
              filter.filterValue.lessThan
            )
          )
            isValid = false;
          return;
        } else if (filter.type === "date") {
          if (
            !rangeFilter(
              filter.filterValue.from,
              new Date(row[filterKey]),
              filter.filterValue.to
            )
          )
            isValid = false;
          return;
        }
      });

      return isValid;
    });
    return res;
  };
  
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Box mx={2} className={classes.tableHeader}>
          <Typography
            className={classes.title}
            style={{ fontWeight: 400, fontSize: "22px" }}
          >
            {text}
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', flex: '20%' }}>
            <Tooltip title={ t("add") + " " + (text.substring(text.length-1)==="s"? text.substring(0,text.length-1): text)} >
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={handleAdd}
                aria-label="add"
                startIcon={<AddIcon  style={{marginLeft: "30%"}}/>}
              >     
              { t("add") } {text.substring(text.length-1)==="s"? text.substring(0,text.length-1): text} 
              </Button>
            </Tooltip>
          </div>

          <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', flex: '5%' }}>
            <Tooltip title={t("filter_label")}>
              <Button
                variant="contained"
                size="small"
                onClick={handleFilterDialogOpen}
                className={classes.filterButton}
                aria-label="add"
                startIcon={<FilterListIcon  style={{marginLeft: "30%"}}/>}
              >      
              {/* t("filter_label") */}  
              </Button>
              </Tooltip>
          </div>
          <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', flex: '5%' }}>
            <Tooltip title="Edit">
            <span disabled={!(selected.length > 0)}>
              <Button
                disabled={selected.length !== 1}
                size="small"
                className={classes.editButton}
                onClick={handleEdit}
                startIcon={<EditIcon  style={{marginLeft: "30%"}}/>}
                aria-label="edit"
                variant="contained"
              >    
              {/* t("filter_label") */}          
              </Button>
              </span>
            </Tooltip>
          </div>
          <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', flex: '5%' }}>
            <Tooltip title="Delete">
            <span disabled={!(selected.length > 0)}>
              <Button
                disabled={!(selected.length > 0)}
                size="small"
                onClick={handleDelete}
                aria-label="delete"
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon style={{marginLeft: "30%"}}/>}
              >
                {/* t("delete_label") */}  
              </Button>
              </span>
            </Tooltip>
          </div>
          <Typography
            className={classes.selectedCount}
            style={{ color: "grey", fontSize: "16px" }}
          >
            {selectedItemText()}
          </Typography>
          <div className={classes.searchBar}>
            <TextField
              label={`${t("search_label")} ${keyColumn.name}`}
              id="outlined-size-small"
              value={searchTerm}
              onChange={filterTerm}
              variant="outlined"
              fullWidth
              size="small"
              dense
            />
          </div>
          
        </Box>

        <DataTable
          selectableRows
          pagination 
          openDialog={
            (<Dialog />).type === (<TestDialog />).type
              ? openTestDialog
              : openDialog
          }
          paginationPerPage={
            typeof paginationPerPage !== 'undefined'
              ? paginationPerPage
              : 90
          }
          customStyles={customStyles}
          striped
          {...tableProps}
          onSelectedRowsChange={(e) => setSelected(e.selectedRows)}
          columns={columns}
          data={filterRows()}
          dense={dense}
          noDataComponent={<div style={{ textAlign: "center", marginBottom: "20px" }}><p>{t("Loading table data")}...</p> <CircularProgress style={{ marginBottom: "50px" }} /></div>}
          paginationRowsPerPageOptions={[30, 60, 90, 120]}
          clearSelectedRows={clearSelectedRows}
          paginationComponentOptions={{
            rowsPerPageText: t("rows_per_page_label"),
            rangeSeparatorText: t("range_seperator_label"),
          }}
        />

      </Paper>
      {/*<FormControlLabel*/}
      {/*  control={<Switch checked={dense} onChange={handleChangeDense} />}*/}
      {/*  label="Dense padding"*/}
      {/*/>*/}
      <Dialog
        openDialog={
          (<Dialog />).type === (<TestDialog />).type
            ? openTestDialog
            : openDialog
        }
        onDialogClose={
          (<Dialog />).type === (<TestDialog />).type
            ? onTestDialogClose
            : onDialogClose
        }
        selectedItems={selected[0]}
        viewing={null}
        parentRow={selectedRow}
        openEdit={openEdit}
        updateTable={() => updateTable(clearOptions)}
      />
      <FilterDialog
        openDialog={openFilterDialog}
        columns={columns}
        handleFilterDialogClose={handleFilterDialogClose}
        keyColumn={keyColumn}
        setColumnFilters={setColumnFilters}
      />
      <OKDialog
                show={OKDialogState.shown}
                title={OKDialogState.title}
                message={OKDialogState.message}
                flavour={OKDialogState.flavour}
                callback={
                    (res) => {
                        let callback = OKDialogState.callback;
                        setOKDialogState({ shown: false });
                        if (callback) callback(res);
                    }
                }
            />
    </div>
  );
}

export default AnalysisTemplatesTable;
