/* eslint-disable react-hooks/exhaustive-deps */


import { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import { Button, Tooltip } from "@material-ui/core";
import { getTestByTestMethodId } from "../../services/TestsService";
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import OKDialog from "../Dialogs/OKDialog";
import { useTranslation } from "react-i18next";

const TestMethodTestsDLG = (props) => {

    const { testMethodId, testMethodName, callback, enabled, checkedTests, numberSelected } = props;
    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalTests, setTotalTests] = useState(0);
    const { t } = useTranslation();

    const spinner = <div style={{ marginTop: "20px" }}>
        <CircularProgress size="1rem" />
        <span style={{ marginLeft: "10px" }}>Loading tests...</span>
    </div>

    const getIsChecked = (r) => {
        if (checkedTests) {
            var x = checkedTests.find((t) => { return t.id === r.id })
            if (x) return true;
            else return false
        }
        else return true;
    }

    const [OKDialogState, setOKDialogState] = useState({
        shown: false, title: "", message: "", flavour: "success"
    })

    useEffect(() => {
        //while (rows.length > 0) rows.pop()
        setLoading(true)
        getTestByTestMethodId(testMethodId).then((res) => {
            setLoading(false)
            if (res) {
                setRows(
                    res.map((r) => {
                        r.checked = getIsChecked(r);
                        return r;
                    })
                )
                setTotalTests(res.length)

            }
        })
    }, [open])

    

    const saveSelectedRows = () => {
        var rtn = rows.filter((r) => { return r.checked });
        if (rtn.length === 0) {
            setOKDialogState({
                shown: true, title: t("No tests selected!"),
                message: t(`A test method is redundant if it contains no tests. Please select at least one.`),
                flavour: "error", callback: () => {}
            })
            return;

        }
        setRows([])
        rtn.forEach((r) => { delete r.checked })
        callback(rtn);
        setOpen(false);
    }



    useEffect(() => {
        console.log(JSON.stringify(rows.map((r) => { return r.checked })))
    }, [rows])


    return (
        <>
            {enabled ? <button style={{ color: numberSelected === 0 ? "red" : "black" }} onClick={() => { setOpen(true) }}>{numberSelected} of {totalTests} Tests</button> : <button disabled >0 Tests</button>}
            <Dialog
                open={open}
                fullWidth={true} maxWidth={'md'}
                onClose={(e, reason) => { if (reason && reason === "backdropClick") return; }}>
                <DialogTitle>Tests for: {testMethodName}
                    <div style={{ fontSize: "small" }}>Deselect the tests you don't wish to include and click apply below:</div>
                    <div style={{ position: 'absolute', top: "15px", right: "20px" }}>
                        <Tooltip title="Cancel">
                            <span style={{ cursor: 'pointer', color: 'red' }} onClick={() => { setOpen(false) }}>
                                <CancelIcon />
                            </span>
                        </Tooltip>
                    </div>

                </DialogTitle>

                <DialogContent>

                    {loading ? spinner : <table style={{ width: "100%", textAlign: "left" }}>
                        <tr>
                            <th>Selected</th>
                            <th>Name</th>
                            <th>Desciption</th>
                        </tr>
                        {rows.length > 0 ? rows.map((row) => {
                            return <tr>
                                <td><Checkbox
                                    onChange={
                                        (e) => {
                                            row.checked = e.target.checked
                                            setRows(JSON.parse(JSON.stringify(rows)))
                                        }
                                    }
                                    checked={row.checked} /></td>
                                <td>{row.name}</td>
                                <td>{row.description}</td>
                            </tr>

                        }) : "No tests available for this method."}
                    </table>}
                </DialogContent>
                <DialogActions>
                    {loading ? null : <div style={{ marginTop: "15px", padding: "15px" }}>
                        <Button variant="contained" onClick={saveSelectedRows}>Apply</Button>
                        <span style={{ marginLeft: "10px" }}><Button variant="contained" onClick={() => { setOpen(false) }}>Cancel</Button></span>
                    </div>}

                </DialogActions>
            </Dialog>

            <OKDialog
                show={OKDialogState.shown}
                title={OKDialogState.title}
                message={OKDialogState.message}
                flavour={OKDialogState.flavour}
                callback={
                    (res) => {
                        let callback = OKDialogState.callback;
                        setOKDialogState({ shown: false });
                        if (callback) callback(res);
                    }
                }
            />
        </>
    
    )

}

export default TestMethodTestsDLG