import HttpService from "./HttpService";
const axios = HttpService.getAxiosClient();
const methods = HttpService.HttpMethods;
const baseURL = process.env.REACT_APP_BACKEND_URL;
const rootRequestMapping = 'department'

const getDepartments = async () => {
  try {
    const res = await axios.request({
      url: `${baseURL}${rootRequestMapping}/all`,
      method: methods.GET
    });
    return res.data;
  }
  catch (error) {
    return null;
  }
}

const checkCode = async (id, code, entity) => {
   
  try {
    let res = await axios.post(`${baseURL}uniquecheck/check`, {
      "id": id,
      "code": code,
      "entity": entity
    })
    console.log(res)
    return res.data;
  }

  catch (error) {
    console.error(error)
  }
}

const getDepartmentByPersonnel = async (id) => {
  let res;
  try {
    res = await axios.request({
      url: `${baseURL}${rootRequestMapping}/personnel/${id}`,
      method: methods.GET
    });
  }
  catch (error) {
    console.error(error);
    return false;
  }

  return (res.status === 200) ? res.data : false;
}

const deleteDepartment = async (id) => {
  try {
    let res = await axios.request({
      url: `${baseURL}${rootRequestMapping}/delete/${id}`,
      method: methods.GET,
    })
  }
  catch (error) {
    console.error(error);
  }
  return false;
}

const addDepartment = async (name, description, code) => {
   
  try {
    let res = await axios.post(`${baseURL}${rootRequestMapping}/save`, {
      "name": name,
      "description": description,
      "code": code
    })
    return res;
  }

  catch (error) {
    console.error(error)
    return error;
  }
}

const updateDepartment = async (name, description, code, id) => {
  try {
    let res = await axios.post(`${baseURL}${rootRequestMapping}/update`, {
      "name": name,
      "description": description,
      "code": code,
      "id": id
    })
    return res;
  }

  catch (error) {
    console.error(error)
    return(error)
  }
}

const canDeleteDepartment = async id => {
  let language = localStorage.getItem("language");
  try{
    let res = await axios.request({
      url: `${baseURL}${rootRequestMapping}/candelete/${id}/${language}`,
      method: methods.GET,
    })
    return res;
  }
  catch(error){
    console.error(error);
  }
  return null;
}



export { getDepartments, addDepartment, updateDepartment, getDepartmentByPersonnel, deleteDepartment, checkCode, canDeleteDepartment }

