/* eslint-disable react-hooks/exhaustive-deps */


import { Bar } from 'react-chartjs-2';
import Paper from '@mui/material/Paper';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react'


ChartJS.register( Tooltip, BarElement, CategoryScale, LinearScale, Legend )

const BarChart = (props) => {

    const { title, data, axisLabels, showLegend, maxWidth  } = props;
    
    const spinner  = <span> Loading chart, please wait... <CircularProgress size="1rem" /></span>
    
    const noResultMessage = <span>No Results</span>

    const [noData, setNoData] = useState(spinner);
    //const []
    const options = {
       
        plugins: { legend: { display: showLegend ? showLegend: false } },
        scales: {
            yAxis: {
                title: {
                    display: true,
                    text: axisLabels && axisLabels.y ? axisLabels.y : ""
                }
            },
            xAxis: {
                title: {
                    display: true,
                    text: axisLabels && axisLabels.x ? axisLabels.x : ""
                }
            },
        }
    }

    useEffect(() => {
        if (data && data.datasets.length === 0 && data.labels.length === 0) {
            setNoData(noResultMessage);
        }
        else {
            setNoData(spinner);
        }
        
       // setTimeout(() => { setNoData("( No data )") }, 1000)
    }, [data])

    const empty = {
        labels: ["( NO DATA )"],
        datasets: []
    }
    return <Paper style={{ padding: "20px" }}>
        <div style={{marginBottom:"20px"}}><b>{title ? title: ""}</b></div>
        {data && data.labels.length > 0 ? <Bar className='analyticsChart' id={title} style={{ maxWidth: maxWidth ? maxWidth : "100%" }} data={data} options={options} /> :
            <Bar style={{ maxWidth: maxWidth ? maxWidth : "100%" }} data={empty} options={options} />}
    </Paper>
}

export default BarChart